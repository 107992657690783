import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ShortageModel } from '../../../_models/shortage-model';

@Component({
    selector: 'app-shortage-table',
    templateUrl: './shortage-table.component.html',
    styleUrls: ['./shortage-table.component.scss']
})
export class ShortageTableComponent implements OnInit {
    displayedColumns: string[] = ['partnerName', 'brandName', 'shortages'];
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    @Input() public dataSource: MatTableDataSource<ShortageModel>;
    private tempFilter: string;

    constructor() {
    }

    ngOnInit() {
        this.dataSource.sort = this.sort;
    }

    applyFilter(filterValue: string) {
        this.tempFilter = filterValue;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

}
