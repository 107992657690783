import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUtils } from '../../../../_utils/api.utils';
import { SearchCriteria } from '../../../../_models/search-criteria.model';


@Injectable()
export class AdminContactsHistoryTabService {

    constructor(private readonly _httpClient: HttpClient) {
    }

    public getContactsHistory(pageParams: SearchCriteria, httpParams: HttpParams = null): Observable<any> {
        let params = SearchCriteria.getRequestParams(pageParams, httpParams);
        return this._httpClient.get(`${ApiUtils.CONTACTS_HISTORY_API_URL}`, {params});
    }


}
