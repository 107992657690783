import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageSeverityEnum } from '../../_enums/message-severity.enum';
import { ContactModel } from '../../_models/contact.model';
import { DictModel } from '../../_models/dict.model';
import { ExternalPersonModel } from '../../_models/external-person.model';
import { PartnerDetailsModel } from '../../_models/partner-details.model';
import { DictionariesService } from '../../_services/dictionaries.service';
import { MessageService } from '../../_services/message.service';
import { ValidationUtils } from '../../_utils/validation.utils';

const reqFields = [
    'name',
    'creationDate',
    'infoSourceId',
    'partnerTypeId',
];

@Component({
    selector: 'app-add-partner-dialog',
    templateUrl: './add-partner-dialog.component.html',
    styleUrls: ['./add-partner-dialog.component.scss']
})
export class AddPartnerDialogComponent implements OnInit {
    public breakpoint: number;
    public pharmacyTypeDict: Array<DictModel>;
    public cooperationStatusDict: Array<DictModel>;
    public gradeDict: Array<DictModel>;
    public infoSourceDict: Array<DictModel>;
    public districtDict: Array<DictModel>;
    public partnerTypeDict: Array<DictModel>;
    public dictChains: Array<DictModel>;
    public filteredChains: Array<DictModel> = [];
    public filteredDistricts: Array<DictModel> = [];
    public showInvalidFields: boolean = false;
    public nullValue: DictModel = {id: null, value: 'Usuń'};


    constructor(@Inject(MAT_DIALOG_DATA) public partnerDetailsModel: PartnerDetailsModel,
                private readonly dictService: DictionariesService,
                private dialogRef: MatDialogRef<AddPartnerDialogComponent>,
                private messageService: MessageService) {
        this.partnerDetailsModel.plainContacts = [new ContactModel(null, null, null, null)];
        this.partnerDetailsModel.peopleContacts = [];
    }

    ngOnInit(): void {
        this.dictService.getDistricts().subscribe((districtDict: Array<DictModel>) => {
            this.districtDict = districtDict;
            this.filteredDistricts = districtDict;
        });
        this.dictService.getCooperationStatues().subscribe((cooperationStatus: Array<DictModel>) => this.cooperationStatusDict = cooperationStatus);
        this.dictService.getInfoSourceType().subscribe((cooperationStatus: Array<DictModel>) => this.infoSourceDict = cooperationStatus);
        this.dictService.getGradeDict().subscribe((cooperationStatus: Array<DictModel>) => this.gradeDict = cooperationStatus);
        this.dictService.getPharmacyType().subscribe((cooperationStatus: Array<DictModel>) => this.pharmacyTypeDict = cooperationStatus);
        this.dictService.getAllChains().subscribe((chains: Array<DictModel>) => {
            this.dictChains = chains;
            this.filteredChains = chains;
        });
        this.dictService.getPartnerTypes().subscribe((partnerType: Array<DictModel>) => this.partnerTypeDict = partnerType);
        this.initBreakpoint();
    }

    public onResize(event): void {
        if ((event.target.innerWidth <= 1024)) {
            this.breakpoint = 1;
            return;
        }
        if (event.target.innerWidth <= 1280) {
            this.breakpoint = 2;
            return;
        }
        if (event.target.innerWidth <= 1366) {
            this.breakpoint = 4;
            return;
        }
        this.breakpoint = 12;
    }

    public setColspanOrReturnDefault(colspan: number): number {
        return colspan > this.breakpoint ? 1 : colspan;
    }

    public isFormInvalid(): boolean {
        return !ValidationUtils.arePartnersValid(this.partnerDetailsModel, reqFields);
    }

    public chainChanged(chain: number) {
        this.partnerDetailsModel.chainId = chain;
    }

    public districtChanged(district: number) {
        this.partnerDetailsModel.address.districtId = district;
    }

    public filterChains(event) {
        if (!this.dictChains) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredChains = this.dictChains;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredChains = this.dictChains.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public filterDisctricts(event) {
        if (!this.districtDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredDistricts = this.districtDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredDistricts = this.districtDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public submitDialog() {
        this.showInvalidFields = false;
        if (this.isFormInvalid()) {
            this.showInvalidFields = true;
            this.messageService.setSnackbar({
                severity: MessageSeverityEnum.failure,
                durationInSecond: 5,
                text: 'Wypełnij wszystkie wymagane pola!'
            });
            return;
        }
        this.dialogRef.close(true);
    }

    public getPlainContacts(): Array<ContactModel> {
        return this.partnerDetailsModel.plainContacts;
    }

    public getPeopleContacts(): Array<ExternalPersonModel> {
        return this.partnerDetailsModel.peopleContacts;
    }

    public addContactToArray(): void {
        this.partnerDetailsModel.plainContacts.push(new ContactModel(null, null, null, null));
    }

    public addPersonContactToArray(): void {
        this.partnerDetailsModel.peopleContacts.push(new ExternalPersonModel(null, null, null, null, null, null));
    }

    public removeCertainContactFromArrayForPartner(contactModel: ContactModel): void {
        this.partnerDetailsModel.plainContacts = this.partnerDetailsModel.plainContacts.filter(contact => contact !== contactModel);
    }

    public removeCertainPersonContactFromArrayForPartner(contactModel: ExternalPersonModel): void {
        this.partnerDetailsModel.peopleContacts = this.partnerDetailsModel.peopleContacts.filter(contact => contact !== contactModel);
    }

    private initBreakpoint() {
        if ((window.innerWidth <= 1024)) {
            this.breakpoint = 1;
            return;
        }
        if (window.innerWidth <= 1280) {
            this.breakpoint = 2;
            return;
        }
        if (window.innerWidth <= 1366) {
            this.breakpoint = 4;
            return;
        }
        this.breakpoint = 12;
    }


}
