import { HttpParams } from '@angular/common/http';

export class SearchCriteria {

    constructor(public page: number,
                public size: number,
                public sort: string) {
    }

    public static getRequestParams(taskReqParams: SearchCriteria, httpParams: HttpParams = null): HttpParams {
        let params = httpParams || new HttpParams();
        params = params.append('page', taskReqParams.page ? (taskReqParams.page + 1).toString() : '1');
        params = params.append('size', taskReqParams.size ? taskReqParams.size.toString() : '10');
        params = params.append('sort', taskReqParams.sort);
        return params;
    }

}
