import { ConstraintInput } from '@fullcalendar/core';
import { Moment } from 'moment';
import { EventExtendedPropsModel } from './event-extended-props-model';

export class EventModel {
    constructor(public id?: number,
                public hostId?: number,
                public host?: string,
                public groupId?: string,
                public allDay?: boolean,
                public start?: Moment,
                public end?: Moment,
                public title?: string,
                public extendedProps?: EventExtendedPropsModel,
                public classNames?: Array<string>,
                public editable?: boolean,
                public overlap?: boolean,
                public backgroundColor?: string,
                public borderColor?: string,
                public textColor?: string,
                public url?: string,
                public startEditable?: boolean,
                public durationEditable?: boolean,
                public resourceEditable?: boolean,
                public rendering?: string,
                public constraint?: ConstraintInput,
                public source?: string,
                public editOnly?: boolean,
                public refreshEvents?: boolean,
                public refreshCalendar?: boolean) {
    }
}

//id
// String. Will uniquely identify your event. Leveraged by getEventById.
//
// groupId
// String. Events that share a groupId will be dragged and resized together automatically.
//
// allDay
// true or false. If the event is shown in the “all-day” section of the view if applicable. If the time text is displayed in the event.
//
// start
// Date object that obeys the current timeZone. When your event begins.
//
// end
// Date object that obeys the current timeZone. When your event ends. Could be null if you never specified an end.
//
// This value is exclusive! I repeat, this value is exclusive!!! An event with the end of 2018-09-03 will appear to span through the 2nd of the month, but will end before the start of the 3rd of the month.
//
// title
// String. The text that will appear on an event.
//
// url
// String. A URL that will be visited when this event is clicked by the user. For more information on controlling this behavior, see the eventClick callback.
//
// classNames
// Array of className strings like [ 'myclass1', myclass2' ]. Determines which HTML classNames will be attached to the rendered event.
//
// editable
// true, false, or null. The value overriding the editable setting for this specific event.
//
// startEditable
// true, false, or null. The value overriding the eventStartEditable setting for this specific event.
//
// durationEditable
// true, false, or null. The value overriding the eventDurationEditable setting for this specific event.
//
// resourceEditable
// true, false, or null. The value overriding the eventResourceEditable setting for this specific event.
//
// rendering
// The rendering type of this event. Can be empty (normal rendering), "background", or "inverse-background"
//
// overlap
// The value overriding the eventOverlap setting for this specific event. If false, prevents this event from being dragged/resized over other events. Also prevents other events from being dragged/resized over this event. Does not accept a function.
//
// constraint
// The eventConstraint override for this specific event.
//
// backgroundColor
// The eventBackgroundColor override for this specific event.
//
// borderColor
// The eventBorderColor override for this specific event.
//
// textColor
// The eventTextColor override for this specific event.
//
// extendedProps
// A plain object holding miscellaneous other properties specified during parsing. Receives properties in the explicitly given extendedProps hash as well as other non-standard properties.
//
// source
// A reference to the Event Source this event came from. If the event was added dynamically via addEvent, and the source parameter was not specified, this value will be null.
