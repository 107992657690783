import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable()
export class AdminUserTabService {

    refresh$: Subject<void> = new Subject<void>();

    constructor(private readonly _httpClient: HttpClient) {
    }


}
