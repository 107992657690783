import { Component, Input, OnInit } from '@angular/core';
import { ContactModel } from '../../../_models/contact.model';
import { DictModel } from '../../../_models/dict.model';
import { PartnerDetailsModel } from '../../../_models/partner-details.model';
import { AuthenticationService } from '../../../_services/authentication.service';
import { DictionariesService } from '../../../_services/dictionaries.service';
import { SozService } from '../../../_services/soz.service';
import {
    canChangeLoginOrResetSozPassword,
    canCreateOrDeactivateSozAccount,
    canEditAddressData,
    canEditChain,
    canEditCoordinatorData,
    canEditCreationDate,
    canEditLicenseNumber,
    canEditLicenseObtainDate,
    canEditNip,
    canEditName,
    canEditPartnerType,
    canEditSource,
    canEditSozFields,
    canViewSozFields,
    canUseForceSozButton
} from '../../../_utils/auth-utils/auth-partner-details-general';
import {EntrepreneurDetailsDialogModel} from '../entrepreneur-details-dialog.model';
import {EntrepreneurDetailsModel} from '../../../_models/entrepreneur-details.model';
import {EntrepreneurWspolnikModel} from '../../../_models/entrepreneur-wspolnik.model';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {AddressModel} from '../../../_models/address.model';
import {MatTableDataSource} from '@angular/material/table';
import {PersonModel} from '../../../_models/person.model';
import {EntrepreneurDeliveryAddressModel} from '../../../_models/entrepreneur-delivery-address.model';
import { canEditEntrepreneur } from '../../entrepreneur-guard';

@Component({
    selector: 'app-entrepreneur-general-information-tab',
    templateUrl: './entrepreneur-general-information-tab.component.html',
    styleUrls: ['entrepreneur-general-information-tab.component.scss']
})
export class EntrepreneurGeneralInformationTabComponent implements OnInit {
    @Input() entrepreneurDetailsDialogModel: EntrepreneurDetailsDialogModel
    @Input() entrepreneurDetailsModel: EntrepreneurDetailsModel

    @Input() wspolnicy: EntrepreneurWspolnikModel[] = [];

    @Input() disabled: boolean;
    @Input() deliveryAddressDataSource: MatTableDataSource<EntrepreneurDeliveryAddressModel> = new MatTableDataSource<EntrepreneurDeliveryAddressModel>();

    gradeDict: Array<DictModel>;
    infoSourceDict: Array<DictModel>;
    districtDict: Array<DictModel>;
    filteredDisctricts: Array<DictModel> = [];

    @Input() showInvalidFields: boolean;
    nullValue: DictModel = {id: null, value: 'Usuń'};

    constructor(public readonly authService: AuthenticationService,
                private readonly dictService: DictionariesService,
                private readonly sozService: SozService
    ) {
    }

    private _currentUserRoles: number[];
    protected displayedColumns: string[] = ['id'/*, 'type'*/, 'name', 'address', 'statusSolgar', 'statusTerranova', 'active'];

    @Input()
    set deliveryAddress(array : Array<EntrepreneurDeliveryAddressModel>) {
        this.deliveryAddressDataSource = new MatTableDataSource<EntrepreneurDeliveryAddressModel>(array);
    }

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    get canEditEntrepreneur() : boolean {
        return canEditEntrepreneur(this.currentUserRoles, this.entrepreneurDetailsDialogModel.entrepreneurBrands)
    }

    get canEditChain(): boolean {
        return canEditChain(this.currentUserRoles);
    }

    get canEditName(): boolean {
        return canEditName(this.currentUserRoles);
    }

    get canEditPartnerType(): boolean {
        return canEditPartnerType(this.currentUserRoles);
    }

    get canEditSource(): boolean {
        return canEditSource(this.currentUserRoles);
    }

    get canEditCreationDate(): boolean {
        return canEditCreationDate(this.currentUserRoles);
    }

    get canEditLicenseNumber(): boolean {
        return canEditLicenseNumber(this.currentUserRoles);
    }

    get canEditLicenseObtainDate(): boolean {
        return canEditLicenseObtainDate(this.currentUserRoles);
    }

    get canEditNip(): boolean {
        return canEditNip(this.currentUserRoles);
    }

    get canEditAddressData(): boolean {
        return canEditAddressData(this.currentUserRoles);
    }

    get canEditCoordinatorData(): boolean {
        return canEditCoordinatorData(this.currentUserRoles);
    }

    get canEditSozFields(): boolean {
        return canEditSozFields(this.currentUserRoles);
    }

    get canViewSozFields(): boolean {
        return canViewSozFields(this.currentUserRoles);
    }

    get canCreateOrDeactivateSozAccount(): boolean {
        return canCreateOrDeactivateSozAccount(this.currentUserRoles);
    }

    get canChangeLoginOrResetSozPassword(): boolean {
        return canChangeLoginOrResetSozPassword(this.currentUserRoles);
    }

    get canUseForceSozButton(): boolean {
        return canUseForceSozButton(this.currentUserRoles);
    }

    ngOnInit(): void {
        // if (!this.entrepreneurDetailsModel.plainContacts?.length) {
        //     this.entrepreneurDetailsModel.plainContacts = [new ContactModel(null, null, null, null)];
        // }
        this.dictService.getDistricts().subscribe((districtDict: Array<DictModel>) => {
            this.districtDict = districtDict.sort((a, b) => a.value.localeCompare(b.value));
            this.filteredDisctricts = this.districtDict;
        });
        this.dictService.getInfoSourceType().subscribe((cooperationStatus: Array<DictModel>) => this.infoSourceDict = cooperationStatus);
        this.dictService.getGradeDict().subscribe((cooperationStatus: Array<DictModel>) => this.gradeDict = cooperationStatus);
        this.dictService.getAllChains().subscribe((chains: Array<DictModel>) => {
            // this.dictChains = chains;
            // this.filteredChains = chains;
        });

        // this.dictService.getPartnerTypes().subscribe((partnerType: Array<DictModel>) => this.partnerTypeDict = partnerType);
    }

    public filterDisctricts(event) {
        if (!this.districtDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredDisctricts = this.districtDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredDisctricts = this.districtDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public dodajWspolnika() {
        this.wspolnicy.splice(this.wspolnicy.length, 0, new EntrepreneurWspolnikModel(null, "", ""))
    }

    editShareholder(i: number, wspolnik: EntrepreneurWspolnikModel) {

    }

    deleteShareholder(i: number) {
        this.wspolnicy.splice(i, 1)
    }

    changeOtherAddress($event: MatCheckboxChange) {
        if (!$event.checked) {
            this.entrepreneurDetailsModel.correspondenceAddress = null;
        } else {
            this.entrepreneurDetailsModel.correspondenceAddress = new AddressModel(null, null, null, null, null, null, null, null, null);
        }
    }

    getSolgarDeliveryAddressIcon(status: string) {
        if (status === "true") {
            return
        } else {

        }
    }

    public getSolgarAuthorizedInfo(entrepreneurDeliveryAddressModel: EntrepreneurDeliveryAddressModel) : string {
        if (entrepreneurDeliveryAddressModel.solgar !== null && entrepreneurDeliveryAddressModel.solgar.authorized) {
            return "A"
        }
        return ""
    }

    public getTerranovaAuthorizedInfo(entrepreneurDeliveryAddressModel: EntrepreneurDeliveryAddressModel) : string {
        if (entrepreneurDeliveryAddressModel.terranova !== null && entrepreneurDeliveryAddressModel.terranova.authorized) {
            return "A"
        }
        return ""
    }

    public getAuthorizedInfo(entrepreneurDeliveryAddressModel: EntrepreneurDeliveryAddressModel) : string {
        // TODO
        // [entrepreneurDeliveryAddressModel.solgar, entrepreneurDeliveryAddressModel.terranova]
        //     .filter(model => model !== null)
        //     .map(model => model.authorized ? )
        //     .join(" | ")

        let text = "";
        if (entrepreneurDeliveryAddressModel.solgar !== null) {
            text += entrepreneurDeliveryAddressModel.solgar.authorized ? "Autoryzowany Solgar" : "Nieautoryzowany Solgar"
        }

        if (entrepreneurDeliveryAddressModel.terranova != null) {
            if (text.length > 0) {
                text += " | "
            }
            text += entrepreneurDeliveryAddressModel.terranova.authorized ? "Autoryzowany Terranova" : "Nieautoryzowany Terranova"
        }

        return text;
    }
}
