import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { ScreenTypeEnum } from '../_enums/screen-type.enum';
import { CogElementModel } from '../_models/cog-element.model';
import { ColumnElementModel } from '../_models/column-element.model';
import { ConfigModel } from '../_models/config.model';
import { ScreenConfigModel } from '../_models/screen-config.model';
import { UserConfigModel } from '../_models/user-config.model';
import { ScreenService } from '../_services/screen.service';
import { UserConfigService } from '../_services/user-config.service';
import { ChipFiltersUtils } from '../_utils/chip-filters.utils';
import { ExcelUtils } from '../_utils/excel.utils';
import { CalendarScreenService } from '../calendar/calendar-screen.service';
import { DynamicTableService } from '../dynamic-table/dynamic-table.service';
import { ChipElement } from '../filter-chips/chip-component/chip.component';
import { EventsService } from './events.service';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit, OnDestroy {

    public showConfiguration: boolean = false;
    public showConfigurationManager: boolean = false;
    public showFilter: boolean = false;
    public collapsed: boolean = true;
    columnsToDisplay: number = 7;
    columnsNumbers: Array<number> = [];
    public rowsPerPage = 15;
    public newConfigurationName: string;
    chipPresentFilters: Array<ChipElement> = null;
    chipEventsPossibleFilters: ChipElement[] = ChipFiltersUtils.EVENTS_POSSIBLE_FILTERS;
    private readonly screenType = ScreenTypeEnum.events;
    private dirty: boolean = false;
    private readonly SCREEN_API_URL = environment.apiUrl + '/api/screen';
    private unsubscribe$: Subject<void> = new Subject<void>();

    private $chipSubscription: Subscription;
    private readonly chipSubject: Subject<ChipElement[]> = this.eventsService.chipSubjectMap.get(this.screenType);

    constructor(public readonly eventsService: EventsService,
                public readonly calendarScreenService: CalendarScreenService,
                public http: HttpClient,
                private readonly userConfigService: UserConfigService,
                private readonly dynamicTableService: DynamicTableService,
                private readonly excelUtils: ExcelUtils,
                private readonly screenService: ScreenService) {
        //TODO: get max-column length from backend ask Konrad how.
        for (let i = 0; i < 12; ++i) {
            this.columnsNumbers.push(i);
        }
        this.userConfigService.getUserConfig().subscribe(
            (userConfig: UserConfigModel) => {
                if (userConfig && userConfig.eventsConfig) {
                    this.columnsToDisplay = userConfig.eventsConfig.columnToDisplay || 7;
                } else {
                    this.columnsToDisplay = 7;
                }
            },
            (error) => {
                console.log('Error on getting user config on finances.', error);
            }
        );

    }


    ngOnInit() {
        this.$chipSubscription = this.chipSubject.subscribe(elem => {
            this.chipPresentFilters = elem || [];
        });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
        if (this.$chipSubscription) {
            this.$chipSubscription.unsubscribe();
        }
    }

    public abortEditingConfiguration(): void {
        this.showConfiguration = false;
    }


    public cleanConfigurationAndExit(): void {
        this.showConfigurationManager = false;
        this.newConfigurationName = null;
    }

    public showInitialState(): boolean {
        return !this.showConfiguration && !this.showFilter && !this.showConfigurationManager;
    }

    updateColumnToDisplay(columnToDisplay: number) {
        this.columnsToDisplay = columnToDisplay;
        this.updateColToDisplayAmount();
        this.dynamicTableService.columnChanged.next(columnToDisplay);
        this.userConfigService.updateEventsConfigColumnToDisplay(columnToDisplay).subscribe();
        this.updateActiveConfig();
    };

    public chipAddToList(chipElement: ChipElement): void {
        this.chipPresentFilters.push(chipElement);
    }

    public assignPrevious(): void {
        if (this.dirty === false) {
            this.eventsService.previousConfiguration = new ScreenConfigModel(this.eventsService.activeConfiguration.id,
                this.eventsService.activeConfiguration.name, this.eventsService.activeConfiguration.isDefault, this.eventsService.activeConfiguration.screenType, new ConfigModel(
                    Object.assign([], this.eventsService.activeConfiguration.configJson.visibleColumnsOrdered),
                    this.eventsService.activeConfiguration.configJson.sort,
                    Object.assign([], this.eventsService.activeConfiguration.configJson.filters),
                    Object.assign([], this.eventsService.activeConfiguration.configJson.cogColumnsOrdered),
                ));
            this.dirty = true;
        }
    }

    public cogUpdateTempConfiguration($event: Array<CogElementModel>, columnToDisplay: number = 9): void {
        this.eventsService.activeConfiguration.configJson.visibleColumnsOrdered = this.cogElementsToVisibleColumns($event, columnToDisplay);
        this.eventsService.getUpdatedData(this.screenType, this.rowsPerPage, null, $event);
    }

    public updateColToDisplayAmount(columnToDisplay: number = 9): void {
        this.cogUpdateTempConfiguration(this.eventsService.activeConfiguration.configJson.cogColumnsOrdered, columnToDisplay);
    }

    public changeActiveConfig(configName: string): void {
        let candidateConfig = this.eventsService.configurationMap.get(configName);
        this.activate(candidateConfig);
    }

    public setScreenConfigAsDefault() {
        this.eventsService.activeConfiguration.isDefault = true;
        this.screenService.setDefaultScreenConfigData(this.SCREEN_API_URL, this.eventsService.activeConfiguration).subscribe(data => {
            this.eventsService.getUpdatedData(this.screenType, this.rowsPerPage);
        });
    }

    public updateActiveConfig(): void {
        let activeConfig = this.eventsService.activeConfiguration;
        this.screenService.updateScreenData(this.SCREEN_API_URL, activeConfig).subscribe(data => {
            this.eventsService.configurationMap.set(data.name, data);
            this.activate(data);
        });
    }

    public addNewConfig(): void {
        if (!this.eventsService.previousConfiguration) {
            this.eventsService.previousConfiguration = new ScreenConfigModel(this.eventsService.activeConfiguration.id,
                this.eventsService.activeConfiguration.name, this.eventsService.activeConfiguration.isDefault, this.eventsService.activeConfiguration.screenType, new ConfigModel(
                    Object.assign([], this.eventsService.activeConfiguration.configJson.visibleColumnsOrdered),
                    this.eventsService.activeConfiguration.configJson.sort,
                    Object.assign([], this.eventsService.activeConfiguration.configJson.filters),
                    Object.assign([], this.eventsService.activeConfiguration.configJson.cogColumnsOrdered),
                ));
        }
        let activeConfig = this.eventsService.activeConfiguration;
        this.dirty = false;
        activeConfig.id = null;
        activeConfig.isDefault = false;
        activeConfig.name = this.newConfigurationName;
        this.eventsService.configurationMap.set(this.eventsService.previousConfiguration.name, this.eventsService.previousConfiguration);
        this.screenService.saveScreenData(this.SCREEN_API_URL, activeConfig).subscribe(data => {
            this.eventsService.configurationMap.set(data.name, data);
            this.manipulateSelect(data.name);
            this.activate(data);
        });
        this.cleanConfigurationAndExit();
    }

    private manipulateSelect(name: string): void {
        let newNames: Array<string> = this.eventsService.selectNames.filter((item, index) => item != name);
        newNames.unshift(name);
        this.eventsService.setSelectNames(newNames);
    }

    private activate(candidateConfig: ScreenConfigModel): void {
        this.eventsService.setActiveConfiguration(candidateConfig);
        this.eventsService.getUpdatedData(this.screenType, this.rowsPerPage);
    }

    private cogElementsToVisibleColumns(cogElements: Array<CogElementModel>, columnToDisplay: number): Array<ColumnElementModel> {
        return cogElements.slice(0, columnToDisplay)
            .map((cogElementModel: CogElementModel) => new ColumnElementModel(cogElementModel.systemName, cogElementModel.displayName, cogElementModel.sortAvailable));
    }

    private addEvent() {
        this.calendarScreenService.setShowAddDialog$({refreshEvents: true})
    }
}
