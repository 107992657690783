import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './_guards/auth.guard';
import { AdminGuardService } from './_services/guards/admin-guard.service';
import { FinancesGuardService } from './_services/guards/finances-guard.service';
import { CalendarScreenComponent } from './calendar/calendar-screen.component';
import { ChainsComponent } from './chains/chains.component';
import { ContactsComponent } from './contacts/contacts.component';
import { EventsComponent } from './events/events.component';
import { FinancesComponent } from './finances/finances.component';
import { LoginComponent } from './login/login.component';
import { MapComponent } from './map/map.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { PartnersComponent } from './partners/partners.component';
import { Playground } from './playground/playground.component';
import { MapGuardService } from './_services/guards/map-guard.service';
import { CalendarGuardService } from './_services/guards/calendar-guard.service';
import { AnalyticsComponent } from './analytics/analytics.component';
import { ProductsComponent } from './products/products.component';
import { ProcuratorsComponent } from './procurators/procurators.component';
import { EntrepreneursComponent } from './entrepreneurs/entrepreneurs.component';

const routes: Routes = [
    {path: '', component: PartnersComponent, canActivate: [AuthGuard]},
    {path: 'map', component: MapComponent, canActivate: [AuthGuard, MapGuardService]},
    {path: 'contacts', component: ContactsComponent, canActivate: [AuthGuard]},
    {path: 'calendar', component: CalendarScreenComponent, canActivate: [AuthGuard, CalendarGuardService]},
    {path: 'partners', component: PartnersComponent, canActivate: [AuthGuard]},
    {path: 'chains', component: ChainsComponent, canActivate: [AuthGuard]},
    {path: 'representatives', component: ProcuratorsComponent, canActivate: [AuthGuard, AdminGuardService]},
    {path: 'finances', component: FinancesComponent, canActivate: [AuthGuard, FinancesGuardService]},
    {path: 'login', component: LoginComponent},
    {path: 'admin', component: AdminPageComponent, canActivate: [AuthGuard, AdminGuardService]},
    {path: '1-2-3-playground', component: Playground},
    {path: 'analytics', component: AnalyticsComponent, canActivate: [AuthGuard, FinancesGuardService]},
    {path: 'products', component: ProductsComponent, canActivate: [AuthGuard, FinancesGuardService]},
    // {path: 'tasks', component: TasksComponent, canActivate: [AuthGuard]},
    {path: 'events', component: EventsComponent, canActivate: [AuthGuard]},
    {path: 'przedsiebiorcy', component: EntrepreneursComponent, canActivate: [AuthGuard]},

    // otherwise redirect to home
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
