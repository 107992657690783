import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ScreenTypeEnum } from '../_enums/screen-type.enum';
import { ScreenConfigModel } from '../_models/screen-config.model';
import { TableEventModel } from '../_models/table-event.model';
import { ScreenService } from '../_services/screen.service';
import { UserConfigService } from '../_services/user-config.service';
import { ExcelUtils } from '../_utils/excel.utils';
import { CalendarScreenService } from '../calendar/calendar-screen.service';
import { EventActionDialogCommunicationModel } from '../calendar/event-calendar/event-calendar.service';
import { ChipElement } from '../filter-chips/chip-component/chip.component';
import { EventsService } from './events.service';
import {DynamicTableColumn} from '../dynamic-table/dynamic-table.service';

@Component({
    selector: 'app-events-table',
    templateUrl: './events-table.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsTableComponent implements OnDestroy {

    @Input()
    public columnsToDisplay: number = 7;

    @Input()
    public widthInVw: number = 95;

    @Input()
    public screenType: ScreenTypeEnum.events | ScreenTypeEnum.events_partner;

    @Input()
    public partnerId?: string;
    public rowsPerPage = 15;
    chipPresentFilters: Array<ChipElement> = null;
    private unsubscribe$: Subject<void> = new Subject<void>();
    private chipSubject: Subject<ChipElement[]>;
    private readonly SCREEN_API_URL = environment.apiUrl + '/api/screen';
    private $chipSubscription: Subscription;

    constructor(public readonly eventsService: EventsService,
                public readonly calendarScreenService: CalendarScreenService,
                public http: HttpClient,
                private readonly userConfigService: UserConfigService,
                private readonly excelUtils: ExcelUtils,
                private readonly screenService: ScreenService) {
    }

    ngOnInit() {
        this.chipSubject = this.eventsService.chipSubjectMap.get(this.screenType);
        this.eventsService.setScreenData(this.screenType, this.partnerId);
        this.eventsService.refreshData.subscribe((refresh: boolean) => refresh && this.eventsService.setScreenData(this.screenType, this.partnerId));

        this.$chipSubscription = this.chipSubject.subscribe(elem => {
            this.chipPresentFilters = elem || [];
        });
        this.calendarScreenService.patchEventSubject.pipe(
            takeUntil(this.unsubscribe$))
            .subscribe((action: EventActionDialogCommunicationModel) =>
                this.calendarScreenService.patchEvent(action).subscribe(() => this.eventsService.setScreenData(this.screenType, this.partnerId, this.rowsPerPage)));
        this.calendarScreenService.deleteEventSubject.pipe(
            takeUntil(this.unsubscribe$))
            .subscribe((action: EventActionDialogCommunicationModel) => {
                    this.calendarScreenService.deleteEvent(action).subscribe(() => this.eventsService.setScreenData(this.screenType, this.partnerId, this.rowsPerPage));
                }
            );
    }

    ngOnDestroy(): void {
        if (this.$chipSubscription) {
            this.$chipSubscription.unsubscribe();
        }
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    public chipUpdateList(chipList: ChipElement[]): void {
        this.chipPresentFilters = chipList;
    }

    public chipUpdateListAndSendRequest(chipList: ChipElement[]): void {
        this.chipUpdateList(chipList);
        this.eventsService.activeConfiguration.configJson.filters = chipList;

        this.updateActiveConfig();

    }

    public getHeaders(): Array<DynamicTableColumn> {
        if (!this.eventsService.activeConfiguration.configJson || !this.eventsService.activeConfiguration.configJson.visibleColumnsOrdered) {
            return;
        }
        return this.eventsService.activeConfiguration.configJson.visibleColumnsOrdered
            .map(col => new DynamicTableColumn(col.displayName, col.sortAvailable));
    }

    public getRows(): Array<Array<string>> {
        if (!this.eventsService.rowsForActiveConfiguration) {
            return;
        }
        return this.eventsService.rowsForActiveConfiguration;
    }

    public getPages(): number {
        if (!this.eventsService.pages) {
            return;
        }
        return this.eventsService.pages;
    }

    public getPage(): number {
        if (!this.eventsService.page) {
            return;
        }
        return this.eventsService.page;
    }

    public getTotalElements(): number {
        if (!this.eventsService.totalElements) {
            return;
        }
        return this.eventsService.totalElements;
    }

    public tableEvent(event: TableEventModel) {
        this.eventsService.page = event.page;
        this.rowsPerPage = event.rowsPerPage;
        this.eventsService.getUpdatedData(this.screenType, this.rowsPerPage, this.partnerId);
    }

    public tableSortEvent(sortQueryParam: string) {
        this.eventsService.sortQueryParam = sortQueryParam;
        this.eventsService.getUpdatedData(this.screenType, this.rowsPerPage, this.partnerId);
    }

    public updateActiveConfig(): void {
        let activeConfig = this.eventsService.activeConfiguration;
        this.screenService.updateScreenData(this.SCREEN_API_URL, activeConfig).subscribe(data => {
            this.eventsService.configurationMap.set(data.name, data);
            this.activate(data);
        });
    }

    private activate(candidateConfig: ScreenConfigModel): void {
        this.eventsService.setActiveConfiguration(candidateConfig);
        this.eventsService.getUpdatedData(this.screenType, this.rowsPerPage, this.partnerId);
    }

}
