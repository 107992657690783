import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-product-image',
    templateUrl: './product-image.component.html',
    styleUrls: ['./product-image.component.scss']
})
export class ProductsImageComponent {

    @Input() private url: string;
    @Input() public urlDescription: string;
    @Input() public description: string;
    @Input() public title: string;

    public isValid() : boolean {
        return !(this.url == null || this.url.toLowerCase() == 'brak');
    }

    public getUrl() : string {
        if (this.isValid()) {
            return this.url
        } else {
            return "https://via.placeholder.com/150x150.png?text=Brak+zdjęcia"
        }
    }

}
