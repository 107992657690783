import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, interval, of, Subject, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { EventNotificationPatchModel } from '../_models/event-notification-patch.model';
import { EventNotificationModel } from '../_models/event-notification.model';
import { PlainNotificationPatchModel } from '../_models/plain-notification-patch.model';
import { PlainNotificationModel } from '../_models/plain-notification.model';
import { AuthenticationService } from '../_services/authentication.service';
import { NotificationService } from '../_services/notification.service';
import { UserService } from '../_services/user.service';
import { CalendarScreenService } from '../calendar/calendar-screen.service';

export class PendingCalendarNotification {
    public id: number;
    public eventNotificationPatchModel: EventNotificationPatchModel;
}

export class PendingPlainNotification {
    public id: number;
    public plainNotificationPatchModel: PlainNotificationPatchModel;
}

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {


    protected showNotifications: boolean = false;
    protected calendarNotificationModels: Array<EventNotificationModel> = [];
    protected plainNotificationModels: Array<PlainNotificationModel> = [];
    protected pendingCalendarNotifications: Array<PendingCalendarNotification> = [];
    protected pendingPlainNotifications: Array<PendingPlainNotification> = [];
    private NOTIFICATION_INTERVAL_IN_SECONDS: number = 10;
    private currentUser: number;
    private unsubscribe$: Subject<void> = new Subject<void>();
    private notificationSubscription$: Subscription;

    constructor(private readonly eRef: ElementRef,
                private readonly notificationService: NotificationService,
                private readonly personService: UserService,
                private readonly calendarService: CalendarScreenService,
                private readonly authService: AuthenticationService) {
    }

    @HostListener('document:click', ['$event'])
    hideOnOutsideClick(event) {
        if (this.showNotifications) {
            if (!this.eRef.nativeElement.contains(event.target)) {
                this.changeVisibility();
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.notificationSubscription$ && this.notificationSubscription$.unsubscribe();
    }

    ngOnInit() {

        this.currentUser = this.authService.currentUserValue.personId;
        this.notificationService.getNewPendingCalendarNotification().subscribe((pendingNotification: PendingCalendarNotification) =>
            this.addPendingCalendarNotification(pendingNotification)
        );
        this.notificationService.getRemovedPendingCalendarNotification().subscribe((notificationId: number) => this.removeFromPending(notificationId));

        this.notificationService.getNewPendingPlainNotification().subscribe((pendingPlainNotification: PendingPlainNotification) => this.addPendingPlainNotification(pendingPlainNotification));

        this.notificationSubscription$ = interval(1000 * this.NOTIFICATION_INTERVAL_IN_SECONDS).pipe(
            startWith(0),
            switchMap(() => {
                    return combineLatest(this.notificationService.getEventNotificationForPersonId(this.currentUser), this.notificationService.getPlainNotificationForPersonId(this.currentUser));
                }
            )
        ).subscribe(([calendarNotifications, plainNotifications]: [Array<EventNotificationModel>, Array<PlainNotificationModel>]) => {
                this.calendarNotificationModels = calendarNotifications.filter(notif => !notif.dismissed);
                this.plainNotificationModels = plainNotifications.filter(notif => !notif.dismissed);
            }
        );
    }

    dismissAll() {
        this.notificationService.dismissAllNotificationsForUser(this.currentUser)
            .subscribe(() => this.reloadNotifications());
    }

    reloadNotifications(): void {
        combineLatest(this.notificationService.getEventNotificationForPersonId(this.currentUser), this.notificationService.getPlainNotificationForPersonId(this.currentUser))
            .subscribe(([calendarNotifications, plainNotifications]: [Array<EventNotificationModel>, Array<PlainNotificationModel>]) => {
                this.calendarNotificationModels = calendarNotifications.filter(notif => !notif.dismissed);
                this.plainNotificationModels = plainNotifications.filter(notif => !notif.dismissed);
                }
            );
    }

    protected shouldLightNotificationButton(): boolean {
        return this.unseenPlainNotificationsPresent() || (this.filterDismissedCalendarNotifications() && this.filterDismissedCalendarNotifications().length > 0);
    }

    protected filterDismissedCalendarNotifications(): Array<EventNotificationModel> {
        return this.calendarNotificationModels && this.calendarNotificationModels.filter((calendarNotificationModel: EventNotificationModel) => !this.pendingCalendarNotifications.find(
            (pendingCalendarNotification: PendingCalendarNotification) => pendingCalendarNotification.id === calendarNotificationModel.eventId && pendingCalendarNotification.eventNotificationPatchModel.dismissed
            )
        ) || [];
    }

    protected filterDismissedPlainNotifications(): Array<PlainNotificationModel> {
        return this.plainNotificationModels && this.plainNotificationModels.filter((plainNotification: PlainNotificationModel) => !this.pendingPlainNotifications.find(
            (pendingPlainNotification: PendingPlainNotification) => pendingPlainNotification.id === plainNotification.id && pendingPlainNotification.plainNotificationPatchModel.dismissed
            )
        ) || [];
    }

    protected removeFromPending(eventId: number): void {
        const pendingElement = this.pendingCalendarNotifications && this.pendingCalendarNotifications.length > 0 && this.pendingCalendarNotifications
            .find((pendingNotification: PendingCalendarNotification) => pendingNotification.id === eventId);

        const index = this.pendingCalendarNotifications.indexOf(pendingElement);

        index > -1 && this.pendingCalendarNotifications.splice(index, 1);

    }

    protected changeVisibility() {
        this.showNotifications = !this.showNotifications;
        !this.showNotifications && this.finalizeNotifications();
    }

    protected isPending(notificationId: number): boolean {
        return this.pendingCalendarNotifications && !!this.pendingCalendarNotifications.find((pending: PendingCalendarNotification) => pending.id === notificationId);
    }

    private unseenPlainNotificationsPresent(): boolean {
        return this.plainNotificationModels.some((notif: PlainNotificationModel) => !notif.seen);
    }

    private addPendingCalendarNotification(pendingCalendarNotification: PendingCalendarNotification) {
        const patchAfterAdding: boolean = pendingCalendarNotification.eventNotificationPatchModel.dismissed && this.filterDismissedPlainNotifications().length === 0 && this.filterDismissedCalendarNotifications().length === 1;

        const pendingElement = this.pendingCalendarNotifications && this.pendingCalendarNotifications.length > 0 && this.pendingCalendarNotifications
            .find((pendingNotification: PendingCalendarNotification) => pendingNotification.id === pendingCalendarNotification.id);
        const index = this.pendingCalendarNotifications.indexOf(pendingElement);
        if (index > -1) {
            this.pendingCalendarNotifications[index] = pendingCalendarNotification;
        } else {
            this.pendingCalendarNotifications.push(pendingCalendarNotification);
        }

        patchAfterAdding && this.changeVisibility();

    }

    private addPendingPlainNotification(pendingPlainNotification: PendingPlainNotification) {
        const patchAfterAdding: boolean = pendingPlainNotification.plainNotificationPatchModel.dismissed && this.filterDismissedPlainNotifications().length === 1 && this.filterDismissedCalendarNotifications().length === 0;


        const pendingElement = this.pendingPlainNotifications && this.pendingPlainNotifications.length > 0 && this.pendingPlainNotifications
            .find((pendingNotification: PendingPlainNotification) => pendingNotification.id === pendingPlainNotification.id);
        const index = this.pendingPlainNotifications.indexOf(pendingElement);
        if (index > -1) {
            this.pendingPlainNotifications[index] = pendingPlainNotification;
        } else {
            this.pendingPlainNotifications.push(pendingPlainNotification);
        }

        patchAfterAdding && this.changeVisibility();

    }

    private finalizeNotifications() {
        let countToReload = 2;
        if (this.pendingCalendarNotifications && this.pendingCalendarNotifications.length > 0) {

            this.pendingCalendarNotifications.forEach((pendingNotification: PendingCalendarNotification) => {
                    this.notificationService.patchEventNotification(pendingNotification)
                        .pipe(
                            switchMap((event: EventNotificationModel) => {
                                    this.removeFromPending(event.id);
                                    if (pendingNotification.eventNotificationPatchModel.eventConfirmation === true || pendingNotification.eventNotificationPatchModel.eventConfirmation === false  && event.eventId) {
                                        return this.calendarService.patchEventConfirmation(event.eventId, this.currentUser, {
                                            accepted: pendingNotification.eventNotificationPatchModel.eventConfirmation
                                        });
                                    }
                                    return of(null);
                                }
                            )
                        )
                        .subscribe(() => {
                            },
                            () => {
                                --countToReload;
                                if (countToReload === 0) {
                                    this.reloadNotifications();
                                }
                            },
                            () => {
                                --countToReload;
                                if (countToReload === 0) {
                                    this.reloadNotifications();
                                }
                            }
                        );

                }
            );
        } else {
            --countToReload;
            if (countToReload === 0) {
                this.reloadNotifications();
            }
        }
        if (this.pendingPlainNotifications && this.pendingPlainNotifications.length > 0) {
            this.pendingPlainNotifications.forEach((pendingNotification: PendingPlainNotification) =>
                this.notificationService.patchPlainNotification(pendingNotification).subscribe(
                    (event: PlainNotificationModel) => this.removeFromPendingPlainNotification(event.id),
                    () => {
                        --countToReload;
                        if (countToReload === 0) {
                            this.reloadNotifications();
                        }
                    },
                    () => {
                        --countToReload;
                        if (countToReload === 0) {
                            this.reloadNotifications();
                        }
                    }
                ));
        } else {
            --countToReload;
            if (countToReload === 0) {
                this.reloadNotifications();
            }
        }


    }

    private removeFromPendingPlainNotification(id: number): void {
        const pendingElement = this.pendingPlainNotifications && this.pendingPlainNotifications.length > 0 && this.pendingPlainNotifications
            .find((pendingNotification: PendingPlainNotification) => pendingNotification.id === id);

        const index = this.pendingPlainNotifications.indexOf(pendingElement);

        index > -1 && this.pendingPlainNotifications.splice(index, 1);

    }

}
