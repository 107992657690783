import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-solgar-logo',
    templateUrl: './solgar-logo.component.html',
    styleUrls: ['../logo.component.scss']
})
export class SolgarLogoComponent {
    @Input() height: string = '30px';

}
