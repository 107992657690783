import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Moment } from 'moment';


export class DateRange {
    startDate: Moment;
    endDate: Moment;
}

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {

    @ViewChild('pickerTo', {static: false}) pickerTo: MatDatepicker<any>;
    @ViewChild('pickerFrom', {static: true}) pickerFrom: MatDatepicker<any>;
    @Output() valueChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();
    @Input() required: boolean = false;
    @Input() startDatePlaceholder: string = 'Od';
    @Input() endDatePlaceHolder: string = 'Do';
    @Input() showEndInput: boolean = true;
    @Input() startInputIconPosition: string = 'right';
    @Input() endInputIconPosition: string = 'right';
    @Input() dateRange: DateRange = {startDate: null, endDate: null};
    @Input() maxDate: Moment;

    ngOnInit(): void {
    }

    setRange(endDate: Moment) {
        this.dateRange.endDate = endDate;
        this.emitValue();
    }

    emitValue() {
        this.dateRange.startDate && this.dateRange.endDate && this.isStartDateBeforeOrEqualEndDate() && this.valueChange.emit(this.dateRange);
    }

    isStartDateBeforeOrEqualEndDate() {
        return this.dateRange.startDate.isSameOrBefore(this.dateRange.endDate);
    }

    openFrom(event) {
        event.target.select();
        this.pickerFrom.open();
    }

    openTo(event) {
        event.target.select();
        this.pickerTo.open();
    }

    protected setFrom(startDate: Moment) {
        this.dateRange.startDate = startDate;
        if (this.dateRange.startDate && (!this.dateRange.endDate || this.dateRange.endDate.isBefore(this.dateRange.startDate))) {
            setTimeout(() => this.pickerTo.open(), 0)
        } else {
            this.emitValue();
        }
    }

}
