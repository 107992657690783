import { AuthRolesEnum, canPerformAction } from './auth-commons';

export const canSearchChain = (roles: number[]): boolean => {
    const hasRightsToSearch: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.SYSTEM_ADMIN,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.REGIONAL_DIRECTOR
    ]
    return canPerformAction(roles, hasRightsToSearch);
}


export const canAddChain = (roles: number[]): boolean => {
    const hasRightsToAdd: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.SYSTEM_ADMIN,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER
    ];
    return canPerformAction(roles, hasRightsToAdd);
}

/**
 * Editing chains means doing any actions located on chain-details-dialog,
 */
export const canEditChains = (roles: number[]): boolean => {
    const hasRightsToEdit: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ];
    return canPerformAction(roles, hasRightsToEdit);
}

export const canViewSozFields = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR
    ]
    return canEditSozFields(roles) || canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditSozFields = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canCreateOrDeactivateSozAccount = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canChangeLoginOrResetSozPassword = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canUseForceSozButton = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

/**
 * viewing chain means being able to enter chain page and being able to open chain-details-dialog
 */
export const canViewChain = (roles: number[]): boolean => {
    const hasRightsToView: AuthRolesEnum[] = [
        AuthRolesEnum.TOA
    ];
    return canEditChains(roles) || canPerformAction(roles, hasRightsToView);
}

export const canRemoveChain = (roles: number[]): boolean => {
    const hasRightsToRemove: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.SYSTEM_ADMIN
    ];
    return canPerformAction(roles, hasRightsToRemove);
}
