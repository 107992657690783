import {DialogResultEnum} from '../../_enums/dialog-result.enum';
import {Subject} from 'rxjs';
import {ProcuratorDetailsModel} from '../../_models/procurator-details.model';

export class ProcuratorDialogModel {
    detailsModel: ProcuratorDetailsModel;
    editOnly?: boolean;
    notification?: Subject<DialogResultEnum>;
    newProcurator: boolean = false;
}
