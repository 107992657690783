import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs/index';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoginModel } from '../_models/auth/login.model';
import { PersonModel } from '../_models/person.model';
import { TaskModel } from '../_models/task.model';

export enum AuthRolesEnum {
    DEFAULT,
    SYSTEM_ADMIN,
    MANAGEMENT,
    CHIEF_EXECUTIVE_OFFICER,
    CHIEF_EXECUTIVE_OFFICER_DEPUTY,
    REGIONAL_DIRECTOR,
    REGIONAL_COORDINATOR,
    TOA,
    ADMINISTRATION_DEPARTMENT,
    TOA_MANAGER
}

@Injectable({providedIn: 'root'})
export class AuthenticationService {

    currentUser: Observable<LoginModel>;
    private currentUserSubject: BehaviorSubject<LoginModel>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<LoginModel>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    get currentUserValue(): LoginModel {
        return this.currentUserSubject.value;
    }

    canWriteChains(): boolean {
        return this.getCurrentUserRoles() &&
            (this.getCurrentUserRoles().includes(AuthRolesEnum.SYSTEM_ADMIN) ||
                this.getCurrentUserRoles().includes(AuthRolesEnum.MANAGEMENT) ||
                this.getCurrentUserRoles().includes(AuthRolesEnum.TOA) ||
                this.getCurrentUserRoles().includes(AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER) ||
                this.getCurrentUserRoles().includes(AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY) ||
                this.getCurrentUserRoles().includes(AuthRolesEnum.REGIONAL_DIRECTOR));
    }

    partnerFullEditScope(): boolean {
        return this.getCurrentUserRoles() &&
            (this.getCurrentUserRoles().includes(AuthRolesEnum.SYSTEM_ADMIN) ||
                this.getCurrentUserRoles().includes(AuthRolesEnum.TOA) ||
                this.getCurrentUserRoles().includes(AuthRolesEnum.ADMINISTRATION_DEPARTMENT));
    }

    canEditConsents(): boolean {
        const currentUserRoles = this.getCurrentUserRoles();
        return currentUserRoles && (
            currentUserRoles.includes(AuthRolesEnum.SYSTEM_ADMIN) ||
            currentUserRoles.includes(AuthRolesEnum.ADMINISTRATION_DEPARTMENT) ||
            currentUserRoles.includes(AuthRolesEnum.TOA) ||
            currentUserRoles.includes(AuthRolesEnum.TOA_MANAGER)
        );
    }

    isCoordinator(): boolean {
        return this.getCurrentUserRoles() && this.getCurrentUserRoles().includes(AuthRolesEnum.REGIONAL_COORDINATOR);
    }

    partnerBrandContactAndGeneralInformationContactEditScope(): boolean {
        return this.getCurrentUserRoles() &&
            this.getCurrentUserRoles().includes(AuthRolesEnum.REGIONAL_COORDINATOR) ||
            this.getCurrentUserRoles().includes(AuthRolesEnum.TOA);
    }

    partnerMarketingDataEditScope(): boolean {
        return this.getCurrentUserRoles() && this.getCurrentUserRoles().includes(AuthRolesEnum.TOA);
    }

    // BENEFITS
    canEditBenefits(): boolean {
        return this.getCurrentUserRoles() &&
            (this.getCurrentUserRoles().includes(AuthRolesEnum.SYSTEM_ADMIN) ||
                this.getCurrentUserRoles().includes(AuthRolesEnum.ADMINISTRATION_DEPARTMENT) ||
                this.getCurrentUserRoles().includes(AuthRolesEnum.TOA_MANAGER) ||
                this.getCurrentUserRoles().includes(AuthRolesEnum.TOA));
    }

    canAddBenefits(): boolean {
        return this.canEditBenefits() || this.isCoordinator();
    }

    getCurrentUserRoles(): number[] | null {
        return this.currentUserValue && this.currentUserValue.systemRoleIds || null;
    }

    login(username: string, password: string) {
        return this.http.post<LoginModel>(environment.apiUrl + '/api/auth/login', {username, password})
            .pipe(map((user: LoginModel) => {
                if (user && user.token) {
                    sessionStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    changePasswordForCurrentUser(password: string) {
        return this.http.put(`${environment.apiUrl}/api/auth/changePasswordForCurrentUser`, password, {responseType: 'text'});
    }

    resetPasswordForUser(personModel: PersonModel) {
        return this.http.put(`${environment.apiUrl}/api/auth/resetPasswordForUser/${personModel.systemData.id}`, null, {responseType: 'text'});
    }

    logout() {
        sessionStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    canEditTask(task: TaskModel): boolean {
        return task.reporter && task.reporter.id === this.currentUserValue.personId || task.assignee.id === this.currentUserValue.personId;
    }
}
