import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs/index';
import {RepresentativeFile} from '../../../_models/representative-file.model';
import {RepresentativeModel} from '../../../_models/representative.model';
import {EntrepreneurDetailsDialogModel} from '../entrepreneur-details-dialog.model';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {concatMap} from 'rxjs/operators';
import {EntrepreneurRepresentativeHistory} from '../../../_models/entrepreneur-details.model';
import {OWSAcceptance} from '../representative-tab/entrepreneur-representative-tab.component';
import {UploadedFile, UploadedFileWithSettings} from '../../../inputs/file-upload/file-management.service';
import {LoaderInterceptorSkipHeader} from '../../../_helpers/loader.inteceptor';

const CONCERN_API_URL = environment.apiUrl + '/api/concern';
const REPRESENTATIVE_API_URL = environment.apiUrl + '/api/representative';

@Component({
    selector: 'app-representative-entry',
    templateUrl: './representative-entry.component.html',
    styleUrls: ['representative-entry.component.scss']
})
export class RepresentativeEntryComponent implements OnInit {

    @Input()
    entrepreneurDetailsDialogModel: EntrepreneurDetailsDialogModel;
    @Input()
    disabled: boolean;
    @Input()
    showInvalidFields: boolean;
    @Input()
    representative: RepresentativeModel;

    @Output()
    onOwsFinish: EventEmitter<any> = new EventEmitter();

    @ViewChild('fileInput')
    fileInput: ElementRef;

    files: Observable<Array<RepresentativeFile>>;

    uploadingFile: boolean;

    constructor(private http: HttpClient) {
    }

    ngOnInit() {
        this.refreshFiles();
    }

    refreshFiles() {
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');

        this.files = this.http.get<Array<RepresentativeFile>>(`${REPRESENTATIVE_API_URL}/${this.representative.id}/files`, {headers})
    }

    markDirty() {
        this.entrepreneurDetailsDialogModel.entrepreneurSaved = false;
    }

    editRepresentative(representative: RepresentativeModel) {
        this.entrepreneurDetailsDialogModel.entrepreneurSaved = false;
    }

    public resetSozPassword(representativeModel : RepresentativeModel) {
        if (!representativeModel.id) {
            return
        }
        this.http.post(`${REPRESENTATIVE_API_URL}/${representativeModel.id}/soz/reset-password`, null, {responseType: 'text'}).subscribe();
    }

    acceptOWS(sendRequest: boolean) : OWSAcceptance {
        const representativeModel = this.representative;
        if (!representativeModel.id) {
            return {valid: false, reason: "Reprezentant nie jest zapisany"}
        }
        if (!representativeModel.login || representativeModel.login.length == 0) {
            return {valid: false, reason: "Nie podano adresu email dla reprezentanta"}
        }
        if (!representativeModel.name) {
            return {valid: false, reason: "Nie podano imienia reprezentanta"}
        }
        if (!representativeModel.lastname) {
            return {valid: false, reason: "Nie podano nazwiska reprezentanta"}
        }
        if (!representativeModel.active) {
            return {valid: false, reason: "Reprezentant nie jest aktywny"}
        }
        if (!this.entrepreneurDetailsDialogModel.entrepreneurSaved) {
            return {valid: false, reason: "Przedsiebiorca nie jest zapisany"}
        }
        const entrepreneurDetailsModel = this.entrepreneurDetailsDialogModel.entrepreneurDetailsModel;
        if (!entrepreneurDetailsModel.id) {
            return {valid: false, reason: "Brakuje pola Id"}
        }
        if (!entrepreneurDetailsModel.nip || !entrepreneurDetailsModel.nip || !entrepreneurDetailsModel.address) {
            return {valid: false, reason: "Brakuje pola NIP"}
        }
        if (this.entrepreneurDetailsDialogModel.entrepreneurBrands.filter(brand => brand.id && brand.cooperationStatusId == 1).length == 0) {
            return {valid: false, reason: "Brak wspołpracy \"OWS - w trakcie wdrożenia\""}
        }
        if (!entrepreneurDetailsModel.nip || !entrepreneurDetailsModel.nip || !entrepreneurDetailsModel.address) {
            return {valid: false, reason: "Brakuje pola NIP"}
        }
        if (sendRequest) {
            this.http.post(`${REPRESENTATIVE_API_URL}/${representativeModel.id}/owsAccept`, null, {responseType: 'text'}).pipe(
                concatMap(data => this.http.get<Array<EntrepreneurRepresentativeHistory>>(`${CONCERN_API_URL}/${entrepreneurDetailsModel.id}/brandHistory`))
            ).subscribe(representativeHistory => {
                this.entrepreneurDetailsDialogModel.entrepreneurDetailsModel.representativeHistory = representativeHistory;
                this.onOwsFinish.emit({})
            });
        }
        return {valid: true, reason: ""}
    }

    openFilesDialog() {
        this.fileInput.nativeElement.click();
    }

    onNewFiles($event: any) {
        if (!this.representative.id) {
            throw new Error('Representative id is required to upload file!');
        }
        this.uploadingFile = true;
        const fileList: FileList = $event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0];
            this.uploadFile(this.representative.id.toString(), file, (data) => {
                this.uploadingFile = false;
            }, (error) => {
                this.uploadingFile = false;
            }, () => {
                this.uploadingFile = false;
                this.refreshFiles();
            });
        }
    }

    public uploadFile(representativeId: string, file: File, successCallback: (file: UploadedFile) => void, errorCallback: (error) => void, completedCallback: () => void) {
        const formData: FormData = new FormData();
        formData.append('uploadedFile', file, file.name);
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        this.http.post<UploadedFile>(`${REPRESENTATIVE_API_URL}/${representativeId}/files`, formData, {headers: headers})
            .subscribe(
                data => successCallback(data),
                error => errorCallback(error),
                () => completedCallback()
            );
    }
}
