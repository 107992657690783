import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BrandNameEnum} from '../_enums/brand-name.enum';
import {FinanceChosenViewEnum} from '../_enums/finance-chosen-view.enum';
import {PeriodPickerModel} from '../_models/period-picker.model';
import {ScreenConfigModel} from '../_models/screen-config.model';
import {UserConfigModel} from '../_models/user-config.model';
import {UserConfigService} from '../_services/user-config.service';
import {ChipFiltersUtils} from '../_utils/chip-filters.utils';
import {ChipElement} from '../filter-chips/chip-component/chip.component';
import {FinancesService} from './finances.service';
import {ChartResultsResolutionEnum} from '../_enums/chart-results-resolution.enum';
import {DataSerieEnum} from '../_enums/data-serie.enum';
import {ChartUtil} from '../_utils/chart.util';
import {ChartMarketCooperationEnum} from '../_enums/chart-market-cooperation.enum';
import {ChartResultModeEnum} from '../_enums/chart-result-mode.enum';
import {ChartSearchTypeEnum} from '../_enums/chart-search-type.enum';

interface ChartType {
    value: number;
    display: string;
}

@Component({
    selector: 'app-finances',
    templateUrl: './finances.template.html',
    styleUrls: ['./finances.style.scss'],
    providers: [FinancesService],
})
export class FinancesComponent implements OnInit {

    public chartType: Array<ChartType> = [
        {value: 0, display: 'Wykres obrotów'},
        {value: 1, display: 'Rotacja asortymentu'},
        {value: 2, display: 'Partnerzy'},
    ];
    selectedTab = new FormControl(0);
    chipPresentFilters: Array<ChipElement> = null;
    chipPartnerPossibleFilters: ChipElement[] = ChipFiltersUtils.FINANCES_POSSIBLE_FILTERS;
    currentConfig: ScreenConfigModel;

    constructor(public readonly financesService: FinancesService,
                public readonly userConfigService: UserConfigService) {
    }

    private _chartPicked: Array<number> = [0, 2];

    get chartPicked(): Array<number> {
        return this._chartPicked;
    }

    set chartPicked(value: Array<number>) {
        value && value.length > 0 ? this._chartPicked = value : this._chartPicked = [0];
    }

    ngOnInit(): void {
        this.financesService.getFinancesConfiguration().subscribe(value => {
            this.currentConfig = value[0];
            this.financesService.filtersValueArray = value[0].configJson.filters || [];
            this.chipPresentFilters = this.financesService.filtersValueArray;
        });

        if (!ChartUtil.getChartColorsFromLocalStorage()) {
            this.financesService.generateRandomColorSet();
        } else {
            const colorsFromLocalSotrage = ChartUtil.getChartColorsFromLocalStorage();
            this.financesService.chartColors = JSON.parse(colorsFromLocalSotrage);
        }

        this.userConfigService.getUserConfig().subscribe(
            (userConfig: UserConfigModel) => {
                this.financesService.periodPicker = PeriodPickerModel.getPeriodPicker(userConfig.financesConfig.periodPicker);
                this.financesService.resolution = userConfig.financesConfig.resolution;
                this.financesService.brandPicked = userConfig.financesConfig.brandPicked || BrandNameEnum.ALL;
                this.financesService.searchType = userConfig.financesConfig.searchType || ChartSearchTypeEnum.B2B;
                this.financesService.chartResultMode = userConfig.financesConfig.chartMode || ChartResultModeEnum.PRICE;
                this.financesService.seriesToDisplay = userConfig.financesConfig.series;
                this._chartPicked = userConfig.financesConfig.screenConfig ? userConfig.financesConfig.screenConfig : [0, 2];
                if (userConfig.financesConfig.filtersWithValues) {
                    this.financesService.filtersValueArray = userConfig.financesConfig.filtersWithValues || [];

                    this.chipPresentFilters = this.financesService.filtersValueArray;
                }
                this.financesService.chosenView = userConfig.financesConfig.chosenView;
                this.financesService.configFetched = true;
                this.financesService.generateChartData();
            },
            (error) => {
                console.log('Error on getting user config on finances.', error);
                this.financesService.generateChartData();

            }
        );
    }

    handleBrandValueChange(brandValue: BrandNameEnum) {
        this.financesService.brandPicked = brandValue;
        this.financesService.generateChartData();
        this.userConfigService.updateBrandPicked(brandValue).subscribe();
    }

    handleResolutionValueChange(selectedResolutionValue: ChartResultsResolutionEnum) {
        this.financesService.resolution = selectedResolutionValue;
        this.financesService.generateChartData();
        this.userConfigService.updateResolution(selectedResolutionValue).subscribe();
    }

    handleChartResultsModeChange(selectedChartResultMode: ChartResultModeEnum) {
        this.financesService.chartResultMode = selectedChartResultMode;
        this.financesService.generateChartData();
        this.userConfigService.updateResultMode(selectedChartResultMode).subscribe();
    }

    handleCooperationValueChange(selectedResolutionValue: ChartSearchTypeEnum) {
        if (selectedResolutionValue.length == 0) {
            selectedResolutionValue = ChartSearchTypeEnum.B2B;
        }
        this.financesService.searchType = selectedResolutionValue;
        this.financesService.generateChartData();
        this.userConfigService.updateCooperationType(selectedResolutionValue, "finances").subscribe();
    }

    handleSeriesValueChange(selectedSeriesValue: DataSerieEnum[]) {
        this.financesService.seriesToDisplay = selectedSeriesValue;
        this.userConfigService.updateSeries(selectedSeriesValue).subscribe();
    }

    public isIncomeChartPicked(): boolean {
        return this.chartPicked.includes(0);
    }

    public isRotatingDataPicked(): boolean {
        return this.chartPicked.includes(1);
    }

    public isPartnersDataPicked(): boolean {
        return this.chartPicked.includes(2);
    }

    public updateScreenConfig(screenConfig: Array<number>) {
        this.userConfigService.updateScreenConfig(screenConfig).subscribe();
    }

    public updateIsChartPicked() {
        this.financesService.chosenView = this.financesService.chosenView === FinanceChosenViewEnum.CHART ? FinanceChosenViewEnum.DETAILS : FinanceChosenViewEnum.CHART;
        this.userConfigService.updateChosenView(this.financesService.chosenView).subscribe();
    }

    public chipUpdateList(chipList: ChipElement[]): void {
        this.chipPresentFilters = chipList;
    }

    public chipAddToList(chipElement: ChipElement): void {
        this.chipPresentFilters.push(chipElement);
    }

    public chipUpdateListAndSendRequest(chipList: ChipElement[]): void {
        this.chipUpdateList(chipList);
        this.financesService.filtersValueArray = chipList;
        this.financesService.generateChartData();
        this.userConfigService.updateFinancesFiltersWithValues(chipList).subscribe();
    }

    public setRandomColors(): void {
        this.financesService.changeChartColors();
    }

    public exportToExcel(): void {
        this.financesService.exportToExcel(this.getSystemNameHeaders());
    }

    private getSystemNameHeaders(): Array<string> {
        if (!this.currentConfig.configJson.visibleColumnsOrdered) {
            return;
        }
        return this.currentConfig.configJson.visibleColumnsOrdered.map(col => col.systemName);
    }

    isExportEnabled() {
        return this.financesService.searchType == ChartSearchTypeEnum.B2B
    }
}
