import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'image-status',
    templateUrl: './image-status.template.html',
    styleUrls: ['./image-status.style.scss'],
})
export class ImageStatusComponent implements OnInit {

    @Input() public status: string;
    @Input() public image: string;
    @Input() public description: string;

    ngOnInit(): void {
    }

}
