import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { finalize, share, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LoaderInterceptorSkipHeader } from '../_helpers/loader.inteceptor';
import { DictModel } from '../_models/dict.model';
import { EventSubTypeDictModel } from '../_models/event-sub-type-dict.model';
import { EventTypeDictModel } from '../_models/event-type-dict.model';
import { ExternalPersonModel } from '../_models/external-person.model';
import { ApiUtils } from '../_utils/api.utils';
import { BenefitTypeModel } from '../partners/partner-details-dialog/benefit-tab/benefit-tab.component';

class CachedDictionary<T> {
    dictionary?: T;
    sharedStream?: Observable<T>;
}

@Injectable()
export class DictionariesService {
    private _brandDictionary: Observable<DictModel[]>;
    private _districtDictionary: Observable<DictModel[]>;
    private _peopleDictionary: Observable<DictModel[]>;
    private _toaPeopleDictionary: Observable<DictModel[]>;
    private _operationPeopleDictionary: Observable<DictModel[]>;
    private _ediFlags: Observable<DictModel[]>;
    private _truthy: Observable<DictModel[]>;
    private _cooperationStatusesDictionary: Observable<DictModel[]>;
    private _concernCooperationStatusDictionary: Observable<DictModel[]>;
    private _pharmacyTypeDictionary: Observable<DictModel[]>;
    private _cooperationTypeDictionary: Observable<DictModel[]>;
    private _infoSourceTypeDictionary: Observable<DictModel[]>;
    private _gradeDictionary: Observable<DictModel[]>;
    private _partnerTypesDictionary: Observable<DictModel[]>;
    private _recurringTypesDictionary: Observable<DictModel[]>;
    private _chainDictionary: Observable<DictModel[]>;
    private _cachedExternalPeopleDictionary: CachedDictionary<DictModel[]>;
    private _reminderTypeDictionary: Observable<DictModel[]>;
    private _taskPrioritiesDictionary: Observable<DictModel[]>;
    private _taskStatusesDictionary: Observable<DictModel[]>;
    private _teamsDictionary: Observable<DictModel[]>;
    private _partnerNamesDictionary: Observable<DictModel[]>;
    private _entrepreneursNamesDictionary: Observable<DictModel[]>;
    private _domainRoleDictionary: Observable<DictModel[]>;
    private _sozRoleDictionary: Observable<DictModel[]>;
    private _systemRoleDictionary: Observable<DictModel[]>;
    private _calendarsDictionary: Observable<DictModel[]>;
    private _townDictionary: Observable<DictModel[]>;
    private _streetDictionary: Observable<DictModel[]>;
    private _eventReminderDirection: Observable<DictModel[]>;
    private _eventTypeDictionary: Observable<EventTypeDictModel[]>;
    private _eventSubtypeDictionary: Observable<EventSubTypeDictModel[]>;
    private _financesProductSolgarDictionary: Observable<DictModel[]>;
    private _financesProductTerranovaDictionary: Observable<DictModel[]>;
    private _productsDictionary: Observable<DictModel[]>;
    private _productCategoriesDictionary: Observable<DictModel[]>;
    private _procuratorRoleDictionary: Observable<DictModel[]>;

    private readonly API_URL = environment.apiUrl + '/api/dicts';

    constructor(private readonly http: HttpClient) {
    }

    public getEventReminderDirection(): Observable<Array<DictModel>> {
        if (!this._eventReminderDirection) {
            this._eventReminderDirection = this.http.get<Array<DictModel>>(`${this.API_URL}/event-reminder-direction`);
        }
        return this._eventReminderDirection;
    }

    public getDistricts(): Observable<Array<DictModel>> {
        if (!this._districtDictionary) {
            this._districtDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/districts`);
        }
        return this._districtDictionary;
    }

    public getPeople(): Observable<Array<DictModel>> {
        if (!this._peopleDictionary) {
            this._peopleDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/people`);
        }
        return this._peopleDictionary;
    }

    public getToaPeople(): Observable<Array<DictModel>> {
        if (!this._toaPeopleDictionary) {
            this._toaPeopleDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/people-toa`);
        }
        return this._toaPeopleDictionary;
    }

    public getOperationsPeople(): Observable<Array<DictModel>> {
        if (!this._operationPeopleDictionary) {
            this._operationPeopleDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/people-operations`);
        }
        return this._operationPeopleDictionary;
    }

    public getEdiFlags(): Observable<Array<DictModel>> {
        if (!this._ediFlags) {
            this._ediFlags = this.http.get<Array<DictModel>>(`${this.API_URL}/edi-flags`);
        }
        return this._ediFlags;
    }

    public getTruthy(): Observable<Array<DictModel>> {
        if (!this._truthy) {
            this._truthy = this.http.get<Array<DictModel>>(`${this.API_URL}/truthy`);
        }
        return this._truthy;
    }

    public clearCachedExternals(): void {
        this._cachedExternalPeopleDictionary = null;
    }

    public getAllExternalPerson(): Observable<Array<DictModel>> {
        if (!this._cachedExternalPeopleDictionary) {
            this._cachedExternalPeopleDictionary = {};
        }

        if (this._cachedExternalPeopleDictionary && this._cachedExternalPeopleDictionary.dictionary) {
            return of(this._cachedExternalPeopleDictionary.dictionary);
        }

        if (!this._cachedExternalPeopleDictionary.sharedStream) {
            this._cachedExternalPeopleDictionary.sharedStream = this.http.get<Array<DictModel>>(`${this.API_URL}/external-people`)
                .pipe(
                    tap(dict => this._cachedExternalPeopleDictionary.dictionary = dict),
                    finalize(this._cachedExternalPeopleDictionary.sharedStream = null),
                    share()
                );

        }

        return this._cachedExternalPeopleDictionary.sharedStream;
    }

    public getConcernCooperationStatus(): Observable<Array<DictModel>> {
        if (!this._concernCooperationStatusDictionary) {
            this._concernCooperationStatusDictionary = this.http.get<any>(`${this.API_URL}/concern-cooperation-status`);
        }
        return this._concernCooperationStatusDictionary;
    }

    public getCooperationStatues(): Observable<Array<DictModel>> {
        if (!this._cooperationStatusesDictionary) {
            this._cooperationStatusesDictionary = this.http.get<any>(`${this.API_URL}/cooperation-status`);
        }
        return this._cooperationStatusesDictionary;
    }

    public getPharmacyType(): Observable<Array<DictModel>> {
        if (!this._pharmacyTypeDictionary) {
            this._pharmacyTypeDictionary = this.http.get<any>(`${this.API_URL}/pharmacy-types`);
        }
        return this._pharmacyTypeDictionary;
    }

    public getCooperationType(): Observable<Array<DictModel>> {
        if (!this._cooperationTypeDictionary) {
            this._cooperationTypeDictionary = this.http.get<any>(`${this.API_URL}/cooperation-types`);
        }
        return this._cooperationTypeDictionary;
    }

    public getInfoSourceType(): Observable<Array<DictModel>> {
        if (!this._infoSourceTypeDictionary) {
            this._infoSourceTypeDictionary = this.http.get<any>(`${this.API_URL}/info-sources`);
        }
        return this._infoSourceTypeDictionary;
    }

    public getGradeDict(): Observable<Array<DictModel>> {
        if (!this._gradeDictionary) {
            this._gradeDictionary = this.http.get<any>(`${this.API_URL}/grades`);
        }
        return this._gradeDictionary;
    }

    public getPartnerTypes(): Observable<Array<DictModel>> {
        if (!this._partnerTypesDictionary) {
            this._partnerTypesDictionary = this.http.get<any>(`${this.API_URL}/partner-types`);
        }
        return this._partnerTypesDictionary;
    }

    public getRecurringType(): Observable<Array<DictModel>> {
        if (!this._recurringTypesDictionary) {
            this._recurringTypesDictionary = this.http.get<any>(`${this.API_URL}/event-recurrence-type`);
        }
        return this._recurringTypesDictionary;
    }

    public getTaskPriorities(): Observable<Array<DictModel>> {
        if (!this._taskPrioritiesDictionary) {
            this._taskPrioritiesDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/task-priority`);
        }
        return this._taskPrioritiesDictionary;
    }

    public getTaskStatuses(): Observable<Array<DictModel>> {
        if (!this._taskStatusesDictionary) {
            this._taskStatusesDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/task-status`);
        }
        return this._taskStatusesDictionary;
    }

    public getTeams(): Observable<Array<DictModel>> {
        if (!this._teamsDictionary) {
            this._teamsDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/teams`);
        }
        return this._teamsDictionary;
    }

    public getBrandDictionary(): Observable<Array<DictModel>> {
        if (!this._brandDictionary) {
            this._brandDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/brands`);
        }
        return this._brandDictionary;
    }

    public getAllChains(): Observable<Array<DictModel>> {
        if (!this._chainDictionary) {
            this._chainDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/chains`);
        }
        return this._chainDictionary;
    }

    public getPartnerNames(): Observable<Array<DictModel>> {
        if (!this._partnerNamesDictionary) {
            this._partnerNamesDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/partner-names`);
        }
        return this._partnerNamesDictionary;
    }

    public getEntrepreneurNames(): Observable<Array<DictModel>> {
        if (!this._entrepreneursNamesDictionary) {
            this._entrepreneursNamesDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/entrepreneur-names`);
        }
        return this._entrepreneursNamesDictionary;
    }

    public getTowns(): Observable<Array<DictModel>> {
        if (!this._townDictionary) {
            this._townDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/towns`);
        }
        return this._townDictionary;
    }

    public getStreets(): Observable<Array<DictModel>> {
        if (!this._streetDictionary) {
            this._streetDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/streets`);
        }
        return this._streetDictionary;
    }

    public getDomainRoles(): Observable<Array<DictModel>> {
        if (!this._domainRoleDictionary) {
            this._domainRoleDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/domain-roles`);
        }
        return this._domainRoleDictionary;
    }

    public getSozRoles(): Observable<Array<DictModel>> {
        if (!this._sozRoleDictionary) {
            this._sozRoleDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/soz-roles`);
        }
        return this._sozRoleDictionary;
    }

    public getSystemRoles(): Observable<Array<DictModel>> {
        if (!this._systemRoleDictionary) {
            this._systemRoleDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/system-roles`);
        }
        return this._systemRoleDictionary;
    }

    public getCalendars(): Observable<Array<DictModel>> {
        if (!this._calendarsDictionary) {
            this._calendarsDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/calendars`);
        }
        return this._calendarsDictionary;
    }

    public getFinancesProductsSolgar(): Observable<Array<DictModel>> {
        if (!this._financesProductSolgarDictionary) {
            this._financesProductSolgarDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/finances-products?brand=solgar`);
        }
        return this._financesProductSolgarDictionary;
    }

    public getFinancesProductsTerranova(): Observable<Array<DictModel>> {
        if (!this._financesProductTerranovaDictionary) {
            this._financesProductTerranovaDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/finances-products?brand=terranova`);
        }
        return this._financesProductTerranovaDictionary;
    }

    public getProducts(): Observable<Array<DictModel>> {
        if (!this._productsDictionary) {
            this._productsDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/all-products`);
        }
        return this._productsDictionary;
    }

    public getProductCategories(): Observable<Array<DictModel>> {
        if (!this._productCategoriesDictionary) {
            this._productCategoriesDictionary = this.http.get<Array<DictModel>>(`${this.API_URL}/product-categories`);
        }
        return this._productCategoriesDictionary;
    }

    public getRecurringDailyIntervalTypes(): Observable<Array<DictModel>> {
        const dict: DictModel[] = [
            {id: 1, value: 'Codziennie'},
            {id: 2, value: 'Co ile dni'},
        ];

        return of(dict);
    }

    public getRecurringWeeklyIntervalTypes(): Observable<Array<DictModel>> {
        const dict: DictModel[] = [
            {id: 1, value: 'Określone dni'},
            {id: 2, value: 'Co ile tygodni'},
        ];

        return of(dict);
    }

    public getRecurringMonthlyIntervalTypes(): Observable<Array<DictModel>> {
        const dict: DictModel[] = [
            {id: 1, value: 'Co miesiąc'},
            {id: 2, value: 'Co ile miesięcy'},
        ];

        return of(dict);
    }

    public getRecurringYearlyIntervalTypes(): Observable<Array<DictModel>> {
        const dict: DictModel[] = [
            {id: 1, value: 'Co roku'},
            {id: 2, value: 'Co ile lat'},
        ];

        return of(dict);
    }

    public getRecurringEndType(): Observable<Array<DictModel>> {
        const dict: DictModel[] = [
            {id: 1, value: 'Nigdy'},
            {id: 2, value: 'Po # wystąpnieniach'},
            {id: 3, value: 'Wybierz datę'},
        ];

        return of(dict);
    }

    public getPartnerByPhraseOfLength(phrase: string, length: number = 3): Observable<Array<DictModel>> {
        return phrase && phrase.length >= length && this.http.get<Array<DictModel>>(`${ApiUtils.PARTNER_API_URL}/search?namePhrase=${phrase}`) || of([]);
    }

    public getPartnerNameById(id: number) {
        return this.http.get(`${ApiUtils.PARTNER_API_URL}/${id}/name`, {responseType: 'text'});
    }

    public getReminder(): Observable<Array<DictModel>> {
        if (!this._reminderTypeDictionary) {
            this._reminderTypeDictionary = this.http.get<any>(`${this.API_URL}/reminder-type`);
        }
        return this._reminderTypeDictionary;
    }

    public getConsentTypes(): Observable<Array<DictModel>> {
        return this.http.get<DictModel[]>(`${this.API_URL}/consent-type`);
    }

    getBenefitTypes(): Observable<BenefitTypeModel[]> {
        return this.http.get<BenefitTypeModel[]>(`${this.API_URL}/benefit-type`);
    }

    public getDaysDict(): Array<DictModel> {
        return [
            {id: 1, value: 'Poniedziałek'},
            {id: 2, value: 'Wtorek'},
            {id: 3, value: 'Środa'},
            {id: 4, value: 'Czwartek'},
            {id: 5, value: 'Piątek'},
            {id: 6, value: 'Sobota'},
            {id: 7, value: 'Niedziela'},
        ];
    }

    public getProductStatusDict(): Array<DictModel> {
        return [
            {id: 1, value: 'Aktywne'},
            {id: 2, value: 'Wycofane'},
            {id: 3, value: 'Do wprowadzenia'},
        ];
    }

    public getProcuratorRoles(): Observable<DictModel[]> {
        if (!this._procuratorRoleDictionary) {
            this._procuratorRoleDictionary = this.http.get<Array<EventTypeDictModel>>(`${this.API_URL}/procurator-roles`);
        }
        return this._procuratorRoleDictionary;
    }

    public getEventTypes(): Observable<Array<EventTypeDictModel>> {
        if (!this._eventTypeDictionary) {
            this._eventTypeDictionary = this.http.get<Array<EventTypeDictModel>>(`${this.API_URL}/events`);
        }
        return this._eventTypeDictionary;
    }

    public getEventSubTypes(): Observable<Array<EventSubTypeDictModel>> {
        if (!this._eventSubtypeDictionary) {
            this._eventSubtypeDictionary = this.http.get<Array<EventSubTypeDictModel>>(`${this.API_URL}/event-subtypes`);
        }
        return this._eventSubtypeDictionary;
    }

    public getExternalPersonById(id: number): Observable<ExternalPersonModel> {
        return this.http.get<ExternalPersonModel>(`${ApiUtils.EXTERNAL_PERSON_API_URL}/${id}`);
    }


    public getContactHistoryEventTypes(): Observable<Array<DictModel>> {
        return this.http.get<Array<DictModel>>(`${ApiUtils.CONTACTS_HISTORY_API_URL}/eventTypes`);
    }

    public getPartnerNamesByPhrase(searchPhrase: string): Observable<DictModel[]> {
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        return searchPhrase && this.http.get<Array<DictModel>>(`${this.API_URL}/partner-names?namePhrase=${searchPhrase}`, {headers}) || of([]);
    }

    public getEntrepreneurNamesByPhrase(searchPhrase: string): Observable<DictModel[]> {
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        return searchPhrase && this.http.get<Array<DictModel>>(`${this.API_URL}/entrepreneur-names?namePhrase=${searchPhrase}`, {headers}) || of([]);
    }

    public getStreetByPhrase(searchPhrase: string): Observable<DictModel[]> {
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        return searchPhrase && this.http.get<Array<DictModel>>(`${this.API_URL}/streets?namePhrase=${searchPhrase}`, {headers}) || of([]);
    }

    public getTownByPhrase(searchPhrase: string): Observable<DictModel[]> {
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        return searchPhrase && this.http.get<Array<DictModel>>(`${this.API_URL}/towns?namePhrase=${searchPhrase}`, {headers}) || of([]);
    }
}


