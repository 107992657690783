import { Component, Input } from '@angular/core';
import { ChartNgModel } from 'src/app/_utils/chart.util';
import {ChartResultModeEnum} from '../../_enums/chart-result-mode.enum';

@Component({
    selector: 'app-finance-chart',
    templateUrl: './finance-chart.component.html',
    styleUrls: ['./finance-chart.component.scss'],
})
export class FinanceChartComponent {

    @Input() chart: ChartNgModel;
    @Input() chartMode: ChartResultModeEnum;

    options = {
        plugins: {
            // Change options for ALL labels of THIS CHART
            datalabels: {
                color: '#36A2EB'
            }
        },
        tooltips: {
            mode: 'x',
            intersect: false,
            callbacks: {

                //TODO: zmiana title

                // title: function (tooltipItem, data) {
                //
                //     if(tooltipItem && tooltipItem[0] && tooltipItem[0].xLabel){
                //         let date = new Date(tooltipItem[0].xLabel);
                //
                //         return date.toLocaleDateString('pl-Pl', { year: 'numeric', month: 'long' });
                //     }
                //     return '';
                //
                // },


                // labelColor: function(tooltipItem, chart) {
                //
                //     console.log(chart);
                //
                //     return {
                //         backgroundColor: 'rgb(255, 0, 0)'
                //     };
                // },
                label: (tooltipItem, data) => {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';

                    if (label) {
                        label = label.substring(0, 11);
                        label += ': ';
                    }
                    if (this.chartMode === ChartResultModeEnum.PRICE) {
                        label += (Math.round(tooltipItem.yLabel * 100) / 100).toLocaleString('pl-PL', {
                            style: 'currency',
                            currency: 'PLN'
                        });
                    } else {
                        label += (Math.round(tooltipItem.yLabel * 100) / 100).toLocaleString('pl-PL', {
                            useGrouping: true,
                            style: "decimal"
                        });
                    }
                    return label;
                }
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    callback: (value, index, values) => {
                        if (this.chartMode === ChartResultModeEnum.PRICE) {
                            return value.toLocaleString('pl-PL', {style: 'currency', currency: 'PLN'});
                        } else {
                            return value.toLocaleString('pl-PL');
                        }
                    }
                }
            }]
        }
    };


    selectData(event) {
        console.log(event);
    }

}
