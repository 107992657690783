import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PartnerDetailsScreenService } from '../partner-details-screen.service';


@Component({
    selector: 'app-add-partner-dialog',
    templateUrl: './confirm-removing-partner-dialog.component.html',
    styleUrls: ['./confirm-removing-partner-dialog.component.scss']
})
export class ConfirmRemovingPartnerDialogComponent {


    constructor(private readonly detailsScreenService: PartnerDetailsScreenService,
                private _dialogRef: MatDialogRef<ConfirmRemovingPartnerDialogComponent>) {
    }

    protected removePartner() {
        this._dialogRef.close(true);
    }

    protected doNotRemove() {
        this._dialogRef.close(false);
    }


}