import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


/** Custom header component for datepicker. */
@Component({
    selector: 'example-header',
    styles: [`
        .example-header {
            display: flex;
            align-items: center;
            padding: 0.5em;
        }

        .example-header-label {
            flex: 1;
            height: 1em;
            font-weight: 500;
            text-align: center;
        }

    `],
    template: `
        <div class="example-header">
            <button mat-icon-button (click)="previousClicked()">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <span class="example-header-label">{{periodLabel}}</span>
            <button mat-icon-button (click)="nextClicked()">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomDatepickerHeader<D> implements OnDestroy {
    private destroyed = new Subject<void>();

    constructor(
        private calendar: MatCalendar<D>, private dateAdapter: DateAdapter<D>,
        @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
        calendar.stateChanges
            .pipe(takeUntil(this.destroyed))
            .subscribe(() => cdr.markForCheck());
    }

    get periodLabel() {
        return this.dateAdapter
            .format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel)
            .toLocaleUpperCase();
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    previousClicked() {
        this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
    }

    nextClicked() {
        this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
    }
}
