import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DictionariesEnum } from '../../_enums/dictionaries-enum';
import { DictModel } from '../../_models/dict.model';
import { DictionariesService } from '../../_services/dictionaries.service';

@Injectable()
export class ChipService {

    constructor(private readonly httpClient: HttpClient, private readonly dictionariesService: DictionariesService) {
    }


    public getDictionary(dictType: DictionariesEnum): Observable<Array<DictModel>> {
        switch (dictType) {
            case DictionariesEnum.PEOPLE_OPERATIONS:
                return this.dictionariesService.getOperationsPeople();

            case DictionariesEnum.DISTRICTS:
                return this.dictionariesService.getDistricts();

            case DictionariesEnum.BRANDS:
                return this.dictionariesService.getBrandDictionary();

            case DictionariesEnum.CHAINS:
                return this.dictionariesService.getAllChains();

            case DictionariesEnum.COOPERATION_STATUS:
                return this.dictionariesService.getCooperationStatues();

            case DictionariesEnum.COOPERATION_TYPE:
                return this.dictionariesService.getCooperationType();

            case DictionariesEnum.CONCERN_COOPERATION_TYPE:
                return this.dictionariesService.getConcernCooperationStatus();

            case DictionariesEnum.GRADES:
                return this.dictionariesService.getGradeDict();

            case DictionariesEnum.INFO_SOURCE:
                return this.dictionariesService.getInfoSourceType();

            case DictionariesEnum.PARTNER_TYPES:
                return this.dictionariesService.getPartnerTypes();

            case DictionariesEnum.PEOPLE_TOA:
                return this.dictionariesService.getToaPeople();

            case DictionariesEnum.PHARMACY_TYPES:
                return this.dictionariesService.getPharmacyType();

            case DictionariesEnum.TRUTHY:
                return this.dictionariesService.getTruthy();

            case DictionariesEnum.PARTNER_NAMES:
                return this.dictionariesService.getPartnerNames();

            case DictionariesEnum.ENTREPRENEUR_NAMES:
                return this.dictionariesService.getEntrepreneurNames();

            case DictionariesEnum.STREETS:
                return this.dictionariesService.getStreets();

            case DictionariesEnum.TOWNS:
                return this.dictionariesService.getTowns();

            case DictionariesEnum.TASK_PRIORITIES:
                return this.dictionariesService.getTaskPriorities();

            case DictionariesEnum.TASK_STATUSES:
                return this.dictionariesService.getTaskStatuses();

            case DictionariesEnum.TEAMS:
                return this.dictionariesService.getTeams();

            case DictionariesEnum.EVENTS:
                return this.dictionariesService.getEventTypes();

            case DictionariesEnum.EVENT_SUBTYPES:
                return this.dictionariesService.getEventSubTypes();

            case DictionariesEnum.CONTACT_HISTORY_EVENT_TYPE:
                return this.dictionariesService.getContactHistoryEventTypes();

            case DictionariesEnum.FINANCES_PRODUCTS_SOLGAR:
                return this.dictionariesService.getFinancesProductsSolgar();

            case DictionariesEnum.FINANCES_PRODUCTS_TERRANOVA:
                return this.dictionariesService.getFinancesProductsTerranova();

            case DictionariesEnum.PRODUCTS:
                return this.dictionariesService.getProducts();

            case DictionariesEnum.PRODUCT_CATEGORIES:
                return this.dictionariesService.getProductCategories();

        }
    }

}
