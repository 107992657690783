export class AddressModel {
    constructor(public town: string,
                public street: string,
                public postalCode: string,
                public buildingNo: string,
                public apartmentNo: string,
                public districtId: number,
                public latitude: number,
                public longitude: number,
                public readonly id?: number) {
    }
}
