import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../loader-component/loader.service';

export const LoaderInterceptorSkipHeader = 'X-Skip-Loader-Interceptor';


@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    private totalRequests: number = 0;

    constructor(public loaderService: LoaderService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has(LoaderInterceptorSkipHeader)) {
            const headers = req.headers.delete(LoaderInterceptorSkipHeader);
            return next.handle(req.clone({ headers }));
        }
        this.totalRequests++;
        this.loaderService.show();
        return next.handle(req).pipe(
            finalize(() => this.decreaseRequests())
        );
    }

    private decreaseRequests() {
        this.totalRequests--;
        if (!this.totalRequests) {
            this.loaderService.hide();
        }
    }

}
