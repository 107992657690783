import { ChainDetailsModel } from '../../_models/chain-details.model';
import { ShortageModel } from '../../_models/shortage-model';
import {DialogResultEnum} from '../../_enums/dialog-result.enum';
import {Subject} from 'rxjs';

export class ChainsDialogModel {
    chainModel: ChainDetailsModel;
    chainShortages: Array<ShortageModel>;
    editOnly?: boolean;
    notification?: Subject<DialogResultEnum>;
}
