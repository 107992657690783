import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminPageService } from '../../admin-page.service';
import { AdminTeamTabService } from './admin-team-tab.service';
import { TeamModel } from '../../../../_models/team.model';
import { MessageSeverityEnum } from '../../../../_enums/message-severity.enum';
import { MessageService } from '../../../../_services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { AdminTeamTabDialogComponent } from './dialog/admin-team-tab-dialog.component';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-admin-team-tab',
    templateUrl: './admin-team-tab.component.html',
    styleUrls: ['./admin-team-tab.component.scss']
})
export class AdminTeamTabComponent implements OnInit, OnDestroy {
    public value: string = '';
    public teams = [];
    private _destroy: Subject<any> = new Subject();


    constructor(private readonly _adminTeamTabService: AdminTeamTabService,
                private readonly _adminPageService: AdminPageService,
                private readonly _messageService: MessageService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.initiateData();
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    protected openDialog(row: TeamModel) {
        let model: TeamModel;

        if (!row) {
            model = {
                id: null,
                name: null,
                leaderId: null,
                memberIds: null,
                newTeam: true
            };
        } else {
            model = _.cloneDeep(row);
        }

        const dialogRef = this.dialog.open(AdminTeamTabDialogComponent,
            {
                maxWidth: '100%',
                panelClass: 'details-dialog',
                data: model,
            }
        );

        dialogRef.afterClosed()
            .pipe(takeUntil(this._destroy))
            .subscribe((result: boolean) => {
                    if (!result) {
                        return;
                    }
                    if (model.newTeam) {
                        this._adminTeamTabService.addTeam(model).pipe(
                            tap(() => this._messageService.setSnackbar({
                                    durationInSecond: 5,
                                    severity: MessageSeverityEnum.success,
                                    text: `Dodano nowy zespół`
                                }),
                                () => this._messageService.setSnackbar({
                                    durationInSecond: 5,
                                    severity: MessageSeverityEnum.failure,
                                    text: `Nie dodano nowego zespołu`
                                })
                            ),
                            switchMap(() => this._adminTeamTabService.getTeams())
                        ).subscribe(res => {
                            this.initiateData();
                        });
                    } else {
                        this._adminTeamTabService.editTeam(model).pipe(
                            tap(() => this._messageService.setSnackbar({
                                    durationInSecond: 5,
                                    severity: MessageSeverityEnum.success,
                                    text: `Dane zostały pomyślnie zmienione`
                                }),
                                () => this._messageService.setSnackbar({
                                    durationInSecond: 5,
                                    severity: MessageSeverityEnum.failure,
                                    text: `Nie udało się zmienić danych`
                                })
                            ),
                            switchMap(() => this._adminTeamTabService.getTeams())
                        ).subscribe(res => {
                            this.initiateData();
                        });
                    }

                }
            );

    }

    public deleteTeam(team: TeamModel) {
        this._adminTeamTabService.deleteTeam(team.id).subscribe(res => {
            this.initiateData();
        });
    }

    private initiateData() {
        this._adminTeamTabService.getTeams().subscribe(team => {
            this.teams = team;
        });
    }

}
