import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DownloadAuthModel } from '../_models/auth/download-auth.model';
import { UtilsService } from '../_services/utils.service';
import { ChipElement } from '../filter-chips/chip-component/chip.component';
import { ChipFiltersUtils } from './chip-filters.utils';
import { LoaderInterceptorSkipHeader } from '../_helpers/loader.inteceptor';
import { tap } from 'rxjs/operators';
import { FileManagementService } from '../inputs/file-upload/file-management.service';

@Injectable()
export class ExcelUtils {


    constructor(private readonly http: HttpClient, private readonly utilsService: UtilsService) {

    }

    public exportToExcel(systemNameHeaders: Array<string>, queryParams: string, filtersWithValues: Array<ChipElement>, screenType: string, exportType?: string): void {
        let params = new HttpParams();
        systemNameHeaders.slice(0, 9).forEach(
            column => {
                params = params.append('columns', column.toString());
            }
        );
        if (queryParams) {
            params = params.append('sort', queryParams);
        }
        this.utilsService.getExcelFile()
            .subscribe((response: DownloadAuthModel) => {
                params = params.append('downloadId', response.downloadId);
                if (filtersWithValues.length === 0 || exportType !== 'all') {
                    window.open(`/api/${screenType}/export/${exportType}?${params}`);
                } else {
                    params = ChipFiltersUtils.prepareParams(filtersWithValues, params);
                    window.open(`api/${screenType}/export?${params}`);
                }
            });
    }

    public downloadExcelFile(params: HttpParams, screenType: string, exportType?: string, fileName?: string) {
        const url = `/api/${screenType}/export/${exportType}?${params}`;
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        this.http.get(url, {headers: headers, observe: 'response', responseType: 'blob'}).pipe(
            tap((resp: HttpResponse<Blob>) => FileManagementService.download(resp.body, fileName))
        ).subscribe();
    }

}
