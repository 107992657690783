import { FormControl } from '@angular/forms';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';

import { ChartResultsResolutionEnum } from '../../_enums/chart-results-resolution.enum';
import { PeriodPicker } from 'src/app/finances/period-picker/period-picker.component';
import { ChartResultsResolutionModel } from 'src/app/_models/chart-results-resolution.model';
import { ScreenTypeEnum } from './../../_enums/screen-type.enum';

@Component({
    selector: 'app-resolution-picker',
    templateUrl: './resolution-picker.component.html',
    styleUrls: ['./resolution-picker.component.scss'],
})
export class ResolutionPickerComponent {
    resolutionControl = new FormControl(null);
    resolutions: Array<ChartResultsResolutionModel> = [
        {value: ChartResultsResolutionEnum.DAYS, displayName: 'Dni'},
        {value: ChartResultsResolutionEnum.WEEKS, displayName: 'Tygodnie'},
        {value: ChartResultsResolutionEnum.MONTHS, displayName: 'Miesiące'},
        {value: ChartResultsResolutionEnum.YEARS, displayName: 'Lata'},
    ];

    @Input() disableDaysIfLargeDateScope = false;
    @Input() periodPicker: PeriodPicker;
    @Input() set resolution(newValue: ChartResultsResolutionEnum) {
        if (newValue !== this.resolutionControl.value) {
            this.resolutionControl.setValue(newValue);
        }
    }
    @Output() resolutionChange = new EventEmitter<ChartResultsResolutionEnum>();

    getAvailableResolutions() {
        /*
        const fromDate = this.periodPicker.period.startDate;
        const endDate = this.periodPicker.period.endDate;
        const differenceBettwenDates = moment.duration(endDate.diff(fromDate)).asDays();
        return differenceBettwenDates > 30 && this.disableDaysIfLargeDateScope
                ? this.resolutions.filter(el => el.value !== ChartResultsResolutionEnum.DAYS)
                : this.resolutions;
         */
        return this.resolutions;
    }

    setPickedResolution() {
        const selectedResolution = this.resolutionControl.value;
        if (this.periodPicker.period && selectedResolution) {
            this.resolutionChange.emit(selectedResolution);
        }
    }
}
