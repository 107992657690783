import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-activate-consent-dialog',
    templateUrl: './confirm-activating-consent-dialog.component.html',
    styleUrls: ['./confirm-activating-consent-dialog.component.scss']
})
export class ConfirmActivatingConsentDialogComponent {


    constructor(private _dialogRef: MatDialogRef<ConfirmActivatingConsentDialogComponent>) {
    }

    protected activateConsent() {
        this._dialogRef.close(true);
    }

    protected doNotActivate() {
        this._dialogRef.close(false);
    }


}
