import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CogElementModel } from '../../_models/cog-element.model';

@Component({
    selector: 'app-cog-element',
    templateUrl: './cog-element.component.html',
    styleUrls: ['./cog-element.component.scss']
})
export class CogElementComponent {

    @Input()
    public element: CogElementModel;

    @Output()
    public moveUpEvent = new EventEmitter<CogElementModel>();

    @Output()
    public moveDownEvent = new EventEmitter<CogElementModel>();

    constructor() {
    }

    public moveSelfDown() {
        this.moveDownEvent.emit(this.element);
    }

    public moveSelfUp() {
        this.moveUpEvent.emit(this.element);
    }
}
