export class EventNotificationModel {
    constructor(public readonly id: number,
                public readonly personId: number,
                public readonly title: string,
                public readonly description: string,
                public readonly eventId: number,
                public readonly seen: boolean,
                public readonly toConfirm: boolean,
                public readonly dismissed: boolean,
                public readonly eventStartDate: string) {
    }
}
