import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MessageSeverityEnum } from '../../../_enums/message-severity.enum';
import { BenefitModel } from '../../../_models/benefit.model';
import { DictModel } from '../../../_models/dict.model';
import { AuthenticationService } from '../../../_services/authentication.service';
import { BenefitService } from '../../../_services/benefit.service';
import { DictionariesService } from '../../../_services/dictionaries.service';
import { MessageService } from '../../../_services/message.service';
import { dictCodeToValue } from '../../../_utils/dict-utils';
import { canSelectSncOrBenefit, canSelectTraining, canSelectBrandingBenefit } from '../../../_utils/auth-utils/auth-partner-details-benefit';

const APOTHECARY_TRAININGS = [
    3, 5, 7, 8
];
const SNC = 6;
const BENEFITS = [1 , 2, 4];

export class BenefitTypeModel {
    id?: number;
    type?: string;
    displayType?: string;
    valueType?: string;
    displayValue?: string;
    defaultValue?: number;
    brandId?: number;
}

@Component({
    selector: 'app-partner-benefit-tab',
    templateUrl: './benefit-tab.component.html',
    styleUrls: ['benefit-tab.component.scss']
})
export class BenefitTabComponent implements OnInit, OnDestroy {
    public displayedColumns: string[] = [
        'brand',
        'type',
        'creationDate',
        'startDate',
        'expirationDate',
        'usageDate',
        'value',
        'url',
        'action',
        'star'
    ];
    @Input() disabled: boolean;
    @Input() partnerId: number;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    benefitSource: MatTableDataSource<BenefitModel>;
    benefitTypeDict: BenefitTypeModel[];
    filteredBenefits: BenefitTypeModel[];
    brandDict: DictModel[];
    private tempFilter: string;
    private destroy$: Subject<void> = new Subject<void>();
    private pageSize: number = 20;
    private _currentUserId;

    constructor(private readonly authService: AuthenticationService,
                private readonly _dictService: DictionariesService,
                private _messageService: MessageService,
                private _cdr: ChangeDetectorRef,
                private _benefitService: BenefitService) {
    }

    private _benefits: BenefitModel[];

    get benefits(): BenefitModel[] {
        return this._benefits;
    }

    @Input() set benefits(value: BenefitModel[]) {
        this._benefits = value;
        this._setTableData(this._benefits);
    }

    private _currentUserRoles: number[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    ngOnInit(): void {
        this._dictService.getBrandDictionary().subscribe(val => this.brandDict = val);
        this._dictService.getBenefitTypes().subscribe(val => this.benefitTypeDict = val);
        this._currentUserId = this.authService.currentUserValue.personId;
    }

    isBenefitTypeDisabled(elem: BenefitTypeModel): boolean {
        if (elem.id == 1) {
            return !canSelectBrandingBenefit(this.currentUserRoles);
        } else if (APOTHECARY_TRAININGS.includes(elem.id)) {
            return !canSelectTraining(this.currentUserRoles);
        } else if (SNC === elem.id || BENEFITS.includes(elem.id)) {
            return !canSelectSncOrBenefit(this.currentUserRoles);
        }
        return false;
    }

    filterBenefits(brandId: number): BenefitTypeModel[] {
        if (!brandId) {
            return []
        }
        return this.benefitTypeDict.filter(value => !value.brandId || value.brandId == brandId)
    }

    brandChanged(element: BenefitModel, value: number) {
        element.brandId = value;
        if (element.benefitTypeId) {
            element.benefitTypeId = null;
        }
        this.valueChanged(element);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openCalendar(event, picker: MatDatepicker<any>) {
        picker.open();
        this._selectFocusedElement(event);
    }

    getBrandName(brandId: number): string {
        if (!this.brandDict) {
            return 'Nie znaleziono słownika marek';
        }
        return dictCodeToValue(brandId, this.brandDict);
    }

    public getPageSize(): number {
        return this.pageSize;
    }

    typeChanged(element: BenefitModel, value: number) {
        element.benefitTypeId = value;

        const type: BenefitTypeModel = this.benefitTypeDict?.find(benefit => benefit.id === value);
        if (type?.defaultValue) {
            element.value = type.defaultValue;
        }
        this.valueChanged(element);
    }

    addBenefit() {
        this._benefitService.addBenefit({partnerId: this.partnerId})
            .pipe(switchMap(() => this._benefitService.getBenefitByPartnerId(this.partnerId)))
            .subscribe((benefitModel: BenefitModel[]) => {
                this.benefits = benefitModel?.length && benefitModel || null;
            });
    }

    public setPageSize(event: PageEvent): void {
        this.pageSize = event.pageSize;
    }

    remove(event, element) {
        if (this.disabled || !this.canEditBenefits(element)) {
            return;
        }

        this._messageService.setActionDialog({
                severity: MessageSeverityEnum.warn,
                confirmTxt: 'Usuń',
                refuseTxt: 'Anuluj',
                callbackFn: () => {
                    this._benefitService.deleteByBenefitId(element.id)
                        .pipe(switchMap(() => this._benefitService.getBenefitByPartnerId(this.partnerId)))
                        .subscribe((benefitModel: BenefitModel[]) => {
                            this.benefits = benefitModel?.length && benefitModel || null;
                        });
                },
                headerText: 'Uwaga',
                text: 'Czy na pewno chcesz usunąć ten rabat/korzyść'
            }
        );
    }

    canAddBenefits(): boolean {
        return this.authService.canAddBenefits();
    }

    isApothecaryTraining(element: BenefitModel): boolean {
        return !element.benefitTypeId || APOTHECARY_TRAININGS.includes(element.benefitTypeId);
    }

    benefitFilledProperly(benefit: BenefitModel): boolean {
        //benefit.urls
        return !!benefit.startDate && !!benefit.benefitTypeId && !!benefit.brandId && !!benefit.value && !!benefit.usageDate && !!benefit.expirationDate || !!benefit.creationDate;
    }

    showInvalid(element) {
        if (this.notActiveAndInvalid(element) && !this.disabled && this.canEditBenefits(element)) {
            element.invalid = true;
            return;
        }
    }

    isValueOptional(element: BenefitModel) {
        return [1, 6].includes(element.benefitTypeId);
        //branding i snc -> nie wymagana
    }

    notActiveAndInvalid(element: BenefitModel) {
        return !element.active && !this.benefitFilledProperly(element);
    }

    activeChange(value: boolean, element: BenefitModel) {
        element.invalid = false;
        const previous: boolean = element.active;

        element.active = value;

        this._benefitService.updateBenefit(element.id, element).subscribe(
            () => {
            },
            () => {
                element.active = previous;
                this._messageService.setSnackbar({
                    durationInSecond: 5,
                    severity: MessageSeverityEnum.failure,
                    text: `Nie udało się zmienić statusu korzyści / rabatu`
                });
            }
        );
    }

    applyFilter(filterValue: string) {
        this.tempFilter = filterValue;
        this.benefitSource.filter = filterValue.trim().toLowerCase();
    }

    canEditBenefits(element?: BenefitModel) {
        if (element) {
            return this.authService.canEditBenefits() || this.canEditBenefitForApothecaryTraining(element);
        }
        return this.authService.canEditBenefits();
    }

    valueChanged(element: BenefitModel): void {
        if (element.active && !this.benefitFilledProperly(element)) {
            element.invalid = true;
            return;
        }

        this._benefitService.updateBenefit(element.id, element).subscribe(
            () => {
            },
            () => {
                this._messageService.setSnackbar({
                    durationInSecond: 5,
                    severity: MessageSeverityEnum.failure,
                    text: `Nie udało się zaktualizować korzyści / rabatu`
                });
            });
    }

    toMoment(date: Moment | string) {
        if (typeof date === 'string') {
            return moment(date);
        }
        return date;
    }

    filesAllowedForBenefit(element: BenefitModel): boolean {
        return [3, 4, 5, 7, 8].includes(element.benefitTypeId);
    }

    private canEditBenefitForApothecaryTraining(element: BenefitModel) {
        return this.authService.isCoordinator() && this.isApothecaryTraining(element);
    }

    private _selectFocusedElement(event) {
        event.target.select();
    }

    private _setTableData = (benefit: BenefitModel[]) => {
        this.benefitSource = new MatTableDataSource<BenefitModel>(benefit);
        this.benefitSource.paginator = this.paginator;
        this.benefitSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'brand':
                    return this.brandDict && dictCodeToValue(item.brandId, this.brandDict) || '';
                case 'type':
                    return this.benefitTypeDict?.find(val => val?.id === item?.benefitTypeId).displayType || '';
                default:
                    return item[property];
            }
        };
        this.benefitSource.sort = this.sort;
        this.benefitSource.filterPredicate = (data, filter) => {
            const options = {
                year: 'numeric', month: 'numeric', day: 'numeric',
                hour: 'numeric', minute: 'numeric', second: 'numeric',
                hour12: false,
            };
            const brand = this.brandDict && dictCodeToValue(data.brandId, this.brandDict) || '';
            const type = this.benefitTypeDict?.find(val => val?.id === data?.benefitTypeId).displayType || '';
            const startDate = data?.startDate && new Intl.DateTimeFormat('pl-PL', options).format(this.toMoment(data.startDate).toDate()) || '';
            const expirationDate = data?.expirationDate && new Intl.DateTimeFormat('pl-PL', options).format(this.toMoment(data.expirationDate).toDate()) || '';
            const usageDate = data?.usageDate && new Intl.DateTimeFormat('pl-PL', options).format(this.toMoment(data.usageDate).toDate()) || '';
            const value = data?.value || '';

            let dataStr = brand + type + startDate + expirationDate + usageDate + value;


            dataStr = dataStr?.toLowerCase() || '';
            filter = filter?.toLowerCase();
            return dataStr.indexOf(filter) != -1;
        };

        if (this.tempFilter) {
            this.applyFilter(this.tempFilter);
        }
    };
}
