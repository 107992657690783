import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

import { debounceTime, map } from 'rxjs/operators';


@Component({
    selector: 'app-search-as-you-type-input',
    templateUrl: './search-as-you-type-input.component.html',
    styleUrls: ['./search-as-you-type-input.component.scss']
})
export class SearchAsYouTypeInputComponent implements AfterViewInit, OnDestroy {

    @Input() public searchTxt: string;
    @Input() public placeholder: string;
    @Input() public debounceTime = 300;
    @Input() public tooltipTxt: string;
    @Input() public inputWidth;
    @Output() public newSearch = new EventEmitter<string>();
    public hovered = false;
    @ViewChild('searchInput', {static: true}) private appSearch: ElementRef;
    private searchSubscription: Subscription;

    public get width(): string {
        return this.inputWidth ? `${this.inputWidth}px` : 'auto';
    }

    public ngAfterViewInit(): void {
        const inputElement = this.appSearch.nativeElement as HTMLInputElement;
        const searchPhrase$ = fromEvent(inputElement, 'input').pipe(
            debounceTime(this.debounceTime),
            map((event: Event) => (<HTMLInputElement>event.target).value)
        );

        this.searchSubscription = searchPhrase$.subscribe(searchPhrase => {
            this.notifyOfSearch(searchPhrase);
        });
    }

    public ngOnDestroy(): void {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
    }

    public clearSearch(): void {
        this.notifyOfSearch('');
        this.searchTxt = '';
    }

    private notifyOfSearch(searchTxt: string): void {
        this.newSearch.emit(searchTxt);
    }
}
