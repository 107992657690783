import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BrandCooperationModel} from '../../../_models/brand-cooperation.model';
import {DictModel} from '../../../_models/dict.model';
import {AuthenticationService} from '../../../_services/authentication.service';
import {MatDialog} from '@angular/material/dialog';
import {BrandCooperationService} from '../../../_services/brand-cooperation.service';
import {DictionariesService} from '../../../_services/dictionaries.service';
import {ArchiveFinancialIdService} from '../archive-financial-id/archive-financial-id.service';
import {EtailerService} from '../../../_services/etailer.service';
import {
    canAddBrand,
    canAddPersonalContact,
    canEditBrandAmbassadorId,
    canEditBrandPersonalContacts,
    canEditDateOfOnlineSalesStart,
    canEditFinancialId,
    canEditIsOnlineSeller,
    canEditName,
    canEditOnBoarderId,
    canEditOnboardingDate,
    canEditOwnerId,
    canEditSoz,
    canArchiveFinancialId,
    canEditStatus,
    canEditCooperationDate,
    canEditToaId,
    canEditWebPage,
    canRemoveBrand,
    canRemovePersonalContact,
    canSetStatusAsWspolpracaOrZakonczonaWspolprace,
    canEditEtailer,
    canAddEtailer,
    canGenerateEtailer
} from 'src/app/_utils/auth-utils/auth-partner-details-brand';
import {PartnerDetailsDialogModel} from '../partner-details-dialog.model';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {AuthRolesEnum} from '../../../_utils/auth-utils/auth-commons';
import {MatTableDataSource} from '@angular/material/table';
import {ArchiveFinancialIdModel} from '../archive-financial-id/archive-financial-id.model';
import {ArchiveFinancialIdDialogComponent} from '../archive-financial-id/archive-financial-id-dialog.component';
import {BrandInfoModel} from '../../../entrepreneurs/entrepreneur-details-dialog/mini/brand-info.model';
import {ReplaySubject, Subject} from 'rxjs';
import {FormControl} from '@angular/forms';
import {ExternalPersonModel} from '../../../_models/external-person.model';
import {AddContactDialogComponent} from '../add-contact-dialog/add-contact-dialog.component';
import {DialogUtils} from '../../../_utils/dialog.utils';
import {EntrepreneurBrandCooperationModel} from '../../../_models/entrepreneur-brand-cooperation.model';
import {MatSelectChange} from '@angular/material/select';
import {BrandEtailer} from '../../../_models/brand-etailer.model';
import {ActionDialog} from '../../../messaging/action-dialog/action-dialog.component';
import {MessageSeverityEnum} from '../../../_enums/message-severity.enum';
import {MessageService} from '../../../_services/message.service';

@Component({
    selector: 'app-single-brand-tab',
    templateUrl: './single-brand-tab.component.html',
    styleUrls: ['single-brand-tab.component.scss']
})
export class SingleBrandTabComponent implements OnInit, OnDestroy {

    @Input() disabled: boolean;
    @Input() showInvalidFields: boolean;
    @Input() partnerDetailsDialogModel: PartnerDetailsDialogModel;
    @Input() brandDict: DictModel;
    _brand: BrandCooperationModel;

    public brandActive: boolean;

    etailerLoaded: boolean;

    public cooperationStatusDict: Array<DictModel>;
    public filteredCooperationStatus: Array<DictModel> = [];
    public dictBrands: Array<DictModel>;
    public cooperationTypeDict: Array<DictModel>;
    public peopleDict: Array<DictModel>;
    public ediFlagsDict: Array<DictModel> = [];
    public filteredOnBoarders: Array<DictModel> = [];
    public filteredOwners: Array<DictModel> = [];
    public toaDict: Array<DictModel>;
    public filteredToas: Array<DictModel>;
    public allExternals: Array<DictModel>;

    public searching: boolean = false;
    public ambassadorSearchCtrl: FormControl = new FormControl({value: '', disabled: this.disabled});
    public filteredExternals1$: ReplaySubject<DictModel[]> = new ReplaySubject<DictModel[]>(1);
    public nullValue: DictModel = {id: null, value: 'Usuń'};

    protected _onDestroy = new Subject<void>();

    archivedDataSource: MatTableDataSource<ArchiveFinancialIdModel>;
    displayedColumns: string[] = [
        'brand',
        'financialId',
        'date',
        'description'
    ];

    constructor(public readonly authService: AuthenticationService,
                private readonly _dialog: MatDialog,
                private readonly apiService: BrandCooperationService,
                private readonly dictService: DictionariesService,
                private readonly archiveFinancialIdService: ArchiveFinancialIdService,
                private readonly etailerService: EtailerService,
                private readonly messageService: MessageService
    ) {
    }

    _archivedData: ArchiveFinancialIdModel[];

    get archivedData(): ArchiveFinancialIdModel[] {
        return this._archivedData;
    }

    @Input() set archivedData(value: ArchiveFinancialIdModel[]) {
        if (value) {
            this._archivedData = value.filter(value => value.brand == this.brandDict.value);
        } else {
            this._archivedData = [];
        }
        this.archivedDataSource = new MatTableDataSource<ArchiveFinancialIdModel>(value);
    }

    @Input() set brand(brand: BrandCooperationModel) {
        if (brand && brand.etailers && brand.etailers.length > 0) {
            brand.etailers = brand.etailers.sort((a, b) => a.id - b.id);
        }
        this._brand = brand;
    }

    get brand() : BrandCooperationModel {
        return this._brand;
    }

    archiveFinancialId(brand: BrandCooperationModel) {
        const archiveIdDialog = this._dialog.open(ArchiveFinancialIdDialogComponent,
            {
                width: '470px'
            });

        archiveIdDialog.afterClosed().subscribe((result: ArchiveFinancialIdModel) => {
            if (!result) {
                return;
            }
            this.archiveFinancialIdService.archiveFinancialId(brand.id, result).pipe(
                tap(() => brand.financialId = result.financialId),
                switchMap(() => this.archiveFinancialIdService.getArchiveData(this.partnerDetailsDialogModel.partnerDetailsModel.id))
            ).subscribe((data) =>
                    this.archivedDataSource = new MatTableDataSource<ArchiveFinancialIdModel>(data)
            );
        });
    }

    ngOnInit() {
        this.dictService.getBrandDictionary().subscribe((brandCooperation: Array<DictModel>) => {
            this.dictBrands = brandCooperation;
        });

        this.dictService.getEdiFlags().subscribe((ediFlagsDict: Array<DictModel>) => {
            this.ediFlagsDict = ediFlagsDict;
        });

        this.dictService.getPeople().subscribe((peopleDict: Array<DictModel>) => {
            this.peopleDict = peopleDict;
            this.filteredOnBoarders = peopleDict;
            this.filteredOwners = peopleDict;
        });
        this.dictService.getToaPeople().subscribe((toaDict: Array<DictModel>) => {
            this.toaDict = toaDict;
            this.filteredToas = toaDict;
        });

        this.dictService.getCooperationStatues().subscribe((cooperationStatus: Array<DictModel>) => {
            this.cooperationStatusDict = cooperationStatus;
            this.filteredCooperationStatus = cooperationStatus;
        });
        this.dictService.getCooperationType().subscribe((agreementStatus: Array<DictModel>) => {
            this.cooperationTypeDict = agreementStatus;
            this.cooperationTypeDict.sort((a, b) => b.id - a.id);
        });
        this.dictService.getAllExternalPerson().subscribe(data => this.allExternals = data);

        this.brandActive = this.brand != null;
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    getInfoModelForBrand(brandId: number) : BrandInfoModel {
        if (brandId == 1) {
            return new BrandInfoModel("Solgar", "assets/solgar-mini.png", "brand-solgar")
        }
        return new BrandInfoModel("Terranova", "assets/terranova-mini.png", "brand-terranova")
    }

    public getBrandName(brandId: number) {
        const brand = this.dictBrands && this.dictBrands.find(brand => brand.id === brandId);
        return brand && `${brand.value}` || '';
    }

    public getBrandShortName(brandId: number) {
        return brandId == 2 ? "tnc" : "snc";
    }

    private _currentUserRoles: number[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    public editBrand(brandCooperationModel: BrandCooperationModel): void {
        if (!this.disabled && !this.partnerDetailsDialogModel.editedBrandCooperation.includes(brandCooperationModel) && !this.partnerDetailsDialogModel.addedBrandCooperation.includes(brandCooperationModel)) {
            this.partnerDetailsDialogModel.editedBrandCooperation.push(brandCooperationModel);
        }

        this.partnerDetailsDialogModel.partnerSaved = false;
    }

    public editBrandAuthorization(event: MatCheckboxChange, brandCooperationModel: BrandCooperationModel): void {
        if (!this.disabled && !this.partnerDetailsDialogModel.editedBrandCooperation.includes(brandCooperationModel) && !this.partnerDetailsDialogModel.addedBrandCooperation.includes(brandCooperationModel)) {
            this.partnerDetailsDialogModel.editedBrandCooperation.push(brandCooperationModel);
        }

        this.partnerDetailsDialogModel.partnerSaved = false;

        if (!event.checked) {
            brandCooperationModel.rightToProductsForSncPoints = false;
            brandCooperationModel.rightToReplanishment = false;
        }
    }

    public get canAddBrand(): boolean {
        return canAddBrand(this.currentUserRoles);
    }

    public get canRemoveBrand(): boolean {
        return canRemoveBrand(this.currentUserRoles);
    }

    public get canEditName(): boolean {
        return canEditName(this.currentUserRoles);
    }

    public get canArchiveFinancialId(): boolean {
        return canArchiveFinancialId(this.currentUserRoles);
    }

    public get canEditOnBoarderId(): boolean {
        return canEditOnBoarderId(this.currentUserRoles);
    }

    public get canEditFinancialId(): boolean {
        return canEditFinancialId(this.currentUserRoles);
    }

    public get canEditOnboardingDate(): boolean {
        return canEditOnboardingDate(this.currentUserRoles);
    }

    public get canEditOwnerId(): boolean {
        return canEditOwnerId(this.currentUserRoles);
    }

    public get canEditToaId(): boolean {
        return canEditToaId(this.currentUserRoles);
    }

    public get canEditIsOnlineSeller(): boolean {
        return canEditIsOnlineSeller(this.currentUserRoles);
    }

    public get canEditDateOfOnlineSalesStart(): boolean {
        return canEditDateOfOnlineSalesStart(this.currentUserRoles);
    }

    public get canEditWebPage(): boolean {
        return canEditWebPage(this.currentUserRoles);
    }

    public get canEditBrandAmbassadorId(): boolean {
        return canEditBrandAmbassadorId(this.currentUserRoles);
    }

    public get canAddPersonalContact(): boolean {
        return canAddPersonalContact(this.currentUserRoles);
    }
    public get canRemovePersonalContact(): boolean {
        return canRemovePersonalContact(this.currentUserRoles);
    }

    public get canEditPersonalContacts(): boolean {
        return canEditBrandPersonalContacts(this.currentUserRoles);
    }

    public get canEditEtailer(): boolean {
        return canEditEtailer(this.currentUserRoles);
    }

    public get canAddEtailer(): boolean {
        return canAddEtailer(this.currentUserRoles);
    }

    public get canGenerateEtailer(): boolean {
        return canGenerateEtailer(this.currentUserRoles);
    }

    public get canEditSoz(): boolean {
        return canEditSoz(this.currentUserRoles);
    }

    get canEditCooperationDate() : boolean {
        return canEditCooperationDate(this.currentUserRoles)
    }

    public canEditStatus(brand: BrandCooperationModel, val: DictModel): boolean {
        const roles = this.currentUserRoles;
        if (roles.includes(AuthRolesEnum.SYSTEM_ADMIN)) {
            return true;
        }

        if (roles.includes(AuthRolesEnum.TOA_MANAGER)) {
            return true;
        }

        if (roles.includes(AuthRolesEnum.TOA)) {
            return true;
        }

        if (roles.includes(AuthRolesEnum.LEGAL_DEPARTMENT)) {
            return true;
        }

        if (roles.includes(AuthRolesEnum.REGIONAL_COORDINATOR)) {
            if ([1, 7].includes(brand.cooperationStatusId)) {
                return false
            }

            return ![1, 7].includes(val.id);
        }

        return false;
    }

    public filterCooperationStatus(event) {
        if (!this.cooperationStatusDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredCooperationStatus = this.cooperationStatusDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredCooperationStatus = this.cooperationStatusDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public filterOnBoarder(event) {
        if (!this.peopleDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredOnBoarders = this.peopleDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredOnBoarders = this.peopleDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public filterOwners(event) {
        if (!this.peopleDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredOwners = this.peopleDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredOwners = this.peopleDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public filterToas(event) {
        if (!this.toaDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredToas = this.toaDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredToas = this.toaDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    brandLoyaltyTooltip(brand: BrandCooperationModel): string {
        if (!brand.authorizedPoint) {
            return "Punkt bez autoryzacji nie ma prawa do punktów " + (brand.brandId == 1 ? "snc" : "tnc");
        }

        let partnerDetailsExtraModel = this.partnerDetailsDialogModel.partnerDetailsExtraModel;

        let concernLoyaltyProgram = brand.brandId === 1 ? partnerDetailsExtraModel.solgarConcernLoyaltyProgram : partnerDetailsExtraModel.terranovaConcernLoyaltyProgram;

        if (concernLoyaltyProgram === null) {
            return "Przedsiebiorca nie znaleziony"
        }
        if (!concernLoyaltyProgram) {
            return "Przedsiebiorca nie zezwala na punkty " + (brand.brandId == 1 ? "snc" : "tnc")
        }
        return "";
    }

    brandLoyaltyTooltipDisabled(brand: BrandCooperationModel): boolean {
        if (this.disabled) {
            return true;
        }

        if (!brand.authorizedPoint) {
            return false;
        }

        let partnerDetailsExtraModel = this.partnerDetailsDialogModel.partnerDetailsExtraModel;
        let concernLoyaltyProgram = brand.brandId === 1 ? partnerDetailsExtraModel.solgarConcernLoyaltyProgram : partnerDetailsExtraModel.terranovaConcernLoyaltyProgram;

        if (concernLoyaltyProgram === null) {
            return false;
        }

        return concernLoyaltyProgram;
    }

    isDisabledCooperation(brand: BrandCooperationModel, option: DictModel) {

        let partnerDetailsExtraModel = this.partnerDetailsDialogModel.partnerDetailsExtraModel;

        let concernCooperationStatusId = brand.brandId === 1 ? partnerDetailsExtraModel.solgarConcernCooperationStatusId : partnerDetailsExtraModel.terranovaConcernCooperationStatusId;

        if (concernCooperationStatusId === null) {
            return true;
        }

        switch (option.id) {
            case 1:
                return concernCooperationStatusId !== 9
            // 2 juz nie istnieje
            case 3:
                return concernCooperationStatusId !== 7
            case 4:
                return ![4, 5, 6].includes(concernCooperationStatusId)
            case 5:
                return concernCooperationStatusId !== 12
        }

        return true
    }

    generateEtailerNumber(brand: BrandCooperationModel, etailer: BrandEtailer) {
        if (etailer) {
            const etailerModelObservable = this.etailerService.generateNumber(etailer.id, brand.id);
            etailerModelObservable.subscribe(etailerModel => {
                etailer.id = etailerModel.etailerId;
                etailer.etailerNumber = etailerModel.etailerNumber;
            });
        }
    }

    generateEtailer(brand: BrandCooperationModel, etailer: BrandEtailer) {
        if (etailer.id) {
            const etailerModelObservable = this.etailerService.generateEtailer(etailer.id, etailer.etailerNumber, etailer.webPage);
            etailerModelObservable.subscribe(() => {
                this.etailerLoaded = false;
                etailer.generated = false;
                setTimeout(() => {
                    etailer.generated = true;
                }, 20);
            }, () => {

            });
        }
    }

    sendEtailerToEmail(brand: BrandCooperationModel) {

    }

    generateEtailerTooltip(brand: BrandCooperationModel, etailer: BrandEtailer) : EtailerGeneratorTooltip {
        if (!this.partnerDetailsDialogModel.partnerSaved) {
            return {disabled: true, text: "Partner nie jest zapisany"}
        }

        if (!etailer) {
            return {disabled: true, text: "Brakuje obiektu etailera"}
        }

        if (etailer.webPage == undefined || etailer.webPage?.length == 0) {
            return {disabled: true, text: "Brakuje adresu strony www"}
        }

        if (etailer.etailerNumber == undefined || etailer.etailerNumber?.length == 0) {
            return {disabled: true, text: "Brakuje numeru etailera"}
        }

        return {disabled: false, text: ""};
    }

    public addPersonContactToArray(externalPersonModels: ExternalPersonModel[]): void {
        const addContact = this._dialog.open(AddContactDialogComponent,
            {
                width: DialogUtils.defaultDialogWidth
            });

        addContact.afterClosed().pipe(takeUntil(this._onDestroy))
            .subscribe((result: ExternalPersonModel) => {
                    if (!result) {
                        return;
                    } else {
                        externalPersonModels.push(result);
                    }
                }
            );
    }

    public removeCertainPersonContactFromArrayForBrand(brand: BrandCooperationModel, contactModel: ExternalPersonModel): void {
        brand.peopleContacts = brand.peopleContacts.filter(contact => contact !== contactModel);
    }

    debug() {

        console.log("")
        console.log("Current: " + this.brand?.id)
        console.log("Full: " + this.partnerDetailsDialogModel.brandCooperationModel.length + " " + this.partnerDetailsDialogModel.brandCooperationModel.map(b => b.id))
        console.log("Added: " + this.partnerDetailsDialogModel.addedBrandCooperation.length + " " + this.partnerDetailsDialogModel.addedBrandCooperation.map(b => b.id))
        console.log("Edited: " + this.partnerDetailsDialogModel.editedBrandCooperation.length + " " + this.partnerDetailsDialogModel.editedBrandCooperation.map(b => b.id))
        console.log("Deleted: " + this.partnerDetailsDialogModel.removedBrandCooperation.length + " " + this.partnerDetailsDialogModel.removedBrandCooperation.map(b => b.id))
        console.log("")
    }

    addEtailerEntry(etailers: Array<BrandEtailer>) {
        etailers.push(new BrandEtailer(null, null, null));
    }

    addBrandCooperation() {
        this.brand = new BrandCooperationModel(this.partnerDetailsDialogModel.partnerDetailsModel.id, this.brandDict.id, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, [], [], [], false, []);
        this.partnerDetailsDialogModel.addedBrandCooperation.push(this.brand);
        this.brandActive = true;
    }

    onBrandCooperationChange(brand: BrandCooperationModel, $event: MatSelectChange) {
        let newStatus = $event.value;

        if (brand.cooperationTypeId != newStatus) {
            let now = new Date()
            brand.onboardingDate = now;
            if (brand.firstOnboardingDate == null) {
                brand.firstOnboardingDate = now;
            }
        }
        brand.cooperationTypeId = newStatus;
        this.editBrand(brand)
    }

    deleteEtailer(etailer: BrandEtailer) {
        if (etailer.id) {

            let es = this.etailerService;

            let action: ActionDialog = {
                headerText: "Usunięcie etailera",
                text: "Czy na pewno chcesz usunąć wygenerowany etailer?",
                confirmTxt: "Usuń",
                refuseTxt: "Cofnij",
                severity: MessageSeverityEnum.warn,
                callbackFn: () => {
                    es.deleteEtailer(etailer.id).subscribe(()=> {
                        let number = this._brand.etailers.indexOf(etailer);
                        if (number > -1) {
                            this._brand.etailers.splice(number, 1);
                        }
                    });
                },
                refusedFn: () => {
                }
            }
            this.messageService.setActionDialog(action);
        } else {
            let number = this._brand.etailers.indexOf(etailer);
            if (number > -1) {
                this._brand.etailers.splice(number, 1);
            }
        }
    }
}

export interface EtailerGeneratorTooltip {
    disabled: boolean;
    text: string
}
