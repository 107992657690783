import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorModel } from '../_models/error/error.model';
import { AuthenticationService } from '../_services/authentication.service';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
                private router: Router,
                private dialog: MatDialog) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log("Error: " + err + " " + JSON.stringify(err));
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                if (this.router.url === '/login') {
                    const dialogContent: ErrorModel = new ErrorModel('Błąd logowania',
                        'Błędne dane logowania, spróbuj ponownie', '');
                    this.dialog.open(ErrorDialogComponent, {
                        data: dialogContent
                    });
                } else {
                    const dialogContent: ErrorModel = new ErrorModel('Błąd uwierzytelnienia',
                        'Błąd uwierzytelnienia - zostaniesz przekierowany na stronę logowania', '');
                    this.dialog.open(ErrorDialogComponent, {
                        data: dialogContent
                    }).afterClosed().subscribe(() => {
                        location.reload(true);
                    });
                }
            } else {
                let errorData;
                if (typeof err.error === 'string') {
                    let error = JSON.parse(err.error);
                    if (error.hasOwnProperty('code')) {
                        errorData = {
                            code: error.code,
                            message: error.message,
                            title: error.title,
                            errorId: error.errorId
                        }
                        this.dialog.open(ErrorDialogComponent, {
                            data: errorData
                        });
                    } else {
                        errorData = {
                            code: 'UNKNOWN',
                            message: 'Nie obśługiwany wyjątek',
                            title: 'Uwaga'
                        };
                        this.dialog.open(ErrorDialogComponent, {
                            data: errorData
                        });
                    }
                } else {
                    if (err && err.error && err.error instanceof Blob) {
                        this.blobErrorInterceptor(err.error);

                    } else if (err.error && err.error.code) {
                        errorData = {
                            code: err.error.code,
                            message: err.error.message,
                            title: err.error.title,
                            errorId: err.error.errorId
                        };
                        this.dialog.open(ErrorDialogComponent, {
                            data: errorData
                        });
                    } else {
                        return null;
                    }
                }

            }

            const error = err.error != null && err.error.message ? err.error.message : err.statusText;
            return throwError(error);
        }));
    }

    private blobErrorInterceptor(blob) {
        const reader = new FileReader();
        reader.onload = e => {
            if (e.target.readyState === 2) {
                let res = {};
                if (window.TextDecoder) {
                    const enc = new TextDecoder('utf-8');
                    // @ts-ignore
                    res = JSON.parse(enc.decode(new Uint8Array(e.target.result)));
                } else {
                    // @ts-ignore
                    res = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(e.target.result)));
                }
                this.dialog.open(ErrorDialogComponent, {
                    data: res
                });
            }
        };

        reader.readAsArrayBuffer(blob);
    }
}
