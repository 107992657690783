import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { MessageSeverityEnum } from '../../../_enums/message-severity.enum';
import { ConsentApprovalModel, ConsentModel } from '../../../_models/consent.model';
import { DictModel } from '../../../_models/dict.model';
import { AuthenticationService } from '../../../_services/authentication.service';
import { DictionariesService } from '../../../_services/dictionaries.service';
import { MessageService } from '../../../_services/message.service';
import { dictCodeToValue } from '../../../_utils/dict-utils';
import { replacePolishChars } from '../../../_utils/table-utils';
import { ConsentService } from '../../../pages/admin-page/sub-pages/admin-consent-tab/consent.service';
import { AdminConsentTabDialogComponent } from '../../../pages/admin-page/sub-pages/admin-consent-tab/dialog/admin-consent-tab-dialog.component';
import { ConsentWithApproval } from '../partner-details-dialog.component';

@Component({
    selector: 'app-partner-consents-tab',
    templateUrl: './consents-tab.component.html',
    styleUrls: ['consents-tab.component.scss']
})
export class ConsentsTabComponent implements OnInit, OnDestroy {
    public displayedColumns: string[] = [
        'brand',
        'title',
        'version',
        'type',
        'concerns',
        'mainContact',
        'consentActions',
        'star'
    ];
    @Input() disabled: boolean;
    @Input() consents: ConsentWithApproval[];
    @Input() partnerId: number;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    consentSource: MatTableDataSource<ConsentWithApproval>
    private tempFilter: string;
    private destroy$: Subject<void> = new Subject<void>();
    private pageSize: number = 20;
    private _brandsDict: DictModel[];
    private _currentUserId;
    private _consentTypesDict: DictModel[];

    constructor(private readonly authService: AuthenticationService,
                private readonly _dictService: DictionariesService,
                private _messageService: MessageService,
                private _consentService: ConsentService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this._dictService.getBrandDictionary().subscribe(value => this._brandsDict = value);
        this._dictService.getConsentTypes().subscribe(value => this._consentTypesDict = value);
        this._currentUserId = this.authService.currentUserValue.personId;
        this._setTableData(this.consents);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    applyFilter(filterValue: string) {
        this.tempFilter = filterValue;
        this.consentSource.filter = filterValue.trim().toLowerCase();
    }

    public getPageSize(): number {
        return this.pageSize;
    }

    public setPageSize(event: PageEvent): void {
        this.pageSize = event.pageSize;
    }

    getBrandName(brandId: number): string {
        if (!this._brandsDict) {
            return 'Nie znaleziono słownika marek';
        }
        return dictCodeToValue(brandId, this._brandsDict);
    }

    mainContactPicked(element: ConsentWithApproval) {
        if (element.approved) {
            this.approvedChange(true, element);
        }
    }

    contactPicked(element: ConsentWithApproval) {
        if (!element.allContactIds?.includes(element.mainContactId)) {
            element.mainContactId = null;
        }
        if (element.approved) {
            this.approvedChange(true, element);
        }
    }

    getMainContacts(element: ConsentWithApproval): DictModel[] {
        const allContacts = element?.allContactIds?.length && element.allContactIds || [];
        return element.allExternalContacts.filter(el => allContacts.includes(el.id));
    }

    getConsentType(consentTypeId: number): string {
        if (!this._consentTypesDict) {
            return 'Nie znaleziono słownika zgód';
        }
        return this._consentTypesDict.find((dictionaryEntry: DictModel) => dictionaryEntry.id === consentTypeId)?.value || 'Nie znaleziono zgód'
    }

    approvedChange(value: boolean, element: ConsentWithApproval) {
        const previous: boolean = element.approved;

        element.approved = value;

        const consentApprovalModel: ConsentApprovalModel = {
            brandId: element.brandId,
            partnerId: this.partnerId,
            consentId: element.id,
            allContactIds: element.allContactIds,
            mainContactId: element.mainContactId
        }

        if (value) {
            this._consentService.approveConsent(consentApprovalModel).subscribe(
                () => {
                },
                () => {
                    element.approved = previous;
                    this._messageService.setSnackbar({
                        durationInSecond: 5,
                        severity: MessageSeverityEnum.failure,
                        text: `Nie udało się zaakceptować zgody`
                    })
                }
            )
        } else {
            this._consentService.deleteConsentApproval(element.id, element.brandId, this.partnerId).subscribe(
                () => {
                },
                () => {
                    element.approved = previous;
                    this._messageService.setSnackbar({
                        durationInSecond: 5,
                        severity: MessageSeverityEnum.failure,
                        text: `Nie udało się usunąć zgody`
                    })
                }
            );
        }
    }

    public canEditConsents(): boolean {
        return this.authService.canEditConsents();
    }

    isMailing(id: number) {
        return id === 1;
    }

    protected openDialog(row: ConsentModel, event) {
        if (!event?.target?.classList?.contains('trigger-dialog')) {
            return;
        }
        const model = row;
        model.consentReadonly = true;
        this.dialog.open(AdminConsentTabDialogComponent,
            {
                maxWidth: '100%',
                panelClass: 'details-dialog',
                data: model,
            }
        );


    }

    private _setTableData = (consent: ConsentWithApproval[]) => {
        this.consentSource = new MatTableDataSource<ConsentWithApproval>(consent);
        this.consentSource.paginator = this.paginator;
        this.consentSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'title':
                    return item?.title && replacePolishChars(item.title) || '';
                case 'brand':
                    return this._brandsDict?.find(val => val?.id === item?.brandId).value || '';
                default:
                    return item[property];
            }
        };
        this.consentSource.sort = this.sort;
        this.consentSource.filterPredicate = (data, filter) => {
            const title = data?.title || '';
            const version = data?.version || '';
            const brand = this._brandsDict?.find(val => val?.id === data?.brandId)?.value || '';
            let dataStr = title + version + brand;
            dataStr = dataStr && dataStr.toLowerCase() || '';
            filter = filter && filter.toLowerCase();

            return dataStr.indexOf(filter) != -1;
        };

        if (this.tempFilter) {
            this.applyFilter(this.tempFilter);
        }
    };

}
