import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SozAccountActivityModel } from '../_models/soz-account-activity.model';

@Injectable(
    {providedIn: 'root'}
)
export class SozService {

    constructor(private readonly _httpClient: HttpClient) {
    }

    changePassword(partnerId: number) {
        return this._httpClient.post(`/api/partner/${partnerId}/soz/change-password`, null, {responseType: 'text'});
    }

    changeProcuratorPassword(procuratorId: number) {
        return this._httpClient.post(`/api/procurator/${procuratorId}/soz/change-password`, null, {responseType: 'text'});
    }

    updateSozAccountActivity(partnerId: number, sozAccountActivation: SozAccountActivityModel) {
        return this._httpClient.patch(`/api/partner/${partnerId}/soz`, sozAccountActivation, {responseType: 'text'});
    }

    createSozAccount(partnerId: number) {
        return this._httpClient.post(`/api/partner/${partnerId}/soz/create-account`, null, {responseType: 'text'});
    }

    changeChainPassword(chainId: number) {
        return this._httpClient.post(`/api/chain/${chainId}/soz/change-password`, null, {responseType: 'text'});
    }

    updateChainSozAccountActivity(chainId: number, sozAccountActivation: SozAccountActivityModel) {
        return this._httpClient.patch(`/api/chain/${chainId}/soz`, sozAccountActivation, {responseType: 'text'});
    }

    createChainSozAccount(chainId: number) {
        return this._httpClient.post(`/api/chain/${chainId}/soz/create-account`, null, {responseType: 'text'});
    }

}
