import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandModel } from '../../_models/brand.model';
import {PartnerExtraDetailsModel, PartnerDetailsModel} from '../../_models/partner-details.model';
import { ApiUtils } from '../../_utils/api.utils';

@Injectable()
export class PartnerDetailsScreenService {

    removingInitialized: boolean = false;


    constructor(private http: HttpClient) {
    }

    public getSubjectExtraDetails(id: number): Observable<PartnerExtraDetailsModel> {
        return this.http.get<PartnerExtraDetailsModel>(`${ApiUtils.PARTNER_API_URL}/${id}/extra`);
    }

    public getSubjectDetails(id: number): Observable<PartnerDetailsModel> {
        return this.http.get<PartnerDetailsModel>(`${ApiUtils.PARTNER_API_URL}/${id}`);
    }

    public putSubjectDetails(url: string, model: PartnerDetailsModel): Observable<PartnerDetailsModel> {
        return this.http.put<PartnerDetailsModel>(`${url}`, model);
    }

    public removeSubject(model: PartnerDetailsModel): Observable<any> {
        return this.http.delete(`${ApiUtils.PARTNER_API_URL}/${model.id}`);
    }

    public getBrands(): Observable<Array<BrandModel>> {
        return this.http.get<Array<BrandModel>>(`api/brand`);
    }

}
