import { ContactModel } from './contact.model';
import { SystemDataModel } from './system-data.model';
import { TeamPersonModel } from './team-person.model';

export class PersonModel {
    constructor(
        public id: number,
        public firstName?: string,
        public familyName?: string,
        public domainRoleIds?: Array<number>,
        public contact?: ContactModel,
        public sozRoleId?: number,
        public systemData?: SystemDataModel,
        public teamPerson?: Array<TeamPersonModel>,
        public newPerson?: boolean//only for frontened purpose
    ) {
    }
}
