import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageSeverityEnum } from '../../../../../_enums/message-severity.enum';
import { DictModel } from '../../../../../_models/dict.model';
import { PersonModel } from '../../../../../_models/person.model';
import { AuthenticationService } from '../../../../../_services/authentication.service';
import { DictionariesService } from '../../../../../_services/dictionaries.service';
import { MessageService } from '../../../../../_services/message.service';
import { UserService } from '../../../../../_services/user.service';
import { AdminUserTabService } from '../admin-user-tab.service';
import { ConfirmRemovingUserDialog } from '../confirm-removing/confirm-removing-user-dialog.component';


@Component({
    selector: 'app-admin-user-tab-dialog',
    templateUrl: './admin-user-tab-dialog.component.html',
    styleUrls: ['./admin-user-tab-dialog.component.scss']
})
export class AdminUserTabDialogComponent implements OnInit {

    public filteredDomainRoles: DictModel[] = [];
    protected dictDomainRoles: DictModel[] = [];
    protected dictSozRoles: DictModel[] = [];
    protected dictSystemRoles: DictModel[] = [];

    constructor(@Inject(MAT_DIALOG_DATA) public personModel: PersonModel,
                private readonly dialog: MatDialog,
                private readonly dictService: DictionariesService,
                private dialogRef: MatDialogRef<AdminUserTabDialogComponent>,
                private readonly adminUserTabService: AdminUserTabService,
                private messageService: MessageService,
                private readonly userService: UserService,
                private readonly _authService: AuthenticationService) {
    }

    ngOnInit(): void {
        this.dictService.getDomainRoles().subscribe((dict: DictModel[]) => {
            this.dictDomainRoles = dict;
            this.filteredDomainRoles = dict;
        });
        this.dictService.getSozRoles().subscribe((dict: DictModel[]) => this.dictSozRoles = dict);
        this.dictService.getSystemRoles().subscribe((dict: DictModel[]) => this.dictSystemRoles = dict);
    }

    public domainRoleChanged(domainRole: number[]) {
        this.personModel.domainRoleIds = domainRole;
    }

    public filterDomainRoles(event) {
        if (!this.dictDomainRoles) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredDomainRoles = this.dictDomainRoles;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredDomainRoles = this.dictDomainRoles.filter(role => role.value.toLowerCase().indexOf(search) > -1);
    }

    public submitDialog(userForm: NgForm) {
        if (userForm.valid) {
            this.dialogRef.close(true);
        } else {
            this.messageService.setSnackbar({
                durationInSecond: 5,
                severity: MessageSeverityEnum.failure,
                text: `Wypełnij wszystkie wymagane pola!`
            });
        }
    }

    delete() {
        const dialogRef = this.dialog.open(ConfirmRemovingUserDialog,
            {
                maxWidth: '100%',
            }
        );

        dialogRef.afterClosed()
            .subscribe((result: boolean) => {
                    if (result) {
                        this.userService.deleteUser(this.personModel.id)
                            .subscribe(() => {
                                    this.adminUserTabService.refresh$.next();
                                    this.dialogRef.close(false);
                                }
                            );
                    }
                }
            );

    }

    protected generateNewPassword() {
        this._authService.resetPasswordForUser(this.personModel).subscribe(
            () => this.messageService.setSnackbar({
                durationInSecond: 5,
                severity: MessageSeverityEnum.success,
                text: `Nowe hasło zostało wysłane na email: ${this.personModel.contact.email}`
            }),
            () => this.messageService.setSnackbar({
                durationInSecond: 5,
                severity: MessageSeverityEnum.failure,
                text: `Nie udało się zmienić hasła`
            }),
        );
    }


}
