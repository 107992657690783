import { Component } from '@angular/core';
import { ChipFiltersUtils } from '../../../_utils/chip-filters.utils';
import { ChipElement } from '../../../filter-chips/chip-component/chip.component';
import { ChainsService } from '../../chains.service';

@Component({
    selector: 'app-chains-detail-multiselect',
    templateUrl: './chains-detail-multiselect.component.html',
    styleUrls: ['./chains-detail-multiselect.component.scss']
})
export class ChainsDetailMultiselectComponent {
    chipPresentFilters: Array<ChipElement> = [];
    chipPartnerPossibleFilters: ChipElement[] = ChipFiltersUtils.PARTNER_POSSIBLE_FILTERS;

    limited: boolean = false;

    constructor(public chainsService: ChainsService) {
    }

    toggleElement(partner) {
        if (this.chainsService.selectedPartners.some(el => el.id === partner.id)) {
            this.chainsService.selectedPartners.splice(this.chainsService.selectedPartners.indexOf(partner.id), 1);
        } else {
            this.chainsService.selectedPartners.push(partner);
        }
    }

    selected(partner) {
        return this.chainsService.selectedPartners.some(el => el.id === partner.id);
    }

    public chipUpdateList(chipList: ChipElement[]): void {
        this.chipPresentFilters = chipList;
    }

    public chipAddToList(chipElement: ChipElement): void {
        this.chipPresentFilters.push(chipElement);
    }

    public chipUpdateListAndSendRequest(chipList: ChipElement[]): void {
        this.chipUpdateList(chipList);
        //TODO: tutaj powinieneś sobie zapisywać to co backend zwróci do twojej listy która powinna być typu PartnerDetails...
        this.chainsService.getPartnersList(chipList).subscribe(partners => {
            this.limited = partners.totalPages > 1;
        });
    }

}
