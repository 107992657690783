import { Component } from '@angular/core';

@Component({
    selector: 'app-one-drive-picker',
    templateUrl: './one-drive-picker.component.html',
    styleUrls: ['./one-drive-picker.component.scss']
})
export class OneDrivePickerComponent {

    public launchOneDrivePicker() {
        const odOptions = {
            clientId: '94797cf3-24c5-4569-a2c8-a0e2143ce758',
            action: 'download',
            multiSelect: true,
            success: (files) => { /* success handler */
                console.log(files);
                files.value.forEach(val => {
                    window.open(val['@microsoft.graph.downloadUrl']);
                });
                // this.download()
            },
            cancel: () => { /* cancel handler */
            },
            error: (error) => { /* error handler */
            },
            advanced: {
                endpointHint: 'https://solgarpolska-my.sharepoint.com/'
            }
        };
        // @ts-ignore
        OneDrive.open(odOptions);
    }

    saveFile() {
        const odOptions = {
            clientId: '94797cf3-24c5-4569-a2c8-a0e2143ce758',
            action: 'save',
            sourceInputElementId: 'fileUploadControl',
            sourceUri: '',
            fileName: 'file.txt',
            openInNewWindow: true,
            advanced: {},
            success: function (files) { /* success handler */
                console.log(files);
            },
            progress: function (percent) { /* progress handler */
            },
            cancel: function () { /* cancel handler */
            },
            error: function (error) { /* error handler */
            }
        };
        // @ts-ignore
        OneDrive.save(odOptions);
    }
}
