import { BrandCooperationModel } from '../../_models/brand-cooperation.model';
import {PartnerExtraDetailsModel, PartnerDetailsModel} from '../../_models/partner-details.model';
import {Subject} from 'rxjs';
import {DialogResultEnum} from '../../_enums/dialog-result.enum';
import {ProcuratorDetailsModel} from '../../_models/procurator-details.model';
import {PartnerProcuratorDetailsModel} from '../../_models/partner-procurator-details.model';

export class PartnerDetailsDialogModel {

    public partnerDetailsModel: PartnerDetailsModel;
    public partnerDetailsExtraModel: PartnerExtraDetailsModel;
    public brandCooperationModel: Array<BrandCooperationModel>;

    public sozLoginSaved: boolean = false

    public loadedProcurators?: Set<number>;
    public partnerProcuratorsDetails?: Array<PartnerProcuratorDetailsModel>;
    public partnerSaved: boolean = true

    public addedBrandCooperation?: Array<BrandCooperationModel>;
    public editedBrandCooperation?: Array<BrandCooperationModel>;
    public removedBrandCooperation?: Array<BrandCooperationModel>;

    editOnly?: boolean;
    selectedIndex?: number;
    notification?: Subject<DialogResultEnum>;

}
