import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '../../_services/notification.service';
import { PlainNotificationModel } from '../../_models/plain-notification.model';
import * as moment from 'moment';

@Component({
    selector: 'app-plain-notification',
    templateUrl: './plain-event-notification.component.html',
    styleUrls: ['./plain-event-notification.component.scss']
})
export class PlainEventNotificationComponent implements OnInit {

    @Input() public plainNotificationModel: PlainNotificationModel;
    @Input() public pending: boolean = false;


    protected expandDetails: boolean = false;

    constructor(private readonly notificationService: NotificationService) {
    }


    ngOnInit() {
        !this.plainNotificationModel.seen && this.notificationService.addNewPendingPlainNotification({
            id: this.plainNotificationModel.id, plainNotificationPatchModel: {
                seen: true
            }
        });
    }

    protected changeView() {
        this.expandDetails = !this.expandDetails;
    }

    protected getCreationDate() {
        if (!this.plainNotificationModel || !this.plainNotificationModel.createdAt) {
            return null;
        }
        const date = moment(this.plainNotificationModel.createdAt);
        return date.locale('pl').format('dddd, MMMM Do YYYY, h:mm:ss');
    }


    private preventBubbling(event): void {
        event.preventDefault();
        event.stopPropagation();
    }

    private dismissNotification(event): void {
        this.preventBubbling(event);
        this.notificationService.addNewPendingPlainNotification({
            id: this.plainNotificationModel.id, plainNotificationPatchModel: {
                dismissed: true
            }
        });
    }

}
