import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ShortageModel } from '../_models/shortage-model';

@Injectable()
export class ShortageService {
    private readonly API_URL = environment.apiUrl + '/api/shortage';


    constructor(private readonly http: HttpClient) {
    }


    public getShortagesForChain(chainId: number): Observable<Array<ShortageModel>> {
        let params = new HttpParams();
        params = params.append('chainId', chainId.toString());

        return this.http.get<Array<ShortageModel>>(this.API_URL, {params});
    }


}
