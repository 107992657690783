export class ExternalPersonModel {
    constructor(
        public id: number,
        public firstName: string,
        public familyName: string,
        public position: string,
        public phoneNo: string,
        public email: string,
        public marketingApproved?: boolean,
        public comment?: string,
        public newContact?: boolean //frontend Only
    ) {
    }
}
