import { ScreenTypeEnum } from '../_enums/screen-type.enum';
import { ConfigModel } from './config.model';

export class ScreenConfigModel {
    constructor(public id: number,
                public name: string,
                public isDefault: boolean,
                public screenType: ScreenTypeEnum,
                public configJson: ConfigModel) {
    }
}
