import {Component, Inject, OnInit} from '@angular/core';
import {AuthenticationService} from '../../_services/authentication.service';
import {ProcuratorDialogModel} from './procurator-dialog.model';
import {MatTableDataSource} from '@angular/material/table';
import {PartnerMiniModel} from '../../_models/partner-mini-model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChainsDialogModel} from '../../chains/chains-detail-dialog/chains-dialog.model';
import {ProcuratorDetailsModel} from '../../_models/procurator-details.model';
import {ProcuratorsService} from '../procurators.service';
import {DialogResultEnum} from '../../_enums/dialog-result.enum';
import { canViewSozFields, canEditSozFields, canResetSozPassword } from 'src/app/_utils/auth-utils/auth-procurator';
import {SozService} from '../../_services/soz.service';
import {ProcuratorFileModel} from '../../_models/procurator-file.model';
import {DictModel} from '../../_models/dict.model';
import {DictionariesService} from '../../_services/dictionaries.service';


@Component({
    selector: 'app-procurator-details-dialog',
    templateUrl: './procurator-details-dialog.component.html',
    styleUrls: ['./procurator-details-dialog.component.scss']
})
export class ProcuratorDetailsDialogComponent implements OnInit {

    disabled: boolean;
    showInvalidFields: boolean;

    displayedColumns: string[] = [
        "name",
        "chain",
        "address",
        "nip",
        "status",
    ];

    private partnersTableSource: MatTableDataSource<PartnerMiniModel>;
    private availableProcurators: Array<ProcuratorDetailsModel>;

    private procuratorFiles: Array<ProcuratorFileModel>;

    constructor(private readonly authService: AuthenticationService,
                private readonly procuratorsService: ProcuratorsService,
                private readonly sozService: SozService,
                private readonly dictionariesService: DictionariesService,
                @Inject(MAT_DIALOG_DATA) public procuratorDialogModel: ProcuratorDialogModel,
                private dialogRef: MatDialogRef<ChainsDialogModel>) {
        this.partnersTableSource = new MatTableDataSource<PartnerMiniModel>(procuratorDialogModel.detailsModel.partners || []);
        this.disabled = !procuratorDialogModel.newProcurator;
        this.procuratorFiles = [];
    }

    ngOnInit(): void {
        this.procuratorsService.getProcurators().subscribe(procurators => {
            this.availableProcurators = procurators;
        });

        this.dictionariesService.getProcuratorRoles().subscribe(dict => {
            this.procuratorRoleDict = dict;
        })

        if (!this.procuratorDialogModel.newProcurator && this.procuratorDialogModel.detailsModel.id) {
            this.procuratorsService.getProcuratorFiles(this.procuratorDialogModel.detailsModel.id).subscribe(procuratorFiles => {
                this.procuratorFiles = procuratorFiles;
            });
        }
    }

    onResize($event: any) {


    }

    ownerEdited($event: any) {

    }

    private _currentUserRoles: number[];
    procuratorRoleDict: DictModel[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    get canResetSozPassword(): boolean {
        return canResetSozPassword(this.currentUserRoles);
    }

    get canViewSozFields(): boolean {
        return canViewSozFields(this.currentUserRoles);
    }

    get canEditSozFields(): boolean {
        return canEditSozFields(this.currentUserRoles);
    }

    submitDialog(close: boolean) {
        if (close) {
            this.procuratorDialogModel.notification.next(DialogResultEnum.SAVE_AND_EXIT);
        } else {
            this.procuratorDialogModel.notification.next(DialogResultEnum.SAVE);
        }
    }

    isFormInvalid() {
        return false;
    }

    resetSozPassword() {
        this.sozService.changeProcuratorPassword(this.procuratorDialogModel.detailsModel.id).subscribe();
    }
}
