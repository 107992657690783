import {Component, Input} from '@angular/core';
import {BrandInfoModel} from './brand-info.model';

@Component({
    selector: 'app-brand-info',
    templateUrl: './brand-info.component.html',
    styleUrls: ['brand-info.component.scss']
})
export class BrandInfoComponent {

    @Input() public infoModel : BrandInfoModel

}
