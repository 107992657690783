import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {combineLatest, of, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {canRemovePartner} from 'src/app/_utils/auth-utils/auth-partner-details-general';
import {MessageSeverityEnum} from '../../_enums/message-severity.enum';
import {BenefitModel} from '../../_models/benefit.model';
import {ConsentApprovalModel, ConsentModel} from '../../_models/consent.model';
import {CorrelatedPartnerModel} from '../../_models/correlated-partner.model';
import {DictModel} from '../../_models/dict.model';
import {AuthenticationService} from '../../_services/authentication.service';
import {BenefitService} from '../../_services/benefit.service';
import {BrandCorrelationService} from '../../_services/brand-correlation.service';
import {DictionariesService} from '../../_services/dictionaries.service';
import {MessageService} from '../../_services/message.service';
import {RedirectionService} from '../../_services/redirection.service';
import {dictCodeToValue} from '../../_utils/dict-utils';
import {ValidationUtils} from '../../_utils/validation.utils';
import {ConsentService} from '../../pages/admin-page/sub-pages/admin-consent-tab/consent.service';
import {EntrepreneurDetailsDialogModel} from './entrepreneur-details-dialog.model';
import {DialogResultEnum} from '../../_enums/dialog-result.enum';
import {canViewAdminPanel} from '../../_utils/auth-utils/auth-admin-panel';
import {EntrepreneursService} from '../entrepreneurs.service';
import {EntrepreneurBrandCooperationModel} from '../../_models/entrepreneur-brand-cooperation.model';
import {ActionDialog} from '../../messaging/action-dialog/action-dialog.component';
import {AddressModel} from '../../_models/address.model';

export interface ConsentWithApproval extends ConsentModel {
    approved: boolean;
    allContactIds: number[];
    mainContactId: number;
    allExternalContacts: DictModel[];
}

@Component({
    selector: 'app-entrepreneur-details-dialog',
    templateUrl: './entrepreneur-details-dialog.component.html',
    styleUrls: ['./entrepreneur-details-dialog.component.scss']
})
export class EntrepreneurDetailsDialogComponent implements OnInit, OnDestroy {

    public disabled: boolean = true;
    public dictBrands: Array<DictModel>;
    public breakpoint: number;
    public showInvalidFields: boolean = false;
    public correlations: Map<number, CorrelatedPartnerModel[]> = new Map();
    //CONSENTS
    public consents: ConsentWithApproval[] = [];
    //Benefits
    benefits: BenefitModel[] = [];
    protected _onDestroy = new Subject<void>();

    constructor(@Inject(MAT_DIALOG_DATA) public entrepreneurDetailsDialogModel: EntrepreneurDetailsDialogModel,
                private readonly _dialog: MatDialog,
                public readonly redirectionService: RedirectionService,
                private readonly dictService: DictionariesService,
                private readonly messageService: MessageService,
                private readonly entrepreneursService: EntrepreneursService,
                private readonly brandCorrelationService: BrandCorrelationService,
                private dialogRef: MatDialogRef<EntrepreneurDetailsDialogComponent>,
                public readonly authService: AuthenticationService,
                private readonly consentService: ConsentService,
                private readonly benefitService: BenefitService) {
        this.initBreakpoint();
        this.disabled = !entrepreneurDetailsDialogModel.newEntrepreneur;
    }

    private _currentUserRoles: number[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    get canRemovePartner(): boolean {
        return canRemovePartner(this.currentUserRoles);
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    ngOnInit(): void {

        this.dictService.getBrandDictionary().subscribe((brandCooperation: Array<DictModel>) => {
            this.dictBrands = brandCooperation;
        });
    }

    public getBrandName(brandId: number) {
        return dictCodeToValue(brandId, this.dictBrands);
    }

    public setColspanOrReturnDefault(colspan: number): number {
        return colspan > this.breakpoint ? this.breakpoint : colspan;
    }

    public onResize(event): void {
        if ((event.target.innerWidth <= 1024)) {
            this.breakpoint = 1;
            return;
        }
        if (event.target.innerWidth <= 1280) {
            this.breakpoint = 2;
            return;
        }
        if (event.target.innerWidth <= 1366) {
            this.breakpoint = 4;
            return;
        }
        this.breakpoint = 12;
    }

    public isFormInvalid(): boolean {
        if (!this.entrepreneurDetailsDialogModel.entrepreneurDetailsModel) {
            return true;
        }
        return false;
    }

    public submitDialog(close: boolean) {
        let callbackFunction = () => {
            this.postSubmit(close);
        };

        if (this.entrepreneurDetailsDialogModel.loyaltyPointsChanged) {
            let action: ActionDialog = {
                headerText: "Zmiana wartości SNC/TNC",
                text: "Operacja ta spowoduje odznaczenie danej flagi u wszystkich Partnerów danego Przedsiębiorcy.",
                confirmTxt: "Akceptuję",
                refuseTxt: "Cofnij",
                severity: MessageSeverityEnum.warn,
                callbackFn: callbackFunction,
                refusedFn: () => {
                }
            }

            this.messageService.setActionDialog(action);
        } else {
            callbackFunction();
        }
    }

    private changesBrandSNC(previous: EntrepreneurBrandCooperationModel[], exists: EntrepreneurBrandCooperationModel[]) {
        for (let exist of exists) {
            if (!exist.inLoyaltyProgram) {
                let entrepreneurBrandCooperationModel = previous.find(p => p.brandId == exist.brandId);
                if (entrepreneurBrandCooperationModel && entrepreneurBrandCooperationModel.inLoyaltyProgram) {
                    return true;
                }
            }
        }
        return false;
    }

    private postSubmit(close: boolean) {
        let entrepreneurDetailsModel = this.entrepreneurDetailsDialogModel.entrepreneurDetailsModel;
        entrepreneurDetailsModel.name = entrepreneurDetailsModel.name.trim();
        entrepreneurDetailsModel.phone = entrepreneurDetailsModel.phone !== null ? entrepreneurDetailsModel.phone.trim() : null;
        EntrepreneurDetailsDialogComponent.trimWhitespaces(entrepreneurDetailsModel.address);
        EntrepreneurDetailsDialogComponent.trimWhitespaces(entrepreneurDetailsModel.correspondenceAddress);
        this.showInvalidFields = false;
        if (this.isFormInvalid()) {
            this.showInvalidFields = true;
            this.messageService.setSnackbar({
                severity: MessageSeverityEnum.failure,
                durationInSecond: 5,
                text: 'Wypełnij wszystkie wymagane pola!'
            });
            return;
        }

        if (close) {
            this.entrepreneurDetailsDialogModel.notification.next(DialogResultEnum.SAVE_AND_EXIT);
        } else {
            this.entrepreneurDetailsDialogModel.notification.next(DialogResultEnum.SAVE);
        }
    }

    private static trimWhitespaces(address: AddressModel) {
        if (address !== null) {
            address.town = address.town !== null ? address.town.trim() : null;
            address.street = address.street !== null ? address.street.trim() : null;
            address.postalCode = address.postalCode !== null ? address.postalCode.trim() : null;
        }
    }

    private initBreakpoint() {
        if ((window.innerWidth <= 1024)) {
            this.breakpoint = 1;
            return;
        }
        if (window.innerWidth <= 1280) {
            this.breakpoint = 2;
            return;
        }
        if (window.innerWidth <= 1366) {
            this.breakpoint = 4;
            return;
        }
        this.breakpoint = 12;
    }

    public canViewAdminTab(): boolean {
        return canViewAdminPanel(this.currentUserRoles);
    }

    getEntrepreneurAddress() {

    }
}
