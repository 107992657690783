import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment';
import { DictionariesEnum } from '../../_enums/dictionaries-enum';
import { DictModel } from '../../_models/dict.model';
import { ChipService } from './chip.service';

// import * as moment from 'moment';

export class ChipRange {
    startDate: Moment;
    endDate: Moment;
}

export class Pair {
    left?: any;
    right?: any;
}

export enum ChipType {
    TEXT = 'TEXT',
    SELECT = 'SELECT',
    MULTI = 'MULTI',
    DATE = 'DATE',
    RANGE = 'RANGE',
    PAIR = 'PAIR'
}

export class ChipDescription {
    name: string;
    display: string;
}

export class ChipElement {
    chipDescription: ChipDescription;
    chipType: ChipType;
    dictType?: DictionariesEnum;
    typeaheadDict?: DictionariesEnum;
    dictionary?: Array<DictModel>;
    chipValue?: ChipValue;
}


export class ChipValue {
    chipName: string; //treat like id
    value: any; //wysyłać jsona
    logicalOperator?: string;
}

@Component({
    selector: 'app-chip',
    templateUrl: './chip.component.html',
    styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {
    @ViewChild('dateRangePicker', {static: false}) dateRangePicker;
    @ViewChild('pickerTo', {static: false}) pickerTo: MatDatepicker<any>;
    @ViewChild('inputs', {static: false}) inputs: ElementRef<HTMLInputElement>;

    @Input() chipDescription: ChipDescription;
    @Input() chipType: ChipType;
    @Input() dictionary: Array<DictModel> = [];
    @Input() dictType: DictionariesEnum;
    @Input() typeaheadDict: DictionariesEnum;
    @Input() chipValue: ChipValue;

    @Input() label: string;
    @Input() removable: boolean;

    @Input() isBigDictionary: boolean = false;
    @Output() value: EventEmitter<ChipValue> = new EventEmitter<ChipValue>();
    @Output() remove: EventEmitter<string> = new EventEmitter<string>();


    funcOrOperator: string = '';

    public valueCtrl: FormControl;
    maxDate: Moment;
    pair: Pair = {left: null, right: null};
    public initMultiSelect = [];
    public initSelectOne = null;
    protected chipRange: ChipRange = {startDate: null, endDate: null};
    protected focused: boolean;
    protected fieldValid: boolean = true;

    constructor(private readonly chipService: ChipService) {

    }

    validateValue(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return !this.fieldValid ? {'valid': {value: control.value}} : null;
        };
    }

    pairLeftChanged(value: string) {
        if (this.funcOrOperator === 'BETWEEN') {
            this.pair.left = value;
            if (this.pair.left && this.pair.right) {
                this.valueCtrl.setValue(this.pair);
            }
        } else {
            this.pair.left = value;
            this.valueCtrl.setValue(value);
        }
    }

    pairRightChanged(value: string) {
        this.pair.right = value;
        if (this.pair.left && this.pair.right) {
            this.valueCtrl.setValue(this.pair);
        }
    }

    setFrom(startDate: Moment) {
        this.chipRange.startDate = startDate;
        if (!this.chipRange.endDate || this.chipRange.endDate.isBefore(this.chipRange.startDate)) {
            setTimeout(() => this.pickerTo.open(), 0);
        } else {
            this.valueCtrl.setValue(this.chipRange);
        }
    }

    setRange(endDate: Moment) {
        this.chipRange.endDate = endDate;
        this.valueCtrl.setValue(this.chipRange);
    }


    ngOnInit(): void {
        this.valueCtrl = new FormControl('', this.validateValue());

        if (this.chipValue) {
            this.funcOrOperator = this.chipValue.logicalOperator;
            this.valueCtrl.setValue(this.chipValue.value);
            if (this.chipType === ChipType.MULTI) {
                this.initMultiSelect = this.chipValue.value;
            }
            if (this.chipType === ChipType.SELECT) {
                this.initSelectOne = this.chipValue.value;
            }
            if (this.chipType === ChipType.RANGE) {
                const chipRange: ChipRange = this.chipValue.value;
                this.chipRange.startDate = moment(chipRange.startDate);
                this.chipRange.endDate = moment(chipRange.endDate);
            }
            if (this.chipType === ChipType.PAIR) {
                if (this.funcOrOperator === 'BETWEEN') {
                    this.pair = this.chipValue.value;
                } else {
                    this.pair.left = this.chipValue.value;
                }
            }

        }
        if (this.dictType) {
            this.initDictionary();
        }
        this.maxDate = moment(new Date());

    }

    handleFocus() {
        this.focused = true;
    }

    public showSignPicker() {
        return this.chipType === 'TEXT' || this.chipType === 'PAIR';
    }

    protected emitValueChange() {
        this.value.emit({
            chipName: this.chipDescription.name,
            value: this.valueCtrl.value,
            logicalOperator: this.funcOrOperator || 'EQ'
        });
    }

    protected funcOrOperatorChanged(value: string) {
        if (this.funcOrOperator === 'BETWEEN' && this.pair.left && value !== 'BETWEEN') {
            this.valueCtrl.setValue(this.pair.left);
        }

        if (this.funcOrOperator !== 'BETWEEN' && this.pair.left && this.pair.right && value === 'BETWEEN') {
            this.valueCtrl.setValue(this.pair);
        }

        this.funcOrOperator = value;
        if (this.valueCtrl.value) {
            this.emitValueChange();
        }
    }

    protected handleKeyInput(keyboardEvent: KeyboardEvent) {
        if (keyboardEvent.key === 'Enter') {
            this.handleValueEmit();
            document.getElementById('inputText').blur();
        }

        if (keyboardEvent.key === 'Escape') {
            this.clearCtrl();
        }
    }

    protected handlePairKeyInput(keyboardEvent: KeyboardEvent) {
        if (keyboardEvent.key === 'Enter') {
            this.handleValueEmit();
            document.getElementById('inputText').blur();
        }

        if (keyboardEvent.key === 'Escape') {
            this.clearCtrl();
        }
    }

    protected handleBlur() {
        this.handleValueEmit();
        this.focused = false;
    }


    protected handleRangeBlur() {
        if (this.valueCtrl.value) {
            const range: ChipRange = this.valueCtrl.value;
            range.startDate && range.endDate && this.handleValueEmit();
        }
        this.focused = false;

    }

    protected handlePairBlur() {
        if (this.valueCtrl.value) {
            this.emitValueChange();
        }
        this.focused = false;
    }

    protected clearCtrl(): void {
        this.valueCtrl.setValue('');
        this.emitValueChange();
    }

    protected removeSelf(): void {
        this.remove.emit(this.chipDescription.name);
    }

    protected setFormControl(value: any): void {
        if (this.chipType === 'MULTI' && value && value.length > 0) {
            this.valueCtrl.setValue(value);
            this.handleValueEmit();
        } else if (value) {
            this.valueCtrl.setValue(value);
            this.handleValueEmit();
        }
    }

    private handleValueEmit() {
        this.emitValueChange();
    }

    private initDictionary() {
        if (!this.isBigDictionary) {
            this.chipService.getDictionary(this.dictType).subscribe((dictionary: Array<DictModel>) => {
                this.dictionary = dictionary;
            });
        } else {
            this.valueCtrl.value &&
            (this.valueCtrl.value as string).length > 2 &&
            this.chipService.getDictionary(this.dictType).subscribe((dictionary: Array<DictModel>) => this.dictionary = dictionary);
        }
    }


}
