export enum AuthRolesEnum {
    DEFAULT,
    SYSTEM_ADMIN,
    MANAGEMENT,
    CHIEF_EXECUTIVE_OFFICER,
    CHIEF_EXECUTIVE_OFFICER_DEPUTY,
    REGIONAL_DIRECTOR,
    REGIONAL_COORDINATOR,
    TOA,
    ADMINISTRATION_DEPARTMENT, //DA
    TOA_MANAGER,
    LEGAL_DEPARTMENT,
    ETAILER
}

export const canPerformAction = (rolesArr: number[], rightfulEntities: AuthRolesEnum[]): boolean => {
    return rolesArr?.some(role => rightfulEntities.includes(role));
}
