import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import * as uuid from 'uuid';
import { DictModel } from '../../_models/dict.model';
import { EventModel } from '../../_models/event-model';
import { EventPersonModel } from '../../_models/event-person-model';
import { EventRecurrenceModel } from '../../_models/event-recurrence.model';
import { EventReminderModel } from '../../_models/event-reminder.model';
import { EventSubTypeDictModel } from '../../_models/event-sub-type-dict.model';
import { EventTypeDictModel } from '../../_models/event-type-dict.model';
import { PersonModel } from '../../_models/person.model';
import { AuthenticationService } from '../../_services/authentication.service';
import { DictionariesService } from '../../_services/dictionaries.service';
import { DialogUtils } from '../../_utils/dialog.utils';
import { ChainsService } from '../../chains/chains.service';
import { EventsService } from '../../events/events.service';
import { PartnersService } from '../../partners/partners.service';
import { CalendarScreenService, EventConfirmationModel } from '../calendar-screen.service';
import {
    ActionType,
    EventActionDialogCommunicationModel,
    EventCalendarService
} from '../event-calendar/event-calendar.service';
import { SeriesActionDialogComponent } from './remove-dialog/remove-series/series-action-dialog.component';

@Component({
    selector: 'app-edit-event-dialog',
    templateUrl: './event-dialog-component.html',
    styleUrls: ['event-dialog-component.scss']
})
export class EventDialogComponent implements OnInit {

    //Arrays for filtered elements from search in selects
    public filteredEventTypes = [];
    calendarForm: FormGroup;
    currentUserId: number;
    /**
     * Dictionaries
     */
    dictBrand: Array<DictModel>;
    dictPeople: Array<DictModel>;
    dictChains: Array<DictModel>;
    dictReminderTypes: Array<DictModel>;
    dictEventType: Array<EventTypeDictModel>;
    dictRecurrenceTypes: Array<DictModel> = [{id: null, value: 'Wybierz'}];
    dictRecurringIntervalDailyTypes: Array<DictModel>;
    dictRecurringIntervalWeeklyTypes: Array<DictModel>;
    dictRecurringIntervalMonthlyTypes: Array<DictModel>;
    dictRecurringIntervalYearlyTypes: Array<DictModel>;
    dictRecurringEndTypes: Array<DictModel>;
    dictDaysOfWeek: Array<DictModel>;
    dictReminderDirection: Array<DictModel>;
    /**
     * Utility fields that helps filling the model before sending to backend
     */
    chipsGuests: Array<EventPersonModel> = [];
    chipsPartners: Array<DictModel> = [];
    chipsChains: Array<number> = [];
    chipOption: number[] = [ENTER, COMMA];
    reminders: Array<EventReminderModel> = [];
    /**
     * Observables
     */
    filteredStartTimeArray$: Observable<Array<string>>;
    filteredEndTimeArray$: Observable<Array<string>>;
    filteredGuestsList$: Observable<DictModel[]>;
    filteredPartnerList$: Observable<DictModel[]>;
    filteredChainList$: Observable<DictModel[]>;

    /**
     * Utilities flags
     */
    showDescriptionField: boolean = false;
    withTime: boolean = false;
    accepted: boolean = false;
    readonly: boolean = false;
    addMode: boolean = false;
    recurrenceId: number = null;
    public _timeArray: Array<string> = [];
    private action: EventActionDialogCommunicationModel = null;
    @ViewChild('guestInput', {static: true}) private guestInput: ElementRef<HTMLInputElement>;
    @ViewChild('partnerInput', {static: true}) private partnerInput: ElementRef<HTMLInputElement>;
    @ViewChild('chainInput', {static: true}) private chainInput: ElementRef<HTMLInputElement>;
    @ViewChild('guest', {static: true}) private guestAutocomplete: MatAutocomplete;
    @ViewChild('partner', {static: true}) private partnerAutoComplete: MatAutocomplete;
    @ViewChild('chainAutoComplete', {static: true}) private chainAutoComplete: MatAutocomplete;
    @ViewChild('mainContainer', {static: true}) private mainContainer;
    @ViewChild('start', {static: true}) private startCalendar;
    private _eventConfirmation: EventConfirmationModel = null;
    private _brandToColor = {
        0: '#e6e6e6',
        1: '#c0b460',
        2: '#ef4e37',
    };

    private _startTimeBuffer: Moment;
    private _endTimeBuffer: Moment;

    /**
     * Controls
     *
     */
    private _titleCtrl = new FormControl({value: '', disabled: this.readonly});
    private _hostCtrl = new FormControl({value: '', disabled: true});
    private _brandCtrl = new FormControl({value: '', disabled: this.readonly});
    private _eventTypeCtrl = new FormControl({value: '', disabled: this.readonly}, Validators.required);
    private _eventTypeSearch = new FormControl('');
    private _eventSubTypeCtrl = new FormControl({value: '', disabled: this.readonly});
    private _startDateCtrl = new FormControl({value: '', disabled: this.readonly}, Validators.required);
    private _startTimeCtrl = new FormControl({value: '00:00', disabled: this.readonly});
    private _endTimeCtrl = new FormControl({value: '00:00', disabled: this.readonly});
    private _endDateCtrl = new FormControl({value: '', disabled: this.readonly}, Validators.required);
    private _guestCtrl = new FormControl({value: [], disabled: this.readonly});
    private _guestChipsCtrl = new FormControl({value: [], disabled: this.readonly});
    private _partnerChipsCtrl = new FormControl({value: [], disabled: this.readonly});
    private _localizationCtrl = new FormControl({value: '', disabled: this.readonly});
    private _descriptionCtrl = new FormControl({value: '', disabled: this.readonly});
    private _partnerCtrl = new FormControl({value: [], disabled: this.readonly});
    private _chainCtrl = new FormControl({value: [], disabled: this.readonly});
    private _eventRemindersCtrl = new FormControl({value: [], disabled: this.readonly});
    private _recurringEventTypeCtrl = new FormControl({value: '', disabled: this.readonly});
    private _recurringIntervalCtrl = new FormControl({value: '', disabled: this.readonly});
    private _recurringEndCtrl = new FormControl({value: '', disabled: this.readonly});
    private _recurringEndDateCtrl = new FormControl({value: '', disabled: this.readonly});
    private _recurringEndAfterNoOfRepeatsCtrl = new FormControl({value: '', disabled: this.readonly});
    private _recurringIntervalNoCtrl = new FormControl({value: '', disabled: this.readonly});
    private _recurringDaysOfWeekCtrl = new FormControl({value: [], disabled: this.readonly});
    private _recurringDayOfMonthCtrl = new FormControl({value: '', disabled: this.readonly});


    constructor(@Inject(MAT_DIALOG_DATA) private data: EventModel,
                private readonly dialogRef: MatDialogRef<EventDialogComponent>,
                private readonly dictService: DictionariesService,
                private readonly eventCalendarService: EventCalendarService,
                private readonly eventService: EventsService,
                private readonly calendarScreenService: CalendarScreenService,
                private readonly authService: AuthenticationService,
                private readonly partnersService: PartnersService,
                private readonly chainsService: ChainsService,
                private _dialog: MatDialog) {
    }

    ngOnInit(): void {

        this.currentUserId = this.authService.currentUserValue.personId;
        this.readonly = this.data.editOnly;
        this.addMode = this.calendarScreenService.addDialogOpened;
        this.initializeControlsValue();
        this.initializeDictionaries();
        this.prepareTimeArray();
        this.initialSubscriptions();
        this.clearAllRecurringControlsOnMainControlChange();
        this._startTimeCtrl.valueChanges.subscribe(value => {
            if (!value) {
                this._endTimeCtrl.setValue(null);
                return;
            }

            const hours = +value.split(':')[0];
            const minutes = +value.split(':')[1];
            let endHours;
            let endMinutes;
            if (minutes < 30) {
                endHours = hours;
                endMinutes = minutes + 30;
            } else {
                endHours = hours + 1;
                endMinutes = (minutes + 30) - 60;
            }
            if (endHours >= 24) {
                endHours = endHours - 24;
                const momentEndDate = moment(this._endDateCtrl.value);
                this._endDateCtrl.setValue(momentEndDate.add('days', 1));
            }
            this._endTimeCtrl.setValue(`${endHours < 10 && '0' + endHours || endHours}:${endMinutes < 10 && '0' + endMinutes || endMinutes}`)
        });

        // Getters for search in selects
        this._eventTypeSearch.valueChanges.subscribe(() => this.filterEventTypes());
    }

    public showPartnerDetails(partnerId: number) {
        this.partnersService.showPartnerDetails(partnerId);
    }

    public showChainDetails(chainId: number) {
        this.chainsService.showChainDetails(chainId);
    }

    public filterEventTypes() {
        if (!this.dictEventType) {
            return;
        }
        let search = this._eventTypeSearch.value;
        if (!search) {
            this.filteredEventTypes = this.dictEventType;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredEventTypes = this.dictEventType.filter(event => event.value.toLowerCase().indexOf(search) > -1);
    }

    addTime() {
        this.withTime = !this.withTime;
        if (this.withTime) {
            this._startTimeCtrl.setValue(`08:00`);
            this._endTimeCtrl.setValue(`08:30`);
        } else {
            this._startTimeCtrl.setValue('00:00');
            this._endTimeCtrl.setValue('00:00');
        }
    }

    isReminderDataDirection(direction: number) {
        return direction && direction === 3;
    }

    isReminderBeforeOrAfter(direction: number) {
        return direction && !this.isReminderDataDirection(direction);
    }

    addTimeToDate(time: string, date: EventReminderModel) {
        const hours = +time.split(':')[0];
        const minutes = +time.split(':')[1];
        date.tempTime = time;
        date.exactDate.hours(hours).minutes(minutes);
    }

    closeDialog(withSuccess: boolean = false): void {
        if (withSuccess) {
            if (this.calendarForm.invalid) {
                return;
            }
            this.dialogRef.close(this.prepareModel());
            return;
        }
        this.dialogRef.close(null);
    }

    successfullyCloseEventDialog() {
        if (this.addMode) {
            this.closeDialog(true);
        } else {
            this.patchEvent();
        }
    }

    addGuest(event: MatChipInputEvent): void {
        if (!this.guestAutocomplete.isOpen) {
            if (event.input) {
                event.input.value = '';
            }

            this._guestCtrl.setValue(null);
        }
    }

    addPartner(event: MatChipInputEvent): void {
        if (!this.partnerAutoComplete.isOpen) {
            if (event.input) {
                event.input.value = '';
            }
            this._partnerCtrl.setValue(null);
        }
    }

    addChain(event: MatChipInputEvent): void {
        if (!this.chainAutoComplete.isOpen) {
            if (event.input) {
                event.input.value = '';
            }
            this._chainCtrl.setValue(null);
        }
    }

    removeGuest(guest: EventPersonModel): void {
        const index = this.chipsGuests.indexOf(guest);
        if (index >= 0) {
            this.chipsGuests.splice(index, 1);
        }
        if (!this.chipsGuests || this.chipsGuests.length === 0) {
            this._guestChipsCtrl.setValue(null);
        }
    }

    removePartner(partner: DictModel): void {
        const index = this.chipsPartners.indexOf(partner);

        if (index >= 0) {
            this.chipsPartners.splice(index, 1);
        }
        if (!this.chipsPartners || this.chipsPartners.length === 0) {
            this._partnerChipsCtrl.setValue(null);
        }
    }

    removeChain(partner: number): void {
        const index = this.chipsChains.indexOf(partner);
        if (index >= 0) {
            this.chipsChains.splice(index, 1);
        }
    }

    selectedGuest(event: MatAutocompleteSelectedEvent): void {
        const eventPerson: EventPersonModel = {personId: event.option.value};
        this.chipsGuests.push(eventPerson);
        this.guestInput.nativeElement.value = '';
        this._guestCtrl.setValue(null);
        this._guestChipsCtrl.setValue(this.chipsGuests);
    }

    selectedPartner(event: MatAutocompleteSelectedEvent): void {
        this.chipsPartners.push({id: event.option.value, value: event.option.viewValue});
        this.partnerInput.nativeElement.value = '';
        this._partnerCtrl.setValue(null);
        this._partnerChipsCtrl.setValue(this.chipsPartners);

    }

    selectedChain(event: MatAutocompleteSelectedEvent): void {
        this.chipsChains.push(event.option.value);
        this.chainInput.nativeElement.value = '';
        this._chainCtrl.setValue(null);
    }

    addReminder() {
        this.reminders.push({
            number: null,
            eventReminderTypeId: null,
            eventReminderDirectionId: null,
            exactDate: null,
            reminderForGuests: false,
            tempTime: null,
            tempId: uuid.v4()
        });
        setTimeout(() => this.mainContainer.nativeElement.scrollTop = this.mainContainer.nativeElement.scrollHeight, 0);
    }

    removeFromReminders(reminder: EventReminderModel) {
        const index = this.reminders.indexOf(reminder);
        index > -1 && this.reminders.splice(index, 1);
    }

    isBrandObligatory(): boolean {
        const pickedEventType = this._eventTypeCtrl.value;
        const chosenEvent = this.dictEventType && this.dictEventType.find((eventType: EventTypeDictModel) => eventType.id === pickedEventType) || null;

        if (this.hasSubtype()) {
            const pickedSubType = this._eventSubTypeCtrl.value;
            const chosenSubType = this.subTypesDict().find(value => value.id === pickedSubType) || null;
            return chosenSubType && chosenSubType.isBrandObligatory || chosenEvent && chosenEvent.isBrandObligatory;
        } else {
            return chosenEvent && chosenEvent.isBrandObligatory;
        }
    }

    isPartnerObligatory(): boolean {
        const pickedEventType = this._eventTypeCtrl.value;
        const chosenEvent = this.dictEventType && this.dictEventType.find((eventType: EventTypeDictModel) => eventType.id === pickedEventType) || null;

        if (this.hasSubtype()) {
            const pickedSubType = this._eventSubTypeCtrl.value;
            const chosenSubType = this.subTypesDict().find(value => value.id === pickedSubType) || null;
            return chosenSubType && chosenSubType.isPartnerObligatory || chosenEvent && chosenEvent.isPartnerObligatory;
        } else {
            return chosenEvent && chosenEvent.isPartnerObligatory;
        }
    }

    isLocalizationObligatory(): boolean {
        const pickedEventType = this._eventTypeCtrl.value;
        const chosenEvent = this.dictEventType && this.dictEventType.find((eventType: EventTypeDictModel) => eventType.id === pickedEventType) || null;

        if (this.hasSubtype()) {
            const pickedSubType = this._eventSubTypeCtrl.value;
            const chosenSubType = this.subTypesDict().find(value => value.id === pickedSubType) || null;
            return chosenSubType && chosenSubType.isLocalizationObligatory || chosenEvent && chosenEvent.isLocalizationObligatory;
        } else {
            return chosenEvent && chosenEvent.isLocalizationObligatory;
        }
    }

    areGuestsObligatory(): boolean {
        const pickedEventType = this._eventTypeCtrl.value;
        const chosenEvent = this.dictEventType && this.dictEventType.find((eventType: EventTypeDictModel) => eventType.id === pickedEventType) || null;

        if (this.hasSubtype()) {
            const pickedSubType = this._eventSubTypeCtrl.value;
            const chosenSubType = this.subTypesDict().find(value => value.id === pickedSubType) || null;
            return chosenSubType && chosenSubType.areGuestsObligatory || chosenEvent && chosenEvent.areGuestsObligatory;
        } else {
            return chosenEvent && chosenEvent.areGuestsObligatory;
        }
    }

    isTitleObligatory(): boolean {
        const pickedEventType = this._eventTypeCtrl.value;
        const chosenEvent = this.dictEventType && this.dictEventType.find((eventType: EventTypeDictModel) => eventType.id === pickedEventType) || null;

        if (this.hasSubtype()) {
            const pickedSubType = this._eventSubTypeCtrl.value;
            const chosenSubType = this.subTypesDict().find(value => value.id === pickedSubType) || null;
            return chosenSubType && chosenSubType.isTitleObligatory || chosenEvent && chosenEvent.isTitleObligatory;
        } else {
            return chosenEvent && chosenEvent.isTitleObligatory;
        }
    }

    getRecurring(): number {
        return this._recurringEventTypeCtrl.value;
    }

    getRecurringInterval(): number {
        return this._recurringIntervalCtrl.value;
    }

    getRecurringEnd(): number {
        return this._recurringEndCtrl.value;
    }

    hasSubtype(): boolean {
        const pickedEventType = this._eventTypeCtrl.value;
        const chosenEvent = this.dictEventType && this.dictEventType.find((eventType: EventTypeDictModel) => eventType.id === pickedEventType) || null;
        return chosenEvent && chosenEvent.hasSubType;
    }

    subTypesDict(id = null): Array<EventSubTypeDictModel> {
        const pickedEventType = id || this._eventTypeCtrl.value;
        const chosenEvent = this.dictEventType && this.dictEventType.find((eventType: EventTypeDictModel) => eventType.id === pickedEventType) || null;
        return chosenEvent && chosenEvent.eventSubTypes;
    }

    filterGuests(personModels: Array<PersonModel>): PersonModel[] {
        return personModels && personModels.filter((element: PersonModel) => !this.chipsGuests.find((guest: EventPersonModel) => guest.personId === element.id) && element.id !== this.currentUserId) || [];
    }

    filterChains(personModels: Array<DictModel>): DictModel[] {
        return personModels && personModels.filter((element: DictModel) => !this.chipsChains.includes(element.id)) || [];
    }

    openCalendar(event, picker: MatDatepicker<any>) {
        picker.open();
        this.selectFocusedElement(event);
    }

    selectFocusedElement(event) {
        event.target.select();
    }

    getStartDate(): Moment {
        return this._startDateCtrl.value;
    }

    removeEvent(): void {
        this.eventCalendarService.showRemoveDialog.next({
            type: ActionType.remove,
            personId: this.currentUserId,
            eventId: this.data.id,
            recurrenceId: this.recurrenceId
        });
        this.closeDialog(false);

    }

    revokeEvent(): void {
        if (this.recurrenceId) {
            this.confirmSeries(false);
        } else {
            this.calendarScreenService.acceptEventSubject.next({
                type: ActionType.confirm,
                personId: this.currentUserId,
                applyToSingle: true,
                eventId: this.data.id,
                confirmation: {accepted: false}
            });
        }
        this.accepted = false;
        this.closeDialog(false);
    }

    patchEvent() {
        if (this.calendarForm.invalid) {
            return;
        }
        if (this.recurrenceId) {
            this.updateSeries();
        } else {
            this.calendarScreenService.patchEventSubject.next({
                type: ActionType.update,
                personId: this.currentUserId,
                eventId: this.data.id,
                applyToSingle: true,
                recurrenceId: this.recurrenceId,
                eventModel: this.prepareModel()
            });
        }
        this.closeDialog(false);
    }

    acceptEvent(): void {
        if (this.recurrenceId) {
            this.confirmSeries(true);
        } else {
            this.calendarScreenService.acceptEventSubject.next({
                type: ActionType.confirm,
                personId: this.currentUserId,
                applyToSingle: true,
                eventId: this.data.id,
                confirmation: {accepted: true}
            });
        }
        this.accepted = true;

    }

    geSuffixForDayMonthOrYearIntervalNo(): string {
        if (this.getRecurring() === 1) {
            return 'dni';
        }

        if (this.getRecurring() === 3) {
            return 'miesięcy';
        }

        return 'lat';
    }

    getRecurringDict(): Array<DictModel> {
        if (this.getRecurring() === 1) {
            return this.dictRecurringIntervalDailyTypes;
        }

        if (this.getRecurring() === 3) {
            return this.dictRecurringIntervalMonthlyTypes;

        }

        return this.dictRecurringIntervalYearlyTypes;

    }

    notYetAnswered(): void {
        if (this.recurrenceId) {
            this.confirmSeries(null);
        } else {
            this.calendarScreenService.acceptEventSubject.next({
                type: ActionType.confirm,
                personId: this.currentUserId,
                applyToSingle: true,
                eventId: this.data.id,
                confirmation: {accepted: null}
            });
        }
        this.accepted = null;

    }

    private getColor(typeId = null, subTypeId = null): string {
        const pickedEventType = typeId || this._eventTypeCtrl.value;
        const pickedSubType = subTypeId || this._eventSubTypeCtrl.value;
        if (this.subTypesDict()) {
            const chosenSubType = this.subTypesDict().find(value => value.id === pickedSubType) || null;
            if (chosenSubType && chosenSubType.color) {
                return chosenSubType.color;
            }

        }
        const chosenEvent = this.dictEventType && this.dictEventType.find((eventType: EventTypeDictModel) => eventType.id === pickedEventType) || null;
        return chosenEvent && chosenEvent.color;
    }

    private prepareModel(): EventModel {
        const hoursFrom = +this._startTimeCtrl.value.toString().split(':')[0];
        const minutesFrom = this._startTimeCtrl.value.toString().split(':')[1];

        const hoursTo = this.withTime && +this._endTimeCtrl.value.toString().split(':')[0] || 0;
        const minutesTo = this.withTime && +this._endTimeCtrl.value.toString().split(':')[1] || 0;

        const dateTimeFrom = moment(this._startDateCtrl.value).hours(hoursFrom).minutes(minutesFrom);
        const dateTimeTo = moment(this._endDateCtrl.value).hours(hoursTo).minutes(minutesTo);

        const color = this.getColor() || this._brandToColor[this._brandCtrl.value || 0];

        let eventRecurrenceModel: EventRecurrenceModel = null;
        if (this.getRecurring() && this.getRecurring() !== 2) {
            eventRecurrenceModel = this.setRecurringDailyModel();
        }

        if (this.getRecurring() === 2) {
            eventRecurrenceModel = this.setRecurringWeeklyModel();
        }

        return {
            id: this.data.id || null,
            hostId: this.currentUserId,
            start: dateTimeFrom,
            end: dateTimeTo,
            extendedProps: {
                title: this._titleCtrl.value,
                guests: this.chipsGuests,
                description: this._descriptionCtrl.value,
                localization: this._localizationCtrl.value,
                brandId: this._brandCtrl.value,
                eventTypeId: this._eventTypeCtrl.value,
                eventSubTypeId: this._eventSubTypeCtrl.value,
                partnerIds: this.chipsPartners.map((partner: DictModel) => partner.id),
                chainIds: this.chipsChains,
                eventConfirmationModel: this._eventConfirmation || null,
                eventRecurrenceDTO: eventRecurrenceModel,
                eventReminders: this.reminders.filter((reminder: EventReminderModel) => (reminder.number && reminder.eventReminderTypeId) || reminder.exactDate),
                action: this.action,
            },
            backgroundColor: color,
            borderColor: color,
            allDay: !this.withTime
        };
    }

    private updateSeries(): void {
        this.eventCalendarService.updateSeriesDialog.next({
            type: ActionType.update,
            personId: this.currentUserId,
            eventId: this.data.id,
            recurrenceId: this.recurrenceId,
            eventModel: this.prepareModel()
        });
    }

    private confirmSeries(confirm: boolean): void {
        this.eventCalendarService.confirmSeriesDialog.next({
            type: ActionType.confirm,
            personId: this.currentUserId,
            eventId: this.data.id,
            recurrenceId: this.recurrenceId,
            confirmation: {accepted: confirm}
        });
    }

    private openSeriesDialog(action: EventActionDialogCommunicationModel): void {
        if (!this.eventCalendarService.seriesDialogOpened) {
            this.eventCalendarService.seriesDialogOpened = true;
            const dialogRef = this._dialog.open(SeriesActionDialogComponent, {
                width: DialogUtils.defaultDialogWidth,
                data: action
            });

            dialogRef.afterClosed().subscribe(() =>
                this.eventCalendarService.seriesDialogOpened = false
            );

        }

    }

    private clearAllRecurringControlsOnMainControlChange() {
        this._recurringEventTypeCtrl.valueChanges.subscribe(
            () => {
                this._recurringIntervalCtrl.setValue('');
                this._recurringEndCtrl.setValue('');
                this._recurringEndDateCtrl.setValue('');
                this._recurringEndAfterNoOfRepeatsCtrl.setValue('');
                this._recurringIntervalNoCtrl.setValue('');
                this._recurringDaysOfWeekCtrl.setValue([]);
                this._recurringDayOfMonthCtrl.setValue('');
            }
        );
    }

    private initializeAllRecurringControls() {
        if (this.data.extendedProps.eventRecurrenceDTO) {
            const eventRecurrence = this.data.extendedProps.eventRecurrenceDTO;
            const recurrenceTypeId = eventRecurrence.eventRecurrenceTypeId;
            if (recurrenceTypeId === 1 || recurrenceTypeId === 3 || recurrenceTypeId === 4) {
                //daily, monthly and yearly
                this._recurringEventTypeCtrl.setValue(recurrenceTypeId);
                eventRecurrence && eventRecurrence.interval === 1 && this._recurringIntervalCtrl.setValue(1);
                if (eventRecurrence && eventRecurrence.interval > 1) {
                    this._recurringIntervalCtrl.setValue(2);
                    this._recurringIntervalNoCtrl.setValue(eventRecurrence.interval);
                }
            } else {
                //weekly
                this._recurringEventTypeCtrl.setValue(2);
                if (eventRecurrence.daysOfWeek) {
                    this._recurringDaysOfWeekCtrl.setValue(eventRecurrence.daysOfWeek);
                    this._recurringIntervalCtrl.setValue(1);
                }

                if (!eventRecurrence.daysOfWeek) {
                    this._recurringIntervalCtrl.setValue(2);
                    this._recurringIntervalNoCtrl.setValue(eventRecurrence.interval);
                }

            }

            if (eventRecurrence.endDate) {
                this._recurringEndCtrl.setValue(3);
                this._recurringEndDateCtrl.setValue(eventRecurrence.endDate);
            } else if (eventRecurrence.occurrences) {
                this._recurringEndCtrl.setValue(2);
                this._recurringEndAfterNoOfRepeatsCtrl.setValue(eventRecurrence.occurrences);
            } else {
                this._recurringEndCtrl.setValue(1);
            }


        }
    }

    private setRecurringDailyModel(): EventRecurrenceModel {

        const recurringEndVal = this.getRecurringEnd() === 2 && this._recurringEndAfterNoOfRepeatsCtrl.value || null;
        const recurringEndDate = this.getRecurringEnd() === 3 && this._recurringEndDateCtrl.value || null;
        let recurringIntervalVal;
        if (this.getRecurringInterval() === 1) {
            recurringIntervalVal = 1;
        } else {
            recurringIntervalVal = this._recurringIntervalNoCtrl.value || null;
        }

        if (!this.getRecurring()) {
            return null;
        }

        return {
            eventRecurrenceTypeId: this.getRecurring(),
            interval: recurringIntervalVal,
            endDate: recurringEndDate && recurringEndDate.format('YYYY-MM-DD'),
            occurrences: recurringEndVal,
        };
    }

    private setRecurringWeeklyModel(): EventRecurrenceModel {
        const recurringEndVal = this.getRecurringEnd() === 2 && this._recurringEndAfterNoOfRepeatsCtrl.value || null;
        const recurringEndDate = this.getRecurringEnd() === 3 && this._recurringEndDateCtrl.value || null;

        const daysOfWeek = this.getRecurringInterval() === 1 && this._recurringDaysOfWeekCtrl.value || null;
        const recurringIntervalVal = this.getRecurringInterval() === 2 && this._recurringIntervalNoCtrl.value || null;

        if (!this.getRecurring()) {
            return null;
        }

        return {
            eventRecurrenceTypeId: this.getRecurring(),
            interval: recurringIntervalVal,
            endDate: recurringEndDate && recurringEndDate.format('YYYY-MM-DD'),
            daysOfWeek: daysOfWeek,
            occurrences: recurringEndVal,
        };
    }

    private initializeControlsValue(): void {
        this.calendarForm = new FormGroup(
            {
                titleCtrl: this._titleCtrl,
                brandCtrl: this._brandCtrl,
                hostCtrl: this._hostCtrl,
                eventTypeCtrl: this._eventTypeCtrl,
                eventTypeSearch: this._eventTypeSearch,
                eventSubTypeCtrl: this._eventSubTypeCtrl,
                startDateCtrl: this._startDateCtrl,
                startTimeCtrl: this._startTimeCtrl,
                endTimeCtrl: this._endTimeCtrl,
                endDateCtrl: this._endDateCtrl,
                guestCtrl: this._guestCtrl,
                guestChipsCtrl: this._guestChipsCtrl,
                partnerChipsCtrl: this._partnerChipsCtrl,
                localizationCtrl: this._localizationCtrl,
                descriptionCtrl: this._descriptionCtrl,
                partnerCtrl: this._partnerCtrl,
                chainCtrl: this._chainCtrl,
                eventRemindersCtrl: this._eventRemindersCtrl,
                recurringEventTypeCtrl: this._recurringEventTypeCtrl,
                recurringIntervalCtrl: this._recurringIntervalCtrl,
                recurringEndCtrl: this._recurringEndCtrl,
                recurringEndDateCtrl: this._recurringEndDateCtrl,
                recurringEndAfterNoOfRepeatsCtrl: this._recurringEndAfterNoOfRepeatsCtrl,
                recurringIntervalNo: this._recurringIntervalNoCtrl,
                recurringDaysOfWeekCtrl: this._recurringDaysOfWeekCtrl,
                recurringDayOfMonthCtrl: this._recurringDayOfMonthCtrl,
            }
        );

        if (this.readonly) {
            this.calendarForm.disable();
        }

        const eventStart = moment(this.data.start);
        let eventEnd: Moment = moment(this.data.end);
        const isTimeSetForStart = !!(eventStart.get('minutes') + eventStart.get('hours'));
        const isTimeSetForEnd = !!(eventEnd.get('minutes') + eventEnd.get('hours'));
        this.withTime = isTimeSetForStart || isTimeSetForEnd;

        this._startDateCtrl.setValue(eventStart);
        this._endDateCtrl.setValue(eventEnd);


        isTimeSetForStart && this._startTimeCtrl.setValue(this.momentDateToTimeString(eventStart));
        isTimeSetForEnd && this._endTimeCtrl.setValue(this.momentDateToTimeString(eventEnd));

        if (this.data.extendedProps) {
            this._titleCtrl.setValue(this.data.extendedProps.title);
            this._hostCtrl.setValue(this.data.host);

            this.recurrenceId = this.data.extendedProps.eventRecurrenceDTO && this.data.extendedProps.eventRecurrenceDTO.id || null;

            this._localizationCtrl.setValue(this.data.extendedProps.localization);
            this._descriptionCtrl.setValue(this.data.extendedProps.description);
            this._eventTypeCtrl.setValue(this.data.extendedProps.eventTypeId);
            this._brandCtrl.setValue(this.data.extendedProps.brandId);
            this._chainCtrl.setValue(this.data.extendedProps.chainIds);
            this._eventSubTypeCtrl.setValue(this.data.extendedProps.eventSubTypeId);
            this.data.extendedProps.partnerIds && this.data.extendedProps.partnerIds.length > 0 && this.data.extendedProps.partnerIds.forEach((guestId: number) => this.dictService.getPartnerNameById(guestId).subscribe(
                (name: string) => this.chipsPartners.push({id: guestId, value: name})
            ));
            this.initializeAllRecurringControls();
            this.chipsGuests = this.data.extendedProps.guests;
            this.chipsChains = this.data.extendedProps.chainIds;
            this._guestChipsCtrl.setValue(this.data.extendedProps.guests);
            this._partnerChipsCtrl.setValue(this.data.extendedProps.partnerIds);

            this.showDescriptionField = this._descriptionCtrl.value;
            this.accepted = this.data.extendedProps.acceptedByCurrentUser;
            this.reminders = this.data.extendedProps.eventReminders || [];
        }
    }

    private initialSubscriptions(): void {
        this.eventCalendarService.showRemoveDialog.subscribe((action: EventActionDialogCommunicationModel) => this.openSeriesDialog(action));
        this.eventCalendarService.updateSeriesDialog.subscribe((action: EventActionDialogCommunicationModel) => this.openSeriesDialog(action));
        this.eventCalendarService.confirmSeriesDialog.subscribe((action: EventActionDialogCommunicationModel) => this.openSeriesDialog(action));


        this._startDateCtrl.valueChanges.subscribe((value: Moment) =>
            value.isAfter(this._endDateCtrl.value) && this._endDateCtrl.setValue('')
        );

        this.filteredGuestsList$ = this._guestCtrl.valueChanges.pipe(
            startWith(null),
            map((guest: string | null) => !!guest ? this._filterPeopleDict(guest, this.dictPeople) : this.dictPeople && this.dictPeople.slice()));
        this.filteredChainList$ = this._chainCtrl.valueChanges.pipe(
            startWith(null),
            map((chain: string | null) => !!chain ? this._filterChainDict(chain, this.dictChains) : this.dictChains && this.dictChains.slice()));
        this.filteredPartnerList$ = this._partnerCtrl.valueChanges
            .pipe(
                debounceTime(300),
                switchMap((value: string) => this.dictService.getPartnerByPhraseOfLength(value, 3))
            );

        this.filteredStartTimeArray$ = this._startTimeCtrl.valueChanges.pipe(
            startWith(''),
            map((time: string) => {
                if (!time) {
                    return this._timeArray;
                } else {
                    if (time > '23:59') {
                        this._startTimeCtrl.setValue(null);
                    }
                    return this._filterTime(time, this._timeArray);
                }
            })
        );

        this.filteredEndTimeArray$ = this._endTimeCtrl.valueChanges.pipe(
            startWith(''),
            map((time: string) => {
                    const startTime = this._startTimeCtrl.value
                    if (!startTime) {
                        return [];
                    }
                    const filteredTimeArray = this._timeArray.filter((value: string) => value > startTime);
                    if (!time) {
                        return filteredTimeArray;
                    } else {
                        if (time > '23:59') {
                            this._endTimeCtrl.setValue(null);
                            return;
                        }
                        return this._filterTime(time, filteredTimeArray);
                    }
                }
            )
        );

    }

    private initializeDictionaries(): void {
        this.dictService.getPeople().subscribe((peopleDict: Array<DictModel>) => {
            this.dictPeople = peopleDict;
        });
        this.dictService.getBrandDictionary().subscribe((brandCooperation: Array<DictModel>) => this.dictBrand = brandCooperation);
        this.dictService.getAllChains().subscribe((chains: Array<DictModel>) => this.dictChains = chains);
        this.dictService.getEventTypes().subscribe((eventTypes: Array<EventTypeDictModel>) => {
            this.dictEventType = eventTypes;
            this.filteredEventTypes = eventTypes;
        });
        this.dictService.getReminder().subscribe((eventTypes: Array<EventTypeDictModel>) => this.dictReminderTypes = eventTypes);
        this.dictService.getRecurringType().subscribe((recurrenceTypes: Array<DictModel>) => this.dictRecurrenceTypes.push(...recurrenceTypes));
        this.dictService.getRecurringDailyIntervalTypes().subscribe((data: DictModel[]) => this.dictRecurringIntervalDailyTypes = data);
        this.dictService.getRecurringWeeklyIntervalTypes().subscribe((data: DictModel[]) => this.dictRecurringIntervalWeeklyTypes = data);
        this.dictService.getRecurringMonthlyIntervalTypes().subscribe((data: DictModel[]) => this.dictRecurringIntervalMonthlyTypes = data);
        this.dictService.getRecurringYearlyIntervalTypes().subscribe((data: DictModel[]) => this.dictRecurringIntervalYearlyTypes = data);
        this.dictService.getRecurringEndType().subscribe((data: DictModel[]) => this.dictRecurringEndTypes = data);
        this.dictService.getEventReminderDirection().subscribe((data: DictModel[]) => this.dictReminderDirection = data);
        this.dictDaysOfWeek = this.dictService.getDaysDict();
    }

    private obtainPersonName(id: any) {
        let dictModel = this.dictPeople && this.dictPeople.find((peopleDict: DictModel) => peopleDict.id === id);
        return dictModel && dictModel.value || null;
    }

    private obtainChainName(id: any) {
        let chainModel = this.dictChains && this.dictChains.find((chainDetails: DictModel) => chainDetails.id === id);
        return chainModel && chainModel.value || null;
    }

    private momentDateToTimeString(date: Moment): string {
        return moment({
            hour: date.get('hours'),
            minute: date.get('minutes')
        }).format('HH:mm');
    }

    private _filterTime(value: string, list: Array<string>): string[] {
        const filterValue = value && typeof value === 'string' && value.toLowerCase() || null;
        if (filterValue?.length === 5) {
            return list;
        }
        return list.filter(element => element.toLowerCase().includes(filterValue));
    }

    private _filterList(value: string, list: Array<string>): string[] {

        const filterValue = value && typeof value === 'string' && value.toLowerCase() || null;

        return list.filter(element => element.toLowerCase().includes(filterValue));
    }

    private _filterPeopleDict(value: string, list: Array<DictModel>): DictModel[] {
        const filterValue = value && typeof value === 'string' && value.toLowerCase() || null;
        return list.filter((element: PersonModel) => this.obtainPersonName(element.id) && this.obtainPersonName(element.id).toLowerCase().includes(filterValue));
    }

    private _filterChainDict(value: string, list: Array<DictModel>): DictModel[] {
        const filterValue = value && typeof value === 'string' && value.toLowerCase() || null;
        return list.filter((element: DictModel) => this.obtainChainName(element.id) && this.obtainChainName(element.id).toLowerCase().includes(filterValue));
    }

    private prepareTimeArray(): void {
        for (let hour = 8; hour < 24; hour++) {
            this._timeArray.push(moment({hour}).format('HH:mm'));
            this._timeArray.push(
                moment({
                    hour,
                    minute: 30
                }).format('HH:mm')
            );
        }
    }

}
