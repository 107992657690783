import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import { environment } from '../../environments/environment';
import { BrandCooperationModel } from '../_models/brand-cooperation.model';
import {BrandCooperationDeliverAddressModel} from '../_models/brand-cooperation-deliver-address.model';


@Injectable()
export class BrandCooperationService {

    private readonly API_URL = environment.apiUrl + '/api/brand-cooperation';


    constructor(private readonly http: HttpClient) {

    }

    public getBrandCooperation(partnerId: number = null): Observable<Array<BrandCooperationModel>> {
        let params: HttpParams = new HttpParams();
        if (partnerId) {
            params = params.append('partnerId', partnerId.toString());
        }

        return this.http.get<Array<BrandCooperationModel>>(`${this.API_URL}`, {params});
    }

    public updateBrand(brandCooperationModel: BrandCooperationModel): Observable<BrandCooperationModel> {
        return this.http.put<BrandCooperationModel>(`${this.API_URL}/${brandCooperationModel.id}`, brandCooperationModel);
    }

    public addBrand(brandCooperationModel: BrandCooperationModel): Observable<BrandCooperationModel> {
        return this.http.post<BrandCooperationModel>(`${this.API_URL}`, brandCooperationModel);
    }

    public deleteBrand(brandCooperationModel: BrandCooperationModel): Observable<BrandCooperationModel> {
        return this.http.delete<BrandCooperationModel>(`${this.API_URL}/${brandCooperationModel.id}`);
    }

    public updateBrands(brandCooperationModels: BrandCooperationModel[]): Observable<BrandCooperationModel[]> {
        console.log("Updating brand request: " + JSON.stringify(brandCooperationModels));
        // return of(brandCooperationModels);
        return this.http.put<BrandCooperationModel[]>(`${this.API_URL}/bulk`, brandCooperationModels);
    }

    public getBrandsDeliveryAddress(brandId : number): Observable<BrandCooperationDeliverAddressModel[]> {
        return this.http.get<Array<BrandCooperationDeliverAddressModel>>(`${this.API_URL}/deliveryAddress/${brandId}`)
    }

    public addBrands(brandCooperationModels: BrandCooperationModel[]): Observable<BrandCooperationModel[]> {
        return this.http.post<BrandCooperationModel[]>(`${this.API_URL}/bulk`, brandCooperationModels);
    }

}
