import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalSystemConfigModel } from '../../../../_models/global-system-config.model';


@Injectable()
export class AdminConfigurationTabService {

    public MAIN_CONFIG_ID = 1;

    constructor(private readonly _httpClient: HttpClient) {
    }

    public getConfiguration(configId: number): Observable<GlobalSystemConfigModel> {
        return this._httpClient.get<GlobalSystemConfigModel>(`/api/global-config/${configId}`);
    }

    public updateConfiguration(globalConfig: GlobalSystemConfigModel): Observable<GlobalSystemConfigModel> {
        return this._httpClient.put<GlobalSystemConfigModel>(`/api/global-config/${globalConfig.id}`, globalConfig);
    }

}
