import { AuthRolesEnum, canPerformAction } from './auth-commons';

export const canRemovePartner = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditPartnerId = (roles: number[]): boolean => {
    return false;
}

export const canEditChain = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditName = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditPartnerType = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditSource = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditCreationDate = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditModificationDate = (roles: number[]): boolean => {
    return false;
}

export const canEditLicenseNumber = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditLicenseObtainDate = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditNip = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.TOA,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditAddressData = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditCoordinatorData = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditSozLoginField = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR
    ]
    return canEditSozFields(roles) || canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditSozFields = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditSozLogin = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canViewSozFields = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR
    ]
    return canEditSozFields(roles) || canPerformAction(roles, hasRightsToPerformActions);
}

export const canCreateOrDeactivateSozAccount = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canChangeLoginOrResetSozPassword = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canUseForceSozButton = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

