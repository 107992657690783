import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageSeverityEnum } from '../../_enums/message-severity.enum';
import { MessagingUtils } from '../messaging.utils';


export class DialogMessage {
    headerText: string;
    text: string;
    severity: MessageSeverityEnum;
}

@Component({
    selector: 'app-dialog-messaging-component',
    templateUrl: 'dialog-messaging.component.html',
    styleUrls: ['dialog-messaging.component.scss'],
})
export class DialogMessagingComponent {

    /**
     * Sample method that trigger showing dialog with custom text, and severity on the screen
     *
     * openDialog(){
     *    this.messageService.setDialog({
     *      headerText: 'Some header text',
     *      severity: MessageSeverityEnum.info,
     *      text: 'Some text'})
     * }
     *
     * messageService is an field of type :MessageService
     *
     * */


    constructor(
        private _dialogRef: MatDialogRef<DialogMessagingComponent>,
        @Inject(MAT_DIALOG_DATA) public message: DialogMessage) {
    }

    protected closeDialog() {
        this._dialogRef.close();
    }

    getLabel(): string {
        return MessagingUtils.getMessageSeverityLabel(this.message.severity);
    }
}
