import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageSeverityEnum } from '../_enums/message-severity.enum';
import { ErrorModel } from '../_models/error/error.model';
import { MessageService } from '../_services/message.service';

@Component({
    selector: 'app-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: ErrorModel, private readonly messageService: MessageService) {
    }

    ngOnInit() {
    }

    copyErrorId(): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.data.errorId;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.messageService.setSnackbar({
            text: 'Informacja o błędzie skopiowana poprawnie, dołącz skopiowaną informację do zgłoszenia. Dziękujemy!',
            durationInSecond: 10,
            severity: MessageSeverityEnum.success
        });

    }

}
