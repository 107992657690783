export const replacePolishChars = (string: string) => {
    //TODO: jak ktoś ma lepszy pomysł to proszę się nie krępować :)
    string = string.replace('ą', 'azzz');
    string = string.replace('Ą', 'Azzz');
    string = string.replace('ę', 'ezzz');
    string = string.replace('Ę', 'Ezzz');
    string = string.replace('ż', 'zzzzz');
    string = string.replace('Ż', 'Zzzzz');
    string = string.replace('ź', 'zzzz');
    string = string.replace('Ź', 'Zzzz');
    string = string.replace('ć', 'czzz');
    string = string.replace('Ć', 'Czzz');
    string = string.replace('ń', 'nzzz');
    string = string.replace('Ń', 'Nzzz');
    string = string.replace('ó', 'ozzz');
    string = string.replace('Ó', 'Ozzz');
    string = string.replace('ł', 'lzzz');
    string = string.replace('Ł', 'Lzzz');
    return string;
}
