import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-add-partner-dialog',
    templateUrl: './confirm-removing-user-dialog.component.html',
    styleUrls: ['./confirm-removing-user-dialog.component.scss']
})
export class ConfirmRemovingUserDialog {


    constructor(private _dialogRef: MatDialogRef<ConfirmRemovingUserDialog>) {
    }

    protected removePartner() {
        this._dialogRef.close(true);
    }

    protected doNotRemove() {
        this._dialogRef.close(false);
    }


}
