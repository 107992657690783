import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as _ from 'lodash';
import {combineLatest, merge, of, Subject, zip} from 'rxjs';
import {delay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {BrandCooperationModel} from './_models/brand-cooperation.model';
import {ChainDetailsModel} from './_models/chain-details.model';
import {EventModel} from './_models/event-model';
import {PartnerExtraDetailsModel, PartnerDetailsModel} from './_models/partner-details.model';
import {ShortageModel} from './_models/shortage-model';
import {BrandCooperationService} from './_services/brand-cooperation.service';
import {DictionariesService} from './_services/dictionaries.service';
import {MessageService} from './_services/message.service';
import {ShortageService} from './_services/shortage.service';
import {DialogUtils} from './_utils/dialog.utils';
import {CalendarScreenService} from './calendar/calendar-screen.service';
import {EventDialogComponent} from './calendar/dialogs/event-dialog-component';
import {ChainDetailsDialogComponent} from './chains/chains-detail-dialog/chain-details-dialog.component';
import {ChainDetailsScreenService} from './chains/chains-detail-dialog/chain-details-screen.service';
import {ChainsDialogModel} from './chains/chains-detail-dialog/chains-dialog.model';
import {ChainsService} from './chains/chains.service';
import {EventsService} from './events/events.service';
import {ActionDialog, ActionDialogComponent} from './messaging/action-dialog/action-dialog.component';
import {DialogMessage, DialogMessagingComponent} from './messaging/dialog-messaging/dialog-messaging.component';
import {SnackbarMessage, SnackbarMessagingComponent} from './messaging/snackbar-messaging/snackbar-messaging.component';
import {PartnerDetailsDialogComponent} from './partners/partner-details-dialog/partner-details-dialog.component';
import {PartnerDetailsDialogModel} from './partners/partner-details-dialog/partner-details-dialog.model';
import {PartnerDetailsScreenService} from './partners/partner-details-dialog/partner-details-screen.service';
import {InitialObjectForPartnerDetails, PartnersService} from './partners/partners.service';
import {DialogResultEnum} from './_enums/dialog-result.enum';
import {InitialObjectForProductDetails, ProductsService} from './products/products.service';
import {ProductDetailsModel} from './_models/product-details.model';
import {ProductDialogModel} from './products/products-detail-dialog/products-dialog.model';
import {ProductsDetailsDialogComponent} from './products/products-detail-dialog/products-details-dialog.component';
import {InitialObjectForProcuratorDetails, ProcuratorsService} from './procurators/procurators.service';
import {ProcuratorDetailsModel} from './_models/procurator-details.model';
import {ProcuratorDialogModel} from './procurators/procurator-details-dialog/procurator-dialog.model';
import {ProcuratorDetailsDialogComponent} from './procurators/procurator-details-dialog/procurator-details-dialog.component';
import { PartnerProcuratorDetailsModel } from './_models/partner-procurator-details.model';
import {Observable} from 'rxjs/index';
import {EntrepreneursService} from './entrepreneurs/entrepreneurs.service';
import {
    EntrepreneurDetailsDialogModel
} from './entrepreneurs/entrepreneur-details-dialog/entrepreneur-details-dialog.model';
import {
    EntrepreneurDetailsDialogComponent
} from './entrepreneurs/entrepreneur-details-dialog/entrepreneur-details-dialog.component';
import {EntrepreneurBulkUpdateModel} from './_models/entrepreneur-bulk-update.model';
import {MessageSeverityEnum} from './_enums/message-severity.enum';
import {EntrepreneurBrandCooperationModel} from './_models/entrepreneur-brand-cooperation.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    public partnerDetailsId = null;
    public chainDetailsId = null;
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(private readonly messageService: MessageService,
                private _snackbar: MatSnackBar,
                private _dialog: MatDialog,
                private readonly calendarScreenService: CalendarScreenService,
                private readonly partnerDetailsScreenService: PartnerDetailsScreenService,
                private readonly chainDetailsScreenService: ChainDetailsScreenService,
                private readonly shortageService: ShortageService,
                private readonly brandService: BrandCooperationService,
                private readonly entrepreneursService: EntrepreneursService,
                private readonly eventsService: EventsService,
                private readonly partnersService: PartnersService,
                private readonly chainsService: ChainsService,
                private readonly productsService: ProductsService,
                private readonly procuratorsService: ProcuratorsService,
                private readonly dictService: DictionariesService
    ) {
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    ngOnInit(): void {
        this.messageService.snackbar$.subscribe((message: SnackbarMessage) => this.openSnackBar(message));
        this.messageService.dialog$.subscribe((message: DialogMessage) => this.openDialogMessage(message));
        this.messageService.actionDialog$.subscribe(action => this.openActionDialog(action))

        this.partnersService.getShowPartnerDetails().pipe(
            takeUntil(this.unsubscribe$),
            switchMap((initialObjectForPartnerDetails: InitialObjectForPartnerDetails) => {
                const brands$ = this.brandService.getBrandCooperation(initialObjectForPartnerDetails.id);
                const procuratorsDetails$ = this.procuratorsService.getPartnerProcurators(initialObjectForPartnerDetails.id);
                const partnerDetails$ = this.partnerDetailsScreenService.getSubjectDetails(initialObjectForPartnerDetails.id);
                const partnerExtraDetails$ = this.partnerDetailsScreenService.getSubjectExtraDetails(initialObjectForPartnerDetails.id)
                return combineLatest([partnerDetails$, brands$, partnerExtraDetails$, procuratorsDetails$, of(initialObjectForPartnerDetails)])

            })
        ).subscribe(
            ([partnerDetails, brands, extra, procuratorsDetails, initialObjectForPartnerDetails]: [PartnerDetailsModel, Array<BrandCooperationModel>, PartnerExtraDetailsModel, Array<PartnerProcuratorDetailsModel>, InitialObjectForPartnerDetails]) => {
                const partnersDialogModel: PartnerDetailsDialogModel = {
                    partnerDetailsModel: partnerDetails,
                    partnerDetailsExtraModel: extra,
                    brandCooperationModel: brands,
                    loadedProcurators: new Set<number>(procuratorsDetails.map(p => p.id)),
                    partnerProcuratorsDetails: procuratorsDetails,
                    sozLoginSaved: partnerDetails.sozLogin != null,
                    partnerSaved: true,
                    addedBrandCooperation: [],
                    editedBrandCooperation: [],
                    removedBrandCooperation: []
                };
                partnersDialogModel.editOnly = !initialObjectForPartnerDetails.canEdit;
                partnersDialogModel.selectedIndex = initialObjectForPartnerDetails.defaultTab;
                this.openPartnersDetailDialog(partnersDialogModel);
            }
        );

        this.productsService.getShowProductDetails().pipe(
            takeUntil(this.unsubscribe$),
            switchMap((initialObjectForProductsDetails: InitialObjectForProductDetails) => {
                return this.productsService.getProductDetails('api/products', initialObjectForProductsDetails.id);
            })
        ).subscribe(
            (productDetails: ProductDetailsModel) => {
                const productDialogModel: ProductDialogModel = {
                    productModel: productDetails
                };
                this.openProductDetailDialog(productDialogModel);
            }
        );

        this.chainsService.getShowChainDetails().pipe(
            takeUntil(this.unsubscribe$),
            switchMap((editOnlyAndIdPair: InitialObjectForPartnerDetails) => {
                const shortages$ = this.shortageService.getShortagesForChain(editOnlyAndIdPair.id);
                const chainDetails$ = this.chainDetailsScreenService.getSubjectDetails('api/chain', editOnlyAndIdPair.id);

                return combineLatest([chainDetails$, shortages$, of(editOnlyAndIdPair.canEdit)])

            })
        ).subscribe(
            ([chainModel, shortages, canEdit]: [ChainDetailsModel, Array<ShortageModel>, boolean]) => {
                const chainDetailsDialog: ChainsDialogModel = {
                    chainModel: chainModel,
                    chainShortages: shortages
                };
                chainDetailsDialog.editOnly = !canEdit;
                this.openChainsDetailDialog(chainDetailsDialog);
            });

        this.calendarScreenService.getShowAddDialog$().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((eventModel: EventModel) => {
            this.openAddCalendarEventDialog(eventModel);
        });

        this.calendarScreenService.getShowEditDialog$().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((eventModel: EventModel) => {
            this.openEventEditDialog(eventModel);
        });

        this.entrepreneursService.getShowEntrepreneurDetails().pipe(
            takeUntil(this.unsubscribe$),
            switchMap((id) => {
                const entrepreneurDetails = this.entrepreneursService.getEntrepreneurDetails(id.id);
                const entrepreneurWspolnicy = this.entrepreneursService.getEntrepreneurWspolnicy(id.id);
                const entrepreneurDeliveryAddress = this.entrepreneursService.getEntrepreneurDeliveryAddress(id.id);
                const entrepreneurBrands = this.entrepreneursService.getEntrepreneurBrands(id.id);
                const entrepreneurRepresentative = this.entrepreneursService.getEntrepreneurRepresentative(id.id);
                return combineLatest([entrepreneurDetails, entrepreneurWspolnicy, entrepreneurDeliveryAddress, entrepreneurBrands, entrepreneurRepresentative]);
            })
        ).subscribe(([entrepreneurDetailsModel, entrepreneurWspolnicy, entrepreneurDeliveryAddress, entrepreneurBrands, entrepreneurRepresentative]) => {
            const dialogModel = new EntrepreneurDetailsDialogModel();
            dialogModel.entrepreneurDetailsModel = entrepreneurDetailsModel
            dialogModel.wspolnicy = entrepreneurWspolnicy
            dialogModel.entrepreneurDeliveryAddress = entrepreneurDeliveryAddress
            dialogModel.entrepreneurBrands = entrepreneurBrands
            dialogModel.entrepreneurRepresentative = entrepreneurRepresentative
            dialogModel.entrepreneurSaved = true;
            dialogModel.loyaltyPointsChanged = false;
            this.openEntrepreneursDialog(dialogModel);
        });

        this.procuratorsService.getShowProcuratorDetails().pipe(
            takeUntil(this.unsubscribe$),
            switchMap((initialObjectForProcuratorDetails: InitialObjectForProcuratorDetails) => {
                const procuratorDetails = this.procuratorsService.getProcuratorDetails(initialObjectForProcuratorDetails.id);
                return combineLatest([procuratorDetails, of(initialObjectForProcuratorDetails)]);
            })
        ).subscribe(([procuratorDetailsModel, procuratorId]: [ProcuratorDetailsModel, InitialObjectForPartnerDetails]) => {
                let procuratorDialogModel = new ProcuratorDialogModel();
                procuratorDialogModel.detailsModel = procuratorDetailsModel;
                procuratorDialogModel.editOnly = !procuratorId.canEdit
                this.openProcuratorDetailDialog(procuratorDialogModel);
            });
    }

    openActionDialog(action: ActionDialog): void {
        this._dialog.open(ActionDialogComponent, {
            width: DialogUtils.defaultDialogWidth,
            data: action
        });
    }

    openDialogMessage(message: DialogMessage): void {
        this._dialog.open(DialogMessagingComponent, {
            width: DialogUtils.defaultDialogWidth,
            data: message
        });
    }

    private openProductDetailDialog(productDialogModel: ProductDialogModel): void {
        productDialogModel.notification = new Subject<DialogResultEnum>();

        const dialogRef = this._dialog.open(ProductsDetailsDialogComponent,
            {
                data: productDialogModel,
                maxWidth: '1400px',
                panelClass: 'details-dialog'
            }
        );

        productDialogModel.notification.subscribe((result: DialogResultEnum) => {
            dialogRef.close();
        });
    }

    private openChainsDetailDialog(chainDialogModel: ChainsDialogModel): void {
        let backup = _.cloneDeep(chainDialogModel);

        chainDialogModel.notification = new Subject<DialogResultEnum>();

        const dialogRef = this._dialog.open(ChainDetailsDialogComponent,
            {
                data: chainDialogModel,
                maxWidth: '100%',
                panelClass: 'details-dialog'
            }
        );

        chainDialogModel.notification.subscribe((result: DialogResultEnum) => {
            if (result === DialogResultEnum.EXIT) {
                chainDialogModel = _.cloneDeep(backup);
                return;
            }
            backup = _.cloneDeep(chainDialogModel);
            this.chainDetailsScreenService.putSubjectDetails('api/chain', chainDialogModel.chainModel).subscribe(
                () => {
                    this.chainsService.getUpdatedData();
                }
            );
            if (result === DialogResultEnum.SAVE_AND_EXIT) {
                dialogRef.close();
            }
        });
    }

    private openSnackBar(message: SnackbarMessage) {
        this._snackbar.openFromComponent(SnackbarMessagingComponent, {
            data: message,
            duration: message.durationInSecond * 1000,
            panelClass: ['background-container-color']
        });
    }

    private openEventEditDialog(eventModel: EventModel): void {
        // Prevents more than one dialogs to be opened at once.
        if (!this.calendarScreenService.editDialogOpened) {
            this.calendarScreenService.editDialogOpened = true;
        } else {
            return;
        }

        const dialogRef = this._dialog.open(EventDialogComponent, {
            width: DialogUtils.calendarEventWidth,
            data: eventModel
        });

        const subscription = dialogRef.afterClosed().subscribe(
            () => {
                eventModel.refreshCalendar && this.calendarScreenService.refreshCalendar.next(true);
            },
            () => {
                this.calendarScreenService.editDialogOpened = false;
                if (eventModel.refreshEvents) {
                    this.eventsService.refreshData.next(true);
                }
                subscription.unsubscribe();
            },
            () => {
                this.calendarScreenService.editDialogOpened = false;
                if (eventModel.refreshEvents) {
                    this.eventsService.refreshData.next(true);
                }
            }
        );
    }

    private openAddCalendarEventDialog(eventModel: EventModel): void {
        // Prevents more than one dialogs to be opened at once.
        if (!this.calendarScreenService.addDialogOpened) {
            this.calendarScreenService.addDialogOpened = true;
        } else {
            return;
        }


        const dialogRef = this._dialog.open(EventDialogComponent, {
            width: DialogUtils.calendarEventWidth,
            data: eventModel
        });

        dialogRef.afterClosed().subscribe(
            (event: EventModel) => {
                if (event) {
                    this.calendarScreenService.addEvent(event).subscribe(
                        () => eventModel.refreshCalendar && this.calendarScreenService.refreshCalendar.next(true)
                    );
                }
                if (eventModel.refreshEvents) {
                    this.eventsService.refreshData.next(true);
                }
            },
            () => this.calendarScreenService.addDialogOpened = false,
            () => this.calendarScreenService.addDialogOpened = false
        )
    }

    private openProcuratorDetailDialog(procuratorDetailsModel: ProcuratorDialogModel): void {
        let backup = _.cloneDeep(procuratorDetailsModel);

        procuratorDetailsModel.notification = new Subject<DialogResultEnum>();

        const dialogRef = this._dialog.open(ProcuratorDetailsDialogComponent,
            {
                data: procuratorDetailsModel,
                maxWidth: '1400px',
                panelClass: 'details-dialog',
            }
        );

        procuratorDetailsModel.notification.subscribe((result: DialogResultEnum) => {

            if (result == DialogResultEnum.EXIT) {
                procuratorDetailsModel = _.cloneDeep(backup);
                dialogRef.close();
                return;
            }

            this.procuratorsService.updateProcurator(procuratorDetailsModel.detailsModel).subscribe(() => {
                if (result === DialogResultEnum.SAVE_AND_EXIT) {
                    dialogRef.close();
                }
            });

        });
    }

    private openPartnersDetailDialog(partnerDetailsDialogModel: PartnerDetailsDialogModel): void {
        let backup : PartnerDetailsDialogModel = _.cloneDeep(partnerDetailsDialogModel);

        partnerDetailsDialogModel.notification = new Subject<DialogResultEnum>();

        const dialogRef = this._dialog.open(PartnerDetailsDialogComponent,
            {
                data: partnerDetailsDialogModel,
                maxWidth: '100%',
                panelClass: 'details-dialog',
            }
        );

        partnerDetailsDialogModel.notification.subscribe((result: DialogResultEnum) => {

                // this.messageService.setSnackbar({
                //     severity: MessageSeverityEnum.success,
                //     durationInSecond: 5,
                //     text: 'Testowy snackbar przekierowujący na benefity',
                //     callbackFn: () => {
                //         this.partnersService.showPartnerDetails(partnerDetailsDialogModel.partnerDetailsModel.id, true, 5);
                //     }
                // });
                if (result === DialogResultEnum.EXIT) {
                    partnerDetailsDialogModel = _.cloneDeep(backup);
                    return;
                }

                backup = _.cloneDeep(partnerDetailsDialogModel);

                const brandsAdded = !!partnerDetailsDialogModel?.addedBrandCooperation?.length;
                const brandsEdited = !!partnerDetailsDialogModel?.editedBrandCooperation?.length;
                const updateBrands = () => this.brandService.updateBrands(partnerDetailsDialogModel.editedBrandCooperation);

                let procuratorIds = partnerDetailsDialogModel.partnerProcuratorsDetails.filter(p => p.id).map(p => p.id);

                let procuratorUpdateObservable = of({});
                if (!_.isEqual(partnerDetailsDialogModel.loadedProcurators, new Set<number>(procuratorIds))) {
                    procuratorUpdateObservable = this.procuratorsService.updatePartnerProcurators(partnerDetailsDialogModel.partnerDetailsModel.id, procuratorIds)
                }

                const updateDetails = () => procuratorUpdateObservable.pipe(
                    tap(() => partnerDetailsDialogModel.partnerSaved = true),
                    switchMap(() => this.partnerDetailsScreenService.putSubjectDetails('api/partner', partnerDetailsDialogModel.partnerDetailsModel)),
                    tap((updatedPartnerDetails) => partnerDetailsDialogModel.sozLoginSaved = updatedPartnerDetails.sozLogin != null)
                );

                const getUpdatedData = () => {
                    this.partnersService.getUpdatedData();
                };

                if (brandsAdded && brandsEdited) {
                    this.brandService.addBrands(partnerDetailsDialogModel.addedBrandCooperation).pipe(
                        tap((addedBrands: Array<BrandCooperationModel>) => {
                            partnerDetailsDialogModel.brandCooperationModel = [
                                ...partnerDetailsDialogModel.brandCooperationModel,
                                ...addedBrands
                            ];
                        }),
                        switchMap(updateBrands),
                        tap((response: Array<BrandCooperationModel>) => {
                            this.replaceInList(partnerDetailsDialogModel.brandCooperationModel, response);
                        }),
                        switchMap(updateDetails)
                    ).subscribe(() => {
                        getUpdatedData();
                    });
                    partnerDetailsDialogModel.editedBrandCooperation = [];
                    partnerDetailsDialogModel.addedBrandCooperation = [];
                } else if (brandsEdited) {
                    updateBrands().pipe(
                        tap((response: Array<BrandCooperationModel>) => {
                            console.log("Response: " + response);
                            this.replaceInList(partnerDetailsDialogModel.brandCooperationModel, response);
                        }),
                        switchMap(updateDetails)
                    ).subscribe(getUpdatedData);
                    partnerDetailsDialogModel.editedBrandCooperation = [];
                } else if (brandsAdded) {
                    this.brandService.addBrands(partnerDetailsDialogModel.addedBrandCooperation).pipe(
                        tap((addedBrands: Array<BrandCooperationModel>) => {
                            partnerDetailsDialogModel.brandCooperationModel = [
                                ...partnerDetailsDialogModel.brandCooperationModel,
                                ...addedBrands
                            ];
                        }),
                        switchMap(updateDetails)
                    ).subscribe(getUpdatedData);
                    partnerDetailsDialogModel.addedBrandCooperation = [];
                } else {
                    procuratorUpdateObservable.pipe(
                        tap(() => partnerDetailsDialogModel.partnerSaved = true),
                        switchMap(() => this.partnerDetailsScreenService.putSubjectDetails('api/partner', partnerDetailsDialogModel.partnerDetailsModel)),
                        tap((updatedPartnerDetails) => partnerDetailsDialogModel.sozLoginSaved = updatedPartnerDetails.sozLogin != null)
                    ).subscribe(getUpdatedData);
                }
                this.dictService.clearCachedExternals();

                if (result === DialogResultEnum.SAVE_AND_EXIT) {
                    dialogRef.close();
                }
            }
        );
    }

    private replaceInList(cooperations: BrandCooperationModel[], newCooperations: BrandCooperationModel[]) {

        for (let i = 0; i < cooperations.length; i++) {
            let brandCooperationModel = cooperations[i];
            if (brandCooperationModel.id) {
                let brandCooperationModel1 = newCooperations.find(nc => nc.id == brandCooperationModel.id);
                if (brandCooperationModel1 != null) {
                    cooperations[i] = brandCooperationModel1;
                }
            }
        }

    }

    public openEntrepreneursDialog(entrepreneurDetailsDialogModel: EntrepreneurDetailsDialogModel) {
        let backup : EntrepreneurDetailsDialogModel = _.cloneDeep(entrepreneurDetailsDialogModel);

        entrepreneurDetailsDialogModel.notification = new Subject<DialogResultEnum>();

        const dialogRef = this._dialog.open(EntrepreneurDetailsDialogComponent,
            {
                data: entrepreneurDetailsDialogModel,
                maxWidth: '1400px',
                panelClass: 'details-dialog',
            }
        );

        entrepreneurDetailsDialogModel.notification.subscribe((result: DialogResultEnum) => {
            if (result === DialogResultEnum.EXIT) {
                entrepreneurDetailsDialogModel = _.cloneDeep(backup);
                return;
            }

            if (entrepreneurDetailsDialogModel.entrepreneurDetailsModel.id) {
                const observable = this.entrepreneursService.entrepreneurBulkUpdate(entrepreneurDetailsDialogModel.entrepreneurDetailsModel.id,
                    new EntrepreneurBulkUpdateModel(entrepreneurDetailsDialogModel.entrepreneurDetailsModel, entrepreneurDetailsDialogModel.entrepreneurBrands, entrepreneurDetailsDialogModel.entrepreneurRepresentative, entrepreneurDetailsDialogModel.wspolnicy)
                );
                observable.subscribe(bulk => {
                    entrepreneurDetailsDialogModel.entrepreneurDetailsModel = bulk.details;
                    entrepreneurDetailsDialogModel.entrepreneurBrands = bulk.brands;
                    entrepreneurDetailsDialogModel.entrepreneurRepresentative = bulk.representatives;
                    entrepreneurDetailsDialogModel.wspolnicy = bulk.partners;
                    entrepreneurDetailsDialogModel.entrepreneurSaved = true;
                    entrepreneurDetailsDialogModel.loyaltyPointsChanged = false;
                    this.entrepreneursService.getUpdatedData()
                })
            } else {
                const observable = this.entrepreneursService.createEntrepreneur(entrepreneurDetailsDialogModel.entrepreneurDetailsModel);
                observable.subscribe(details => {
                    entrepreneurDetailsDialogModel.entrepreneurDetailsModel = details;
                    this.entrepreneursService.getUpdatedData()
                })
            }

            if (result === DialogResultEnum.SAVE_AND_EXIT) {
                dialogRef.close();
            }

            // const entrepreneurDetailsModelObservable = this.entrepreneursService.updateEntrepreneurDetails(entrepreneurDetailsDialogModel.entrepreneurDetailsModel);
            //
            // entrepreneurDetailsModelObservable.pipe(
            //     tap(newModel => entrepreneurDetailsDialogModel.entrepreneurDetailsModel = newModel),
            //     switchMap(newModel => this.entrepreneursService.updateEntrepreneurRepresentative(newModel.id, entrepreneurDetailsDialogModel.entrepreneurRepresentative)),
            //     tap(newModel => entrepreneurDetailsDialogModel.entrepreneurRepresentative = newModel),
            //     switchMap(newModel => this.entrepreneursService.updateEntrepreneurWspolnicy(entrepreneurDetailsDialogModel.entrepreneurDetailsModel.id, entrepreneurDetailsDialogModel.wspolnicy)),
            //     tap(newModel => entrepreneurDetailsDialogModel.wspolnicy = newModel),
            //     switchMap(newModel => this.entrepreneursService.updateEntrepreneurBrands(entrepreneurDetailsDialogModel.entrepreneurDetailsModel.id, entrepreneurDetailsDialogModel.entrepreneurBrands)),
            //     tap(newModel => entrepreneurDetailsDialogModel.entrepreneurBrands = newModel),
            // ).subscribe()

        })
    }
}
