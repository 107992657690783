import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PartnerWithLocalizationModel } from '../_models/partner-with-localization.model';
import { BrandModel } from '../_models/brand.model';
import { BrandCooperationModel } from '../_models/brand-cooperation.model';
import { DictModel } from '../_models/dict.model';
import { CooperationStatusMapEnum } from './map.service';
import { PartnersService } from '../partners/partners.service';

@Component({
    selector: 'app-info-window',
    templateUrl: './custom-info-window.component.html',
    styleUrls: ['./map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomInfoWindowComponent {

    public partnerLoc: PartnerWithLocalizationModel;
    public brandsDictionary: Array<BrandModel>;
    public partnerBrandCooperationsMap: Map<number, Map<number, BrandCooperationModel>>;
    public partnerIdForDetails;

    public statusesDictionary: Array<DictModel>;

    constructor(private readonly partnersService: PartnersService) {
    }

    public enterCorrelationMode = (partner: PartnerWithLocalizationModel, brand: BrandModel) => {
    };

    public setAsPartOfRoute = (partnerLoc: PartnerWithLocalizationModel) => {
    };

    public openPartnerDetails(partnerId: number) {
        this.partnerIdForDetails = partnerId;
        this.partnersService.showPartnerDetails(partnerId, true);
    }

    public getStatusValueForBrand(brandId: number, partnerId: number) {
        const dictModel = this.getStatusForBrand(brandId, partnerId);
        if (!dictModel) {
            return 'Brak';
        }
        return this.getStatusForBrand(brandId, partnerId).value;
    }

    public getStatusForBrand(brandId: number, partnerId: number): DictModel {
        const cooperationsForBrand = this.partnerBrandCooperationsMap.get(brandId);
        if (!cooperationsForBrand.has(partnerId)) {
            return null;
        }
        const brandCooperationForPartner = cooperationsForBrand.get(partnerId);
        return this.statusesDictionary.find((dictModel: DictModel) => dictModel.id === brandCooperationForPartner.cooperationStatusId);

    }

    public isCorrelationOptionsVisible(partnerWithLocalization: PartnerWithLocalizationModel, brand: BrandModel): boolean {
        const status = this.getStatusForBrand(brand.id, partnerWithLocalization.partner.id);
        return status && status.id === CooperationStatusMapEnum.LISTA_REZERWOWA;
    }
}
