import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import * as moment from 'moment';
import { DictModel } from '../_models/dict.model';
import { SearchCriteria } from '../_models/search-criteria.model';
import { TaskModel } from '../_models/task.model';
import { UserConfigModel } from '../_models/user-config.model';
import { AuthenticationService } from '../_services/authentication.service';
import { DictionariesService } from '../_services/dictionaries.service';
import { TaskService } from '../_services/task.service';
import { UserConfigService } from '../_services/user-config.service';
import { ChipFiltersUtils } from '../_utils/chip-filters.utils';
import { DynamicTableService } from '../dynamic-table/dynamic-table.service';
import { ChipElement } from '../filter-chips/chip-component/chip.component';
import { PartnersService } from '../partners/partners.service';
import { TaskDialogComponent } from './task-dialog/task-dialog.component';

class Task {
    public isEditing = false;

    constructor(public task: TaskModel) {
    }
}

@Component({
    selector: 'app-tasks',
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
    public readonly cols = [
        'select', 'title', 'dueDate', 'status', 'priority', 'assignee', 'reporter', 'creationDate', 'updateDate', 'partners', 'options'];

    public readonly taskReqParams: SearchCriteria = new SearchCriteria(0, 10, 'desc-dueDate');
    public totalElements: number = 0;
    public readonly tableWidth: string = '100vw';
    public statusesDict: Array<DictModel>;
    public prioritiesDict: Array<DictModel>;

    public tasks: Array<Task> = [];

    public editedTasks: Map<number, TaskModel> = new Map();

    public selection = new SelectionModel<Task>(true, []);

    public chipPresentFilters: ChipElement[] = [];
    chipTaskPossibleFilters = ChipFiltersUtils.TASK_POSSIBLE_FILTERS;
    public compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;

    constructor(private readonly taskService: TaskService,
                public readonly authService: AuthenticationService,
                public readonly dynamicTableService: DynamicTableService,
                private readonly partnersService: PartnersService,
                private readonly dictService: DictionariesService,
                private readonly userConfigService: UserConfigService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.dictService.getTaskStatuses().subscribe(statusesDict => this.statusesDict = statusesDict);
        this.dictService.getTaskPriorities().subscribe(prioritiesDict => this.prioritiesDict = prioritiesDict);

        this.userConfigService.getUserConfig().subscribe(
            (userConfig: UserConfigModel) => {
                if (userConfig && userConfig.tasksConfig && userConfig.tasksConfig.filtersWithValues) {
                    this.chipPresentFilters = userConfig.tasksConfig.filtersWithValues || [];
                }
                this.getTasks();
            },
            (error) => {
                console.log('Error on getting user config on finances.', error);
                this.getTasks();
            }
        );
    }

    public sortTasks(event: Sort): void {
        if (!!event.direction && ['asc', 'desc'].includes(event.direction)) {
            this.taskReqParams.sort = event.direction + '-' + event.active;
            this.getTasks();
        }
    }

    public openDialog(task?: TaskModel): void {
        const config = task ? {
            maxWidth: '100%',
            panelClass: 'add-partner-dialog',
            data: task
        } : {
            maxWidth: '100%',
            panelClass: 'add-partner-dialog',
        };
        const dialogRef = this.dialog.open(TaskDialogComponent,
            config
        );

        dialogRef.afterClosed().subscribe((result: boolean) => {
                if (!result) {
                    return;
                }
            this.getTasks();
            }
        );
    }

    public getDate(date: Date) {
        return date ? moment(date).format('YYYY-MM-DD') : '';
    }

    public pageChanged(event: PageEvent) {
        this.taskReqParams.page = event.pageIndex;
        this.taskReqParams.size = event.pageSize;
        this.getTasks();
    }

    public showPartnerDetails(partner: DictModel) {
        this.partnersService.showPartnerDetails(partner.id);
    }

    public confirmEditedData(task: Task) {
        if (!this.editedTasks.has(task.task.id)) {
            throw new Error('Not editing this row currently!');
        }
        this.taskService.updateTask(this.editedTasks.get(task.task.id)).subscribe(() => {
            task.task = this.editedTasks.get(task.task.id);
            task.isEditing = false;
        });
    }

    public startEditing(task: Task) {
        task.isEditing = true;
        this.editedTasks.set(task.task.id, new TaskModel(task.task.id,
            task.task.status,
            task.task.priority,
            task.task.title,
            task.task.description,
            task.task.creationDate,
            task.task.updateDate,
            task.task.dueDate,
            task.task.assignee,
            task.task.reporter,
            task.task.partners));
    }

    public isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.tasks.length;
        return numSelected == numRows;
    }

    public masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.tasks
                .filter(row => this.authService.canEditTask(row.task))
                .forEach(row => this.selection.select(row));
    }

    public removeSelected() {
        this.taskService.removeTasks(this.selection.selected.map(task => task.task.id)).subscribe(() => {
            this.selection.clear();
            this.selection.isEmpty();
            this.getTasks();
        });
    }

    public chipUpdateList(chipList: ChipElement[]): void {
        this.chipPresentFilters = chipList;
    }

    public chipAddToList(chipElement: ChipElement): void {
        this.chipPresentFilters.push(chipElement);
    }

    public chipUpdateListAndSendRequest(chipList: ChipElement[]): void {
        this.chipUpdateList(chipList);
        this.userConfigService.updateTasksFiltersWithValues(chipList).subscribe();
        this.getTasks();
    }

    private getTasks() {

        let params = ChipFiltersUtils.prepareParams(this.chipPresentFilters);
        this.taskService.getTasks(this.taskReqParams, params)
            .subscribe(value => {
                this.tasks = value.content.map(taskModel => new Task(taskModel));
                this.totalElements = value.totalElements;
                this.editedTasks = new Map();
            });
    }

    private compareByValue(f1: DictModel, f2: DictModel) {
        return f1 && f2 && f1.id === f2.id;
    }
}
