import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { EventModel } from '../_models/event-model';
import { TableEventModel } from '../_models/table-event.model';
import {
    REDIRECTION_PARTNER_CHAIN,
    REDIRECTION_PARTNER_MAP,
    RedirectionService
} from '../_services/redirection.service';
import { CalendarScreenService } from '../calendar/calendar-screen.service';
import { ChainsService } from '../chains/chains.service';
import { PartnersService } from '../partners/partners.service';
import {DynamicTableColumn, DynamicTableService} from './dynamic-table.service';
import {ColumnElementModel} from '../_models/column-element.model';
import {ProductsService} from '../products/products.service';
import {ProcuratorsService} from '../procurators/procurators.service';
import {EntrepreneursService} from '../entrepreneurs/entrepreneurs.service';

@Component({
    selector: 'app-dynamic-table',
    templateUrl: './dynamic-table.component.html',
    styleUrls: ['./dynamic-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class DynamicTableComponent implements OnInit, AfterViewInit {

    public readonly AMOUNT_OF_COLUMNS_TO_SKIP = 2; //ID and MONTHS_OF_INACTIVITY
    @Input() amountOfColumnsToDisplay: number;
    @Input() tabletCols: number;
    @Input() mobileCols: number;
    @Input() pages: number;
    @Input() showFooter: boolean;
    @Input() recordsNumber: number;
    @Input() headers: Array<DynamicTableColumn>;
    @Input() summarizeColumns: Array<string> = [];
    @Input() averageColumns: Array<string> = [];
    @Input() public type: string;
    @Input() public lowerBound: number = 4;
    @Input() public upperBound: number = 6;
    @Output() tableEvent = new EventEmitter<TableEventModel>();
    @Output() tableSortEvent = new EventEmitter<string>();
    public startPageAmount: number;
    public endPageAmount: number;
    @Input()
    public isLoadingResults: boolean;
    tableWidth: string = '95vw';
    private readonly MONTHS_OF_INACTIVITY_INDEX = 1;
    private _currentPage: number;
    private elementId;

    constructor(public readonly dynamicTableService: DynamicTableService,
                private readonly partnersService: PartnersService,
                private readonly chainsService: ChainsService,
                private readonly entrepreneursService: EntrepreneursService,
                private readonly productService: ProductsService,
                private readonly procuratorsService: ProcuratorsService,
                private readonly redirectionService: RedirectionService,
                private readonly calendarScreenService: CalendarScreenService) {
    }

    private _rows: Array<Array<string>>;
    public _summarized: Array<string> = [];

    get rows(): Array<Array<string>> {
        return this._rows;
    }

    get summarized(): Array<string> {
        return this._summarized;
    }

    @Input()
    set rows(rows: Array<Array<string>>) {
        this._rows = rows;
        this.isLoadingResults = false;
    }

    @Input()
    set summarized(summarized: Array<string>) {
        this._summarized = summarized;
        console.log("Summarized length: " + summarized.length)
    }

    private _page: number;

    get page(): number {
        return this._page;
    }

    @Input()
    set page(page: number) {
        const pageNumeric = +page;
        this._page = pageNumeric;
        this._currentPage = pageNumeric;
        this.setStartAndEndPages();
    }

    private _rowsPerPage: number;

    get rowsPerPage(): number {
        return this._rowsPerPage;
    }

    @Input()
    set rowsPerPage(rowsPerPage: number) {
        this._rowsPerPage = rowsPerPage;
        this.setStartAndEndPages();
    }

    getHighlightClass(element: any, index: number) {
        if (this.type == 'product') {
            return element[this.MONTHS_OF_INACTIVITY_INDEX];
        }

        if (!this.upperBound || !this.lowerBound || index > 0 || this.type !== 'partner') {
            return;
        }
        //TODO: constant At the moment should come with partnerDetails
        const monthsOfInactivity = element && element[this.MONTHS_OF_INACTIVITY_INDEX]; //element.monthsOfInactivity;
        if (monthsOfInactivity > this.upperBound) {
            return 'red-border';
        } else if (monthsOfInactivity > this.lowerBound) {
            return 'orange-border';
        } else {
            return 'green-border';
        }

    }

    rowClick(elementId) {
        this.elementId = elementId;
        if (this.type === 'partner') {
            this.partnersService.showPartnerDetails(elementId, true);
        }
        if (this.type === 'chain') {
            this.chainsService.showChainDetails(elementId, true);
        }

        if (this.type === 'product') {
            this.productService.showProductDetails(elementId);
        }

        if (this.type === 'procurator') {
            this.procuratorsService.showProcuratorDetails(elementId);
        }

        if (this.type === 'entrepreneurs') {
            this.entrepreneursService.showEntrepreneurDetails(elementId);
        }

        if (this.type === 'events') {
            this.calendarScreenService.getEvent(elementId + '').subscribe((value: EventModel) => {
                value.refreshEvents = true;
                this.calendarScreenService.setShowEditDialog$(value);
            });
        }
    }

    ngOnInit() {
        this.tableWidth = (this.amountOfColumnsToDisplay * 200) + 'px';
        this.isLoadingResults = true;
        this.dynamicTableService.columnChanged.subscribe(
            (columns: number) => this.tableWidth = (columns * 200) + 'px'
        );

    }

    ngAfterViewInit() {
        this.redirectionService.getRoutingState().subscribe(value => {
            if (value.hasOwnProperty('redirectionType') && (value.redirectionType === REDIRECTION_PARTNER_MAP || value.redirectionType === REDIRECTION_PARTNER_CHAIN)) {
                this.rowClick(value.value.id);
            }
        });
    }

    public visibleColumnsForHeader(): Array<DynamicTableColumn> {
        if (!this.headers) {
            return;
        }
        const array = this.headers.slice(0, this.amountOfColumnsToDisplay);
        array.push(new DynamicTableColumn('star', false));
        return array;
    }

    public visibleColumns(): Array<string> {
        if (!this.headers) {
            return;
        }
        const array = this.headers.slice(0, this.amountOfColumnsToDisplay).map(dtc => dtc.name);
        array.push('star');
        return array;
    }


    public pageChange($event: PageEvent): void {
        const tableEventModel: TableEventModel = new TableEventModel($event.pageIndex + 1, $event.pageSize);
        this.emitTableEvent(tableEventModel);
    }

    public sortData(sort: Sort) {
        let sortPrefix = null;
        if (sort.direction) {
            sortPrefix = sort.direction;
        }
        let preparedString = sortPrefix ? `${sortPrefix}-${sort.active}` : null;
        this.isLoadingResults = true;
        this.tableSortEvent.emit(preparedString);
    }

    removeHtmlTags(string: any) {
        if (!string || typeof string !== 'string' || this.type !== 'events') {
            return string;
        }
        let regex = /(&nbsp;|<([^>]+)>)/ig;
        return string.replace(regex, "");
    }

    private setStartAndEndPages() {
        if (this._page === this.pages) {
            this.endPageAmount = this.recordsNumber;
        } else {
            this.endPageAmount = this._page * this._rowsPerPage;
        }
        this.startPageAmount = (this._page * this._rowsPerPage) - (this._rowsPerPage - 1);
    }

    private emitTableEvent(tableEventModel: TableEventModel): void {
        this.isLoadingResults = true;
        this.tableEvent.emit(tableEventModel);
    }

    summarize(columnIndex: number) : string {
        if (columnIndex == 0) {
            return "Podsumowanie";
        }
        const dynamicTableColumn = this.headers[columnIndex];
        if (dynamicTableColumn?.name != null) {
            if (this.summarizeColumns.includes(dynamicTableColumn.name)) {
                return this.sumColumnProducts(columnIndex);
            }
            if (this.averageColumns.includes(dynamicTableColumn.name)) {
                return this.averageColumnProducts(columnIndex, 2);
            }
        }
        return "-";
    }

    sumColumnProducts(columnIndex: number, maximumFractionDigits: number = 0) : string {
        const length = this._rows.length;
        if (length == 0) {
            return "0";
        }

        const number : number = this._rows.map(row => {
            return Number(row[columnIndex + this.AMOUNT_OF_COLUMNS_TO_SKIP].replace("%", "").replace(/\s/g, "").replace(",", "."));
        }).reduce((sum, current) => sum + current);
        return number.toLocaleString("ru-RU", {minimumFractionDigits: maximumFractionDigits, maximumFractionDigits: maximumFractionDigits, useGrouping: true});
    }

    averageColumnProducts(columnIndex: number, maximumFractionDigits: number = 0) : string {
        const length = this._rows.length;
        if (length == 0) {
            return "0";
        }

        let number : number = this._rows.map(row => {
            return Number(row[columnIndex + this.AMOUNT_OF_COLUMNS_TO_SKIP].replace("%", "").replace(/\s/g, "").replace(",", "."));
        }).reduce((sum, current) => sum + current);

        number = number / length;

        return number.toLocaleString("ru-RU", {minimumFractionDigits: maximumFractionDigits, maximumFractionDigits: maximumFractionDigits, useGrouping: true}) + "%";
    }

    has() {
        return this._summarized.length > 0;
    }
}
