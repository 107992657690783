import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChipElement } from '../../../filter-chips/chip-component/chip.component';
import { ChipFiltersUtils } from '../../../_utils/chip-filters.utils';
import { EventsService } from '../../../events/events.service';
import { ScreenTypeEnum } from '../../../_enums/screen-type.enum';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-events-tab',
    templateUrl: './events-tab.component.html'
})
export class EventsTabComponent implements OnInit, OnDestroy {

    @Input()
    public partnerId?: string;

    public screenType = ScreenTypeEnum.events_partner;
    chipPresentFilters: Array<ChipElement> = null;
    chipEventsPossibleFilters: ChipElement[] = ChipFiltersUtils.PARTNER_EVENTS_POSSIBLE_FILTERS;

    private readonly chipSubject: Subject<ChipElement[]> = this.eventsService.chipSubjectMap.get(this.screenType);
    private $chipSubscription: Subscription;

    constructor(private readonly eventsService: EventsService) {
    }

    ngOnInit(): void {
        this.$chipSubscription = this.chipSubject.subscribe(elem => {
            this.chipPresentFilters = elem || [];
        });
    }

    ngOnDestroy(): void {
        if (this.$chipSubscription) {
            this.$chipSubscription.unsubscribe();
        }
    }

    public chipAddToList(chipElement: ChipElement): void {
        this.chipPresentFilters.push(chipElement);
    }
}