import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MessageSeverityEnum } from '../../_enums/message-severity.enum';
import { MessagingUtils } from '../messaging.utils';


export class SnackbarMessage {
    text: string;
    severity: MessageSeverityEnum;
    durationInSecond: number;
    callbackFn?: () => void;
}

@Component({
    selector: 'app-snackbar-messaging',
    templateUrl: 'snackbar-messaging.component.html',
    styleUrls: ['snackbar-messaging.component.scss'],
})
export class SnackbarMessagingComponent {

    /**
     * Sample method that trigger showing snackbar with custom text, and severity and time on the screen
     *
     * openSnackbar(){
     *     this.messageService.setSnackbar({
     *          text: 'Some text',
     *          durationInSecond: 5,
     *          severity: MessageSeverityEnum.success
     *     })
     * }
     *
     * messageService is an field of type :MessageService
     *
     * */

    constructor(@Inject(MAT_SNACK_BAR_DATA) public message: SnackbarMessage) {
    }

    getSeverityClass(): string {
        return MessagingUtils.getMessageSeverityClass(this.message.severity);
    }

    getLabel(): string {
        return MessagingUtils.getMessageSeverityLabel(this.message.severity);
    }

    fireCallbackOnClick() {
        this.message.callbackFn && this.message.callbackFn();
    }

}
