import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { DictionariesEnum } from '../_enums/dictionaries-enum';
import { DictModel } from '../_models/dict.model';
import { ChipElement, ChipRange, ChipType, ChipValue, Pair } from '../filter-chips/chip-component/chip.component';
import {EntrepreneursComponent} from '../entrepreneurs/entrepreneurs.component';

export class ChipFiltersUtils {

    public static readonly FINANCES_POSSIBLE_FILTERS: ChipElement[] = [
        {
            chipDescription: {
                name: 'name',
                display: 'Nazwa'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.PARTNER_NAMES
        },
        {
            chipDescription: {
                name: 'owner',
                display: 'Właściciel'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_OPERATIONS
        },
        {
            chipDescription: {
                name: 'onboarder',
                display: 'Wprowadzający'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_OPERATIONS
        },
        {
            chipDescription: {
                name: 'teams',
                display: 'Zespół'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.TEAMS
        },
        {
            chipDescription: {
                name: 'toa',
                display: 'TOA'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_TOA
        },
        {
            chipDescription: {
                name: 'brandIds',
                display: 'Marka'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.BRANDS,
        },
        {
            chipDescription: {
                name: 'brandCooperations',
                display: 'Status współpracy'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.COOPERATION_STATUS
        },
        {
            chipDescription: {
                name: 'brandCooperationTypes',
                display: 'Typ współpracy'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.COOPERATION_TYPE
        },
        {
            chipDescription: {
                name: 'partnerType',
                display: 'Typ partnera'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PARTNER_TYPES
        },
        {
            chipDescription: {
                name: 'district',
                display: 'Województwo'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.DISTRICTS
        },
        {
            chipDescription: {
                name: 'town',
                display: 'Miasto'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.TOWNS
        },
        {
            chipDescription: {
                name: 'grades',
                display: 'Ocena'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.GRADES
        },
        {
            chipDescription: {
                name: 'pharmacyType',
                display: 'Typ jednostki'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PHARMACY_TYPES
        },
        {
            chipDescription: {
                name: 'infoSource',
                display: 'Źródło danych'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.INFO_SOURCE
        },
        {
            chipDescription: {
                name: 'street',
                display: 'Ulica'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.STREETS
        },
        {
            chipDescription: {
                name: 'webPage',
                display: 'Strona internetowa'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'isOnlineSeller',
                display: 'Sprzedaż internetowa'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        },
        {
            chipDescription: {
                name: 'zatowarowanie',
                display: 'Zatowarowanie'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        },
        {
            chipDescription: {
                name: 'employeesNo',
                display: 'Ilość pracowników'
            },
            chipType: ChipType.PAIR,
        },
        {
            chipDescription: {
                name: 'averagePatientsNo',
                display: 'Ilość pacjentów'
            },
            chipType: ChipType.PAIR,
        },
        {
            chipDescription: {
                name: 'licenseNo',
                display: 'Numer licencji'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'financialId',
                display: 'Id kontrahenta'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'chain',
                display: 'Sieć'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.CHAINS
        },
        {
            chipDescription: {
                name: 'product',
                display: 'Nazwa produktu'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PRODUCTS
        },
        {
            chipDescription: {
                name: 'onboardingDate',
                display: 'Data ostatniego wdrożenia'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'creationDate',
                display: 'Data powstania'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'updateDate',
                display: 'Data modyfikacji'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'dateOfOnlineSalesStart',
                display: 'Data startu sprzedaży online'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'isAuthorizedPoint',
                display: 'Punkt autoryzowany'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        },
        {
            chipDescription: {
                name: 'dateOfLicenseObtaining',
                display: 'Data nadania licencji'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'hasMarketingMaterials',
                display: 'Segregator'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        },
        {
            chipDescription: {
                name: 'hasBranding',
                display: 'Wizualizacja marki'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        },
        {
            chipDescription: {
                name: 'hasAmbassador',
                display: 'Ambasador'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        },
        {
            chipDescription: {
                name: 'email',
                display: 'Email'
            },
            chipType: ChipType.TEXT
        }
    ];

    public static readonly PARTNER_POSSIBLE_FILTERS: ChipElement[] = [
        {
            chipDescription: {
                name: 'name',
                display: 'Nazwa partnera'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.PARTNER_NAMES
        },
        {
            chipDescription: {
                name: 'owner',
                display: 'Właściciel'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_OPERATIONS
        },
        {
            chipDescription: {
                name: 'onboarder',
                display: 'Wprowadzający'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_OPERATIONS
        },
        {
            chipDescription: {
                name: 'teams',
                display: 'Zespół'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.TEAMS
        },
        {
            chipDescription: {
                name: 'toa',
                display: 'TWS'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_TOA
        },
        {
            chipDescription: {
                name: 'brandIds',
                display: 'Marka'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.BRANDS,
        },
        {
            chipDescription: {
                name: 'brandCooperations',
                display: 'Status współpracy'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.COOPERATION_STATUS
        },
        {
            chipDescription: {
                name: 'brandCooperationTypes',
                display: 'Typ współpracy'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.COOPERATION_TYPE
        },
        {
            chipDescription: {
                name: 'partnerType',
                display: 'Typ partnera'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PARTNER_TYPES
        },
        {
            chipDescription: {
                name: 'district',
                display: 'Województwo'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.DISTRICTS
        },
        {
            chipDescription: {
                name: 'town',
                display: 'Miasto'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.TOWNS
        },
        {
            chipDescription: {
                name: 'grades',
                display: 'Ocena'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.GRADES
        },
        {
            chipDescription: {
                name: 'pharmacyType',
                display: 'Typ jednostki'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PHARMACY_TYPES
        },
        {
            chipDescription: {
                name: 'infoSource',
                display: 'Źródło danych'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.INFO_SOURCE
        },
        {
            chipDescription: {
                name: 'street',
                display: 'Ulica'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.STREETS
        },
        {
            chipDescription: {
                name: 'webPage',
                display: 'Strona internetowa'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'isOnlineSeller',
                display: 'Sprzedaż internetowa'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        },
        {
            chipDescription: {
                name: 'isAuthorizedPoint',
                display: 'Punkt autoryzowany'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        },
        {
            chipDescription: {
                name: 'employeesNo',
                display: 'Ilość pracowników'
            },
            chipType: ChipType.PAIR,
        },
        {
            chipDescription: {
                name: 'averagePatientsNo',
                display: 'Ilość pacjentów'
            },
            chipType: ChipType.PAIR,
        },
        {
            chipDescription: {
                name: 'licenseNo',
                display: 'Numer licencji'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'financialId',
                display: 'Id kontrahenta'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'chain',
                display: 'Sieć'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.CHAINS
        },
        {
            chipDescription: {
                name: 'onboardingDate',
                display: 'Data ostatniego wdrożenia'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'firstOnboardingDate',
                display: 'Data pierwszego wdrożenia'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'creationDate',
                display: 'Data powstania'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'updateDate',
                display: 'Data modyfikacji'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'dateOfOnlineSalesStart',
                display: 'Data startu sprzedaży online'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'dateOfLicenseObtaining',
                display: 'Data nadania licencji'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'hasMarketingMaterials',
                display: 'Segregator'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        },
        {
            chipDescription: {
                name: 'hasBranding',
                display: 'Wizualizacja marki'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        },
        {
            chipDescription: {
                name: 'hasAmbassador',
                display: 'Ambasador'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        },
        {
            chipDescription: {
                name: 'email',
                display: 'Email'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'nip',
                display: 'NIP'
            },
            chipType: ChipType.TEXT
        }
    ];

    public static readonly CHAIN_POSSIBLE_FILTERS: ChipElement[] = [
        {
            chipDescription: {
                name: 'name',
                display: 'Nazwa'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.CHAINS
        },
        {
            chipDescription: {
                name: 'owner',
                display: 'Właściciel'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_OPERATIONS
        },
        {
            chipDescription: {
                name: 'toa',
                display: 'TOA'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_TOA
        },
        {
            chipDescription: {
                name: 'creationDate',
                display: 'Data powstania'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'updateDate',
                display: 'Data modyfikacji'
            },
            chipType: ChipType.RANGE,
        },
    ];

    public static readonly TASK_POSSIBLE_FILTERS: ChipElement[] = [
        {
            chipDescription: {
                name: 'assignee',
                display: 'Właściciel'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_OPERATIONS
        },
        {
            chipDescription: {
                name: 'dueDate',
                display: 'Termin'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'creationDate',
                display: 'Data utworzenia'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'teams',
                display: 'Zespół'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.TEAMS
        },
        {
            chipDescription: {
                name: 'partnerName',
                display: 'Nazwa partnera'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.PARTNER_NAMES
        },
        {
            chipDescription: {
                name: 'priority',
                display: 'Priorytet'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.TASK_PRIORITIES
        },
        {
            chipDescription: {
                name: 'status',
                display: 'Status'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.TASK_STATUSES
        }
    ];

    public static readonly CONTACTS_HISTORY_FILTERS: ChipElement[] = [
        {
            chipDescription: {
                name: 'contactOwnerName',
                display: 'Nazwa partnera/sieci'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'eventInitiator',
                display: 'Zmienione przez'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_TOA
        },
        {
            chipDescription: {
                name: 'eventType',
                display: 'Typ zdarzenia'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.CONTACT_HISTORY_EVENT_TYPE
        },
        {
            chipDescription: {
                name: 'creationDate',
                display: 'Data zmiany'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'description',
                display: 'Opis'
            },
            chipType: ChipType.TEXT
        }
    ];

    public static readonly EVENTS_POSSIBLE_FILTERS: ChipElement[] = [
        {
            chipDescription: {
                name: 'brandIds',
                display: 'Marka'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.BRANDS
        },
        {
            chipDescription: {
                name: 'hostIds',
                display: 'Właściciel wydarzenia'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_OPERATIONS
        },
        {
            chipDescription: {
                name: 'chainIds',
                display: 'Sieci'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.CHAINS
        },
        {
            chipDescription: {
                name: 'guestIds',
                display: 'Goście'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_OPERATIONS
        },
        {
            chipDescription: {
                name: 'typeIds',
                display: 'Typ'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.EVENTS
        },
        {
            chipDescription: {
                name: 'subTypeIds',
                display: 'Cel'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.EVENT_SUBTYPES
        },
        {
            chipDescription: {
                name: 'teamIds',
                display: 'Zespół'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.TEAMS
        },
        {
            chipDescription: {
                name: 'partnerIds',
                display: 'Nazwa partnera'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.PARTNER_NAMES
        },
        {
            chipDescription: {
                name: 'localization',
                display: 'Lokalizacja'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'title',
                display: 'Tytuł wydarzenia'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'start',
                display: 'Data rozpoczęcia'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'end',
                display: 'Data końca'
            },
            chipType: ChipType.RANGE,
        },
    ];
    public static readonly PARTNER_EVENTS_POSSIBLE_FILTERS: ChipElement[] = [
        {
            chipDescription: {
                name: 'brandIds',
                display: 'Marka'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.BRANDS
        },
        {
            chipDescription: {
                name: 'hostIds',
                display: 'Właściciel wydarzenia'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_OPERATIONS
        },
        {
            chipDescription: {
                name: 'chainIds',
                display: 'Sieci'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.CHAINS
        },
        {
            chipDescription: {
                name: 'guestIds',
                display: 'Goście'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_OPERATIONS
        },
        {
            chipDescription: {
                name: 'typeIds',
                display: 'Typ'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.EVENTS
        },
        {
            chipDescription: {
                name: 'subTypeIds',
                display: 'Cel'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.EVENT_SUBTYPES
        },
        {
            chipDescription: {
                name: 'teamIds',
                display: 'Zespół'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.TEAMS
        },
        {
            chipDescription: {
                name: 'localization',
                display: 'Lokalizacja'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'title',
                display: 'Tytuł wydarzenia'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'start',
                display: 'Data rozpoczęcia'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'end',
                display: 'Data końca'
            },
            chipType: ChipType.RANGE,
        },
    ];

    public static readonly ANALYTICS_POSSIBLE_FILTERS: ChipElement[] = [
        {
            chipDescription: {
                name: 'name',
                display: 'Nazwa partnera'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.PARTNER_NAMES
        },
        {
            chipDescription: {
                name: 'owner',
                display: 'Właściciel'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_OPERATIONS
        },
        {
            chipDescription: {
                name: 'toa',
                display: 'TWS'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_TOA
        },
        {
            chipDescription: {
                name: 'partnerType',
                display: 'Typ partnera'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PARTNER_TYPES
        },
        {
            chipDescription: {
                name: 'district',
                display: 'Województwo'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.DISTRICTS
        },
        {
            chipDescription: {
                name: 'town',
                display: 'Miasto'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.TOWNS
        },
        {
            chipDescription: {
                name: 'financialId',
                display: 'Id kontrahenta'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'brandIds',
                display: 'Marka produktu'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.BRANDS,
        },
        {
            chipDescription: {
                name: 'product',
                display: 'Nazwa produktu'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PRODUCTS
        },
        {
            chipDescription: {
                name: 'category',
                display: 'Kategoria produktu'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PRODUCT_CATEGORIES
        },
        {
            chipDescription: {
                name: 'brandCooperations',
                display: 'Status współpracy'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.COOPERATION_STATUS
        },
        {
            chipDescription: {
                name: 'brandCooperationTypes',
                display: 'Typ współpracy'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.COOPERATION_TYPE
        },
        {
            chipDescription: {
                name: 'isAuthorizedPoint',
                display: 'Punkt autoryzowany'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        },
        {
            chipDescription: {
                name: 'chain',
                display: 'Sieć'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.CHAINS
        },
        {
            chipDescription: {
                name: 'isOnlineSeller',
                display: 'Sprzedaż internetowa'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        }
    ];

    public static readonly PRODUCTS_POSSIBLE_FILTERS: ChipElement[] = [
        {
            chipDescription: {
                name: 'owner',
                display: 'Właściciel'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_OPERATIONS
        },
        {
            chipDescription: {
                name: 'toa',
                display: 'TWS'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_TOA
        },
        {
            chipDescription: {
                name: 'partnerType',
                display: 'Typ partnera'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PARTNER_TYPES
        },
        {
            chipDescription: {
                name: 'district',
                display: 'Województwo'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.DISTRICTS
        },
        {
            chipDescription: {
                name: 'brandIds',
                display: 'Marka'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.BRANDS,
        },
        {
            chipDescription: {
                name: 'product',
                display: 'Nazwa produktu'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PRODUCTS
        },
        {
            chipDescription: {
                name: 'isAuthorizedPoint',
                display: 'Punkt autoryzowany'
            },
            chipType: ChipType.SELECT,
            dictType: DictionariesEnum.TRUTHY
        },
        {
            chipDescription: {
                name: 'category',
                display: 'Kategoria produktu'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PRODUCT_CATEGORIES
        },
        {
            chipDescription: {
                name: 'town',
                display: 'Miasto'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.TOWNS
        },
        {
            chipDescription: {
                name: 'financialId',
                display: 'Id kontrahenta'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'street',
                display: 'Ulica'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.STREETS
        },
        {
            chipDescription: {
                name: 'brandCooperations',
                display: 'Status współpracy'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.COOPERATION_STATUS
        },
        {
            chipDescription: {
                name: 'brandCooperationTypes',
                display: 'Typ współpracy'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.COOPERATION_TYPE
        },
        {
            chipDescription: {
                name: 'chain',
                display: 'Sieć'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.CHAINS
        },
    ];

    public static readonly ENTREPRENEUR_STATIC_FILTERS: ChipElement[] = [
        // {
        //     chipDescription: {
        //         name: 'nip',
        //         display: 'NIP'
        //     },
        //     chipType: ChipType.TEXT,
        // },
    ];

    public static readonly ENTREPRENEUR_POSSIBLE_FILTERS: ChipElement[] = [
        {
            chipDescription: {
                name: 'nip',
                display: 'NIP'
            },
            chipType: ChipType.TEXT,
        },
        {
            chipDescription: {
                name: 'name',
                display: 'Nazwa przesiębiorcy'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.ENTREPRENEUR_NAMES
        },
        {
            chipDescription: {
                name: 'owner',
                display: 'Właściciel'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_OPERATIONS
        },
        {
            chipDescription: {
                name: 'tws',
                display: 'TWS'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.PEOPLE_TOA
        },
        {
            chipDescription: {
                name: 'concernBrandCooperations',
                display: 'Status umowy'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.CONCERN_COOPERATION_TYPE
        },
        {
            chipDescription: {
                name: 'district',
                display: 'Województwo'
            },
            chipType: ChipType.MULTI,
            dictType: DictionariesEnum.DISTRICTS
        },
        {
            chipDescription: {
                name: 'town',
                display: 'Miasto'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.TOWNS
        },
        {
            chipDescription: {
                name: 'onboardingDate',
                display: 'Data ostatniego wdrożenia'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'firstOnboardingDate',
                display: 'Data pierwszego wdrożenia'
            },
            chipType: ChipType.RANGE,
        },
        {
            chipDescription: {
                name: 'street',
                display: 'Ulica'
            },
            chipType: ChipType.MULTI,
            typeaheadDict: DictionariesEnum.STREETS
        },

        {
            chipDescription: {
                name: 'financialId',
                display: 'Id kontrahenta'
            },
            chipType: ChipType.TEXT
        },
        {
            chipDescription: {
                name: 'email',
                display: 'Email'
            },
            chipType: ChipType.TEXT
        }
    ];



    public static getValueOfMultiselectChip(chipElement: ChipElement) {
        const value: DictModel[] = (chipElement.chipValue.value as DictModel[]);
        if (value && typeof value !== 'string' && value.length > 0) {
            if (chipElement.chipDescription.name === 'town' || chipElement.chipDescription.name === 'street') {
                return value.map(dictModel => ({ chipName: chipElement.chipDescription.name, value: dictModel.value })) as ChipValue[];
            } else {
                return value.map(dictModel => ({ chipName: chipElement.chipDescription.name, value: dictModel.id })) as ChipValue[];
            }
        }
    }

    public static getValueOfTextChip(chipElement: ChipElement, withLogicalOperator: boolean) {
        return {
            chipName: chipElement.chipDescription.name,
            value: withLogicalOperator ? `${chipElement.chipValue.logicalOperator}:${chipElement.chipValue.value}` : chipElement.chipValue.value
        } as ChipValue;
    }

    public static getValueOfSelectChip(chipElement: ChipElement) {
        const value: DictModel = (chipElement.chipValue.value as DictModel);
        return { chipName: chipElement.chipDescription.name, value: value.id } as ChipValue;
    }

    public static prepareParams(chipElements: ChipElement[], httpParams: HttpParams = null): HttpParams {
        let params: HttpParams = httpParams || new HttpParams();
        chipElements.filter((chipElement: ChipElement) => chipElement.chipValue).forEach((chipElement: ChipElement) => {
            switch (chipElement.chipType) {
                case ChipType.MULTI:
                    params = ChipFiltersUtils.prepareParamOfMultiselect(params, chipElement);
                    break;

                case ChipType.PAIR:
                    params = ChipFiltersUtils.prepareParamOfPair(params, chipElement);
                    break;

                case ChipType.SELECT:
                    params = ChipFiltersUtils.prepareParamOfSelect(params, chipElement);
                    break;

                case ChipType.TEXT:
                    params = ChipFiltersUtils.prepareParamOfText(params, chipElement);
                    break;

                case ChipType.RANGE:
                    params = ChipFiltersUtils.prepareParamOfRange(params, chipElement);
                    break;
            }
        });
        return params;
    }

    private static prepareParamOfMultiselect(params: HttpParams, chipElement: ChipElement): HttpParams {
        const chipValue = this.getValueOfMultiselectChip(chipElement);
        if (chipValue) {
            chipValue.forEach(el => {
                if (el.value) {
                    params = params.append(el.chipName, el.value.toString())
                }
            });
        }
        return params;
    }

    private static prepareParamOfText(params: HttpParams, chipElement: ChipElement): HttpParams {
        const chipValue = this.getValueOfTextChip(chipElement, true);
        if (chipValue.value) {
            params = params.append(chipValue.chipName, chipValue.value);
        }
        return params;
    }

    private static prepareParamOfSelect(params: HttpParams, chipElement: ChipElement): HttpParams {
        const chipValue = this.getValueOfSelectChip(chipElement);
        if (chipValue.value) {
            params = params.append(chipValue.chipName, `${chipValue.value}`);
        }
        return params;
    }

    private static prepareParamOfRange(params: HttpParams, chipElement: ChipElement): HttpParams {
        const value: ChipRange = chipElement.chipValue.value;
        if (value.startDate && value.endDate) {
            value.endDate = moment(value.endDate);
            value.startDate = moment(value.startDate);
            params = params.append(chipElement.chipDescription.name, `GOE:${value.startDate.format('YYYY-MM-DD')}`);
            params = params.append(chipElement.chipDescription.name, `LOE:${value.endDate.format('YYYY-MM-DD')}`);
        }
        return params;
    }

    private static prepareParamOfPair(params: HttpParams, chipElement: ChipElement): HttpParams {
        if (chipElement.chipValue.logicalOperator === 'BETWEEN') {
            const value: Pair = chipElement.chipValue.value;
            if (value.right && value.left) {
                params = params.append(chipElement.chipDescription.name, `GOE:${value.left}`);
                params = params.append(chipElement.chipDescription.name, `LOE:${value.right}`);
            }
        } else {
            params = params.append(chipElement.chipDescription.name, `${chipElement.chipValue.logicalOperator}:${chipElement.chipValue.value}`);
        }
        return params;
    }
}
