import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MessageSeverityEnum } from '../../_enums/message-severity.enum';
import { ChainPartnerTableRecord } from '../../_models/chain-partner-table-record.model';
import { ContactModel } from '../../_models/contact.model';
import { DictModel } from '../../_models/dict.model';
import { ExternalPersonModel } from '../../_models/external-person.model';
import { ShortageModel } from '../../_models/shortage-model';
import { AuthenticationService } from '../../_services/authentication.service';
import { DictionariesService } from '../../_services/dictionaries.service';
import { MessageService } from '../../_services/message.service';
import { RedirectionService } from '../../_services/redirection.service';
import {
    canEditChains,
    canRemoveChain,
    canViewSozFields,
    canEditSozFields,
    canCreateOrDeactivateSozAccount,
    canChangeLoginOrResetSozPassword,
    canUseForceSozButton
} from '../../_utils/auth-utils/auth-chains';
import { ValidationUtils } from '../../_utils/validation.utils';
import { ChainsService } from '../chains.service';
import { ChainsDialogModel } from './chains-dialog.model';
import {DialogResultEnum} from '../../_enums/dialog-result.enum';
import {SozService} from '../../_services/soz.service';

@Component({
    selector: 'app-chain-details-dialog',
    templateUrl: './chain-details-dialog.component.html',
    styleUrls: ['./chain-details-dialog.component.scss']
})
export class ChainDetailsDialogComponent implements OnInit {
    public disabled: boolean = true;
    public breakpoint: number;
    public dataSource: MatTableDataSource<ShortageModel>;
    public peopleDict: Array<DictModel>;
    public filteredOwners: Array<DictModel> = [];
    public showInvalidFields: boolean = false;

    public filteredPartners: Array<ChainPartnerTableRecord> = [];
    // TODO: po okresie testowym wywalić, plus wywalić ify w htmlu używjące poniższych
    protected canEdit = true;
    protected canSave = true;

    constructor(@Inject(MAT_DIALOG_DATA) public chainsDialogModel: ChainsDialogModel,
                private readonly dictService: DictionariesService,
                private dialogRef: MatDialogRef<ChainsDialogModel>,
                private messageService: MessageService,
                public readonly redirectionService: RedirectionService,
                private chainsService: ChainsService,
                private readonly sozService: SozService,
                public readonly authService: AuthenticationService) {
        this.dataSource = new MatTableDataSource<ShortageModel>(chainsDialogModel.chainShortages);
    }

    private _currentUserRoles: number[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    get canEditChain(): boolean {
        return canEditChains(this.currentUserRoles);
    }

    get canRemoveChain(): boolean {
        return canRemoveChain(this.currentUserRoles);
    }

    ngOnInit(): void {
        this.breakpoint = (window.innerWidth <= 800) ? 1 : 12;
        this.dictService.getPeople().subscribe((peopleDict: Array<DictModel>) => {
            this.peopleDict = peopleDict;
            this.filteredOwners = peopleDict;
        });
        this.chainsService.selectedPartners = this.chainsDialogModel.chainModel.partners;
        this.filteredPartners = this.chainsService.selectedPartners;

    }

    public selectionChange(event: ChainPartnerTableRecord[]) {
        this.chainsService.selectedPartners = event;
        this.filteredPartners = this.chainsService.selectedPartners;
    }

    public onResize(event): void {
        this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 12;
    }

    public ownerEdited(owner: number) {
        this.chainsDialogModel.chainModel.ownerId = owner;
    }

    public toaEdited(toa: number) {
        this.chainsDialogModel.chainModel.toaId = toa;
    }

    public filterPerson(event) {
        if (!this.peopleDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredOwners = this.peopleDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredOwners = this.peopleDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public submitDialog(close: boolean) {
        this.chainsDialogModel.chainModel.partners = this.chainsService.selectedPartners;
        this.showInvalidFields = false;
        if (this.isFormInvalid()) {
            this.showInvalidFields = true;
            this.messageService.setSnackbar({
                severity: MessageSeverityEnum.failure,
                durationInSecond: 5,
                text: 'Wypełnij wszystkie wymagane pola!'
            });
            return;
        }
        if (close) {
            this.chainsDialogModel.notification.next(DialogResultEnum.SAVE_AND_EXIT);
        } else {
            this.chainsDialogModel.notification.next(DialogResultEnum.SAVE);
        }
    }

    public isFormInvalid(): boolean {

        if (!this.chainsDialogModel.chainModel) {
            return true;
        }
        return !ValidationUtils.isChainValid(this.chainsDialogModel.chainModel);
    }

    public addPlainContactToArray(): void {
        this.chainsDialogModel.chainModel.plainContacts.push(new ContactModel(null, null, null, null));
    }

    public removeCertainContactFromArrayForChain(contactModel: ContactModel): void {
        this.chainsDialogModel.chainModel.plainContacts = this.chainsDialogModel.chainModel.plainContacts.filter(contact => contact !== contactModel);
    }

    public addPersonContactToArray(): void {
        this.chainsDialogModel.chainModel.peopleContacts.push(new ExternalPersonModel(null, null, null, null, null, null));
    }

    public removeCertainPersonContactFromArrayForPartner(contactModel: ExternalPersonModel): void {
        this.chainsDialogModel.chainModel.peopleContacts = this.chainsDialogModel.chainModel.peopleContacts.filter(contact => contact !== contactModel);
    }

    public filterSelectedPartners(event) {
        if (!this.chainsService.selectedPartners) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredPartners = this.chainsService.selectedPartners;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredPartners = this.chainsService.selectedPartners.filter(
            partner => partner.name.toLowerCase().indexOf(search) > -1 ||
                partner.town.toLowerCase().indexOf(search) > -1 ||
                partner.street.toLowerCase().indexOf(search) > -1 ||
                partner.postalCode.toLowerCase().indexOf(search) > -1 ||
                partner.brandCooperations.toLowerCase().indexOf(search) > -1
        );
    }

    protected removeChain() {
        this.chainsService.showDeleteConfirmationDialog.next(this.chainsDialogModel.chainModel);
        this.chainsDialogModel.notification.next(DialogResultEnum.EXIT);
    }

    get canViewSozFields(): boolean {
        return canViewSozFields(this.currentUserRoles);
    }

    get canEditSozFields(): boolean {
        return canEditSozFields(this.currentUserRoles);
    }

    get canCreateOrDeactivateSozAccount(): boolean {
        return canCreateOrDeactivateSozAccount(this.currentUserRoles);
    }

    get canChangeLoginOrResetSozPassword(): boolean {
        return canChangeLoginOrResetSozPassword(this.currentUserRoles);
    }

    get canUseForceSozButton(): boolean {
        return canUseForceSozButton(this.currentUserRoles);
    }

    isSozLoginRequired(): boolean {
        return false;
    }


    createSozAccount(changeButton: boolean) {
        if (changeButton) {
            this.chainsDialogModel.chainModel.sozAccountCreated = true;
        }
        this.sozService.createChainSozAccount(this.chainsDialogModel.chainModel.id).subscribe();
    }

    toggleActive() {
        if (this.chainsDialogModel.chainModel.sozAccountActive) {
            this.chainsDialogModel.chainModel.sozAccountActive = false;
            this.sozService.updateChainSozAccountActivity(this.chainsDialogModel.chainModel.id, {active: false}).subscribe();
        } else {
            this.chainsDialogModel.chainModel.sozAccountActive = true;
            this.sozService.updateChainSozAccountActivity(this.chainsDialogModel.chainModel.id, {active: true}).subscribe();
        }
    }

    getSozActivateButtonTitle() {
        if (this.chainsDialogModel.chainModel.sozAccountActive) {
            return 'Dezaktywuj konto SOZ';
        }
        return 'Aktywuj konto SOZ';
    }

    resetSozPassword() {
        this.sozService.changeChainPassword(this.chainsDialogModel.chainModel.id).subscribe();
    }

}
