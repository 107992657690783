import { Component, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Component({
    selector: 'app-util-wrapper',
    templateUrl: './util-wrapper.component.html'
})
export class UtilWrapperComponent {


    @Output()
    public clickedOutside = new EventEmitter<void>();

    constructor(private eRef: ElementRef) {
    }

    @HostListener('document:click', ['$event'])
    emitOutsideClick(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.clickedOutside.emit();
        }
    }

}
