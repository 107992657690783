import { AddressModel } from './address.model';
import { ContactModel } from './contact.model';
import { ExternalPersonModel } from './external-person.model';

export class PartnerDetailsModel {

    constructor(public chainId: number,
                public name: string,
                public address: AddressModel,
                public gradeId: number,
                public pharmacyTypeId: number,
                public partnerTypeId: number,
                public infoSourceId: number,
                public averagePatientsNo: number,
                public employeesNo: number,
                public creationDate: Date,
                public updateDate: Date,
                public licenseNo: string,
                public dateOfLicenseObtaining: Date,
                public plainContacts: Array<ContactModel>,
                public peopleContacts: Array<ExternalPersonModel>,
                public owsMinNumberAccepts: number,
                public phone?: string,
                public email?: string,
                public nip?: string,
                public gln?: string,
                public sozLogin?: string,
                public sozAccountCreated?: boolean,
                public sozAccountActive?: boolean,
                public sozNetworkLogin?: boolean,
                public partnerVIP?: boolean,
                public notes?: string,
                public readonly id?: number) {

    }
}

export class PartnerExtraDetailsModel {
    constructor(public solgarConcernLoyaltyProgram: boolean,
                public solgarConcernCooperationStatusId: number,
                public terranovaConcernLoyaltyProgram: boolean,
                public terranovaConcernCooperationStatusId: number,) {
    }
}
