import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ScreenTypeEnum } from '../_enums/screen-type.enum';
import { ColumnElementModel } from '../_models/column-element.model';
import { ConfigModel } from '../_models/config.model';
import { PartnerDetailsModel } from '../_models/partner-details.model';
import { ScreenConfigModel } from '../_models/screen-config.model';
import { PageParams, ScreenService } from '../_services/screen.service';
import { ChipFiltersUtils } from '../_utils/chip-filters.utils';
import { ChipElement } from '../filter-chips/chip-component/chip.component';

export class InitialObjectForPartnerDetails {
    public id: number;
    public canEdit: boolean;
    public defaultTab?: number;
}

@Injectable()
export class PartnersService {
    public lowerBound: number = 4;
    public upperBound: number = 6;
    public updateDataSubject: Subject<boolean> = new Subject<boolean>();
    public showDeleteConfirmationDialog: Subject<PartnerDetailsModel> = new Subject<PartnerDetailsModel>();
    public size: number = 15;
    // TODO: 3/4 pól i metod do komponentu zostawić tutaj praktycznie tylko metody http zwracające observable
    public selectNames: Array<string> = [];
    public configurationMap: Map<string, ScreenConfigModel> = new Map<string, ScreenConfigModel>();
    public activeConfiguration: ScreenConfigModel = new ScreenConfigModel(
        null,
        null,
        null,
        null,
        new ConfigModel([], null, [], []));
    public previousConfiguration: ScreenConfigModel = null;
    // TODO: Ambasador Initial data tryout
    // public allAmbassadors: Array<ExternalPersonModel> = [];
    public page = 1;
    public pages: number;
    public totalElements: number;
    public rowsForActiveConfiguration: Array<Array<string>> = [];
    public filtersForActiveConfiguration: Array<ChipElement> = [];
    public sortQueryParam: string = null;
    public requestsToInitLeft = 0;
    public chipSubject: Subject<ChipElement[]> = new Subject<ChipElement[]>();
    private showPartnerDetails$: Subject<InitialObjectForPartnerDetails> = new Subject<InitialObjectForPartnerDetails>();
    private readonly SCREEN_API_URL = environment.apiUrl + '/api/screen';
    private PARTNER_API_URL_INITIAL = environment.apiUrl + '/api/partner';
    private PARTNER_API_URL = environment.apiUrl + '/api/partner/with/configuration';

    constructor(public http: HttpClient, private readonly screenService: ScreenService) {
    }

    public showPartnerDetails(id: number, canEdit?: boolean, defaultTab?: number): void {
        this.showPartnerDetails$.next({id: id, canEdit: !!canEdit, defaultTab: defaultTab || 0});
    }

    public hidePartnerDetails(): void {
        this.showPartnerDetails$.next(null);
    }

    public getShowPartnerDetails(): Observable<InitialObjectForPartnerDetails> {
        return this.showPartnerDetails$.asObservable();
    }

    public setScreenData(callback: Function) {
        this.http.get<Array<ScreenConfigModel>>(`${this.SCREEN_API_URL}/?screenType=${ScreenTypeEnum.partners}`).pipe(
            tap((configurationList: Array<ScreenConfigModel>) => this.setInitialConfigurationOnScreen(configurationList)),
            tap(() => this.getUpdatedData())
        ).subscribe(() => callback());
    }

    //TODO: this should be moved to component.
    public getUpdatedData(visibleColumnsOrdered: ColumnElementModel[] = this.activeConfiguration.configJson.visibleColumnsOrdered) {
        let params: HttpParams = null;
        if (this.activeConfiguration.configJson.filters && this.activeConfiguration.configJson.filters.length > 0) {
            this.filtersForActiveConfiguration = this.activeConfiguration.configJson.filters;

            params = ChipFiltersUtils.prepareParams(this.filtersForActiveConfiguration);
        }

        const pageParams: PageParams = {
            url: this.PARTNER_API_URL,
            page: this.page,
            size: this.size,
            columnsOrder: visibleColumnsOrdered,
            sortQueryParam: this.sortQueryParam
        };

        this.screenService.getUpdatedData(pageParams, params).pipe(
            tap(data => this.setInitialData(data))
        ).subscribe();
    }

    public setActiveConfiguration(model: ScreenConfigModel): void {
        this.activeConfiguration = Object.assign({}, model);
    }

    public setSelectNames(newNames: Array<string>): void {
        this.selectNames = newNames;
    }

    public addPartner(partnerDetailsModel: PartnerDetailsModel): Observable<PartnerDetailsModel> {
        return this.http.post<PartnerDetailsModel>(this.PARTNER_API_URL_INITIAL, partnerDetailsModel);
    }

    protected setInitialData(data): void {
        this.rowsForActiveConfiguration = this.screenService.setRows(data, this.activeConfiguration.configJson.visibleColumnsOrdered);


        this.chipSubject.next(this.filtersForActiveConfiguration);

        this.pages = data['totalPages'];
        this.totalElements = data['totalElements'];
        this.page = data['pageable'] ? data['pageable']['pageNumber'] : 0;

    }

    private setInitialConfigurationOnScreen(configurationList: Array<ScreenConfigModel>): void {
        this.activeConfiguration = Object.assign({}, configurationList.find(configuration => configuration.isDefault));
        this.selectNames = [];
        this.selectNames.push(this.activeConfiguration.name);
        this.configurationMap = new Map<string, ScreenConfigModel>(configurationList
            .sort((a, b) => a.name < b.name ? -1 : 1)
            .map(singleConfig => {
                if (!singleConfig.isDefault) {
                    this.selectNames.push(singleConfig.name);
                }
                return [singleConfig.name, singleConfig] as [string, ScreenConfigModel];
            }));
        this.configurationMap.set(this.activeConfiguration.name, this.activeConfiguration);
    }


}
