import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, zip } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { DictionariesEnum } from '../_enums/dictionaries-enum';
import { ChainDetailsModel } from '../_models/chain-details.model';
import { CogElementModel } from '../_models/cog-element.model';
import { ColumnElementModel } from '../_models/column-element.model';
import { ConfigModel } from '../_models/config.model';
import { PartnerDetailsModel } from '../_models/partner-details.model';
import { ScreenConfigModel } from '../_models/screen-config.model';
import { TableEventModel } from '../_models/table-event.model';
import { UserConfigModel } from '../_models/user-config.model';
import { AuthenticationService } from '../_services/authentication.service';
import { DictionariesService } from '../_services/dictionaries.service';
import { REDIRECTION_CHAIN_PARTNER, RedirectionService } from '../_services/redirection.service';
import { ScreenService } from '../_services/screen.service';
import { UserConfigService } from '../_services/user-config.service';
import { canAddPartner } from '../_utils/auth-utils/auth-partners';
import { ChipFiltersUtils } from '../_utils/chip-filters.utils';
import { DialogUtils } from '../_utils/dialog.utils';
import { ExcelUtils } from '../_utils/excel.utils';
import {DynamicTableColumn, DynamicTableService} from '../dynamic-table/dynamic-table.service';
import { ChipElement, ChipType } from '../filter-chips/chip-component/chip.component';
import { AdminConfigurationTabService } from '../pages/admin-page/sub-pages/admin-configuration-tab/admin-configuration-tab.service';
import { ConfirmRemovingPartnerDialogComponent } from './partner-details-dialog/confirm-removing/confirm-removing-partner-dialog.component';
import { PartnerDetailsScreenService } from './partner-details-dialog/partner-details-screen.service';
import { PartnersService } from './partners.service';

@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit, OnDestroy {
    public showConfiguration: boolean = false;
    public showConfigurationManager: boolean = false;
    public showFilter: boolean = false;
    public collapsed: boolean = true;
    columnsToDisplay: number = 9;
    columnsNumbers: Array<number> = [];
    dataLoaded: boolean = false;
    public newConfigurationName: string;
    public redirectionChain: ChainDetailsModel;
    chipPresentFilters: Array<ChipElement> = null;
    chipPartnerPossibleFilters: ChipElement[] = ChipFiltersUtils.PARTNER_POSSIBLE_FILTERS;
    private _destroy: Subject<boolean> = new Subject<boolean>();
    private dirty: boolean = false;
    private readonly SCREEN_API_URL = environment.apiUrl + '/api/screen';

    constructor(public readonly partnersService: PartnersService,
                public http: HttpClient,
                private dialog: MatDialog,
                private readonly dynamicTableService: DynamicTableService,
                private readonly userConfigService: UserConfigService,
                private readonly excelUtils: ExcelUtils,
                private readonly screenService: ScreenService,
                private readonly detailsScreenService: PartnerDetailsScreenService,
                public readonly redirectionService: RedirectionService,
                public readonly adminConfigurationTabService: AdminConfigurationTabService,
                public readonly authService: AuthenticationService,
                private readonly dictionariesService: DictionariesService) {
        for (let i = 0; i < 25; ++i) {
            this.columnsNumbers.push(i);
        }


        this.userConfigService.getUserConfig().subscribe(
            (userConfig: UserConfigModel) => {
                if (userConfig && userConfig.partnersConfig) {
                    this.columnsToDisplay = userConfig.partnersConfig.columnToDisplay || 9;
                }
                this.dataLoaded = true;
            },
            (error) => {
                console.log('Error on getting user config on finances.', error);
                this.dataLoaded = true;
            }
        );

    }

    private _currentUserRoles: number[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    get canAddPartner(): boolean {
        return canAddPartner(this.currentUserRoles);
    }

    ngOnDestroy(): void {
        this._destroy.next(true);
        this._destroy.complete();
    }

    public cleanAndExitConfiguration(): void {
        this.cleanConfigurationAndExit();
        this.abortEditingConfiguration();
    }

    public abortEditingConfiguration(): void {
        this.showConfiguration = false;
    }

    public showInitialState(): boolean {
        return !this.showConfiguration && !this.showFilter && !this.showConfigurationManager;
    }

    public exportToExcel(): void {
        this.excelUtils.exportToExcel(this.getSystemNameHeaders(), this.partnersService.sortQueryParam, this.getFilters(), 'partner', 'all');
    }

    public exportBrandingBenefitToExcel(): void {
        this.excelUtils.exportToExcel(this.getSystemNameHeaders(), this.partnersService.sortQueryParam, this.getFilters(), 'partner', 'branding-benefit');
    }

    public exportMarkegintMaterialsToExcel(): void {
        this.excelUtils.exportToExcel(this.getSystemNameHeaders(), this.partnersService.sortQueryParam, this.getFilters(), 'partner', 'marketing-materials-benefit');
    }

    exportManagersTrainingToExcel(): void {
        this.excelUtils.exportToExcel(this.getSystemNameHeaders(), this.partnersService.sortQueryParam, this.getFilters(), 'partner', 'managers-training');
    }

    updateColumnToDisplay(columnToDisplay: number) {
        this.columnsToDisplay = columnToDisplay;
        this.updateColToDisplayAmount();
        this.dynamicTableService.columnChanged.next(columnToDisplay);
        this.userConfigService.updatePartnersConfigColumnToDisplay(columnToDisplay).subscribe();
        //TODO: lets talk about it Konrad
        this.updateActiveConfig();
    };

    ngOnInit() {
        this.partnersService.setScreenData(() => this.setRedirectionFilters());
        this.adminConfigurationTabService.getConfiguration(this.adminConfigurationTabService.MAIN_CONFIG_ID)
            .subscribe(config => {
                this.partnersService.lowerBound = config.partnerAttentionLowerBoundary || 4;
                this.partnersService.upperBound = config.partnerAttentionUpperBoundary || 6;
            });

        this.partnersService.showDeleteConfirmationDialog.pipe(
            takeUntil(this._destroy)
        ).subscribe((partnerDetails: PartnerDetailsModel) => {
            if (!this.detailsScreenService.removingInitialized) {
                this.detailsScreenService.removingInitialized = true;
            } else {
                return;
            }

            const deleteDialog = this.dialog.open(ConfirmRemovingPartnerDialogComponent,
                {
                    width: DialogUtils.defaultDialogWidth
                });

            deleteDialog.afterClosed().pipe(takeUntil(this._destroy))
                .subscribe((result: boolean) => {
                        if (!result) {
                            return;
                        }
                        this.detailsScreenService.removeSubject(partnerDetails).subscribe(() => this.partnersService.updateDataSubject.next(true));
                    },
                    () => this.detailsScreenService.removingInitialized = false,
                    () => this.detailsScreenService.removingInitialized = false
                );

        });

        this.partnersService.updateDataSubject.pipe(
            takeUntil(this._destroy)
        ).subscribe(() => this.partnersService.getUpdatedData());
    }

    public getHeaders(): Array<DynamicTableColumn> {
        if (!this.partnersService.activeConfiguration.configJson || !this.partnersService.activeConfiguration.configJson.visibleColumnsOrdered) {
            return;
        }
        return this.partnersService.activeConfiguration.configJson.visibleColumnsOrdered
            .map(col => new DynamicTableColumn(col.displayName, col.sortAvailable));
    }

    public getSystemNameHeaders(): Array<string> {
        if (!this.partnersService.activeConfiguration.configJson.visibleColumnsOrdered) {
            return;
        }
        return this.partnersService.activeConfiguration.configJson.visibleColumnsOrdered.map(col => col.systemName);
    }

    public getRows(): Array<Array<string>> {
        if (!this.partnersService.rowsForActiveConfiguration) {
            return;
        }
        return this.partnersService.rowsForActiveConfiguration;
    }

    public getPages(): number {
        if (!this.partnersService.pages) {
            return;
        }
        return this.partnersService.pages;
    }

    public getPage(): number {
        if (!this.partnersService.page) {
            return;
        }
        return this.partnersService.page;
    }

    public getTotalElements(): number {
        if (!this.partnersService.totalElements) {
            return;
        }
        return this.partnersService.totalElements;
    }

    public tableEvent(event: TableEventModel) {
        this.partnersService.page = event.page;
        this.partnersService.size = event.rowsPerPage;
        //TODO: Czy aktualna strona powinna być zapisana do konfiguracji? Jeśli tak to dodać, spytać Konrada
        this.partnersService.getUpdatedData();
    }

    public tableSortEvent(sortQueryParam: string) {
        this.partnersService.sortQueryParam = sortQueryParam;
        //TODO: Czy aktualne sortowanie powinno być zapisane do konfiguracji? Jeśli tak to dodać, spytać Konrada
        this.partnersService.getUpdatedData();
    }

    public assignPrevious(): void {
        if (this.dirty === false) {
            this.partnersService.previousConfiguration = new ScreenConfigModel(this.partnersService.activeConfiguration.id,
                this.partnersService.activeConfiguration.name, this.partnersService.activeConfiguration.isDefault, this.partnersService.activeConfiguration.screenType, new ConfigModel(
                    Object.assign([], this.partnersService.activeConfiguration.configJson.visibleColumnsOrdered),
                    this.partnersService.activeConfiguration.configJson.sort,
                    Object.assign([], this.partnersService.activeConfiguration.configJson.filters),
                    Object.assign([], this.partnersService.activeConfiguration.configJson.cogColumnsOrdered),
                ));
            this.dirty = true;
        }
    }

    public cogUpdateTempConfiguration($event: Array<CogElementModel>, columnToDisplay: number = 9): void {
        this.partnersService.activeConfiguration.configJson.visibleColumnsOrdered = this.cogElementsToVisibleColumns($event, columnToDisplay);
        this.partnersService.getUpdatedData($event);
    }

    public updateColToDisplayAmount(): void {
        this.cogUpdateTempConfiguration(this.partnersService.activeConfiguration.configJson.cogColumnsOrdered, this.columnsToDisplay || 9);
    }

    public changeActiveConfig(configName: string): void {
        let candidateConfig = this.partnersService.configurationMap.get(configName);
        this.activate(candidateConfig);
    }

    public setScreenConfigAsDefault() {
        this.partnersService.activeConfiguration.isDefault = true;
        this.screenService.setDefaultScreenConfigData(this.SCREEN_API_URL, this.partnersService.activeConfiguration).subscribe(data => {
            this.partnersService.getUpdatedData();
        });
    }

    public updateActiveConfig(): void {
        let activeConfig = this.partnersService.activeConfiguration;
        this.screenService.updateScreenData(this.SCREEN_API_URL, activeConfig).subscribe(data => {
            this.partnersService.configurationMap.set(data.name, data);
            this.activate(data);
        });
    }

    public addNewConfig(): void {
        if (!this.partnersService.previousConfiguration) {
            this.partnersService.previousConfiguration = new ScreenConfigModel(this.partnersService.activeConfiguration.id,
                this.partnersService.activeConfiguration.name, this.partnersService.activeConfiguration.isDefault, this.partnersService.activeConfiguration.screenType, new ConfigModel(
                    Object.assign([], this.partnersService.activeConfiguration.configJson.visibleColumnsOrdered),
                    this.partnersService.activeConfiguration.configJson.sort,
                    Object.assign([], this.partnersService.activeConfiguration.configJson.filters),
                    Object.assign([], this.partnersService.activeConfiguration.configJson.cogColumnsOrdered),
                ));
        }
        let activeConfig = this.partnersService.activeConfiguration;
        this.dirty = false;
        activeConfig.id = null;
        activeConfig.isDefault = false;
        activeConfig.name = this.newConfigurationName;
        this.partnersService.configurationMap.set(this.partnersService.previousConfiguration.name, this.partnersService.previousConfiguration);
        this.screenService.saveScreenData(this.SCREEN_API_URL, activeConfig).subscribe(data => {
            this.partnersService.configurationMap.set(data.name, data);
            this.manipulateSelect(data.name);
            this.activate(data);
        });
        this.cleanConfigurationAndExit();

    }

    public getFilters(): Array<ChipElement> {
        return this.partnersService.activeConfiguration.configJson.filters;
    }

    public chipUpdateList(chipList: ChipElement[]): void {
        console.log(chipList);
        this.chipPresentFilters = chipList;
    }

    public chipAddToList(chipElement: ChipElement): void {
        this.chipPresentFilters.push(chipElement);
    }

    public chipUpdateListAndSendRequest(chipList: ChipElement[]): void {
        this.chipUpdateList(chipList);
        this.partnersService.activeConfiguration.configJson.filters = chipList;
        this.updateActiveConfig();

    }

    public chipUpdateListAndSendRequestForRedirection(chipList: ChipElement[]): void {
        this.chipUpdateList(chipList);
        this.partnersService.activeConfiguration.configJson.filters = chipList;
        this.partnersService.getUpdatedData();
    }

    private cleanConfigurationAndExit(): void {
        this.showConfigurationManager = false;
        this.newConfigurationName = null;
    }

    private manipulateSelect(name: string): void {
        let newNames: Array<string> = this.partnersService.selectNames.filter((item, index) => item != name);
        newNames.unshift(name);
        this.partnersService.setSelectNames(newNames);
    }

    private activate(candidateConfig: ScreenConfigModel): void {
        this.partnersService.setActiveConfiguration(candidateConfig);
        this.partnersService.getUpdatedData();
    }

    private cogElementsToVisibleColumns(cogElements: Array<CogElementModel>, columnToDisplay: number): Array<ColumnElementModel> {
        return cogElements.slice(0, columnToDisplay)
            .map((cogElementModel: CogElementModel) => new ColumnElementModel(cogElementModel.systemName, cogElementModel.displayName, cogElementModel.sortAvailable));
    }

    private setRedirectionFilters(): void {
        zip(this.partnersService.chipSubject.pipe(take(1)), this.redirectionService.getRoutingState(), this.dictionariesService.getCooperationStatues())
            .subscribe(chipsAndStateAndCoopDict => {
                const previousChips = chipsAndStateAndCoopDict[0];
                const redirectionState = chipsAndStateAndCoopDict[1];
                const coopDict = chipsAndStateAndCoopDict[2];
                if (!redirectionState || !redirectionState.hasOwnProperty('redirectionType')) {
                    this.chipPresentFilters = previousChips || [];
                    return
                }
                if (redirectionState.hasOwnProperty('redirectionType') && redirectionState.redirectionType === REDIRECTION_CHAIN_PARTNER) {
                    this.redirectionChain = redirectionState.value.chain;
                    const chainElem = {
                        chipDescription: {
                            name: 'chain',
                            display: 'Sieć'
                        },
                        chipType: ChipType.MULTI,
                        dictType: DictionariesEnum.CHAINS,
                        chipValue: {
                            value: [
                                {
                                    id: this.redirectionChain.id,
                                    value: this.redirectionChain.name
                                }
                            ],
                            logicalOperator: 'EQ',
                            chipName: 'chain'
                        }
                    };
                    const chipElems: ChipElement[] = [chainElem];

                    const brandId = redirectionState.value.brand;
                    const brandLabel = brandId ? brandId === 1 ? 'Solgar' : 'Terranova' : null;
                    if (brandLabel) {
                        const brandElem = {
                            chipDescription: {
                                name: 'brandIds',
                                display: 'Marka'
                            },
                            chipType: ChipType.MULTI,
                            dictType: DictionariesEnum.BRANDS,
                            chipValue: {
                                value: [{
                                    id: brandId,
                                    value: brandLabel
                                }],
                                logicalOperator: 'EQ',
                                chipName: 'brandIds'
                            }
                        };
                        chipElems.push(brandElem);
                    }

                    const cooperations = redirectionState.value.cooperations;
                    if (cooperations) {
                        const cooperationElem = {
                            chipDescription: {
                                name: 'brandCooperations',
                                display: 'Status współpracy'
                            },
                            chipType: ChipType.MULTI,
                            dictType: DictionariesEnum.COOPERATION_STATUS,
                            chipValue: {
                                value: coopDict.filter(dictVal => cooperations.includes(dictVal.id)),
                                logicalOperator: 'EQ',
                                chipName: 'brandCooperations'
                            }
                        };
                        chipElems.push(cooperationElem);
                    }

                    this.chipUpdateListAndSendRequestForRedirection(chipElems);
                }
            });
    }

}
