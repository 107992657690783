import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { combineLatest, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { MessageSeverityEnum } from '../../../../_enums/message-severity.enum';
import { ConsentModel } from '../../../../_models/consent.model';
import { DictModel } from '../../../../_models/dict.model';
import { AuthenticationService } from '../../../../_services/authentication.service';
import { DictionariesService } from '../../../../_services/dictionaries.service';
import { MessageService } from '../../../../_services/message.service';
import { replacePolishChars } from '../../../../_utils/table-utils';
import { AdminPageService } from '../../admin-page.service';
import { ConfirmActivatingConsentDialogComponent } from './confirm-activating/confirm-activating-consent-dialog.component';
import { ConfirmRemovingConsentDialogComponent } from './confirm-removing/confirm-removing-consent-dialog.component';
import { ConsentService } from './consent.service';
import { AdminConsentTabDialogComponent } from './dialog/admin-consent-tab-dialog.component';

@Component({
    selector: 'app-admin-consent-tab',
    templateUrl: './admin-consent-tab.component.html',
    styleUrls: ['./admin-consent-tab.component.scss']
})
export class AdminConsentTabComponent implements OnInit, OnDestroy {

    public displayedColumns: string[] = [
        'title',
        'version',
        'brand',
        'consentType',
        'consentActions',
        'star'
    ];


    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    protected consentSource: MatTableDataSource<ConsentModel>;
    private _destroy: Subject<void> = new Subject<void>()
    private tempFilter: string;
    private pageSize: number = 20;
    private _currentUserId: number;
    private _consentTypesDict: DictModel[];
    private _brandsDict: DictModel[];

    constructor(private readonly _adminConsentTabService: ConsentService,
                private readonly _adminPageService: AdminPageService,
                private readonly _dictService: DictionariesService,
                private readonly authService: AuthenticationService,
                private _messageService: MessageService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this._currentUserId = this.authService.currentUserValue.personId;

        combineLatest([this._dictService.getConsentTypes(), this._dictService.getBrandDictionary()])
            .pipe(takeUntil(this._destroy))
            .subscribe(([consentTypes, brands]: [DictModel[], DictModel[]]) => {
                this._consentTypesDict = consentTypes;
                this._brandsDict = brands;
            });


        this._initializeConsents();

        this.pageSize = localStorage.getItem(`adminPageConsentPageSize${this._currentUserId}`) && JSON.parse(localStorage.getItem(`adminPageUsersPageSize${this._currentUserId}`)) || 20;
        this._adminConsentTabService.refreshConsents$.pipe(takeUntil(this._destroy)).subscribe(() => this._initializeConsents());
    }

    getBrandName(brandId: number): string {
        if (!this._brandsDict) {
            return 'Nie znaleziono słownika marek';
        }
        return this._brandsDict.find((dictionaryEntry: DictModel) => dictionaryEntry.id === brandId)?.value || 'Nie znaleziono marki'
    }

    getConsentType(consentTypeId: number): string {
        if (!this._consentTypesDict) {
            return 'Nie znaleziono słownika zgód';
        }
        return this._consentTypesDict.find((dictionaryEntry: DictModel) => dictionaryEntry.id === consentTypeId)?.value || 'Nie znaleziono zgód'
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    applyFilter(filterValue: string) {
        this.tempFilter = filterValue;
        this.consentSource.filter = filterValue.trim().toLowerCase();
    }

    public getPageSize(): number {
        return this.pageSize;
    }

    public setPageSize(event: PageEvent): void {
        this.pageSize = event.pageSize;
        localStorage.setItem(`adminPageConsentPageSize${this._currentUserId}`, JSON.stringify(this.pageSize));
    }

    remove(event, id: number) {
        event.preventDefault();
        event.stopPropagation();
        const dialogRef = this.dialog.open(ConfirmRemovingConsentDialogComponent,
            {
                maxWidth: '100%',
            }
        );

        dialogRef.afterClosed()
            .subscribe((result: boolean) => {
                    if (result) {
                        this._adminConsentTabService.deleteConsent(id)
                            .subscribe(() => {
                                    this._adminConsentTabService.refreshConsents$.next();
                                }
                            );
                    }
                }
            );
    }

    activate(event, id: number) {
        event.preventDefault();
        event.stopPropagation();
        const dialogRef = this.dialog.open(ConfirmActivatingConsentDialogComponent,
            {
                maxWidth: '100%',
            }
        );

        dialogRef.afterClosed()
            .subscribe((result: boolean) => {
                    if (result) {
                        this._adminConsentTabService.activateConsent(id, {active: true}).subscribe(() =>
                            this._adminConsentTabService.refreshConsents$.next()
                        )
                    }
                }
            );
    }

    protected openDialog(row: ConsentModel) {
        let model: ConsentModel;

        if (!row) {
            model = {
                active: false,
                newConsent: true
            };
        } else {
            model = _.cloneDeep(row);
        }

        const dialogRef = this.dialog.open(AdminConsentTabDialogComponent,
            {
                maxWidth: '100%',
                panelClass: 'details-dialog',
                data: model,
            }
        );

        dialogRef.afterClosed()
            .pipe(takeUntil(this._destroy))
            .subscribe((result: boolean) => {
                    if (!result) {
                        return;
                    }
                    if (model.newConsent) {
                        this._adminConsentTabService.addConsent(model).pipe(
                            tap(() => this._messageService.setSnackbar({
                                    durationInSecond: 5,
                                    severity: MessageSeverityEnum.success,
                                    text: `Dodano nową zgodę`
                                }),
                                () => this._messageService.setSnackbar({
                                    durationInSecond: 5,
                                    severity: MessageSeverityEnum.failure,
                                    text: `Nie dodano nowej zgody`
                                })
                            ),
                            switchMap(() => this._adminConsentTabService.getConsents())
                        ).subscribe(this._setTableData);
                    } else {
                        this._adminConsentTabService.addConsent(model).pipe(
                            tap(() => this._messageService.setSnackbar({
                                    durationInSecond: 5,
                                    severity: MessageSeverityEnum.success,
                                    text: `Zgoda zmodyfikowana poprawnie`
                                }),
                                () => this._messageService.setSnackbar({
                                    durationInSecond: 5,
                                    severity: MessageSeverityEnum.failure,
                                    text: `Zgoda nie została zmodyfikowana`
                                })
                            ),
                            switchMap(() => this._adminConsentTabService.getConsents())
                        ).subscribe(this._setTableData);
                    }
                }
            );

    }

    private _initializeConsents() {
        this._adminConsentTabService.getConsents()
            .subscribe(this._setTableData)
    }

    private _setTableData = (consent: ConsentModel[]) => {
        this.consentSource = new MatTableDataSource<ConsentModel>(consent);
        this.consentSource.paginator = this.paginator;
        this.consentSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'title':
                    return item?.title && replacePolishChars(item.title) || '';
                case 'brand':
                    return this._brandsDict?.find(val => val?.id === item?.brandId).value || '';
                case 'consentType':
                    return this._consentTypesDict?.find(val => val?.id === item?.consentTypeId)?.value &&
                        replacePolishChars(this._consentTypesDict?.find(val => val?.id === item?.consentTypeId)?.value) || '';
                default:
                    return item[property];
            }
        };
        this.consentSource.sort = this.sort;
        this.consentSource.filterPredicate = (data, filter) => {
            const title = data?.title || '';
            const version = data?.version || '';
            const brand = this._brandsDict?.find(val => val?.id === data?.brandId)?.value || '';
            const consentType = this._consentTypesDict?.find(val => val?.id === data?.consentTypeId)?.value &&
                replacePolishChars(this._consentTypesDict?.find(val => val?.id === data?.consentTypeId)?.value) || '';

            let dataStr = title + version + brand + consentType;
            dataStr = dataStr && dataStr.toLowerCase() || '';
            filter = filter && filter.toLowerCase();

            return dataStr.indexOf(filter) != -1;
        };

        if (this.tempFilter) {
            this.applyFilter(this.tempFilter);
        }


    };

}
