import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerDetailsModel } from '../_models/partner-details.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ChainDetailsModel } from '../_models/chain-details.model';

export const REDIRECTION_PARTNER_MAP = 'partnerToMap';
export const REDIRECTION_CHAIN_PARTNER = 'chainToPartner';
export const REDIRECTION_PARTNER_CHAIN = 'partnerToChain';

export class RedirectionModel {
    constructor(public redirectionType: string, public value: any) {
    }
}

@Injectable()
export class RedirectionService {

    private routeStateObs;

    constructor(private readonly activatedRoute: ActivatedRoute, private readonly router: Router) {
        this.routeStateObs = this.activatedRoute.paramMap
            .pipe(map(() => window.history.state));
    }

    public navigateFromPartnerToMap(partnerDetailsModel: PartnerDetailsModel) {
        this.router.navigate(['/map'], {state: new RedirectionModel(REDIRECTION_PARTNER_MAP, partnerDetailsModel)});
    }

    public navigateToPartner(partnerDetailsModel: PartnerDetailsModel) {
        this.router.navigate(['/partner'], {state: new RedirectionModel(REDIRECTION_PARTNER_MAP, partnerDetailsModel)});
    }

    public navigateToPartnersWithFilters(chain: ChainDetailsModel, brandToFilter?: number, coopStatuses?: Array<number>) {
        this.router.navigate(['/partner'], {
            state: new RedirectionModel(REDIRECTION_CHAIN_PARTNER, {
                'chain': chain,
                'brand': brandToFilter,
                'cooperations': coopStatuses
            })
        });
    }

    public navigateToChain(chain: ChainDetailsModel) {
        this.router.navigate(['/chains'], {state: new RedirectionModel(REDIRECTION_PARTNER_CHAIN, chain)});
    }

    public getRoutingState(): Observable<any> {
        return this.routeStateObs;
    }

}
