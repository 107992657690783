import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import { canViewMap } from '../../_utils/auth-utils/auth-map';

@Injectable()
export class MapGuardService implements CanActivate {
    constructor(private readonly _authenticationService: AuthenticationService, private readonly _router: Router) {

    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (canViewMap(this._authenticationService.getCurrentUserRoles())) {
            return true;
        }
        return false;
    }


}
