import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import * as cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { MessageSeverityEnum } from '../../_enums/message-severity.enum';
import { DictModel } from '../../_models/dict.model';
import { PersonModel } from '../../_models/person.model';
import { TaskModel } from '../../_models/task.model';
import { AuthenticationService } from '../../_services/authentication.service';
import { DictionariesService } from '../../_services/dictionaries.service';
import { MessageService } from '../../_services/message.service';
import { TaskService } from '../../_services/task.service';
import { UserService } from '../../_services/user.service';

@Component({
    selector: 'task-dialog',
    templateUrl: './task-dialog.component.html',
    styleUrls: ['./task-dialog.component.scss']
})
export class TaskDialogComponent implements OnInit {

    public readonly isEditDialog = !!this.task;
    public isNotEditing = true;
    public prioritiesDict: Array<DictModel>;
    public statusesDict: Array<DictModel>;
    public allUsersDict: Array<PersonModel>;
    public filteredAssignees: Array<PersonModel> = [];
    public partnersEditing = true;
    public compareFn: ((f1: any, f2: any) => boolean) | null = this.compareByValue;
    protected form: FormGroup;
    protected chipOption: number[] = [ENTER, COMMA];
    protected filteredPartnerList$: Observable<DictModel[]>;
    protected chipsPartners: Array<DictModel> = [];
    @ViewChild('partner', {static: true}) private partnerAutoComplete: MatAutocomplete;
    @ViewChild('partnerInput', {static: true}) private partnerInput: ElementRef<HTMLInputElement>;

    constructor(@Inject(MAT_DIALOG_DATA) public task: TaskModel,
                public readonly authService: AuthenticationService,
                private readonly dictService: DictionariesService,
                private readonly personService: UserService,
                private messageService: MessageService,
                private readonly taskService: TaskService,
                private dialogRef: MatDialogRef<TaskDialogComponent>) {
    }

    ngOnInit(): void {

        this.isNotEditing = !!this.task;
        this.form = new FormGroup(
            {
                titleCtrl: new FormControl({
                    value: this.task ? this.task.title : '',
                    disabled: this.isEditDialog
                }, Validators.required),
                descCtrl: new FormControl({
                    value: this.task ? this.task.description : '',
                    disabled: this.isEditDialog
                }, Validators.required),
                dueDateCtrl: new FormControl({
                    value: this.task ? this.task.dueDate : '',
                    disabled: this.isEditDialog
                }, Validators.required),
                statusCtrl: new FormControl({
                    value: this.task ? this.task.status : '',
                    disabled: this.isEditDialog
                }, Validators.required),
                priorityCtrl: new FormControl({
                    value: this.task ? this.task.priority : '',
                    disabled: this.isEditDialog
                }, Validators.required),
                assigneeCtrl: new FormControl({
                    value: this.task ? this.task.assignee : '',
                    disabled: this.isEditDialog
                }, Validators.required),
                assigneeCtrlSearch: new FormControl(''),
                partnerCtrl: new FormControl({value: [], disabled: this.isEditDialog}),
            }
        );
        this.chipsPartners = !!this.task ? cloneDeep(this.task.partners) : [];
        this.partnersEditing = !this.isEditDialog;


        this.dictService.getTaskPriorities().subscribe(prioritiesDict => this.prioritiesDict = prioritiesDict);
        this.dictService.getTaskStatuses().subscribe(statusesDict => this.statusesDict = statusesDict);
        this.personService.getAllUsers().subscribe(value => {
            this.allUsersDict = value;
            this.filteredAssignees = value;
        });
        this.filteredPartnerList$ = this.form.get('partnerCtrl').valueChanges
            .pipe(
                debounceTime(300),
                switchMap(value => this.dictService.getPartnerByPhraseOfLength(value, 3))
            );

        this.form.get('assigneeCtrlSearch').valueChanges.subscribe(() => this.filterAssignees());
    }

    public filterAssignees() {
        if (!this.allUsersDict) {
            return;
        }
        let search = this.form.get('assigneeCtrlSearch').value;
        if (!search) {
            this.filteredAssignees = this.allUsersDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredAssignees = this.allUsersDict.filter(user => user.firstName.toLowerCase().indexOf(search) > -1);
    }

    public enableEditing() {
        this.isNotEditing = false;
        this.form.get('titleCtrl').enable();
        this.form.get('descCtrl').enable();
        this.form.get('dueDateCtrl').enable();
        this.form.get('assigneeCtrl').enable();
        this.form.get('priorityCtrl').enable();
        this.form.get('statusCtrl').enable();
        this.partnersEditing = true;
    }

    public submitDialog() {

        if (!this.form.invalid) {
            if (this.isEditDialog) {
                this.taskService.updateTask(this.prepareModel()).subscribe();
            } else {
                this.taskService.saveTask(this.prepareModel()).subscribe();
            }
            this.dialogRef.close(true);
        } else {
            this.messageService.setSnackbar({
                severity: MessageSeverityEnum.failure,
                durationInSecond: 5,
                text: 'Wypełnij wszystkie wymagane pola!'
            });
        }
    }

    protected addPartner(event: MatChipInputEvent): void {
        if (!this.partnerAutoComplete.isOpen) {
            if (event.input) {
                event.input.value = '';
            }
            this.form.get('partnerCtrl').setValue(null);
        }
    }

    protected removePartner(partner: DictModel): void {
        const index = this.chipsPartners.indexOf(partner);

        if (index >= 0) {
            this.chipsPartners.splice(index, 1);
        }
    }

    protected selectedPartner(event: MatAutocompleteSelectedEvent): void {
        this.chipsPartners.push({id: event.option.value, value: event.option.viewValue});
        this.partnerInput.nativeElement.value = '';
        this.form.get('partnerCtrl').setValue(null);
    }

    private compareByValue(f1: DictModel, f2: DictModel) {
        return f1 && f2 && f1.id === f2.id;
    }

    private prepareModel() {
        const task = new TaskModel();
        if (this.isEditDialog) {
            task.id = this.task.id;
            task.creationDate = this.task.creationDate;
        }
        task.title = this.form.get('titleCtrl').value;
        task.description = this.form.get('descCtrl').value;
        task.dueDate = this.form.get('dueDateCtrl').value;
        task.assignee = this.form.get('assigneeCtrl').value;
        task.priority = this.form.get('priorityCtrl').value;
        task.status = this.form.get('statusCtrl').value;
        task.partners = this.chipsPartners;
        return task;
    }

}
