import {Component, Inject, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {BrandCooperationDeliverAddressModel} from '../../../../_models/brand-cooperation-deliver-address.model';
import {HttpClient} from '@angular/common/http';
import {BrandCooperationService} from '../../../../_services/brand-cooperation.service';

@Component({
    selector: 'app-delivery-address',
    templateUrl: './delivery-address-table.component.html',
    styleUrls: ['delivery-address-table.component.scss']
})
export class DeliveryAddressTableComponent implements OnInit {

    deliveryAddressColumns: string[] = [
        'street',
        'postalCode',
        'town',
        'district',
        'mainAddress',
    ];

    @Input()
    private brandId : number

    datasource : MatTableDataSource<BrandCooperationDeliverAddressModel> = new MatTableDataSource<BrandCooperationDeliverAddressModel>([])

    constructor(private http: HttpClient,
                private brandService: BrandCooperationService) {
    }

    ngOnInit(): void {
        if (this.brandId > 0) {
            this.brandService.getBrandsDeliveryAddress(this.brandId).subscribe(data => {
                this.datasource = new MatTableDataSource<BrandCooperationDeliverAddressModel>(data)
            })
        }
    }

}
