import { AuthRolesEnum, canPerformAction } from './auth-commons';

/**
 * Being able to view finances means being able to enter finances page.
 *
 */
export const canViewFinances = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canEditFinances = (roles: number[]): boolean => {
    return false;
}