import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChainDetailsScreenService } from '../chain-details-screen.service';

@Component({
  selector: 'app-confirm-removing-chain-dialog',
  templateUrl: './confirm-removing-chain-dialog.component.html',
  styleUrls: ['./confirm-removing-chain-dialog.component.scss']
})
export class ConfirmRemovingChainDialogComponent {

  constructor(private readonly detailsScreenService: ChainDetailsScreenService,
              private _dialogRef: MatDialogRef<ConfirmRemovingChainDialogComponent>) {
  }

  protected removeChain() {
    this._dialogRef.close(true);
  }

  protected doNotRemove() {
    this._dialogRef.close(false);
  }

}
