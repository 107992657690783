import {ColumnElementModel} from '../_models/column-element.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ChipFiltersUtils} from '../_utils/chip-filters.utils';
import {PageParams, ScreenService} from '../_services/screen.service';
import {tap} from 'rxjs/operators';
import {ScreenConfigModel} from '../_models/screen-config.model';
import {ConfigModel} from '../_models/config.model';
import {ChipElement} from '../filter-chips/chip-component/chip.component';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {ScreenTypeEnum} from '../_enums/screen-type.enum';
import {Observable} from 'rxjs/index';
import {Subject} from 'rxjs';
import * as _ from 'lodash';
import {InitialObjectForProcuratorDetails} from '../procurators/procurators.service';
import {EntrepreneurDetailsDialogModel} from './entrepreneur-details-dialog/entrepreneur-details-dialog.model';
import {EntrepreneurDetailsModel} from '../_models/entrepreneur-details.model';
import {EntrepreneurDeliveryAddressModel} from '../_models/entrepreneur-delivery-address.model';
import {EntrepreneurBrandCooperationModel} from '../_models/entrepreneur-brand-cooperation.model';
import {RepresentativeModel} from '../_models/representative.model';
import {EntrepreneurWspolnikModel} from '../_models/entrepreneur-wspolnik.model';
import {EntrepreneurBulkUpdateModel} from '../_models/entrepreneur-bulk-update.model';
import {DialogResultEnum} from '../_enums/dialog-result.enum';
import {EntrepreneurDetailsDialogComponent} from './entrepreneur-details-dialog/entrepreneur-details-dialog.component';
import {MatDialog} from '@angular/material/dialog';

const API_URL = environment.apiUrl + '/api/concern';
const REPRESENTATIVE_API_URL = environment.apiUrl + '/api/representative';
const SCREEN_API_URL = environment.apiUrl + '/api/screen';

export class InitialObjectForEntrepreneurDetails {
    public id: number;
    public canEdit: boolean;
}

@Injectable()
export class EntrepreneursService {
    public requestsToInitLeft: number = 0;

    public size: number = 15;
    public page = 1;
    public pages: number;
    public totalElements: number;
    public rowsForActiveConfiguration: Array<Array<string>> = [];
    public sortQueryParam: string = null;
    public configurationMap: Map<string, ScreenConfigModel> = new Map<string, ScreenConfigModel>();
    public activeConfiguration: ScreenConfigModel = new ScreenConfigModel(
        null,
        null,
        null,
        null,
        new ConfigModel([], null, [], []));

    private showEntrepreneurDetails$: Subject<InitialObjectForEntrepreneurDetails> = new Subject<InitialObjectForEntrepreneurDetails>();

    public filtersForActiveConfiguration: Array<ChipElement> = [];

    constructor(public http: HttpClient, private _dialog: MatDialog, public readonly screenService: ScreenService) { }


    public getShowEntrepreneurDetails(): Observable<InitialObjectForEntrepreneurDetails> {
        return this.showEntrepreneurDetails$.asObservable();
    }

    public getUpdatedData(visibleColumnsOrdered: ColumnElementModel[] = this.activeConfiguration.configJson.visibleColumnsOrdered) {
        let params: HttpParams = null;
        if (this.activeConfiguration.configJson.filters && this.activeConfiguration.configJson.filters.length > 0) {
            this.filtersForActiveConfiguration = this.activeConfiguration.configJson.filters;

            params = ChipFiltersUtils.prepareParams([...this.filtersForActiveConfiguration, ...ChipFiltersUtils.ENTREPRENEUR_STATIC_FILTERS]);
        }

        const pageParams: PageParams = {
            url: API_URL + "/all",
            page: this.page,
            size: this.size,
            columnsOrder: visibleColumnsOrdered,
            sortQueryParam: this.sortQueryParam
        };

        // this.screenService.getUpdatedData(pageParams, params).pipe(
        //     tap(data => this.setInitialData(data))
        // ).subscribe(data => this.setInitialData(data));
        this.screenService.getUpdatedData(pageParams, params)
            .subscribe(data => this.setInitialData(data));
    }

    public getScreenData() : Observable<Array<ScreenConfigModel>> {
        return this.http.get<Array<ScreenConfigModel>>(`${SCREEN_API_URL}/?screenType=${ScreenTypeEnum.concerns}`);
    }

    public setActiveConfiguration(model: ScreenConfigModel): void {
        this.activeConfiguration = Object.assign({}, model);
    }

    protected setInitialData(data): void {
        this.rowsForActiveConfiguration = this.screenService.setRows(data, this.activeConfiguration.configJson.visibleColumnsOrdered);


        this.pages = data['totalPages'];
        this.totalElements = data['totalElements'];
        this.page = data['pageable'] ? data['pageable']['pageNumber'] : 0;

    }

    public showEntrepreneurDetails(elementId, canEdit?: boolean) {
        this.showEntrepreneurDetails$.next({id: elementId, canEdit: !!canEdit})
    }

    public getEntrepreneurDetails(id: number) : Observable<EntrepreneurDetailsModel> {
        return this.http.get<EntrepreneurDetailsModel>(`${API_URL}/${id}`);
    }

    public updateEntrepreneurDetails(model: EntrepreneurDetailsModel): Observable<EntrepreneurDetailsModel> {
        if (model.id) {
            return this.http.put<EntrepreneurDetailsModel>(`${API_URL}/${model.id}`, model);
        } else {
            return this.http.post<EntrepreneurDetailsModel>(`${API_URL}`, model);
        }
    }

    public getEntrepreneurDeliveryAddress(id: number) : Observable<Array<EntrepreneurDeliveryAddressModel>> {
        return this.http.get<Array<EntrepreneurDeliveryAddressModel>>(`${API_URL}/${id}/deliveryAddress`);
    }

    public getEntrepreneurBrands(id: number) : Observable<Array<EntrepreneurBrandCooperationModel>> {
        return this.http.get<Array<EntrepreneurBrandCooperationModel>>(`${API_URL}/${id}/brands`);
    }

    public updateEntrepreneurBrands(id: number, models : Array<EntrepreneurBrandCooperationModel>) : Observable<Array<EntrepreneurBrandCooperationModel>> {
        return this.http.put<Array<EntrepreneurBrandCooperationModel>>(`${API_URL}/${id}/brands`, models);
    }

    public getEntrepreneurWspolnicy(id: number) : Observable<Array<EntrepreneurWspolnikModel>> {
        return this.http.get<Array<EntrepreneurWspolnikModel>>(`${API_URL}/${id}/partners`);
    }

    public updateEntrepreneurWspolnicy(id: number, wspolnikModels : Array<EntrepreneurWspolnikModel>): Observable<Array<EntrepreneurWspolnikModel>> {
        return this.http.put<Array<EntrepreneurWspolnikModel>>(`${API_URL}/${id}/partners`, wspolnikModels);
    }

    public getEntrepreneurRepresentative(id: number) : Observable<Array<RepresentativeModel>> {
        return this.http.get<Array<RepresentativeModel>>(`${REPRESENTATIVE_API_URL}/concern/${id}`);
    }

    public updateEntrepreneurRepresentative(id: number, representative : Array<RepresentativeModel>): Observable<Array<RepresentativeModel>> {
        return this.http.put<Array<RepresentativeModel>>(`${REPRESENTATIVE_API_URL}/concern/${id}`, representative);
    }

    public entrepreneurBulkUpdate(id: number, bulk : EntrepreneurBulkUpdateModel): Observable<EntrepreneurBulkUpdateModel> {
        return this.http.put<EntrepreneurBulkUpdateModel>(`${API_URL}/update/${id}`, bulk);
    }

    public createEntrepreneur(entrepreneurDetailsModel: EntrepreneurDetailsModel) : Observable<EntrepreneurDetailsModel> {
        return this.http.post<EntrepreneurDetailsModel>(`${API_URL}`, entrepreneurDetailsModel);
    }
}
