import { EventEmitter, Injectable } from '@angular/core';


@Injectable()
export class NavbarService {

    public navbarClickedEmitter: EventEmitter<void>;


    constructor() {
        this.navbarClickedEmitter = new EventEmitter();
    }


    emitNavbarClicked() {
        this.navbarClickedEmitter.emit();
    }

}
