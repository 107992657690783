import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-remove-consent-dialog',
    templateUrl: './confirm-removing-consent-dialog.component.html',
    styleUrls: ['./confirm-removing-consent-dialog.component.scss']
})
export class ConfirmRemovingConsentDialogComponent {


    constructor(private _dialogRef: MatDialogRef<ConfirmRemovingConsentDialogComponent>) {
    }

    protected removeConsent() {
        this._dialogRef.close(true);
    }

    protected doNotRemove() {
        this._dialogRef.close(false);
    }


}
