import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ColumnElementModel } from '../_models/column-element.model';
import { ScreenConfigModel } from '../_models/screen-config.model';


export class PageParams {
    url: string;
    page: number = 1;
    size: number = 15;
    columnsOrder: Array<ColumnElementModel> = [];
    sortQueryParam?: string;
}


@Injectable()
export class ScreenService {

    constructor(private readonly http: HttpClient) {

    }


    public getUpdatedData(pageParams: PageParams, httpParams: HttpParams = null) {
        let params = this.getRequestParams(pageParams, httpParams);
        return this.http.get(pageParams.url, {params});
    }

    public updateScreenData(apiUrl: string, model: ScreenConfigModel): Observable<ScreenConfigModel> {
        return this.http.put<ScreenConfigModel>(`${apiUrl}/${model.id}`, model);
    }

    public setDefaultScreenConfigData(apiUrl: string, model: ScreenConfigModel): Observable<ScreenConfigModel> {
        return this.http.put<ScreenConfigModel>(`${apiUrl}/${model.id}/default`, model);
    }

    public saveScreenData(apiUrl: string, model: ScreenConfigModel): Observable<ScreenConfigModel> {
        return this.http.post<ScreenConfigModel>(`${apiUrl}/`, model);
    }

    public setRows(data, visibleColumnOrdered: Array<ColumnElementModel>): Array<Array<string>> {
        let rowsForActiveConfiguration = [];
        data['content'].forEach(element => {
            const row: Array<string> = [];
            row.push(element['id']);
            row.push(element['monthsOfInactivity']);
            visibleColumnOrdered.forEach((header: ColumnElementModel) => {
                row.push(element[header.systemName]);
            });
            rowsForActiveConfiguration.push(row);
        });
        return rowsForActiveConfiguration;
    }

    private getRequestParams(pageParams: PageParams, httpParams: HttpParams = null): HttpParams {
        let params = httpParams || new HttpParams();

        params = params.append('page', pageParams.page && pageParams.page.toString() || '1');
        params = params.append('size', pageParams.size.toString());
        if (pageParams.sortQueryParam) {
            params = params.append('sort', pageParams.sortQueryParam);
        }
        params = params.append('columns', 'id');
        pageParams.columnsOrder.forEach(
            column => {
                params = params.append('columns', column.systemName);
            }
        );
        return params;
    }

}
