import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ScreenService} from '../_services/screen.service';
import {ProcuratorDetailsModel} from '../_models/procurator-details.model';
import {environment} from '../../environments/environment';
import {BrandCooperationModel} from '../_models/brand-cooperation.model';
import {PartnerDetailsDialogModel} from '../partners/partner-details-dialog/partner-details-dialog.model';
import {PartnerProcuratorDetailsModel} from '../_models/partner-procurator-details.model';
import {ProcuratorFileModel} from '../_models/procurator-file.model';

const API_URL = environment.apiUrl + '/api/procurator';

export class InitialObjectForProcuratorDetails {
    public id: number;
    public canEdit: boolean;
    public defaultTab?: number;
}

@Injectable()
export class ProcuratorsService {

    private showProcuratorDetails$: Subject<InitialObjectForProcuratorDetails> = new Subject<InitialObjectForProcuratorDetails>();

    constructor(public http: HttpClient, private readonly screenService: ScreenService) {
    }

    public showProcuratorDetails(elementId: number, canEdit?: boolean, defaultTab?: number) {
        this.showProcuratorDetails$.next({id: elementId, canEdit: !!canEdit, defaultTab: defaultTab || 0})
    }

    public getShowProcuratorDetails(): Observable<InitialObjectForProcuratorDetails> {
        return this.showProcuratorDetails$.asObservable();
    }

    public getProcuratorDetails(procuratorId: number): Observable<ProcuratorDetailsModel> {
        return this.http.get<ProcuratorDetailsModel>(API_URL + "/" + procuratorId);
    }

    public getProcurators(partnerId: number = null): Observable<Array<ProcuratorDetailsModel>> {
        let params: HttpParams = new HttpParams();
        if (partnerId) {
            params = params.append('partnerId', partnerId.toString());
        }
        return this.http.get<Array<ProcuratorDetailsModel>>(API_URL, {params});
    }

    public getPartnerProcurators(partnerId: number): Observable<Array<PartnerProcuratorDetailsModel>> {
        return this.http.get<Array<PartnerProcuratorDetailsModel>>(API_URL + "/partner/" + partnerId);
    }

    public updatePartnerProcurators(partner: number, procuratorIds: number[]): Observable<any> {
        return this.http.put<any>(API_URL + "/partner/" + partner, procuratorIds);
    }

    public newProcurator(procurator: ProcuratorDetailsModel): Observable<ProcuratorDetailsModel> {
        return this.http.post<ProcuratorDetailsModel>(API_URL, procurator);
    }

    public sendPartnerOWSAcceptRequest(partnerId: number) {
        return this.http.post(environment.apiUrl + `/api/partner/${partnerId}/soz/acceptOWSRequest`, null, {responseType: 'text'})
    }

    public updateProcurator(procurator: ProcuratorDetailsModel) {
        return this.http.patch<any>(API_URL + "/" + procurator.id, procurator);
    }

    public getProcuratorFiles(procuratorId: number): Observable<Array<ProcuratorFileModel>> {
        return this.http.get<Array<ProcuratorFileModel>>(API_URL + "/" + procuratorId + "/files")
    }
}