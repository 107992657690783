import { BrandCooperationModel } from '../_models/brand-cooperation.model';
import { ChainDetailsModel } from '../_models/chain-details.model';
import { ContactModel } from '../_models/contact.model';
import { ExternalPersonModel } from '../_models/external-person.model';
import { PartnerDetailsModel } from '../_models/partner-details.model';
import { PartnerDetailsDialogModel } from '../partners/partner-details-dialog/partner-details-dialog.model';

export class ValidationUtils {


    public static isSozLoginValid(partnersDetailsDialogModel: PartnerDetailsDialogModel): boolean {
        if (partnersDetailsDialogModel?.partnerDetailsModel?.sozLogin) {
            return true;
        }

        const isAnyBrandInCooperation = partnersDetailsDialogModel?.brandCooperationModel?.some(value => value.cooperationStatusId === 1) ||
            partnersDetailsDialogModel?.addedBrandCooperation?.some(value => value.cooperationStatusId === 1);

        return !isAnyBrandInCooperation;
    }

    public static arePartnersValid(partnerDetailsModel: PartnerDetailsModel,
                                   partnerDetailsRequiredFields: string[] = [
                                       'name',
                                       'creationDate',
                                       'infoSourceId',
                                       'partnerTypeId',
                                       'phone',
                                       'email'
                                   ]): boolean {

        const partnerDetailsAddressReqFields = [
            'street',
            'postalCode',
            'town',
            'districtId',
        ];

        const partnerDetailsInvalid = partnerDetailsRequiredFields.some((reqField: string) => {
            return !partnerDetailsModel[reqField];
        });

        const partnerAddressInvalid = partnerDetailsModel.address ? partnerDetailsAddressReqFields.some((reqField: string) => {
            return !partnerDetailsModel.address[reqField];
        }) : false;

        const postalCodeRegex = RegExp(/^[0-9]{2}-[0-9]{3}$/);

        let partnerNipValid = true;
        if (partnerDetailsModel.nip && partnerDetailsModel.nip.length > 0) {
            const nipRegex = RegExp(/^[0-9]{10}$/);
            partnerNipValid = nipRegex.test(partnerDetailsModel.nip);
        }

        const partnerPostCodeValid = postalCodeRegex.test(partnerDetailsModel.address.postalCode);

        return !partnerAddressInvalid && !partnerDetailsInvalid && partnerPostCodeValid && partnerNipValid;
    }

    public static areBrandsValid(brandCooperationModel: Array<BrandCooperationModel>, addedBrands: Array<BrandCooperationModel> = null, showLogs = [false, false, false, false]): boolean {
        const brandCooperationConditionallyRequiredFields = {
            //'isOnlineSeller': ['webPage', 'dateOfOnlineSalesStart'],
        };

        const brandCooperationConditionallyRequiredWhenStatus = {
            1: ['onBoarderId', 'financialId'],
            3: ['onBoarderId'],
            2: ['endDate', 'cooperationEndNote'],
            7: ['endDate', 'cooperationEndNote', 'onBoarderId', 'financialId'],
        };

        const brandCooperationRequiredFields: Array<string> = [
            'brandId',
            'cooperationStatusId',
            'ownerId',
            'toaId',
        ];

        const valid: boolean = !brandCooperationModel || !brandCooperationModel.some((brandCooperationModel: BrandCooperationModel) => {
                return !ValidationUtils.arePartnerContactsValid(brandCooperationModel.peopleContacts) || brandCooperationRequiredFields.some((reqField: string) => {
                    showLogs[0] && console.log('brandValidCheck', reqField, brandCooperationModel[reqField]);
                    return !brandCooperationModel[reqField];
                });
            }
        );

        const brandCooperationConditionalsInvalid = Object.keys(brandCooperationConditionallyRequiredFields).some(key => {
                return brandCooperationModel.some(
                    (brandMdl: BrandCooperationModel) => {
                        showLogs[1] && console.log('brandCooperationConditionalsInvalid outerCheck', key, brandMdl[key]);
                        return brandMdl[key] && brandCooperationConditionallyRequiredFields[key].some((reqField: string) => {
                                showLogs[2] && console.log('brandCooperationConditionalsByStatusInvalid innerCheck', reqField, brandMdl[reqField]);
                                return !brandMdl[reqField];
                            }
                        );
                    }
                );
            }
        );

        const brandCooperationConditionalsByStatusInvalid = Object.keys(brandCooperationConditionallyRequiredWhenStatus).some(key => {
                return brandCooperationModel.some(
                    (brandMdl: BrandCooperationModel) => {
                        showLogs[2] && console.log('brandCooperationConditionalsByStatusInvalid outerCheck', key, brandMdl[key]);
                        return brandMdl.cooperationStatusId === +key && brandCooperationConditionallyRequiredWhenStatus[key].some((reqField: string) => {
                                showLogs[2] && console.log('brandCooperationConditionalsByStatusInvalid innerCheck', reqField, brandMdl[reqField]);

                                // if (reqField == 'onboardingDate' && brandMdl.cooperationWithoutAgreement) {
                                //     return false
                                // }

                                return !brandMdl[reqField];
                            }
                        );
                    }
                );
            }
        );

        const brandCooperationConditionalsByCooperationStatusInvalid = brandCooperationModel.some((brandMdl: BrandCooperationModel) => {
            if (brandMdl.cooperationStatusId === 1 && [3, 4].includes(brandMdl.cooperationTypeId)) {
                return brandMdl.onboardingDate === null;
            }
            return false;
        });

        if (addedBrands && addedBrands.length > 0) {
            const validAdded: boolean = !addedBrands || !addedBrands.some((addedBrands: BrandCooperationModel) => {
                    return !ValidationUtils.arePartnerContactsValid(addedBrands.peopleContacts) || brandCooperationRequiredFields.some((reqField: string) => {
                        showLogs[0] && console.log('brandValidCheckAdded', reqField, addedBrands[reqField]);
                        return !addedBrands[reqField];
                    });
                }
            );

            const brandCooperationConditionalsInvalidAdded = Object.keys(brandCooperationConditionallyRequiredFields).some(key => {
                    return addedBrands.some(
                        (brandMdl: BrandCooperationModel) => {
                            showLogs[1] && console.log('brandCooperationConditionalsInvalidAdded outerCheck', key, brandMdl[key]);
                            return brandMdl[key] && brandCooperationConditionallyRequiredFields[key].some((reqField: string) => {
                                    showLogs[2] && console.log('brandCooperationConditionalsByStatusInvalidAdded innerCheck', reqField, brandMdl[reqField]);
                                    return !brandMdl[reqField];
                                }
                            );
                        }
                    );
                }
            );

            const brandCooperationConditionalsByStatusInvalidAdded = Object.keys(brandCooperationConditionallyRequiredWhenStatus).some(key => {
                    return addedBrands.some(
                        (brandMdl: BrandCooperationModel) => {
                            showLogs[2] && console.log('brandCooperationConditionalsByStatusInvalidAdded outerCheck', key, brandMdl[key]);
                            return brandMdl.cooperationStatusId === +key && brandCooperationConditionallyRequiredWhenStatus[key].some((reqField: string) => {
                                    showLogs[2] && console.log('brandCooperationConditionalsByStatusInvalidAdded innerCheck', reqField, brandMdl[reqField]);

                                    // if (reqField == 'onboardingDate' && brandMdl.cooperationWithoutAgreement) {
                                    //     return false
                                    // }

                                    return !brandMdl[reqField];
                                }
                            );
                        }
                    );
                }
            );
            return valid && !brandCooperationConditionalsInvalid && !brandCooperationConditionalsByStatusInvalid && validAdded && !brandCooperationConditionalsInvalidAdded && !brandCooperationConditionalsByStatusInvalidAdded;

        }

        showLogs[3] && console.log('brandCoopPlainFields Valid: ', valid, 'brandCooperationConditionalsValid:', !brandCooperationConditionalsInvalid, 'brandCooperationConditionalsByStatusValid: ', !brandCooperationConditionalsByStatusInvalid);

        return valid && !brandCooperationConditionalsInvalid && !brandCooperationConditionalsByStatusInvalid && !brandCooperationConditionalsByCooperationStatusInvalid;
    }


    public static arePlainContactsValid(plainContacts: Array<ContactModel>, showLogs = false): boolean {

        const plainContactRequiredFields: Array<string> = [
            'phoneNo',
            'email',
            'description',
        ];

        return !plainContacts || !plainContacts.some((plainContact: ContactModel) =>
            plainContactRequiredFields.some((reqField: string) => {
                showLogs && console.log('plainContactValidCheck', reqField, plainContact[reqField]);
                return !plainContact[reqField];
            })
        );
    }

    public static arePartnerContactsValid(partnerContacts: Array<ExternalPersonModel>, showLogs = false): boolean {

        const partnerContactReqFields: Array<string> = [
            'firstName',
            'familyName',
            'position',
            'email',
        ];

        return !partnerContacts || !partnerContacts.some((partnerContact: ExternalPersonModel) =>
            partnerContactReqFields.some((reqField: string) => {
                showLogs && console.log('partnerContactValidCheck', reqField, partnerContact[reqField]);
                return !partnerContact[reqField];
            })
        );
    }


    public static isChainValid(chainModel: ChainDetailsModel, showLogs = [false]): boolean {

        const chainRequiredFields: Array<string> = [
            'name',
            'ownerId'
        ];

        const isChainModelInvalid = chainRequiredFields.some((reqField: string) => {
            showLogs[0] && console.log('chainInvalidCheck', reqField, chainModel[reqField]);
            return !chainModel[reqField];
        });

        return !isChainModelInvalid;

    }

}
