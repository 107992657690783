import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'app-google-places-autocomplete',
    templateUrl: './google-places-autocomplete.component.html',
    styleUrls: ['google-places-autocomplete.component.scss']
})
export class GooglePlacesAutocompleteComponent implements AfterViewInit {

    @Input()
    public map: google.maps.Map;
    @Input()
    public label = 'Wprowadź lokalizację';
    @Output()
    onAutocompleteSelected: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter();
    @Output()
    change: EventEmitter<KeyboardEvent> = new EventEmitter();

    @ViewChild('addressInput', {static: false})
    addressInput: ElementRef;

    private autocomplete: google.maps.places.Autocomplete;

    constructor() {
    }

    ngAfterViewInit(): void {
        this.autocomplete = new google.maps.places.Autocomplete(this.addressInput.nativeElement);
        this.autocomplete.bindTo('bounds', this.map);

        // Set the data fields to return when the user selects a place.
        this.autocomplete.setFields(
            ['address_components', 'geometry', 'icon', 'name']);
        this.autocomplete.addListener('place_changed', () => this.onPlaceChanged());
    }

    private onPlaceChanged() {
        const place = this.autocomplete.getPlace();
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(17);
        this.onAutocompleteSelected.emit(place);
    }

    onInputChanges(value: KeyboardEvent) {
        this.change.emit(value);
    }
}
