import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DictModel } from '../../_models/dict.model';


@Component({
    selector: 'app-chip-select-one',
    templateUrl: './chip-select-one.component.html',
    styleUrls: ['./chip-select-one.component.scss']
})
export class ChipSelectOneComponent implements OnDestroy, OnInit, AfterViewInit {

    @Input() label: string;
    @Input() iconLess: boolean = false;
    @Input() borderless: boolean = false;
    @Input() transparent: boolean = false;
    @Input() initValue: DictModel = null;
    @Input() public options: Array<DictModel> = [];
    @Output() public emitSelected: EventEmitter<DictModel> = new EventEmitter<DictModel>();
    public selected: DictModel;
    public isOpen: boolean;
    public searchStr = '';
    @ViewChild('body', {static: true})
    public selectBody: ElementRef;
    @ViewChildren('searchInput') searchInput: QueryList<ElementRef>;
    private _destroy = new Subject();


    public constructor() {
    }

    public get visibleOptions(): Array<DictModel> {
        return this.options.filter(opt => opt.value.toLowerCase().includes(this.searchStr.toLowerCase()));
    }

    public passValues(): void {
        this.emitSelected.emit(this.selected);
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        if (!this.selectBody.nativeElement.contains(event.target) && this.isOpen) {
            this.close();
        }
    }

    public ngOnInit(): void {
        //For some irrational reason after adding and removing filter without refreshing the value of previous chip persist as json string
        if (this.initValue && typeof this.initValue !== 'string') {
            this.selected = this.initValue;
        }
    }

    ngAfterViewInit(): void {
        this.searchInput && this.searchInput.changes
            .pipe(
                takeUntil(this._destroy)
            )
            .subscribe(() => {
                this.setFocus();
            });
    }

    public ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    private setFocus() {
        if (this.searchInput.length > 0) {
            this.searchInput.first.nativeElement.focus();
        }
    }

    clearFilter(event) {
        event.preventDefault();
        event.stopPropagation();
        this.searchStr = '';
    }

    public toggleOpen(): void {
        if (this.isOpen) {
            this.close();
        } else {
            this.isOpen = true;
        }
    }

    public close(): void {
        this.isOpen = false;
        this.passValues();
    }

    public isOptionSelected(option: DictModel): boolean {
        return this.selected && this.selected.id === option.id || false;
    }


    public toggleSelected(option: DictModel, event): void {
        event.preventDefault();
        event.stopPropagation();
        this.selected = option;
        this.close();
    }

    protected presentValues(): string {
        if (this.selected) {
            return this.selected.value;
        } else {
            return 'Wybierz';
        }
    }

}
