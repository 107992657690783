import { PlotDataModel } from '../_models/plot-data-model';
import { PeriodPicker } from 'src/app/finances/period-picker/period-picker.component';

export class ChartNgModel {
    constructor(public readonly labels: Array<any>, public readonly datasets: Array<DataSetModel>) { }
}

export class DataSetModel {
    public borderColor: string;

    constructor(public readonly label: string, public readonly data: Array<any>, public readonly fill: boolean,
                readonly customColor: string) {

        this.borderColor = customColor || ChartUtil.getRandomColor();
    }
}

export class ChartUtil {

    public static generateChartModel(plotDataModel: Array<PlotDataModel>, labels: Array<string>, customColor: Array<string> = null): ChartNgModel {
        const dataSet: Array<DataSetModel> = plotDataModel.map((model: PlotDataModel, index: number) => new DataSetModel(model.name, model.series, false, customColor && customColor[index] || null));
        return new ChartNgModel(labels, dataSet);
    }

    public static getRandomColor() {
        const letters = '0123456789ABCDEF'.split('');
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    public static generateRandomColorSet(periodPicker: PeriodPicker) {
        const tempColors = [];
        const maxDataSetsCount = periodPicker && periodPicker.years && (periodPicker.years.length * 2) || 2;
        for (let i = 0; i <= maxDataSetsCount; ++i) {
            tempColors.push(this.getRandomColor());
        }
        return tempColors;
    }

    public static getChartColorsFromLocalStorage() {
        return localStorage.getItem('chartColors');
    }

    public static setChartColorsInLocalStorage(chartColors: string[]) {
        if (this.isLocalStorageExist()) {
            localStorage.setItem('chartColors', JSON.stringify(chartColors));
        }
    }

    public static isLocalStorageExist() { // Checks if local storage exists or if user disabled it
        const test = 'test' + new Date().valueOf();
        try {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        } catch (e) {
            return false;
        }
    }
}
