import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DictionariesEnum } from '../../_enums/dictionaries-enum';
import { DictModel } from '../../_models/dict.model';
import { DictionariesService } from '../../_services/dictionaries.service';


@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss']
})
export class MultiSelect implements OnDestroy, OnInit, AfterViewInit {

    @Input() label: string;
    @Input() iconLess: boolean = false;
    @Input() borderless: boolean = false;
    @Input() transparent: boolean = false;
    @Input() public options: Array<DictModel> = [];
    public typeaheadOptions: Array<DictModel> = [];
    @Input() public typeAheadDict: DictionariesEnum;
    @Input() initValue: DictModel[] = [];
    @Output() public emitSelected: EventEmitter<DictModel[]> = new EventEmitter<DictModel[]>();
    public selected: Array<DictModel> = [];
    public isOpen: boolean;
    public searchStr = '';
    @ViewChild('body', {static: true})
    public selectBody: ElementRef;
    @ViewChildren('searchInput') searchInput: QueryList<ElementRef>;
    private _destroy = new Subject();

    public constructor(private dictionariesService: DictionariesService) {
    }

    public get visibleOptions(): Array<DictModel> {
        return this.options.filter(opt => opt.value.toLowerCase()
            .includes(this.searchStr.toLowerCase()) || this.isOptionSelected(opt))
            .sort(this._sortBySelected)
    }

    clearFilter(event) {
        event.preventDefault();
        event.stopPropagation();
        this.searchStr = '';
    }

    public searchStrChange(str: string) {
        this.searchStr = str;

        console.log("searchStrChange: " + str)

        if (this.typeAheadDict && str && str.length > 2) {
            this.getDictionary(str)
                .pipe(
                    takeUntil(this._destroy)
                )
                .subscribe(dict => {
                    this.typeaheadOptions = [...this.selected,
                        ...(dict.filter(opt => this.selected?.findIndex(value => value.id === opt.id) === -1))
                    ].sort(this._sortBySelected);
                });
        } else if (this.typeAheadDict) {
            this.typeaheadOptions = this.selected;
        }
    }

    public passValues(): void {
        this.emitSelected.emit(this.selected);
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        if (!this.selectBody.nativeElement.contains(event.target) && this.isOpen) {
            this.close();
        }
    }

    trackByFn(index, dictModel: DictModel) {
        return dictModel.id;
    }

    public ngOnInit(): void {
        //For some irrational reason after adding and removing filter without refreshing the value of previous chip persist as json string
        if (this.initValue && typeof this.initValue !== 'string') {
            this.selected = this.initValue;
            this.typeaheadOptions = this.selected;
        }

    }

    ngAfterViewInit(): void {
        this.searchInput && this.searchInput.changes
            .pipe(
                takeUntil(this._destroy)
            )
            .subscribe(() => {
                this.setFocus();
            });
    }

    public ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    public toggleOpen(): void {
        if (this.isOpen) {
            this.close();
        } else {
            this.isOpen = true;
        }
    }

    public close(): void {
        this.isOpen = false;
        this.passValues();
    }

    public isOptionSelected(option: DictModel): boolean {
        return !!this.selected.find(val => option.id === val.id);
    }

    public isAllSelected(): boolean {
        if (this.options) {
            return this.selected.length === this.options.length;
        } else {
            return false;
        }
    }

    public toggleSelected(option: DictModel, event): void {
        event.preventDefault();
        event.stopPropagation();
        const optionIdx = this.selected.findIndex(val => val.id === option.id);
        if (optionIdx >= 0) {
            this.selected.splice(optionIdx, 1);
        } else {
            this.selected.push(option);
        }
        if (this.typeaheadOptions) {
            this.typeaheadOptions = this.typeaheadOptions.sort(this._sortBySelected);
        }
    }

    public toggleSelectAll(): void {
        if (this.isAllSelected()) {
            this.selected = [];
        } else {
            this.selected = this.options.slice(0);
        }
    }

    protected presentValues(): string {
        if (this.selected && this.selected.length > 0) {
            if (this.selected.length > 3) {
                return `Wybrano ${this.selected.length} elementów`;
            } else {
                return this.selected.map(elem => elem.value).toString().replace(',', ', ');
            }
        } else {
            return 'Wybierz';
        }
    }

    private _sortBySelected = (a: DictModel, b: DictModel) => {
        if (this.isOptionSelected(a) && !this.isOptionSelected(b)) {
            return -1;
        }

        if (!this.isOptionSelected(a) && this.isOptionSelected(b)) {
            return 1;
        }

        if (a.value === b.value) {
            return 0;
        }

        return a.value.localeCompare(b.value, 'pl');
        //return a.value < b.value && -1 || 1;
    }

    private setFocus() {
        if (this.searchInput.length > 0) {
            this.searchInput.first.nativeElement.focus();
        }
    }

    private getDictionary(searchPhrase: string): Observable<DictModel[]> {
        switch (this.typeAheadDict) {
            case DictionariesEnum.PARTNER_NAMES:
                return this.dictionariesService.getPartnerNamesByPhrase(searchPhrase);

            case DictionariesEnum.ENTREPRENEUR_NAMES:
                return this.dictionariesService.getEntrepreneurNamesByPhrase(searchPhrase);

            case DictionariesEnum.STREETS:
                return this.dictionariesService.getStreetByPhrase(searchPhrase);

            case DictionariesEnum.TOWNS:
                return this.dictionariesService.getTownByPhrase(searchPhrase);
        }
    }

}
