import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PartnerDetailsScreenService } from '../partner-details-screen.service';


@Component({
    selector: 'app-remove-brand-dialog',
    templateUrl: './confirm-removing-brand-dialog.component.html',
    styleUrls: ['./confirm-removing-brand-dialog.component.scss']
})
export class ConfirmRemovingBrandDialogComponent {

    constructor(private readonly detailsScreenService: PartnerDetailsScreenService,
                private _dialogRef: MatDialogRef<ConfirmRemovingBrandDialogComponent>) {
    }

    protected removeBrand() {
        this._dialogRef.close(true);
    }

    protected doNotRemove() {
        this._dialogRef.close(false);
    }


}
