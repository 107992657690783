import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { FinancialCards } from '../../_models/financial-cards';
import { PlotDataModel } from '../../_models/plot-data-model';
import { RecordModel } from '../../_models/record-model';
import { RotatingCard } from '../../_models/rotating-card';
import { RotatingProducts } from '../../_models/rotating-products.model';
import { ChartNgModel, ChartUtil } from '../../_utils/chart.util';
import { CardModel } from '../details-card/card.model';
import { FinancesService } from '../finances.service';


@Component({
    selector: 'app-finance-table',
    templateUrl: './finance-table.component.html',
    styleUrls: ['./finance-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0', minHeight: '0', display: 'none'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class FinanceTableComponent implements OnInit {

    //TODO(Konrad): tak ma byc?
    public financialCardsModelToNameMapper = {
        JANUARY: 'Styczeń',
        FEBRUARY: 'Luty',
        MARCH: 'Marzec',
        APRIL: 'Kwiecień',
        MAY: 'Maj',
        JUNE: 'Czerwiec',
        JULY: 'Lipiec',
        AUGUST: 'Sierpień',
        SEPTEMBER: 'Wrzesień',
        OCTOBER: 'Październik',
        NOVEMBER: 'Listopad',
        DECEMBER: 'Grudzień',
        SUM: 'Suma',
        DIFF_SUM: 'Różnica sum',
        AVERAGE: 'Średnia m-c',
        DIFF_AVG: "Różnica średnich m-c",
        DIFF_PERCENTAGE: 'Procentowa różnica'
    };

    color = 'accent';
    checked = false;

    incomeCardData: Array<CardModel>;
    returningCardData: Array<CardModel>;
    chartData: ChartNgModel;
    incomeChartData: ChartNgModel;
    returningChartData: ChartNgModel;
    element: FinanceRow;
    selectedTab = new FormControl(0);
    @Input() records: Array<RecordModel>;
    @Input() plotLabels: Array<string>;

    years: Array<number> = [2016, 2017];
    columnsToDisplayDb = ['name', 'district', 'town', 'street', 'owner', 'toggle'];

    systemNameToDisplayName = {
        name: 'Nazwa',
        district: 'Województwo',
        town: 'Miasto',
        street: 'Ulica',
        owner: 'Właściciel'
    };

    public expandedElement: FinanceRow | null;

    constructor(public readonly financesService: FinancesService) {
    }

    public generateRowData(element: FinanceRow) {
        this.expandedElement = this.expandedElement === element ? null : element;
        if (!this.element || this.element !== element) {
            this.element = element;
            this.incomeCardData = this.financesService.generateIncomeCardData(element.financialCards, this.financialCardsModelToNameMapper);
            this.returningCardData = this.financesService.generateReturningCardData(element.financialCards, this.financialCardsModelToNameMapper);

            this.chartData = this.getChartData(element.plotData);
            this.incomeChartData = this.getIncomeChartData(element.plotData);
            this.returningChartData = this.getReturningChartData(element.plotData);

        }

    }

    public sortData(sort: Sort) {
        let sortPrefix = null;
        if (sort.direction) {
            sortPrefix = sort.direction;
        }
        this.financesService.sortQueryParam = sortPrefix ? `${sortPrefix}-${this.systemNameToDisplayName[sort.active]}` : null;
        this.financesService.generateChartData();
    }

    public getIncomeChartData(plotDataModels: Array<PlotDataModel>): ChartNgModel {
        let incomeData = _.cloneDeep(plotDataModels);
        incomeData = incomeData.filter((plotDataModel: PlotDataModel) => plotDataModel.name.includes('Obroty'));

        return ChartUtil.generateChartModel(incomeData, this.financesService.plotLabels, this.financesService.chartColors);
    }

    public getReturningChartData(plotDataModels: Array<PlotDataModel>): ChartNgModel {
        let returningData = _.cloneDeep(plotDataModels);
        returningData = returningData.filter((plotDataModel: PlotDataModel) => plotDataModel.name.includes('Zwroty'));
        return ChartUtil.generateChartModel(returningData, this.financesService.plotLabels, this.financesService.chartColors);

    }

    public getChartData(plotDataModels: Array<PlotDataModel>): ChartNgModel {
        return this.chartData = ChartUtil.generateChartModel(plotDataModels, this.financesService.plotLabels, this.financesService.chartColors);
    }

    ngOnInit(): void {
        if (!ChartUtil.getChartColorsFromLocalStorage()) {
            this.financesService.generateRandomColorSet();
        } else {
            const colorsFromLocalSotrage = ChartUtil.getChartColorsFromLocalStorage();
            this.financesService.chartColors = JSON.parse(colorsFromLocalSotrage);
        }
    }


    public pageChange($event: PageEvent): void {
        this.financesService.size = $event.pageSize;
        this.financesService.page = $event.pageIndex + 1;
        this.financesService.generateChartData();
    }


}

export interface FinanceRow {
    name: string;
    district: string;
    town: string;
    street: string;
    owner: string;
    plotData: Array<PlotDataModel>;
    financialCards: Array<FinancialCards>;
    rotatingProductData: Array<RotatingProducts>;
    rotatingCards: Array<RotatingCard>;
}
