import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {combineLatest, of, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {canRemovePartner} from 'src/app/_utils/auth-utils/auth-partner-details-general';
import {MessageSeverityEnum} from '../../_enums/message-severity.enum';
import {BenefitModel} from '../../_models/benefit.model';
import {ConsentApprovalModel, ConsentModel} from '../../_models/consent.model';
import {CorrelatedPartnerModel} from '../../_models/correlated-partner.model';
import {DictModel} from '../../_models/dict.model';
import {AuthenticationService} from '../../_services/authentication.service';
import {BenefitService} from '../../_services/benefit.service';
import {BrandCorrelationService} from '../../_services/brand-correlation.service';
import {DictionariesService} from '../../_services/dictionaries.service';
import {MessageService} from '../../_services/message.service';
import {RedirectionService} from '../../_services/redirection.service';
import {dictCodeToValue} from '../../_utils/dict-utils';
import {ValidationUtils} from '../../_utils/validation.utils';
import {ConsentService} from '../../pages/admin-page/sub-pages/admin-consent-tab/consent.service';
import {PartnersService} from '../partners.service';
import {ArchiveFinancialIdModel} from './archive-financial-id/archive-financial-id.model';
import {ArchiveFinancialIdService} from './archive-financial-id/archive-financial-id.service';
import {PartnerDetailsDialogModel} from './partner-details-dialog.model';
import {DialogResultEnum} from '../../_enums/dialog-result.enum';
import {canViewAdminPanel} from '../../_utils/auth-utils/auth-admin-panel';
import {BrandCooperationModel} from '../../_models/brand-cooperation.model';

export interface ConsentWithApproval extends ConsentModel {
    approved: boolean;
    allContactIds: number[];
    mainContactId: number;
    allExternalContacts: DictModel[];
}

export interface BrandAndExternalContactAsDict {
    brandId: number;
    externalContacts: DictModel[];
}

@Component({
    selector: 'app-partner-details-dialog',
    templateUrl: './partner-details-dialog.component.html',
    styleUrls: ['./partner-details-dialog.component.scss']
})
export class PartnerDetailsDialogComponent implements OnInit, OnDestroy {

    public disabled: boolean = true;
    public dictBrands: Array<DictModel>;
    public breakpoint: number;
    public showInvalidFields: boolean = false;
    public correlations: Map<number, CorrelatedPartnerModel[]> = new Map();
    //CONSENTS
    public consents: ConsentWithApproval[] = [];
    //Benefits
    benefits: BenefitModel[] = [];
    public archivedData: ArchiveFinancialIdModel[];
    protected _onDestroy = new Subject<void>();

    constructor(@Inject(MAT_DIALOG_DATA) public partnerDetailsDialogModel: PartnerDetailsDialogModel,
                private readonly _dialog: MatDialog,
                public readonly redirectionService: RedirectionService,
                private readonly dictService: DictionariesService,
                private readonly messageService: MessageService,
                private readonly partnersService: PartnersService,
                private readonly brandCorrelationService: BrandCorrelationService,
                private dialogRef: MatDialogRef<PartnerDetailsDialogComponent>,
                private readonly archiveFinancialIdService: ArchiveFinancialIdService,
                public readonly authService: AuthenticationService,
                private readonly consentService: ConsentService,
                private readonly benefitService: BenefitService) {
        this.initBreakpoint();
    }

    private _currentUserRoles: number[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    get canRemovePartner(): boolean {
        return canRemovePartner(this.currentUserRoles);
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    ngOnInit(): void {

        this.archiveFinancialIdService.getArchiveData(this.partnerDetailsDialogModel.partnerDetailsModel.id).subscribe(
            value => this.archivedData = value
        )

        this.dictService.getBrandDictionary().subscribe((brandCooperation: Array<DictModel>) => {
            this.dictBrands = brandCooperation;
        });

        this.getBrandCorrelations();
        const brandIds: number[] = this.partnerDetailsDialogModel.brandCooperationModel
            .filter(brandCooperation => brandCooperation.cooperationStatusId === 1)
            .map(brandCooperation => brandCooperation.brandId);
        const externalContactForBrandId: BrandAndExternalContactAsDict[] = this.partnerDetailsDialogModel.brandCooperationModel.map(brandCooperation => {
            return {
                brandId: brandCooperation.brandId,
                externalContacts: brandCooperation.peopleContacts.map(val => ({
                    id: val.id,
                    value: `${val.email}`
                }))
            };
        })

        this.consentService.getActiveConsents(brandIds).pipe(
            takeUntil(this._onDestroy),
            switchMap((consents: ConsentModel[]) => {
                if (!consents || consents.length === 0) {
                    return combineLatest([of(null), of(null)])
                }
                const consentIds = consents.map(consent => consent.id) || null;
                return combineLatest([
                    this.consentService.getConsentApprovalsForPartner(this.partnerDetailsDialogModel.partnerDetailsModel.id, consentIds) || of([]),
                    of(consents)
                ])
            })
        ).subscribe(([consentApprovals, consents]: [ConsentApprovalModel[], ConsentModel[]]) => {
            if (!consents) {
                return;
            }

            const consentObj: ConsentWithApproval[] = consents.map(consent => {
                const mattchedApprovals: ConsentApprovalModel = consentApprovals.find(consentApproval =>
                    consentApproval.brandId === consent.brandId && consentApproval.consentId === consent.id
                );

                const approved: boolean = !!mattchedApprovals;
                const allContacts: number[] = mattchedApprovals?.allContactIds?.length && mattchedApprovals.allContactIds || null;
                const mainContactId: number = mattchedApprovals?.mainContactId ?? null;
                const consentWithApproval: ConsentWithApproval = {
                    ...consent,
                    approved,
                    allContactIds: allContacts,
                    mainContactId: mainContactId,
                    allExternalContacts: externalContactForBrandId.find(val => val.brandId === consent.brandId)?.externalContacts || []
                };
                return consentWithApproval;
            })

            this.consents = consentObj?.length && consentObj || null;
        })

        this.benefitService.getBenefitByPartnerId(this.partnerDetailsDialogModel.partnerDetailsModel.id).subscribe(
            (benefitModel: BenefitModel[]) => {
                this.benefits = benefitModel?.length && benefitModel || []
            }
        )

    }

    public getBrandName(brandId: number) {
        return dictCodeToValue(brandId, this.dictBrands);
    }

    public setColspanOrReturnDefault(colspan: number): number {
        return colspan > this.breakpoint ? this.breakpoint : colspan;
    }

    public onResize(event): void {
        if ((event.target.innerWidth <= 1024)) {
            this.breakpoint = 1;
            return;
        }
        if (event.target.innerWidth <= 1280) {
            this.breakpoint = 2;
            return;
        }
        if (event.target.innerWidth <= 1366) {
            this.breakpoint = 4;
            return;
        }
        this.breakpoint = 12;
    }

    anyBrandInCooperation(): boolean {
        return this.partnerDetailsDialogModel?.brandCooperationModel?.some(value => value.cooperationStatusId === 1) ||
            this.partnerDetailsDialogModel.addedBrandCooperation?.some(value => value.cooperationStatusId === 1)
    }

    public isFormInvalid(): boolean {
        if (!this.partnerDetailsDialogModel.partnerDetailsModel) {
            return true;
        }
        let b1 = !ValidationUtils.areBrandsValid(this.partnerDetailsDialogModel.brandCooperationModel, this.partnerDetailsDialogModel.addedBrandCooperation);
        let b2 = !ValidationUtils.arePartnersValid(this.partnerDetailsDialogModel.partnerDetailsModel);
        let b3 = !ValidationUtils.isSozLoginValid(this.partnerDetailsDialogModel);
        //console.log("B1: " + b1 + " B2: " + b2 + " B3: " + b3);
        return b1 || b2 || b3;
    }

    private clearFields() {
        const partnerDetailsModel = this.partnerDetailsDialogModel.partnerDetailsModel;
        partnerDetailsModel.name = partnerDetailsModel.name?.trim();
        partnerDetailsModel.email = partnerDetailsModel.email?.trim();
        partnerDetailsModel.sozLogin = partnerDetailsModel.sozLogin?.trim();
        if (partnerDetailsModel.address) {
            partnerDetailsModel.address.street = partnerDetailsModel.address.street?.trim();
            partnerDetailsModel.address.town = partnerDetailsModel.address.town?.trim();
        }

        this.partnerDetailsDialogModel.brandCooperationModel.forEach( b => {
            if (!b.isOnlineSeller) {
                b.etailers = [];
            }
        });

    }

    public submitDialog(close: boolean) {
        this.showInvalidFields = false;

        this.clearFields();

        if (this.isFormInvalid()) {
            this.showInvalidFields = true;
            this.messageService.setSnackbar({
                severity: MessageSeverityEnum.failure,
                durationInSecond: 5,
                text: 'Wypełnij wszystkie wymagane pola!'
            });
            return;
        }
        if (close) {
            this.partnerDetailsDialogModel.notification.next(DialogResultEnum.SAVE_AND_EXIT);
        } else {
            this.partnerDetailsDialogModel.notification.next(DialogResultEnum.SAVE);
        }
    }

    getPartnerAddress(): string {
        const address = this.partnerDetailsDialogModel.partnerDetailsModel.address;
        const street = address?.street && `${address?.street} ` || '';
        const town = address?.town && `${address?.town} ` || '';
        const apartmentNo = address?.apartmentNo && `${address?.apartmentNo} ` || '';
        const buildingNo = address?.buildingNo && `${address?.buildingNo} ` || '';
        const postalCode = address?.postalCode && `${address?.postalCode} ` || ''

        return street + buildingNo + apartmentNo + postalCode + town;
    }

    protected removePartner() {
        this.partnersService.showDeleteConfirmationDialog.next(this.partnerDetailsDialogModel.partnerDetailsModel);
        this.partnerDetailsDialogModel.notification.next(DialogResultEnum.EXIT);
    }

    private getBrandCorrelations(): void {
        this.partnerDetailsDialogModel.brandCooperationModel.forEach(coop => {
            this.brandCorrelationService.getCorrelationsForBrandCoop(coop.id)
                .subscribe(value => {
                    this.correlations.set(coop.brandId, value);
                });
        });
    }

    private initBreakpoint() {
        if ((window.innerWidth <= 1024)) {
            this.breakpoint = 1;
            return;
        }
        if (window.innerWidth <= 1280) {
            this.breakpoint = 2;
            return;
        }
        if (window.innerWidth <= 1366) {
            this.breakpoint = 4;
            return;
        }
        this.breakpoint = 12;
    }

    public canViewAdminTab(): boolean {
        return canViewAdminPanel(this.currentUserRoles);
    }

    findBrand(brand: DictModel) : BrandCooperationModel | null {
        return this.partnerDetailsDialogModel.brandCooperationModel.filter(b => b.brandId == brand.id)[0] || null;
    }
}
