import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GlobalSystemConfigModel } from '../../../../_models/global-system-config.model';
import { AdminPageService } from '../../admin-page.service';
import { AdminConfigurationTabService } from './admin-configuration-tab.service';

@Component({
    selector: 'app-admin-configuration-tab',
    templateUrl: './admin-configuration-tab.component.html',
    styleUrls: ['./admin-configuration-tab.component.scss']
})
export class AdminConfigurationTabComponent implements OnInit {

    public globalSystemConfig: GlobalSystemConfigModel;

    constructor(private readonly _adminConfigurationTabService: AdminConfigurationTabService,
                private readonly _adminPageService: AdminPageService) {
    }

    ngOnInit() {
        this._adminConfigurationTabService.getConfiguration(this._adminConfigurationTabService.MAIN_CONFIG_ID)
            .subscribe(config => this.globalSystemConfig = config);
    }

    public updateConfig(form: NgForm): void {
        if (form.valid) {
            this._adminConfigurationTabService.updateConfiguration(this.globalSystemConfig).subscribe(config => this.globalSystemConfig = config);
        }

    }


}
