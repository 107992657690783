import { environment } from '../../environments/environment';

export class ApiUtils {

    public static readonly PERSON_API_URL: string = environment.apiUrl + '/api/person';
    public static readonly PARTNER_API_URL: string = environment.apiUrl + '/api/partner';
    public static readonly EVENT_API_URL: string = environment.apiUrl + '/api/event';
    public static readonly INVITATION_API_URL: string = environment.apiUrl + '/api/invitation';
    public static readonly NOTIFICATION_API_URL: string = environment.apiUrl + '/api/notification';
    public static readonly EXTERNAL_PERSON_API_URL: string = environment.apiUrl + '/api/externalPerson';
    public static readonly TASK_API_URL: string = environment.apiUrl + '/api/task';
    public static readonly CONTACTS_HISTORY_API_URL: string = environment.apiUrl + '/api/contactsHistory';
    public static readonly BRAND_CORRELATION_API_URL: string = environment.apiUrl + '/api/correlation';


}
