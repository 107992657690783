export class PartnerProcuratorDetailsModel {
    constructor(public id: number,
                public login: string,
                public name: string,
                public lastname: string,
                public role: number,
                public active: boolean,
                public solgarAccepted: boolean,
                public terranovaAccepted: boolean) {
    }
}