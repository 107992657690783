import { Component, Input, OnInit } from '@angular/core';
import { RotatingCard } from '../../_models/rotating-card';

@Component({
    selector: 'app-rotating-card',
    templateUrl: './rotating-card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class RotatingCardComponent implements OnInit {

    header: string;
    value: string;

    public rotatingCardModelToCard = {
        INCOME_PRICE_MAX: {header: 'Największy obrót', value: 'Kwota'},
        INCOME_QUANTITY_MAX: {header: 'Największa sprzedaż', value: 'Ilość'},
        INCOME_PRODUCTS_SHARE: {header: 'Rotacja asortymentu', value: 'Ilość'},
        RETURNINGS_PRICE_MAX: {header: 'Największy zwrot', value: 'Kwota'},
        RETURNINGS_QUANTITY_MAX: {header: 'Najczęściej zwracany', value: 'Ilość'},
        RETURNING_PRODUCTS_SHARE: {header: 'Udział w zwrotach', value: 'Ilość'},
        LACK_PRODUCTS: {header: 'Udział w brakach', value: 'Ilość'},
    };

    private _cardModel: RotatingCard;

    get cardModel(): RotatingCard {
        return this._cardModel;
    }

    @Input()
    set cardModel(value: RotatingCard) {
        this._cardModel = value;
    }

    ngOnInit(): void {
        this.header = this.rotatingCardModelToCard[this.cardModel.label].header;
        this.value = this.rotatingCardModelToCard[this.cardModel.label].value;

    }
}