import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PersonModel } from '../_models/person.model';
import { ApiUtils } from '../_utils/api.utils';

@Injectable()
export class UserService {

    constructor(private readonly http: HttpClient) {
    }

    public getAllUsers(): Observable<Array<PersonModel>> {
        return this.http.get<Array<PersonModel>>(`${ApiUtils.PERSON_API_URL}`);
    }

    public updateUsers(personModel: PersonModel): Observable<PersonModel> {
        return this.http.put<PersonModel>(`${ApiUtils.PERSON_API_URL}/${personModel.id}`, personModel);
    }

    public deleteUser(userId: number): Observable<any> {
        return this.http.delete(`${ApiUtils.PERSON_API_URL}/${userId}`);
    }

    public resetPasswordForUser(userId: number): Observable<string> {
        return of('tratatata');
    }

    public addUser(personModel: PersonModel): Observable<PersonModel> {
        return this.http.post<PersonModel>(`${ApiUtils.PERSON_API_URL}`, personModel);
    }

}
