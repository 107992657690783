import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { DownloadAuthModel } from '../_models/auth/download-auth.model';

@Injectable()
export class UtilsService {
    public theme$: Subject<string> = new Subject<string>();


    constructor(private readonly http: HttpClient) {

    }

    getExcelFile() {
        return this.http.get<DownloadAuthModel>(`${environment.apiUrl}/api/auth/download`);

    }


    public setTheme(theme: string) {
        this.theme$.next(theme);
    }


}
