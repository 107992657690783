import { Component, Input } from '@angular/core';


@Component({
    selector: 'app-mat-body-sidebar',
    templateUrl: './body-with-sidebar.template.html',
    styleUrls: ['./body-with-sidebar.styles.scss']
})
export class BodyWithSidebarComponent {

    @Input()
    currentPage: string;
    @Input()
    showCloseButton: boolean;
    @Input()
    hideMenuButton: boolean = false;
}
