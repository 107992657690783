import { DictModel } from '../_models/dict.model';

export const dictCodeToValue = (id: number, dict: DictModel[]) => {
    return dict.find((entry: DictModel) => entry.id === id)?.value || '';
}

export const addNullValue = (dict: DictModel[]) => {
    dict.push({id: null, value: 'Usuń'});
    return dict;
}
