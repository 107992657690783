import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ChartResultModeEnum} from '../../_enums/chart-result-mode.enum';

@Component({
    selector: 'extended-picker',
    templateUrl: './extended-picker.html',
    styleUrls: [ './extended-picker.scss' ]
})
export class ExtendedPicker {

    placeholder = 'Wybierz opcję...';
    selectedValue: ExtendedPickerEntry;
    @Input() options : Array<ExtendedPickerEntry>
    @Output() onSelect : EventEmitter<ExtendedPickerEntry> = new EventEmitter<ExtendedPickerEntry>();

    isOptionSelected(): boolean {
        return !(this.selectedValue === undefined || this.selectedValue === null)
    }

    selectOption(option: ExtendedPickerEntry) {
        this.selectedValue = option;
        this.onSelect.emit(option)
    }
}

export class ExtendedPickerEntry {

    constructor(private name: string, private fullName: string) {
    }
}