import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChainDetailsModel } from '../../_models/chain-details.model';

@Injectable()
export class ChainDetailsScreenService {

    removingInitialized: boolean = false;

    constructor(private http: HttpClient) {
    }

    public getSubjectDetails(url: string, id: number): Observable<ChainDetailsModel> {
        return this.http.get<ChainDetailsModel>(`${url}/${id}`);
    }

    public putSubjectDetails(url: string, model: ChainDetailsModel): Observable<ChainDetailsModel> {
        return this.http.put<ChainDetailsModel>(`${url}`, model);
    }

    public removeSubject(model: ChainDetailsModel): Observable<any> {
        return this.http.delete(`api/chain/${model.id}`);
    }
}
