import { Component, ElementRef, HostListener } from '@angular/core';
import { UtilsService } from '../../_services/utils.service';


@Component({
    selector: 'app-theme-picker',
    templateUrl: './theme-picker.component.html',
    styleUrls: ['./theme-picker.component.scss']
})
export class ThemePickerComponent {
    showThemePicker: boolean = false;
    public text: String;

    constructor(private eRef: ElementRef, private readonly utilsService: UtilsService) {
    }

    @HostListener('document:click', ['$event'])
    hideOnOutsideClick(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.showThemePicker = false;
        }
    }

    public onSetTheme(theme: string): void {
        this.utilsService.setTheme(theme);
    }


}
