import { FormControl } from '@angular/forms';
import { Component, Input, EventEmitter, Output } from '@angular/core';

import { DataSerieEnum } from 'src/app/_enums/data-serie.enum';

export interface SeriesModel {
    value: DataSerieEnum;
    displayName: string;
}

@Component({
    selector: 'app-series-picker',
    templateUrl: './series-picker.component.html',
    styleUrls: ['./series-picker.component.scss'],
})
export class SeriesPickerComponent {
    seriesOptions: SeriesModel[] = [
        { value: DataSerieEnum.INCOME, displayName: 'Obroty' },
        { value: DataSerieEnum.RETURN, displayName: 'Zwroty' }
    ];
    seriesControl = new FormControl(null);

    @Input() set series(newValue: DataSerieEnum[]) {
        if (newValue !== this.seriesControl.value) {
            this.seriesControl.setValue(newValue && newValue.length > 0
                ? newValue
                : [DataSerieEnum.INCOME, DataSerieEnum.RETURN]);
        }
    }
    @Output() seriesChange = new EventEmitter<DataSerieEnum[]>();

    updateSeries() {
        this.seriesChange.emit(this.seriesControl.value);
    }
}
