import {RotatingProducts} from '../_models/rotating-products.model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {ProductDetailsModel} from '../_models/product-details.model';
import {ScreenConfigModel} from '../_models/screen-config.model';
import {ConfigModel} from '../_models/config.model';
import {ChipElement} from '../filter-chips/chip-component/chip.component';
import {environment} from '../../environments/environment';
import {ColumnElementModel} from '../_models/column-element.model';
import {ChipFiltersUtils} from '../_utils/chip-filters.utils';
import {CogElementModel} from '../_models/cog-element.model';
import {DateRange} from '../inputs/date-range-picker/date-range-picker.component';
import * as moment from 'moment';
import {ExcelUtils} from '../_utils/excel.utils';
import {DictionariesService} from '../_services/dictionaries.service';
import {ChartMarketCooperationEnum} from '../_enums/chart-market-cooperation.enum';
import {ChartSearchTypeEnum} from '../_enums/chart-search-type.enum';

const API_URL = environment.apiUrl + '/api/products';

export class ProductParams {
    url: string;
    page: number = 1;
    size: number = 15;
    columnsOrder: Array<ColumnElementModel> = [];
    sortQueryParam?: string;
}

export class InitialObjectForProductDetails {
    public id: number;
    public defaultTab?: number;
}

@Injectable()
export class ProductsService {

    public rotatingProducts: Array<RotatingProducts>;

    private showProductDetails$: Subject<InitialObjectForProductDetails> = new Subject<InitialObjectForProductDetails>();

    public updateDataSubject: Subject<boolean> = new Subject<boolean>();
    public pageSize: number = 100;
    public periodRange: DateRange = {startDate: moment(new Date()), endDate: moment(new Date())};
    public searchType: ChartSearchTypeEnum = ChartSearchTypeEnum.B2B;

    //
    public selectNames: Array<string> = [];
    public configurationMap: Map<string, ScreenConfigModel> = new Map<string, ScreenConfigModel>();
    public activeConfiguration: ScreenConfigModel = new ScreenConfigModel(
        null,
        null,
        null,
        null,
        new ConfigModel([], null, [], []));
    public previousConfiguration: ScreenConfigModel = null;
    // TODO: Ambasador Initial data tryout
    // public allAmbassadors: Array<ExternalPersonModel> = [];
    public page = 1;
    public pages: number;
    public totalElements: number;
    public rowsForActiveConfiguration: Array<Array<string>> = [];
    public summarizedRowsForActiveConfiguration: Array<string> = [];
    public sortQueryParam: string = null;
    public requestsToInitLeft = 0;
    public chipSubject: Subject<ChipElement[]> = new Subject<ChipElement[]>();
    private readonly SCREEN_API_URL = environment.apiUrl + '/api/screen';
    private PRODUCTS_API_URL = environment.apiUrl + '/api/products/with/configuration';
    public filtersValueArray: Array<ChipElement> = [];

    constructor(private http: HttpClient,
                private readonly excelUtils: ExcelUtils,
                private readonly dictionariesService: DictionariesService) {
    }

    public getProductDetails(url: string, productId: number): Observable<ProductDetailsModel> {
        return this.http.get<ProductDetailsModel>(`${url}/${productId}`);
    }

    public showProductDetails(productId: number, defaultTab?: number): void {
        this.showProductDetails$.next({id: productId, defaultTab: defaultTab || 0});
    }

    public hidePartnerDetails(): void {
        this.showProductDetails$.next(null);
    }

    public getShowProductDetails(): Observable<InitialObjectForProductDetails> {
        return this.showProductDetails$.asObservable();
    }

    public getProductsConfiguration(): Observable<Array<ScreenConfigModel>> {
        return this.http.get<Array<ScreenConfigModel>>(this.SCREEN_API_URL + `?screenType=PRODUCTS`);
    }

    public generateTable() {
        this.getProductsPage().subscribe(rotatingProducts => {
            this.rotatingProducts = rotatingProducts;
            this.totalElements = rotatingProducts.length;
            this.pages = rotatingProducts.length <= this.pageSize ? 0 : Math.ceil(rotatingProducts.length / this.pageSize);
            this.page = this.pages > 1 ? 1 : 0;

            this.prepareTableData();
        })
    }

    public getProductsPage(): Observable<Array<RotatingProducts>> {
        let params = new HttpParams();

        if (this.periodRange != null) {
            params = params.append('from', this.periodRange.startDate.toDate().toLocaleDateString('en-GB'));
            params = params.append('to', this.periodRange.endDate.toDate().toLocaleDateString('en-GB'));
        }
        params = params.append('searchType', this.searchType);

        if (this.filtersValueArray && this.filtersValueArray.length > 0) {
            params = ChipFiltersUtils.prepareParams(this.filtersValueArray, params);
        }

        return this.http.get<Array<RotatingProducts>>(`${API_URL}/all`, {params});
    }

    exportToExcel(systemNameHeaders: Array<string>) {
        let params = new HttpParams();
        let from = this.periodRange.startDate.toDate().toLocaleDateString('en-GB');
        let to = this.periodRange.endDate.toDate().toLocaleDateString('en-GB');
        params = params.append('from', from);
        params = params.append('to', to);
        params = params.append('searchType', this.searchType);

        if (this.filtersValueArray && this.filtersValueArray.length > 0) {
            params = ChipFiltersUtils.prepareParams(this.filtersValueArray, params);
        }

        systemNameHeaders.forEach(
            column => {
                params = params.append('columns', column.toString());
            }
        );

        const fileName = 'products_' + from + '-' + to +'.xlsx';
        this.excelUtils.downloadExcelFile(
            params,
            'products',
            'all',
            fileName);
    }

    // TODO: this should be moved to component.
    public prepareTableData(visibleColumnsOrdered: ColumnElementModel[] = this.activeConfiguration.configJson.visibleColumnsOrdered) {
        let rotatingProducts = this.rotatingProducts;
        let sortParams = this.sortQueryParam;

        if (sortParams == null) {
            sortParams = "desc-Nazwa"
        }

        let splitArgs = sortParams.split("-", 2);
        let cogElement = this.getCogElementFromDisplayName(splitArgs[1]);
        if (splitArgs[0] == "asc") {
            rotatingProducts = rotatingProducts.sort((a, b) => {
                let aElement = a[cogElement.systemName];
                let bElement = b[cogElement.systemName];

                if (typeof aElement === "string") {
                    return aElement.localeCompare(bElement, 'pl');
                } else {
                    return aElement < bElement ? -1 : (aElement > bElement ? 1 : 0);
                }
            })
        } else {
            rotatingProducts = rotatingProducts.sort((a, b) => {
                let aElement = a[cogElement.systemName];
                let bElement = b[cogElement.systemName];

                if (typeof bElement === "string") {
                    return bElement.localeCompare(aElement, 'pl');
                } else {
                    return aElement < bElement ? 1 : (aElement > bElement ? -1 : 0);
                }
            })
        }

        let columns: Array<string> = visibleColumnsOrdered.map((column) => column.systemName);

        let pageNumber = Math.max(this.page - 1, 0);

        let lowLimit = (pageNumber * this.pageSize);
        let upperLimit = Math.min((pageNumber + 1) * this.pageSize, rotatingProducts.length);


        let products = rotatingProducts.slice(lowLimit, upperLimit);
        // console.log("Slice: " + lowLimit + " " + upperLimit + " " + products.length)
        let rowsForActiveConfiguration: Array<Array<string>> = [];
        let summarized = new Map<string, number>();
        let summarizedRowsForActiveConfiguration : Array<string> = [];

        for (let rotatingProduct of rotatingProducts) {
            for (let columnName of columns) {

                if (rotatingProduct[columnName]) {
                    let parsedItem = rotatingProduct[columnName].toLocaleString("ru-RU").replace(",", ".").replace(/\s/g, "");
                    let v = Number(parsedItem);
                    if (!isNaN(v)) {
                        summarized.set(columnName, (summarized.has(columnName) ? summarized.get(columnName) : 0) + v);
                    }
                }
            }
        }

        for (let product of products) {
            let highlightClass = product.totalAvailability <= product.minimalReservation ? "red-border" : ""
            let arr : Array<string> = [product.id.toString(), highlightClass];
            for (let columnName of columns) {
                // zabijcie mnie za tego hacka, ale inaczej nie umiałem :(
                if (columnName == 'status') {
                    let statusName = '';
                    switch (product.status) {
                        case 2:
                            statusName = 'W'
                            break;
                        case 3:
                            statusName = 'N'
                            break;
                    }
                    arr.push(statusName)
                } else {
                    let parsedItem = product[columnName]?.toLocaleString("ru-RU");
                    if (columnName.toLowerCase().includes("percentage")) {
                        parsedItem = parsedItem + "%";
                    }
                    arr.push(parsedItem)
                }
            }
            rowsForActiveConfiguration.push(arr);
        }

        for (let column of columns) {
            if (column === "productName") {
                summarizedRowsForActiveConfiguration.push("Podsumowanie");
            } else if (["categoryIndex", "category", "waproId", "status", "releaseDate"].includes(column)) {
                summarizedRowsForActiveConfiguration.push("");
            } else if (summarized.has(column)) {
                let number = summarized.get(column);
                if (column.toLocaleLowerCase().includes("percentage")) {
                    number = number / rotatingProducts.length;
                    summarizedRowsForActiveConfiguration.push(number.toLocaleString("ru-RU") + "%")
                } else {
                    summarizedRowsForActiveConfiguration.push(number.toLocaleString("ru-RU"))
                }
            } else {
                summarizedRowsForActiveConfiguration.push("");
            }
        }

        this.page = pageNumber;
        this.rowsForActiveConfiguration = rowsForActiveConfiguration;
        this.summarizedRowsForActiveConfiguration = summarizedRowsForActiveConfiguration;
    }

    private getCogElementFromDisplayName(displayName: string) : CogElementModel {
        for (let cogElementModel of this.activeConfiguration.configJson.cogColumnsOrdered) {
            if (cogElementModel.displayName == displayName) {
                return cogElementModel;
            }
        }

        return null;
    }

    public setActiveConfiguration(model: ScreenConfigModel): void {
        this.activeConfiguration = Object.assign({}, model);
    }

    public setSelectNames(newNames: Array<string>): void {
        this.selectNames = newNames;
    }

    public setInitialConfigurationOnScreen(configurationList: Array<ScreenConfigModel>): void {
        this.activeConfiguration = Object.assign({}, configurationList.find(configuration => configuration.isDefault));
        this.selectNames = [];
        this.selectNames.push(this.activeConfiguration.name);
        this.configurationMap = new Map<string, ScreenConfigModel>(configurationList
            .sort((a, b) => a.name < b.name ? -1 : 1)
            .map(singleConfig => {
                if (!singleConfig.isDefault) {
                    this.selectNames.push(singleConfig.name);
                }
                return [singleConfig.name, singleConfig] as [string, ScreenConfigModel];
            }));
        this.configurationMap.set(this.activeConfiguration.name, this.activeConfiguration);
    }


}
