import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { EventApi } from '@fullcalendar/core/api/EventApi';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EventModel } from '../_models/event-model';
import { EventPersonModel } from '../_models/event-person-model';
import { AuthenticationService } from '../_services/authentication.service';
import { ApiUtils } from '../_utils/api.utils';
import { EventActionDialogCommunicationModel } from './event-calendar/event-calendar.service';


export class EventConfirmationModel {
    accepted?: boolean;
}

@Injectable()
export class CalendarScreenService implements OnInit {
    public currentView: string = 'dayGridMonth';
    currentUserId: number;


    public patchEventSubject: Subject<EventActionDialogCommunicationModel> = new Subject<EventActionDialogCommunicationModel>();
    public deleteEventSubject: Subject<EventActionDialogCommunicationModel> = new Subject<EventActionDialogCommunicationModel>();
    public acceptEventSubject: Subject<EventActionDialogCommunicationModel> = new Subject<EventActionDialogCommunicationModel>();
    public refreshCalendar: Subject<boolean> = new Subject<boolean>();
    private _currentUserId;
    public editDialogOpened = false;
    public addDialogOpened = false;
    private showPartnerDetails$: Subject<number> = new Subject<number>();
    private showChainDetails$: Subject<number> = new Subject<number>();
    private _showEditDialog$: Subject<EventModel> = new Subject<EventModel>();
    private _showAddDialog$: Subject<EventModel> = new Subject<EventModel>();

    constructor(private readonly http: HttpClient, private readonly authService: AuthenticationService) {
    }

    getShowEditDialog$(): Observable<EventModel> {
        return this._showEditDialog$.asObservable();
    }

    setShowEditDialog$(value: EventModel) {
        const hostId = value.extendedProps.hostId || value.hostId;
        value.editOnly = !(hostId === this.authService.currentUserValue.personId);
        this._showEditDialog$.next(value);
    }

    getShowAddDialog$(): Observable<EventModel> {
        return this._showAddDialog$.asObservable();
    }

    setShowAddDialog$(value: EventModel) {
        this._showAddDialog$.next(value);
    }

    public hideChainDetails(): void {
        this.showChainDetails$.next(null);
    }


    ngOnInit(): void {
        this.currentUserId = this.authService.currentUserValue.personId;
    }

    public getEvent(eventId: string): Observable<EventModel> {
        let params: HttpParams = new HttpParams();
        params = params.append('eventId', eventId);
        return this.http.get<EventModel>(ApiUtils.EVENT_API_URL, {params});
    }

    public getEventDescription(eventId: string): Observable<string> {
        let params: HttpParams = new HttpParams();
        params = params.append('eventId', eventId);
        return this.http.get(ApiUtils.EVENT_API_URL + "/description", {params: params, responseType: 'text'});
    }

    public getLatestEvents(): Observable<EventModel[]> {
        return this.http.get<Array<EventModel>>(ApiUtils.EVENT_API_URL + '/latest');
    }

    public getEventsByParams(personId: number, from: Moment, to: Moment): Observable<Array<EventModel>> {
        let usersPicked = localStorage.getItem(`usersCalendar${personId}`) && JSON.parse(localStorage.getItem(`usersCalendar${personId}`)) || [];
        let params: HttpParams = new HttpParams();
        usersPicked.forEach((userId: number) => params = params.append('peopleIds', userId.toString()));
        params = params.append('from', from.toDate().toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }));
        params = params.append('to', to.toDate().toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' }));

        return this.http.get<Array<EventModel>>(ApiUtils.EVENT_API_URL + '/bulk', {params});
    }

    public addEvent(eventModel: EventModel): Observable<EventModel> {
        return this.http.post<EventModel>(ApiUtils.EVENT_API_URL, eventModel);
    }

    public patchEvent(action: EventActionDialogCommunicationModel): Observable<EventModel> {
        if (action.applyToSeries) {
            return this.http.put<EventModel>(`${ApiUtils.EVENT_API_URL}/${action.eventId}/recurrence/${action.recurrenceId}`, action.eventModel);
        }
        return this.http.put<EventModel>(`${ApiUtils.EVENT_API_URL}/${action.eventId}`, action.eventModel);
    }

    public patchOnDrop(event: EventApi): Observable<EventModel> {
        return this.getEvent(event.id)
            .pipe(
                switchMap((eventModel: EventModel) => {
                    eventModel.start = moment(event.start);
                    if (event.allDay) {
                        eventModel.end = event.end && moment(event.end).subtract(1, 'days') || moment(event.start);
                    } else {
                        eventModel.end = event.end && moment(event.end) || null;
                    }
                    return this.http.put<EventModel>(`${ApiUtils.EVENT_API_URL}/${eventModel.id}`, eventModel);
                })
            );
    }

    public copyOnDrop(event: EventApi, currentUser: number): Observable<EventModel> {
        return this.getEvent(event.id)
            .pipe(
                switchMap((eventModel: EventModel) => {
                    eventModel.id = null;
                    eventModel.hostId = currentUser;
                    eventModel.host = event.extendedProps.host;
                    eventModel.start = moment(event.start);
                    if (eventModel.extendedProps.eventReminders) {
                        eventModel.extendedProps.eventReminders.forEach(value => value.id = null);
                    }
                    if (eventModel.extendedProps.eventRecurrenceDTO) {
                        eventModel.extendedProps.eventRecurrenceDTO.id = null;
                    }
                    if (event.allDay) {
                        eventModel.end = event.end && moment(event.end).subtract(1, 'days') || moment(event.start);
                    } else {
                        eventModel.end = event.end && moment(event.end) || null;
                    }
                    eventModel.extendedProps.guests = eventModel.extendedProps.guests && eventModel.extendedProps.guests
                        .filter((guest: EventPersonModel) => !(guest.personId === currentUser)) || [];
                    return this.http.post<EventModel>(ApiUtils.EVENT_API_URL, eventModel);
                })
            );
    }


    public confirmEvent(action: EventActionDialogCommunicationModel): Observable<EventConfirmationModel> {
        if (action.applyToSeries) {
            return this.http.patch<EventConfirmationModel>(`${ApiUtils.EVENT_API_URL}/${action.eventId}/recurrence/events/person/${action.personId}`, action.confirmation);
        }
        return this.http.patch<EventConfirmationModel>(`${ApiUtils.EVENT_API_URL}/${action.eventId}/person/${action.personId}`, action.confirmation);

    }

    public deleteEvent(action: EventActionDialogCommunicationModel): Observable<any> {
        if (action.applyToSeries) {
            return this.http.delete(`${ApiUtils.EVENT_API_URL}/${action.eventId}/recurrence/${action.recurrenceId}`);
        }
        return this.http.delete(`${ApiUtils.EVENT_API_URL}/${action.eventId}`);
    }

    public patchEventConfirmation(eventId: number, personId: number, eventConfirmationModel: EventConfirmationModel): Observable<EventConfirmationModel> {
        return this.http.patch<EventConfirmationModel>(`${ApiUtils.EVENT_API_URL}/${eventId}/person/${personId}`, eventConfirmationModel);
    }
}
