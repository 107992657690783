import { ChartResultsResolutionEnum } from '../_enums/chart-results-resolution.enum';
import { DataSerieEnum } from '../_enums/data-serie.enum';
import { PeriodPickerModel } from './period-picker.model';
import {ChartResultModeEnum} from '../_enums/chart-result-mode.enum';
import {AnalyticsSeriesConfigModel} from './analytics-series-config.model';
import {ChartMarketCooperationEnum} from '../_enums/chart-market-cooperation.enum';
import {ChartSearchTypeEnum} from '../_enums/chart-search-type.enum';

export class AnalyticsConfigModel {
    constructor(public seriesConfigs?: AnalyticsSeriesConfigModel[],
                public periodPicker?: PeriodPickerModel,
                public resolution?: ChartResultsResolutionEnum,
                public series?: DataSerieEnum[],
                public chartResultMode?: ChartResultModeEnum,
                public searchType?: ChartSearchTypeEnum,
    ) {}
}
