import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-save-configuration',
    templateUrl: 'update-configuration.template.html',
    styleUrls: ['update-configuration.styles.scss']
})
export class UpdateConfigurationComponent implements OnInit {

    @Output()
    public configUpdateEvent = new EventEmitter<void>();

    ngOnInit(): void {
    }

    public emitUpdateEvent(): void {
        this.configUpdateEvent.emit();
    }

}
