import { AuthRolesEnum, canPerformAction } from './auth-commons';

export const canSelectTraining = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ];
    return canPerformAction(roles, hasRightsToPerformActions);
};

export const canSelectSncOrBenefit = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ];
    return canPerformAction(roles, hasRightsToPerformActions);
};

export const canSelectBrandingBenefit = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.SYSTEM_ADMIN
    ];
    return canPerformAction(roles, hasRightsToPerformActions);
};
