import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { BrandNameEnum } from '../_enums/brand-name.enum';
import { FinanceChosenViewEnum } from '../_enums/finance-chosen-view.enum';
import { ChartResultsResolutionEnum } from '../_enums/chart-results-resolution.enum';
import { MessageSeverityEnum } from '../_enums/message-severity.enum';
import { UserConfigModel } from '../_models/user-config.model';
import { AuthenticationService } from '../_services/authentication.service';
import { MessageService } from '../_services/message.service';
import { UserConfigService } from '../_services/user-config.service';
import { UtilsService } from '../_services/utils.service';
import { AvatarService } from './avatar-service';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

    @Input() firstName = '';
    @Input() lastName = '';

    public showMenu: boolean;

    constructor(private readonly eRef: ElementRef,
                private readonly authenticationService: AuthenticationService,
                private readonly router: Router,
                public readonly avatarService: AvatarService,
                private readonly userConfigService: UserConfigService,
                private readonly utilsService: UtilsService,
                private readonly messageService: MessageService) {
    }

    @HostListener('document:click', ['$event'])
    hideOnOutsideClick(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.showMenu = false;
        }
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    changePassword() {
        this.avatarService.showChangePassword = true;
    }

    openSnackbar() {
        this.messageService.setSnackbar({
            text: 'Nie udało się ustawić domyślnego wyglądu',
            severity: MessageSeverityEnum.warn,
            durationInSecond: 5
        });
    }

    ngOnInit(): void {
        this.userConfigService.getUserConfig().pipe(
            mergeMap((userConfig: UserConfigModel) => {
                if (!userConfig) {
                    return this.userConfigService.newUserConfig({
                        theme: 'default-theme',
                        financesConfig: {
                            screenConfig: [0, 2],
                            periodPicker: {
                                period: {
                                    startDate: '2017-01-01',
                                    endDate: '2017-02-02'
                                },
                                years: [1, 2],
                            },
                            series: [0, 1],
                            chosenView: FinanceChosenViewEnum.CHART,
                            resolution: ChartResultsResolutionEnum.DAYS,
                            brandPicked: BrandNameEnum.ALL,
                        },
                        partnersConfig: {
                            columnToDisplay: 9
                        },
                        chainsConfig: {
                            columnToDisplay: 7
                        },
                        tasksConfig: {
                            filtersWithValues: []
                        },
                        eventsConfig: {
                            columnToDisplay: 7
                        }
                    });
                }
                return of(userConfig);
            })
        ).subscribe(
            (userConfig: UserConfigModel) => {
                if (userConfig && userConfig.theme) {
                    this.utilsService.setTheme(userConfig.theme);
                }
            },
            (error) => {
                this.openSnackbar();
                this.utilsService.setTheme('default-theme');
            }
        );


    }

}
