import {Component, Input, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ProcuratorModel} from '../../../_models/procurator.model';
import {PageEvent} from '@angular/material/paginator';
import {AuthenticationService} from '../../../_services/authentication.service';
import {ProcuratorDetailsModel} from '../../../_models/procurator-details.model';
import {ProcuratorsService} from '../../../procurators/procurators.service';
import * as _ from 'lodash';
import {PartnerDetailsDialogModel} from '../partner-details-dialog.model';
import { canViewSozFields, canEditSozFields } from 'src/app/_utils/auth-utils/auth-procurator';
import {PartnerProcuratorDetailsModel} from '../../../_models/partner-procurator-details.model';
import {DictionariesService} from '../../../_services/dictionaries.service';
import {DictModel} from '../../../_models/dict.model';
import {ErrorModel} from '../../../_models/error/error.model';
import {ErrorDialogComponent} from '../../../error-dialog/error-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {InfoModel} from '../../../_models/info-dialog';
import {InfoDialogComponent} from '../../../info-dialog/info-dialog.component';

@Component({
    selector: 'app-partner-procurator-tab',
    templateUrl: './procurator-information-tab.component.html',
    styleUrls: ['./procurator-information-tab.component.scss']
})
export class ProcuratorInformationTabComponent implements OnInit {

    @Input() disabled: boolean;
    @Input() partnerDetailsDialogModel: PartnerDetailsDialogModel
    @Input() showInvalidFields: boolean
    private allProcurators: Array<ProcuratorDetailsModel>;
    private availableProcurators: Array<ProcuratorDetailsModel>;
    private filteredProcurators: Array<ProcuratorDetailsModel>;

    procuratorSource: MatTableDataSource<PartnerProcuratorDetailsModel> = new MatTableDataSource<PartnerProcuratorDetailsModel>();
    displayedColumns: string[] = ['login', 'name', 'lastname', 'position', 'star'];
    private pageSize: number;
    private tempFilter: string;
    private roles: DictModel[];

    constructor(private readonly authService: AuthenticationService,
                private readonly procuratorsService: ProcuratorsService,
                private readonly dictionaryServices: DictionariesService,
                private readonly dialog: MatDialog) {
    }

    ngOnInit(): void {
        // let proc: ProcuratorModel[] = [
        //     new ProcuratorModel(1, "a", "b", "c", "d", true, ["1", "11", "333"]),
        //     new ProcuratorModel(2, "aa", "bb", "cc", "dd", true, ["2", "22"]),
        //     new ProcuratorModel(3, "ddd", "mmm", "kamil", "aaa", true, ["2", "22"])
        // ];

        this.procuratorsService.getProcurators().subscribe(procurators => {
            this.allProcurators = procurators;
            this.rebuildAvailable();
        });
        this.dictionaryServices.getProcuratorRoles().subscribe(roles => this.roles = roles);
    }

    applyFilter(filterValue: string) {
        this.tempFilter = filterValue;
        this.procuratorSource.filter = filterValue.trim().toLowerCase();
    }

    private _currentUserRoles: number[];

    get canSendOWSAcceptRequest(): boolean {
        return true; // TODO uprawnienia
    }

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    canAddProcurator() {
        return true; // TODO uprawnienia
    }

    private rebuildAvailable() {
        let procuratorDetailsModels = _.cloneDeep(this.allProcurators);

        this.availableProcurators = procuratorDetailsModels.filter(procurator => !this.find(procurator)).sort((p1,p2) => p1.login.localeCompare(p2.login));
        this.filteredProcurators = this.availableProcurators;
        console.log("Available: " + this.availableProcurators.map(p => p.login));

        this.procuratorSource.data = this.partnerDetailsDialogModel.partnerProcuratorsDetails;
    }

    private find(procurator: ProcuratorDetailsModel): boolean {
        for (let i = 0; i < this.partnerDetailsDialogModel.partnerProcuratorsDetails.length; i++) {
            if (this.partnerDetailsDialogModel.partnerProcuratorsDetails[i] && this.partnerDetailsDialogModel.partnerProcuratorsDetails[i].id == procurator.id) {
                return true;
            }
        }

        return false;
    }

    addProcurator() {
        this.partnerDetailsDialogModel.partnerProcuratorsDetails = this.partnerDetailsDialogModel.partnerProcuratorsDetails.concat(new PartnerProcuratorDetailsModel(null, null, null, null, null, null, false, false));
        this.rebuildAvailable();
        this.partnerDetailsDialogModel.partnerSaved = false;
    }

    public getPageSize(): number {
        return this.pageSize;
    }

    public setPageSize(event: PageEvent): void {
        this.pageSize = event.pageSize;
    }

    canEditProcurator(element) {
        return true; // TODO uprawnienia
    }

    remove($event: MouseEvent, element, index: number) {
        this.partnerDetailsDialogModel.partnerProcuratorsDetails.splice(index, 1);
        this.rebuildAvailable();
        this.partnerDetailsDialogModel.partnerSaved = false;
    }

    procuratorChanged(current: ProcuratorDetailsModel, index: number, newId: number) {
        if (newId) {
            this.partnerDetailsDialogModel.partnerProcuratorsDetails[index] = ProcuratorInformationTabComponent.mapToPartnerProcurator(this.allProcurators.find(p => p.id === newId));
            this.rebuildAvailable();
        }
        this.partnerDetailsDialogModel.partnerSaved = false;
    }

    public getAvailableProcurators(element: ProcuratorDetailsModel): Array<ProcuratorDetailsModel> {
        if (element && element.id) {
            return this.filteredProcurators.concat(element);
        } else {
            return this.filteredProcurators;
        }
    }

    sendOWSAcceptRequest() {
        if (this.canSendOWSAcceptRequest) {
            this.procuratorsService.sendPartnerOWSAcceptRequest(this.partnerDetailsDialogModel.partnerDetailsModel.id).subscribe(result => {
                const dialogContent: InfoModel = new InfoModel('Sukces!', 'Pomyślnie wysłano prośbe o akceptacje OWS');
                this.dialog.open(InfoDialogComponent, {
                    data: dialogContent
                }).afterClosed().subscribe(() => {
                    location.reload(true);
                });
            }, error => {
                const dialogContent: ErrorModel = new ErrorModel('Błąd integracyjny',
                    'Błąd wysyłania zapytania OWS', '');
                this.dialog.open(ErrorDialogComponent, {
                    data: dialogContent
                }).afterClosed().subscribe(() => {
                    location.reload(true);
                });
            });
        }
    }

    private static mapToPartnerProcurator(procurator: ProcuratorDetailsModel) : PartnerProcuratorDetailsModel {
        return new PartnerProcuratorDetailsModel(procurator.id, procurator.login, procurator.name, procurator.lastname, procurator.role, procurator.active, false, false);
    }

    public filterProcurators(event) {
        if (!this.availableProcurators) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredProcurators = this.availableProcurators;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredProcurators = this.availableProcurators.filter(procurator => procurator.login.toLowerCase().indexOf(search) > -1);
    }

    isSelected(id: number) {
        for (let i = 0; i < this.partnerDetailsDialogModel.partnerProcuratorsDetails.length; i++) {
            if (this.partnerDetailsDialogModel.partnerProcuratorsDetails[i] && this.partnerDetailsDialogModel.partnerProcuratorsDetails[i].id == id) {
                return true;
            }
        }

        return false;
    }

    private countValidProcurators() {
        return this.partnerDetailsDialogModel.partnerProcuratorsDetails.filter(p => p && p.id).length
    }

    isOwsButtonEnabled() {
        const brandStatuses = this.partnerDetailsDialogModel.brandCooperationModel
            .map((brand) => brand.cooperationStatusId)
            .concat(
                this.partnerDetailsDialogModel.addedBrandCooperation.map((brand) => brand.cooperationStatusId)
            );

        return this.partnerDetailsDialogModel.partnerSaved && this.partnerDetailsDialogModel.partnerDetailsModel.nip && this.partnerDetailsDialogModel.sozLoginSaved && this.countValidProcurators() >= this.partnerDetailsDialogModel.partnerDetailsModel.owsMinNumberAccepts && this.partnerDetailsDialogModel.partnerDetailsModel.sozAccountCreated && brandStatuses.includes(9);
    }

    getOwsButtonTooltip(): string {
        if (!this.partnerDetailsDialogModel.partnerSaved) {
            return "Partner nie jest zapisany"
        }
        if (!this.partnerDetailsDialogModel.partnerDetailsModel.sozAccountCreated) {
            return "Konto partnera nie jest utworzone w SOZ"
        }
        if (!this.partnerDetailsDialogModel.sozLoginSaved) {
            return "Konto partnera nie jest utworzone w SOZ"
        }
        if (!this.partnerDetailsDialogModel.partnerDetailsModel.nip) {
            return "Konto partnera nie zawiera numeru NIP"
        }
        if (this.countValidProcurators() < this.partnerDetailsDialogModel.partnerDetailsModel.owsMinNumberAccepts) {
            return "Niewystarczająca ilość przypisanych reprezentantów"
        }

        const brandStatuses = this.partnerDetailsDialogModel.brandCooperationModel
            .map((brand) => brand.cooperationStatusId)
            .concat(
                this.partnerDetailsDialogModel.addedBrandCooperation.map((brand) => brand.cooperationStatusId)
            );

        if (!brandStatuses.includes(9)) {
            return "Partner nie ma przypisanej współpracy ze statusem OWS - oczekiwanie na akceptacje"
        }

        return ""
    }

    getRoleName(role: number) {
        return this.roles[role].value || "Brak";
    }
}
