import { Component, EventEmitter, Input, Output } from '@angular/core';
import _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CogElementModel } from '../../_models/cog-element.model';

@Component({
    selector: 'app-cog',
    templateUrl: './cog.component.html',
    styleUrls: ['./cog.component.scss']
})
export class CogComponent {

    @Input()
    public screenType: string;

    @Input()
    public cogElements: Array<CogElementModel>;

    @Output()
    public changedData = new EventEmitter<Array<CogElementModel>>();

    @Input()
    public visibleColumns = 9;

    constructor(public readonly deviceService: DeviceDetectorService) {
    }

    public emitChangedConfiguration(): void {
        this.changedData.emit(this.cogElements);
    }

    public moveElementDown(element: CogElementModel): void {
        const elemIndex = this.cogElements.findIndex((elem) => elem === element);
        if (elemIndex + 1 === this.cogElements.length) {
            return;
        }
        this.swapElements(elemIndex, elemIndex + 1);
        this.emitChangedConfiguration();
    }

    public moveElementUp(element: CogElementModel): void {
        const elemIndex = _.findIndex(this.cogElements, (elem) => elem === element);
        if (elemIndex === 0) {
            return;
        }
        this.swapElements(elemIndex, elemIndex - 1);
        this.emitChangedConfiguration();
    }

    private swapElements(elem1Index: number, elem2Index: number): void {
        this.cogElements = this.cogElements.slice();
        [this.cogElements[elem1Index], this.cogElements[elem2Index]] = [this.cogElements[elem2Index], this.cogElements[elem1Index]];
    }

    public isDimmed(index: number) {
        return index >= this.visibleColumns;
    }


}
