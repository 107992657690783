import { Component, ElementRef, HostListener } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ExcelExportService } from './excel-export.service';

@Component({
    selector: 'app-excel-export',
    templateUrl: './excel-export.component.html',
    styleUrls: ['./excel-export.component.scss']
})
export class ExcelExportComponent {

    public showMenu: boolean;

    constructor(private readonly eRef: ElementRef,
                public readonly avatarService: ExcelExportService) {
    }

    @HostListener('document:click', ['$event'])
    hideOnOutsideClick(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.showMenu = false;
        }
    }

    showDropdown(button: MatButton) {
        this.showMenu = !this.showMenu;
    }

}
