import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ExternalPersonModel } from '../../../_models/external-person.model';
import { AddContactService } from './add-contact.service';


@Component({
    selector: 'app-add-contact-dialog',
    templateUrl: './add-contact-dialog.component.html',
    styleUrls: ['./add-contact-dialog.component.scss']
})
export class AddContactDialogComponent implements OnInit {

    externalPersons: ExternalPersonModel[];
    filteredContacts: ExternalPersonModel[];
    data: ExternalPersonModel;
    private newContact: ExternalPersonModel = new ExternalPersonModel(null, null, null, null, null, null, false, null, true)

    constructor(private readonly addContactService: AddContactService,
                private _dialogRef: MatDialogRef<AddContactDialogComponent>) {
    }

    getContactLabel(entry: ExternalPersonModel) {
        if (entry.newContact) {
            return 'Nowy kontakt';
        }
        const firstName = entry?.firstName && entry?.firstName + ' ' || '';
        const lastName = entry?.familyName || '';
        const name = firstName + lastName;
        return name || 'Brak danych do wyświetlenia';
    }

    filterContacts(phrase: string) {
        if (!this.externalPersons?.length) {
            return;
        }
        let search = phrase && phrase.replace(/\s/g, '') || '';
        if (!search) {
            this.filteredContacts = this.externalPersons;
            return;
        } else {
            search = search.toLowerCase();
        }

        const getFilterPredicate = (ext: ExternalPersonModel) => {
            return ((ext?.email || '') + (ext?.firstName || '') + (ext?.familyName || '') + (ext?.phoneNo || '')).toLowerCase();
        }

        this.filteredContacts = this.externalPersons.filter(person => getFilterPredicate(person).indexOf(search) > -1);

    }

    ngOnInit(): void {
        this.data = this.newContact;
        this.addContactService.getExternalContacts().subscribe(
            (data) => {
                this.externalPersons = [this.newContact, ...data];
                this.filteredContacts = [...this.externalPersons];
            }
        );
    }

    protected cancel() {
        this._dialogRef.close(null);
    }

    protected addContact() {
        this._dialogRef.close(this.data);
    }


}