import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { FilterSelectOptionModel } from '../../_models/filter-select-option.model';


@Component({
    selector: 'app-filter-select',
    templateUrl: './filter-select.component.html',
    styleUrls: ['./filter-select.component.scss']
})
export class FilterSelectComponent implements AfterViewInit {
    @ViewChild('selectOne', {static: true}) mySelect: MatSelect;
    @Input()
    public selectOptions: Array<FilterSelectOptionModel>;
    public selectValue: any;
    @Output()
    public emitSelectValue = new EventEmitter<String>();

    @Output()
    public emitCloseEvent = new EventEmitter<void>();

    ngAfterViewInit(): void {

        this.mySelect.open();
    }

    public emitValue(value: string) {
        this.emitSelectValue.emit(value);
    }

    public toggled(value: boolean) {
        if (!value) {
            this.emitCloseEvent.emit()
        }
    }


}
