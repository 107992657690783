import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as localization from 'moment/locale/pl';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { ComparisonPeriodModel } from '../../_models/comparison-period.model';
import { PeriodPickerModel } from '../../_models/period-picker.model';
import { RangeModel } from '../../_models/range.model';
import { UserConfigService } from '../../_services/user-config.service';
import { FinancesService } from '../finances.service';

moment.locale('pl', localization);

export interface Range {
    startDate: Moment;
    endDate: Moment;
}

export interface PeriodPicker {
    period: {
        startDate: Moment,
        endDate: Moment
    };
    years: Array<number>;
}

@Component({
    selector: 'app-period-picker',
    templateUrl: './period-picker.component.html',
    styleUrls: ['./period-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PeriodPickerComponent {
    maxDate: Moment;

    years: Array<ComparisonPeriodModel> = [];

    locale: LocaleConfig = {
        applyLabel: 'Akceptuj',
        customRangeLabel: ' - ',
        daysOfWeek: moment.weekdaysMin(),
        monthNames: moment.monthsShort(),
        firstDay: moment.localeData().firstDayOfWeek(),
    };

    @Output() periodPickerOutput = new EventEmitter<PeriodPicker>();

    constructor(public readonly financesService: FinancesService, private readonly userConfigService: UserConfigService) {
        let currentYear: number = new Date().getFullYear();
        for (let i = currentYear; i > 2000; --i) {
            this.years.push(new ComparisonPeriodModel(i - 2000));
        }
        this.years = this.years.reverse();
        this.maxDate = moment(new Date());
    }


    public setPickedValuesAndUpdateData() {
        if (this.financesService.periodPicker.period && this.financesService.resolution) {
            this.financesService.generateChartData();
        }
        let startDateString = this.financesService.periodPicker.period.startDate ? this.financesService.periodPicker.period.startDate.format('YYYY-MM-DD').toString() : null;
        let endDateString = this.financesService.periodPicker.period.endDate ? this.financesService.periodPicker.period.endDate.format('YYYY-MM-DD').toString() : null;
        this.userConfigService.updatePeriodPicker(new PeriodPickerModel(
            new RangeModel(startDateString, endDateString),
            this.financesService.periodPicker.years)
        ).subscribe();

    }


    public updatePeriod(period: Range): void {
        this.financesService.periodPicker.period = period;
        this.setPickedValuesAndUpdateData();
        let startDateString = period.startDate.format('YYYY-MM-DD').toString();
        let endDateString = period.endDate.format('YYYY-MM-DD').toString();
        this.userConfigService.updatePeriodPicker(new PeriodPickerModel(
            new RangeModel(startDateString, endDateString),
            this.financesService.periodPicker.years)
        ).subscribe();
    }

}