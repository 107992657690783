import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from "@angular/material/table";
import { ContactHistoryModel } from '../../../../_models/contact-history.model';
import { SearchCriteria } from '../../../../_models/search-criteria.model';
import { AuthenticationService } from '../../../../_services/authentication.service';
import { ChipFiltersUtils } from '../../../../_utils/chip-filters.utils';
import { ChipElement } from '../../../../filter-chips/chip-component/chip.component';
import { AdminContactsHistoryTabService } from './admin-contacts-history-tab.service';

@Component({
    selector: 'app-admin-contacts-history-tab',
    templateUrl: './admin-contacts-history-tab.component.html',
    styleUrls: ['./admin-contacts-history-tab.component.scss']
})
export class AdminContactsHistoryTabComponent implements OnInit {

    public displayedColumns: string[] = [
        'contactOwnerName',
        'eventInitiator',
        'eventType',
        'creationDate',
        'description'
    ];
    public readonly taskReqParams: SearchCriteria = new SearchCriteria(0, 10, 'desc-creationDate');

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    public chipPresentFilters: ChipElement[] = [];
    public chipTaskPossibleFilters = ChipFiltersUtils.CONTACTS_HISTORY_FILTERS;
    protected contactsHistorySource: MatTableDataSource<ContactHistoryModel>;
    private tempFilter: string;
    private pageSize: number = 20;
    private _currentUserId: number;

    constructor(private readonly _adminUserTabService: AdminContactsHistoryTabService,
                private readonly _authenticationService: AuthenticationService,) {
    }

    ngOnInit() {
        this._currentUserId = this._authenticationService.currentUserValue.personId;
        this.getContactsHistory();
    }

    public getPageSize(): number {
        return this.pageSize;
    }

    public setPageSize(event: PageEvent): void {
        this.pageSize = event.pageSize;
        localStorage.setItem(`adminPageContactHistoryPageSize${this._currentUserId}`, JSON.stringify(this.pageSize));
    }

    public sortContactsHistory(event: Sort): void {
        if (!!event.direction && ['asc', 'desc'].includes(event.direction)) {
            this.taskReqParams.sort = event.direction + '-' + event.active;
            this.getContactsHistory();
        }
    }

    public applyFilter(filterValue: string) {
        this.tempFilter = filterValue;
        // this.usersSource.filter = filterValue.trim().toLowerCase();
    }

    public chipAddToList(chipElement: ChipElement): void {
        this.chipPresentFilters.push(chipElement);
    }

    public chipUpdateList(chipList: ChipElement[]): void {
        this.chipPresentFilters = chipList;
    }

    public chipUpdateListAndSendRequest(chipList: ChipElement[]): void {
        this.chipUpdateList(chipList);
        // this.userConfigService.updateTasksFiltersWithValues(chipList).subscribe();
        this.getContactsHistory();
    }

    private getContactsHistory() {

        let params = ChipFiltersUtils.prepareParams(this.chipPresentFilters);
        this._adminUserTabService.getContactsHistory(this.taskReqParams, params)
            .subscribe(value => {
                this.contactsHistorySource = new MatTableDataSource<ContactHistoryModel>(value.content);
                this.contactsHistorySource.paginator = this.paginator;
            });
    }
}
