import { Moment } from 'moment';

export class ConsentApprovalModel {
    consentId: number;
    brandId: number;
    partnerId: number;
    allContactIds?: number[];
    mainContactId?: number;
}

export class ConsentActivationModel {
    active: boolean;
}

export class ConsentModel {
    active: boolean;
    id?: number;
    version?: string;
    brandId?: number;
    title?: string;
    content?: string;
    consentTypeId?: number;
    startDate?: Moment;
    creationDate?: Moment;
    endDate?: Moment;
    newConsent?: boolean; //only frontend props
    consentReadonly?: boolean; //frontend only
}
