import { Component, OnInit } from '@angular/core';
import {ChipElement} from '../filter-chips/chip-component/chip.component';
import {ChipFiltersUtils} from '../_utils/chip-filters.utils';
import {UserConfigModel} from '../_models/user-config.model';
import {PeriodPickerModel} from '../_models/period-picker.model';
import {BrandNameEnum} from '../_enums/brand-name.enum';
import {ChartMarketCooperationEnum} from '../_enums/chart-market-cooperation.enum';
import {UserConfigService} from '../_services/user-config.service';
import {EntrepreneursService} from './entrepreneurs.service';
import {DynamicTableColumn} from '../dynamic-table/dynamic-table.service';
import {TableEventModel} from '../_models/table-event.model';
import {takeUntil} from 'rxjs/operators';
import {ScreenConfigModel} from '../_models/screen-config.model';
import {environment} from '../../environments/environment';
import {ScreenService} from '../_services/screen.service';

@Component({
  selector: 'app-entrepreneurs',
  templateUrl: './entrepreneurs.component.html',
  styleUrls: ['./entrepreneurs.component.scss']
})
export class EntrepreneursComponent implements OnInit {

  chipPresentFilters: Array<ChipElement> = [];
  chipEntrepreneurStaticFilters: ChipElement[] = ChipFiltersUtils.ENTREPRENEUR_STATIC_FILTERS;
  chipEntrepreneurPossibleFilters: ChipElement[] = ChipFiltersUtils.ENTREPRENEUR_POSSIBLE_FILTERS;
  dataLoaded: boolean;
  columnsToDisplay: number;
  private readonly SCREEN_API_URL = environment.apiUrl + '/api/screen';


  constructor(public userConfigService: UserConfigService,
              public entrepreneursService: EntrepreneursService,
              private readonly screenService: ScreenService) { }

  ngOnInit(): void {

    this.entrepreneursService.getScreenData().subscribe((screenConfigModelArray) => {
      this.entrepreneursService.activeConfiguration = Object.assign({}, screenConfigModelArray.find(configuration => configuration.isDefault));
      // this.chipPresentFilters = this.entrepreneursService.activeConfiguration.configJson.filters;
      this.chipUpdateListAndSendRequestForRedirection(this.entrepreneursService.activeConfiguration.configJson.filters)
      this.entrepreneursService.getUpdatedData()
      this.dataLoaded = true;

    })


    this.userConfigService.getUserConfig().subscribe(
        (userConfig: UserConfigModel) => {
          // this.financesService.periodPicker = PeriodPickerModel.getPeriodPicker(userConfig.financesConfig.periodPicker);
          // this.financesService.resolution = userConfig.financesConfig.resolution;
          // this.financesService.brandPicked = userConfig.financesConfig.brandPicked || BrandNameEnum.ALL;
          // this.financesService.marketCooperation = userConfig.financesConfig.cooperation || ChartMarketCooperationEnum.B2B;
          // this.financesService.seriesToDisplay = userConfig.financesConfig.series;
          // this._chartPicked = userConfig.financesConfig.screenConfig ? userConfig.financesConfig.screenConfig : [0, 2];
          // if (userConfig.financesConfig.filtersWithValues) {
          //   this.financesService.filtersValueArray = userConfig.financesConfig.filtersWithValues || [];
          //
          //   this.chipPresentFilters = this.financesService.filtersValueArray;
          // }
          // this.financesService.chosenView = userConfig.financesConfig.chosenView;
          // this.financesService.configFetched = true;
          // this.financesService.generateChartData();

          //this.chipPresentFilters = [];
          //this.entrepreneursService.getUpdatedData();
          // this.dataLoaded = true;
        },
        (error) => {
          console.log('Error on getting user config on finances.', error);
         // this.financesService.generateChartData();
        }
    );


  }

  get canAddEntrepreneur() : boolean {
    return true;
  }

  public chipUpdateList(chipList: ChipElement[]): void {
    this.chipPresentFilters = chipList;
  }

  public chipAddToList(chipElement: ChipElement): void {
    this.chipPresentFilters.push(chipElement);
  }

  chipUpdateListAndSendRequest(chipList: ChipElement[]) {
    console.log("Update chips");
    this.chipUpdateList(chipList);
    this.entrepreneursService.activeConfiguration.configJson.filters = chipList;
    this.updateActiveConfig();
  }

  public chipUpdateListAndSendRequestForRedirection(chipList: ChipElement[]): void {
    this.chipUpdateList(chipList);
    this.entrepreneursService.activeConfiguration.configJson.filters = chipList;
    this.entrepreneursService.getUpdatedData();
  }

  public updateActiveConfig(): void {
    let activeConfig = this.entrepreneursService.activeConfiguration;
    this.screenService.updateScreenData(this.SCREEN_API_URL, activeConfig).subscribe(data => {
      this.entrepreneursService.configurationMap.set(data.name, data);
      this.activate(data);
    });
  }

  private activate(candidateConfig: ScreenConfigModel): void {
    this.entrepreneursService.setActiveConfiguration(candidateConfig);
    this.entrepreneursService.getUpdatedData();
  }

  public getHeaders(): Array<DynamicTableColumn> {
    if (!this.entrepreneursService.activeConfiguration.configJson || !this.entrepreneursService.activeConfiguration.configJson.visibleColumnsOrdered) {
      return;
    }
    return this.entrepreneursService.activeConfiguration.configJson.visibleColumnsOrdered
        .map(col => new DynamicTableColumn(col.displayName, col.sortAvailable));
  }

  public getRows(): Array<Array<string>> {
    if (!this.entrepreneursService.rowsForActiveConfiguration) {
      return;
    }
    return this.entrepreneursService.rowsForActiveConfiguration;
  }

  public getPages(): number {
    if (!this.entrepreneursService.pages) {
      return;
    }
    return this.entrepreneursService.pages;
  }

  public getPage(): number {
    if (!this.entrepreneursService.page) {
      return;
    }
    return this.entrepreneursService.page;
  }

  public getTotalElements(): number {
    if (!this.entrepreneursService.totalElements) {
      return;
    }
    return this.entrepreneursService.totalElements;
  }

  public tableEvent(event: TableEventModel) {
    this.entrepreneursService.page = event.page;
    this.entrepreneursService.size = event.rowsPerPage;
    //TODO: Czy aktualna strona powinna być zapisana do konfiguracji? Jeśli tak to dodać, spytać Konrada
    this.entrepreneursService.getUpdatedData();
  }

  public tableSortEvent(sortQueryParam: string) {
    this.entrepreneursService.sortQueryParam = sortQueryParam;
    //TODO: Czy aktualne sortowanie powinno być zapisane do konfiguracji? Jeśli tak to dodać, spytać Konrada
    this.entrepreneursService.getUpdatedData();
  }
}
