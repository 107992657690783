import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EventModel } from '../../_models/event-model';
import { EventConfirmationModel } from '../calendar-screen.service';

export enum ActionType {
    update,
    remove,
    confirm
}

export class EventActionDialogCommunicationModel {
    type: ActionType;
    eventId: number;
    personId: number;
    recurrenceId?: number;
    applyToSingle?: boolean;
    applyToSeries?: boolean;
    confirmation?: EventConfirmationModel;
    eventModel?: EventModel;
}

@Injectable()
export class EventCalendarService {

    public showRemoveDialog: Subject<EventActionDialogCommunicationModel> = new Subject<EventActionDialogCommunicationModel>();
    public updateSeriesDialog: Subject<EventActionDialogCommunicationModel> = new Subject<EventActionDialogCommunicationModel>();
    public confirmSeriesDialog: Subject<EventActionDialogCommunicationModel> = new Subject<EventActionDialogCommunicationModel>();
    public seriesDialogOpened: boolean = false;

    constructor() {
    }


}
