import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarScreenService } from '../../../calendar-screen.service';
import { ActionType, EventActionDialogCommunicationModel } from '../../../event-calendar/event-calendar.service';


@Component({
    selector: 'app-series-action-dialog',
    templateUrl: 'series-action-dialog.component.html',
    styleUrls: ['series-action-dialog.component.scss'],
})
export class SeriesActionDialogComponent {


    constructor(
        private _dialogRef: MatDialogRef<SeriesActionDialogComponent>,
        private readonly calendarScreenService: CalendarScreenService,
        @Inject(MAT_DIALOG_DATA) public communicationModel: EventActionDialogCommunicationModel) {
    }

    protected singleAction() {
        this.communicationModel.applyToSingle = true;
        this.closeDialog(this.communicationModel);
    }

    protected seriesAction() {
        this.communicationModel.applyToSeries = true;
        this.closeDialog(this.communicationModel);
    }

    protected abort() {
        this.closeDialog(null);
    }

    protected getMessage(): string {
        if (this.communicationModel.type === ActionType.remove) {
            return this.communicationModel.recurrenceId && 'Czy chcesz usunąć serie wydarzeń czy tylko to wydarzenie?' || 'Czy napewno chcesz usunąć to wydarzenie?';
        }
        if (this.communicationModel.type === ActionType.confirm) {
            return 'Czy chcesz potwierdzić serię wydarzeń czy tylko to wydarzenie?';

        }
        if (this.communicationModel.type === ActionType.update) {
            return 'Czy chcesz wprowadzić zmianę do serię czy tylko do tego wydarzenia?';

        }
    }

    private closeDialog(action: EventActionDialogCommunicationModel) {
        if (action) {
            if (action.type === ActionType.remove) {
                this.calendarScreenService.deleteEventSubject.next(action);
                this._dialogRef.close();
            } else if (action.type === ActionType.confirm) {
                this.calendarScreenService.acceptEventSubject.next(action);
                this._dialogRef.close();
            } else {
                this.calendarScreenService.patchEventSubject.next(action);
                this._dialogRef.close();
            }
        }
        this._dialogRef.close();
    }


}
