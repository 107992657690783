import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {RotatingProducts} from '../../_models/rotating-products.model';


@Component({
    selector: 'app-rotating-table',
    templateUrl: './rotating-table.component.html',
    styleUrls: ['./rotating-table.component.scss']
})
export class RotatingTableComponent {

    public displayedColumns: string[] = [
        'id',
        'productName',
        'totalAvailability',
        'ordered',
        'lackDays',
        'status',
        'releaseDate',
        'incomeTotalQuantity',
        'averageIncomeQuantityPerMonth',
        'incomeProductQuantityPercentageShare',
        'incomeTotalPrice',
        'averageIncomePricePerMonth',
        'incomeProductPricePercentageShare',
        'returningTotalQuantity',
        'returningProductQuantityPercentageShare',
        'returningTotalPrice',
        'returningProductPricePercentageShare',
        'star'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @Output() clickEvent: EventEmitter<RotatingProducts> = new EventEmitter();

    private _rawData: Array<RotatingProducts>;
    private _dataSource: MatTableDataSource<RotatingProducts>;

    get dataSource(): MatTableDataSource<RotatingProducts> {
        return this._dataSource;
    }

    @Input()
    set data(data: Array<RotatingProducts>) {
        this._rawData = data;
        this.sortData("productName", "asc")
    }

    public isSoldOut(row: RotatingProducts) {
        return row.totalAvailability <= row.minimalReservation;
    }

    onElementClick(row: RotatingProducts) {
        this.clickEvent.emit(row);
    }

    getDisplayStatus(status: number) {
        switch (status) {
            case 2:
                return 'W';
            case 3:
                return 'N';
        }
        return '';
    }

    onSort(sort: Sort) {
        this.sortData(sort.active, sort.direction)
    }

    sortData(active, direction : string) {
        console.log("Sort: " + active + " " + direction);

        if (!active) {
            active = "productName"
        }
        if (!direction) {
            active = "productName"
            direction = "asc"
        }

        const data = this._rawData.slice();

        this._dataSource = new MatTableDataSource<RotatingProducts>(data.sort((a, b) => {
            const isAsc = direction === 'asc';
            let aElement = a[active];
            let bElement = b[active];
            if (typeof aElement === "string") {
                console.log("String: " + aElement + " " + bElement)
                return aElement.localeCompare(bElement, 'pl-PL') * (isAsc ? 1 : -1);
            } else {
                console.log("Not string: " + aElement + " " + bElement)
                return (aElement < bElement ? -1 : 1) * (isAsc ? 1 : -1);
            }
        }));
        this._dataSource.paginator = this.paginator;
    }

    sumColumnProducts(column: string, maximumFractionDigits: number = 0) {
        const length = this._rawData.length;
        if (length == 0) {
            return 0;
        }

        const number : number = this._rawData.map(row => row[column]).reduce((sum, current) => sum + current);
        return number.toLocaleString("ru-RU", {minimumFractionDigits: maximumFractionDigits, maximumFractionDigits: maximumFractionDigits, useGrouping: true});
    }

    averageColumnProducts(column: string, maximumFractionDigits: number = 2) {
        const length = this._rawData.length;
        if (length == 0) {
            return 0;
        }

        const number = this._rawData.map(row => row[column]).reduce((sum, current) => sum + current) / length;
        return number.toLocaleString("ru-RU", {minimumFractionDigits: maximumFractionDigits, maximumFractionDigits: maximumFractionDigits, useGrouping: true});
    }
}

