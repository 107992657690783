import * as moment from 'moment';
import { PeriodPicker } from '../finances/period-picker/period-picker.component';

import { RangeModel } from './range.model';

export class PeriodPickerModel {

    constructor(public readonly period: RangeModel,
                public readonly years: Array<number>) {
    }

    public static getPeriodPicker(periodPickerModel: PeriodPickerModel): PeriodPicker {
        return {
            period: {
                startDate: moment(periodPickerModel.period.startDate),
                endDate: moment(periodPickerModel.period.endDate)
            },
            years: periodPickerModel.years
        }
    }

}

