import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export declare type DialogResponse = 'copy' | 'move' | 'dismiss'

@Component({
    selector: 'app-on-drop-dialog',
    templateUrl: 'on-drop-dialog.component.html',
    styleUrls: ['on-drop-dialog.component.scss'],
})
export class OnDropDialogComponent {


    constructor(
        private _dialogRef: MatDialogRef<OnDropDialogComponent>) {
    }

    private closeDialog(dialogResponse: DialogResponse) {
        this._dialogRef.close(dialogResponse);
    }


}
