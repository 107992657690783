import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { canViewFinances } from '../../_utils/auth-utils/auth-finances';
import { AuthenticationService } from '../authentication.service';

@Injectable()
export class FinancesGuardService implements CanActivate {
    constructor(private readonly _authenticationService: AuthenticationService, private readonly _router: Router) {

    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (canViewFinances(this._authenticationService.getCurrentUserRoles())) {
            return true;
        }
        this._router.navigate(['/calendar']);
        return false;
    }


}
