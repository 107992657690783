import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConsentActivationModel, ConsentApprovalModel, ConsentModel } from '../../../../_models/consent.model';

const API_URL = '/api/consent';
const API_APPROVAL = 'api/consent-approval';

@Injectable(
    {providedIn: 'root'}
)
export class ConsentService {

    refreshConsents$: Subject<void> = new Subject<void>();

    constructor(private readonly _httpClient: HttpClient) {
    }

    public getConsents(): Observable<ConsentModel[]> {
        return this._httpClient.get<ConsentModel[]>(API_URL);
    }

    getActiveConsents(brandIds: number[]): Observable<ConsentModel[]> {
        let params: HttpParams = new HttpParams();
        brandIds.forEach(brandId => params = params.append('brandIds', brandId.toString()));
        return this._httpClient.get<ConsentModel[]>(`${API_URL}/active`, {params});
    }

    public activateConsent(consentId: number, active: ConsentActivationModel): Observable<ConsentModel> {
        return this._httpClient.patch<ConsentModel>(`${API_URL}/${consentId}`, active);
    }

    public addConsent(consent: ConsentModel): Observable<ConsentModel> {
        return this._httpClient.post<ConsentModel>(`${API_URL}`, consent);
    }

    public deleteConsent(consentId: number): Observable<void> {
        return this._httpClient.delete<void>(`${API_URL}/${consentId}`);
    }

    getConsentApprovalsForPartner(partnerId: number, consentIds: number[]): Observable<ConsentApprovalModel[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('partnerId', partnerId.toString())
        consentIds.forEach(consentId => params = params.append('consentIds', consentId.toString()));

        return this._httpClient.get<ConsentApprovalModel[]>(API_APPROVAL, {params});
    }

    approveConsent(consentApproval: ConsentApprovalModel): Observable<ConsentApprovalModel> {
        return this._httpClient.post<ConsentApprovalModel>(API_APPROVAL, consentApproval);
    }

    deleteConsentApproval(consentId: number, brandCoopId: number, partnerId: number): Observable<void> {
        return this._httpClient.delete<void>(`${API_APPROVAL}/${consentId}/${partnerId}/${brandCoopId}`);
    }


}
