export enum ScreenTypeEnum {

    chains = 'CHAINS',
    concerns = 'CONCERNS',
    partners = 'PARTNERS',
    analytics = 'ANALYTICS',
    products = 'PRODUCTS',
    events = 'EVENTS',
    events_partner = 'EVENTS_PARTNER'

}
