import {EntrepreneurBrandCooperationModel} from '../_models/entrepreneur-brand-cooperation.model';
import {AuthRolesEnum} from '../_utils/auth-utils/auth-commons';

export const canEditEntrepreneur = (rolesArr: number[], brands: EntrepreneurBrandCooperationModel[]): boolean => {
    if (rolesArr.some(role => [AuthRolesEnum.SYSTEM_ADMIN, AuthRolesEnum.TOA, AuthRolesEnum.LEGAL_DEPARTMENT].includes(role))) {
        return true
    }

    const statuses = brands.map(brand => brand.cooperationStatusId).filter(status => status != null);

    if (rolesArr.includes(AuthRolesEnum.REGIONAL_COORDINATOR)) {
        if (statuses.some(status => [3, 4, 5, 6, 7, 8].includes(status))) {
            return false;
        }
        return true
    }

    return false;
}

export const canEditCooperationDate = (rolesArr: number[]): boolean => {
    return rolesArr.includes(AuthRolesEnum.SYSTEM_ADMIN);
}

export const canEditEntrepreneurBrand = (rolesArr: number[], brand: EntrepreneurBrandCooperationModel): boolean => {
    if (rolesArr.some(role => [AuthRolesEnum.SYSTEM_ADMIN, AuthRolesEnum.TOA, AuthRolesEnum.LEGAL_DEPARTMENT].includes(role))) {
        return true
    }

    if (rolesArr.includes(AuthRolesEnum.REGIONAL_COORDINATOR)) {
        return [1, 2, 9].includes(brand?.cooperationStatusId);
    }

    return false;
}

export const canAddRepresentativeEntrepreneur = (rolesArr: number[], brands: EntrepreneurBrandCooperationModel[]): boolean => {
    if (rolesArr.some(role => [AuthRolesEnum.SYSTEM_ADMIN, AuthRolesEnum.TOA, AuthRolesEnum.LEGAL_DEPARTMENT].includes(role))) {
        return true
    }

    const statuses = brands.map(brand => brand.cooperationStatusId).filter(status => status != null);

    if (rolesArr.includes(AuthRolesEnum.REGIONAL_COORDINATOR)) {
        return statuses.some(status => [1].includes(status));
    }

    return false;
}

export const canEditEntrepreneurBrandStatus = (rolesArr: number[], brand: EntrepreneurBrandCooperationModel, newStatus: number): boolean => {
    if (rolesArr.includes(AuthRolesEnum.SYSTEM_ADMIN)) {
        return true
    }

    const statusId = brand.cooperationStatusId;

    if (statusId == null) {
        return true
    }

    if (statusId == 10) {
        return rolesArr.includes(AuthRolesEnum.SYSTEM_ADMIN);
    }

    if (rolesArr.some(role => [AuthRolesEnum.SYSTEM_ADMIN, AuthRolesEnum.TOA, AuthRolesEnum.LEGAL_DEPARTMENT].includes(role))) {
        return true
    }

    if (rolesArr.includes(AuthRolesEnum.REGIONAL_COORDINATOR)) {
        if (statusId == 7) {
            return newStatus == 1;
        }

        if ([9, 1, 2].includes(statusId) && [9, 1].includes(newStatus)) {

            return true
        }
    }

    return false

    // AuthRolesEnum.REGIONAL_COORDINATOR

    /*if (rolesArr.includes(1)) {
        return true
    }

    const statusId = brand.cooperationStatusId;

    if (statusId == null) {
        return true
    }

    if (statusId === 1 || statusId === 2) {
        return rolesArr.includes(6)
    }
    if (statusId === 2) {
        return rolesArr.includes(10)
    }

    return rolesArr.some(role => [7, 10].includes(role))*/
}
