import {Subject} from 'rxjs';
import {DialogResultEnum} from '../../_enums/dialog-result.enum';
import {EntrepreneurDetailsModel} from '../../_models/entrepreneur-details.model';
import {EntrepreneurDeliveryAddressModel} from '../../_models/entrepreneur-delivery-address.model';
import {EntrepreneurBrandCooperationModel} from '../../_models/entrepreneur-brand-cooperation.model';
import {RepresentativeModel} from '../../_models/representative.model';
import {EntrepreneurWspolnikModel} from '../../_models/entrepreneur-wspolnik.model';

export class EntrepreneurDetailsDialogModel {

    public entrepreneurDetailsModel: EntrepreneurDetailsModel;
    public entrepreneurDeliveryAddress: Array<EntrepreneurDeliveryAddressModel>;
    public entrepreneurBrands: Array<EntrepreneurBrandCooperationModel>;
    public entrepreneurRepresentative: Array<RepresentativeModel>;
    public wspolnicy: Array<EntrepreneurWspolnikModel>;

    public entrepreneurSaved: boolean = true
    public newEntrepreneur: boolean;
    public loyaltyPointsChanged: boolean;
    public notification?: Subject<DialogResultEnum>;

}
