import {Component, Input} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ChainDetailsModel} from '../../../_models/chain-details.model';
import {ChainsService} from '../../chains.service';

import {AddChainDialogComponent} from '../add-chain-dialog.component';


@Component({
    selector: 'app-add-chain',
    templateUrl: './add-chain-trigger.component.html',
    styleUrls: ['./add-chain-trigger.component.scss']
})
export class AddChainTriggerComponent {

    @Input() private subjectId: number;
    private chainModel: ChainDetailsModel = new ChainDetailsModel(null, null, null, null, null, null, null, null, null, null, null, null);

    constructor(private dialog: MatDialog,
                private readonly chainService: ChainsService) {
    }


    public openDialog(): void {
        const dialogRef = this.dialog.open(AddChainDialogComponent,
            {
                data: this.chainModel,
                maxWidth: '100%',
                panelClass: 'add-partner-dialog'
            }
        );

        dialogRef.afterClosed().subscribe((result: boolean) => {
                if (!result) {
                    return;
                }

                this.chainService.addChain(this.chainModel).subscribe(
                    () => this.chainService.getUpdatedData()
                );

            }
        );
    }

}
