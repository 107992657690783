import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef } from '@angular/material/dialog';
import { ArchiveFinancialIdModel } from './archive-financial-id.model';
import { ArchiveFinancialIdService } from './archive-financial-id.service';


@Component({
    selector: 'app-archive-financial-id-dialog',
    templateUrl: './archive-financial-id-dialog.component.html',
    styleUrls: ['./archive-financial-id-dialog.component.scss']
})
export class ArchiveFinancialIdDialogComponent implements OnInit {

    form: FormGroup;
    private _newIdCtrl = new FormControl(null);
    private _descriptionCtrl = new FormControl('');
    private _archiveDateCtrl = new FormControl(Date.now());

    constructor(private readonly addContactService: ArchiveFinancialIdService,
                private _dialogRef: MatDialogRef<ArchiveFinancialIdDialogComponent>) {
    }

    ngOnInit(): void {
        this.form = new FormGroup(
            {
                newIdCtrl: this._newIdCtrl,
                descriptionCtrl: this._descriptionCtrl,
                archiveDateCtrl: this._archiveDateCtrl
            }
        );
    }

    openCalendar(event, picker: MatDatepicker<any>) {
        picker.open();
        this.selectFocusedElement(event);
    }

    cancel() {
        this._dialogRef.close(null);
    }

    archiveId() {
        if (this.form.invalid) {
            return;
        }
        const archiveModel: ArchiveFinancialIdModel = {
            date: this._archiveDateCtrl.value,
            financialId: this._newIdCtrl.value,
            description: this._descriptionCtrl.value
        }
        this._dialogRef.close(archiveModel);
    }

    private selectFocusedElement(event) {
        event.target.select();
    }
}