import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { CalendarModule } from 'angular-calendar';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { DndModule } from 'ngx-drag-drop';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { QuillModule } from 'ngx-quill';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { ToastModule } from 'primeng/toast';
import { MaterialModule } from '../util-modules/material-module';
import { SharedModule } from '../util-modules/SharedModule';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { LoaderInterceptor } from './_helpers/loader.inteceptor';
import { MatPaginatorIntlPlProvider } from './_providers/mat-paginator-intl-pl.provider';
import { BrandCooperationService } from './_services/brand-cooperation.service';
import { BrandCorrelationService } from './_services/brand-correlation.service';
import { CacheService } from './_services/cache.service';
import { DictionariesService } from './_services/dictionaries.service';
import { AdminGuardService } from './_services/guards/admin-guard.service';
import { FinancesGuardService } from './_services/guards/finances-guard.service';
import { MessageService } from './_services/message.service';
import { MomentUtcDateAdapter } from './_services/moment-utc-date.adapter';
import { NotificationService } from './_services/notification.service';
import { RedirectionService } from './_services/redirection.service';
import { ScreenService } from './_services/screen.service';
import { ShortageService } from './_services/shortage.service';
import { TaskService } from './_services/task.service';
import { UserConfigService } from './_services/user-config.service';
import { UserService } from './_services/user.service';
import { UtilsService } from './_services/utils.service';
import { ExcelUtils } from './_utils/excel.utils';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AvatarService } from './avatar/avatar-service';
import { AvatarComponent } from './avatar/avatar.component';
import { ChangePasswordComponent } from './avatar/change-password/change-password.component';
import { ChangePasswordDialogComponent } from './avatar/change-password/dialog/change-password-dialog.component';
import { ThemePickerComponent } from './avatar/theme-picker/theme-picker.component';

import { BodyWithSidebarComponent } from './body-with-sidebar/body-with-sidebar.component';
import { CalendarScreenComponent } from './calendar/calendar-screen.component';
import { CalendarScreenService } from './calendar/calendar-screen.service';
import { EventDialogComponent } from './calendar/dialogs/event-dialog-component';
import { SeriesActionDialogComponent } from './calendar/dialogs/remove-dialog/remove-series/series-action-dialog.component';
import { EventCalendarComponent } from './calendar/event-calendar/event-calendar.component';
import { EventCalendarService } from './calendar/event-calendar/event-calendar.service';
import { OnDropDialogComponent } from './calendar/event-calendar/on-drop-dialog/on-drop-dialog.component';
import { CalendarSidebarComponent } from './calendar/sidebar/calendar-sidebar.component';
import { CalendarSidebarService } from './calendar/sidebar/calendar-sidebar.service';
import { AddChainDialogComponent } from './chains/chain-adder/add-chain-dialog.component';
import { ChainAdderMultiselectComponent } from './chains/chain-adder/chain-adder-multiselect/chain-adder-multiselect.component';
import { AddChainTriggerComponent } from './chains/chain-adder/trigger/add-chain-trigger.component';
import { ChainDetailsDialogComponent } from './chains/chains-detail-dialog/chain-details-dialog.component';
import { ChainDetailsScreenService } from './chains/chains-detail-dialog/chain-details-screen.service';
import { ChainsDetailMultiselectComponent } from './chains/chains-detail-dialog/chains-detail-multiselect/chains-detail-multiselect.component';
import { ConfirmRemovingChainDialogComponent } from './chains/chains-detail-dialog/confirm-removing/confirm-removing-chain-dialog.component';
import { ShortageTableComponent } from './chains/chains-detail-dialog/shortage-table/shortage-table.component';
import { ChainsComponent } from './chains/chains.component';
import { ChainsService } from './chains/chains.service';
import { CogComponent } from './cog/cog-component/cog.component';
import { CogElementComponent } from './cog/cog-element/cog-element.component';
import { ContactsComponent } from './contacts/contacts.component';
import { DynamicTableComponent } from './dynamic-table/dynamic-table.component';
import { DynamicTableService } from './dynamic-table/dynamic-table.service';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { EventsTableComponent } from './events/events-table.component';
import { EventsComponent } from './events/events.component';
import { EventsService } from './events/events.service';
import { ExcelExportComponent } from './excel-export/excel-export.component';
import { ChipComponent } from './filter-chips/chip-component/chip.component';
import { ChipService } from './filter-chips/chip-component/chip.service';
import { SignPicker } from './filter-chips/chip-component/sign-picker/sign-picker.component';
import { ChipListComponent } from './filter-chips/chip-list-component/chip-list.component';
import { ChipListService } from './filter-chips/chip-list-component/chip-list.service';
import { AddFilterComponent } from './filter-chips/filter-component/add-filter/add-filter.component';
import { FilterListComponent } from './filter-chips/filter-component/filter-list/filter-list.component';
import { FilterService } from './filter-chips/filter-component/filter.service';
import { MultiSelect } from './filter-chips/multi-select/multi-select.component';
import { ChipSelectOneComponent } from './filter-chips/select/chip-select-one.component';
import { FilterMultiComponent } from './filter/filter-multi/filter-multi.component';
import { FilterSelectComponent } from './filter/filter-select/filter-select.component';
import { BrandPickerComponent } from './inputs/brand-picker/brand-picker.component';
import { FinanceChartComponent } from './finances/chart/finance-chart.component';
import { FinanceDetailsCardComponent } from './finances/details-card/finance-details-card.component';
import { RotatingCardComponent } from './finances/details-card/rotating-card.component';
import { FinancesComponent } from './finances/finances.component';
import { CustomDatepickerHeader } from './finances/period-picker/custom-header.component';
import { PeriodPickerComponent } from './finances/period-picker/period-picker.component';
import { ResolutionPickerComponent } from './inputs/resolution-picker/resolution-picker.component';
import { RotatingTableComponent } from './finances/rotating-table/rotating-table.component';
import { SeriesPickerComponent } from './inputs/series-picker/series-picker.component';
import { FinanceTableComponent } from './finances/table/finance-table.component';
import { DateRangePickerComponent } from './inputs/date-range-picker/date-range-picker.component';
import { FileUploadComponent } from './inputs/file-upload/file-upload.component';
import { OneDrivePickerComponent } from './inputs/one-drive-picker/one-drive-picker.component';
import { LoaderComponent } from './loader-component/loader.component';
import { LoaderService } from './loader-component/loader.service';
import { LoginComponent } from './login/login.component';
import { SolgarConstructComponent } from './logos/solgar-construct/solgar-construct.component';
import { SolgarLogoComponent } from './logos/solgar/solgar-logo.component';
import { CustomInfoWindowComponent } from './map/custom-info-window.component';
import { GooglePlacesAutocompleteComponent } from './map/google-places-autocomplete/google-places-autocomplete.component';
import { MapComponent } from './map/map.component';
import { MapService } from './map/map.service';
import { ActionDialogComponent } from './messaging/action-dialog/action-dialog.component';
import { DialogMessagingComponent } from './messaging/dialog-messaging/dialog-messaging.component';
import { SnackbarMessagingComponent } from './messaging/snackbar-messaging/snackbar-messaging.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavbarService } from './navbar/navbar.service';
import { CalendarEventNotificationComponent } from './notification-component/calendar-event-notification/calendar-event-notification.component';
import { NotificationComponent } from './notification-component/notification.component';
import { PlainEventNotificationComponent } from './notification-component/plain-notification/plain-event-notification.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { AdminPageService } from './pages/admin-page/admin-page.service';
import { AdminConfigurationTabComponent } from './pages/admin-page/sub-pages/admin-configuration-tab/admin-configuration-tab.component';
import { AdminConfigurationTabService } from './pages/admin-page/sub-pages/admin-configuration-tab/admin-configuration-tab.service';
import { AdminConsentTabComponent } from './pages/admin-page/sub-pages/admin-consent-tab/admin-consent-tab.component';
import { ConfirmActivatingConsentDialogComponent } from './pages/admin-page/sub-pages/admin-consent-tab/confirm-activating/confirm-activating-consent-dialog.component';
import { ConfirmRemovingConsentDialogComponent } from './pages/admin-page/sub-pages/admin-consent-tab/confirm-removing/confirm-removing-consent-dialog.component';
import { AdminConsentTabDialogComponent } from './pages/admin-page/sub-pages/admin-consent-tab/dialog/admin-consent-tab-dialog.component';
import { AdminContactsHistoryTabComponent } from './pages/admin-page/sub-pages/admin-contacts-history-tab/admin-contacts-history-tab.component';
import { AdminContactsHistoryTabService } from './pages/admin-page/sub-pages/admin-contacts-history-tab/admin-contacts-history-tab.service';
import { AdminTeamTabComponent } from './pages/admin-page/sub-pages/admin-team-tab/admin-team-tab.component';
import { AdminTeamTabService } from './pages/admin-page/sub-pages/admin-team-tab/admin-team-tab.service';
import { AdminTeamTabDialogComponent } from './pages/admin-page/sub-pages/admin-team-tab/dialog/admin-team-tab-dialog.component';
import { AdminUserTabComponent } from './pages/admin-page/sub-pages/admin-user-tab/admin-user-tab.component';
import { AdminUserTabService } from './pages/admin-page/sub-pages/admin-user-tab/admin-user-tab.service';
import { ConfirmRemovingUserDialog } from './pages/admin-page/sub-pages/admin-user-tab/confirm-removing/confirm-removing-user-dialog.component';
import { AdminUserTabDialogComponent } from './pages/admin-page/sub-pages/admin-user-tab/dialog/admin-user-tab-dialog.component';
import { AddPartnerDialogComponent } from './partners/partner-adder/add-partner-dialog.component';
import { AddPartnerTriggerComponent } from './partners/partner-adder/trigger/add-partner-trigger.component';
import { AddContactDialogComponent } from './partners/partner-details-dialog/add-contact-dialog/add-contact-dialog.component';
import { ArchiveFinancialIdDialogComponent } from './partners/partner-details-dialog/archive-financial-id/archive-financial-id-dialog.component';
import { BenefitAttachmentComponent } from './partners/partner-details-dialog/benefit-attachment/benefit-attachment.component';
import { BenefitTabComponent } from './partners/partner-details-dialog/benefit-tab/benefit-tab.component';
import { BrandTabComponent } from './partners/partner-details-dialog/brand-tab/brand-tab.component';
import { ConfirmRemovingBrandDialogComponent } from './partners/partner-details-dialog/confirm-removing-brand/confirm-removing-brand-dialog.component';
import { ConfirmRemovingPartnerDialogComponent } from './partners/partner-details-dialog/confirm-removing/confirm-removing-partner-dialog.component';
import { ConsentsTabComponent } from './partners/partner-details-dialog/consents-tab/consents-tab.component';
import { EventsTabComponent } from './partners/partner-details-dialog/events-tab/events-tab.component';
import { GeneralInformationTabComponent } from './partners/partner-details-dialog/general-tab/general-information-tab.component';
import { PartnerDetailsDialogComponent } from './partners/partner-details-dialog/partner-details-dialog.component';
import { PartnerDetailsScreenService } from './partners/partner-details-dialog/partner-details-screen.service';

import { PartnersComponent } from './partners/partners.component';
import { PartnersService } from './partners/partners.service';
import { Playground } from './playground/playground.component';
import { ConfigurationPickerComponent } from './screen-configuration/configuration-picker/configuration-picker.component';
import { UpdateConfigurationComponent } from './screen-configuration/update-configuration/update-configuration.component';
import { SearchAsYouTypeInputComponent } from './search-as-you-type/search-as-you-type-input.component';
import { TaskDialogComponent } from './tasks/task-dialog/task-dialog.component';
import { TasksComponent } from './tasks/tasks.component';
import { UtilWrapperComponent } from './util-wrapper-component/util-wrapper.component';
import { CalendarGuardService } from './_services/guards/calendar-guard.service';
import { MapGuardService } from './_services/guards/map-guard.service';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AnalyticsPeriodPickerComponent } from './analytics/analytics-period-picker/analytics-period-picker.component';
import { AnalyticsChartComponent } from './analytics/analytics-chart/analytics-chart.component';
import { ChartModePickerComponent } from './analytics/chart-mode-picker/chart-mode-picker.component';
import {ProductsComponent} from './products/products.component';
import {ProductsService} from './products/products.service';
import {ProductsDetailsDialogComponent} from './products/products-detail-dialog/products-details-dialog.component';
import {ProductsImageComponent} from './products/products-detail-dialog/base/product-image.component';
import {FinancesService} from './finances/finances.service';
import {ProcuratorsComponent} from './procurators/procurators.component';
import {ProcuratorDetailsDialogComponent} from './procurators/procurator-details-dialog/procurator-details-dialog.component';
import {ProcuratorInformationTabComponent} from './partners/partner-details-dialog/procurator-tab/procurator-information-tab.component';
import {ProcuratorsService} from './procurators/procurators.service';
import {ExtendedPicker} from './inputs/extended-picker/extended-picker';
import {InfoDialogComponent} from './info-dialog/info-dialog.component';
import {CooperationPickerComponent} from './inputs/cooperation-picker/cooperation-picker.component';
import {SimpleCardComponent} from './finances/simple-card/simple-card.component';
import {EntrepreneursComponent} from './entrepreneurs/entrepreneurs.component';
import {AddEntrepreneurTriggerComponent} from './entrepreneurs/entrepreneur-adder/trigger/add-entrepreneur-trigger.component';
import {EntrepreneursService} from './entrepreneurs/entrepreneurs.service';
import {EntrepreneurDetailsDialogComponent} from './entrepreneurs/entrepreneur-details-dialog/entrepreneur-details-dialog.component';
import {
    EntrepreneurGeneralInformationTabComponent
} from './entrepreneurs/entrepreneur-details-dialog/general-tab/entrepreneur-general-information-tab.component';
import {ImageStatusComponent} from './entrepreneurs/entrepreneur-details-dialog/mini/image-status.component';
import {
    EntrepreneurRepresentativeTabComponent
} from './entrepreneurs/entrepreneur-details-dialog/representative-tab/entrepreneur-representative-tab.component';
import {BrandInfoComponent} from './entrepreneurs/entrepreneur-details-dialog/mini/brand-info.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {VarDirective} from './_utils/ng-var.directive';
import {
    RepresentativeEntryComponent
} from './entrepreneurs/entrepreneur-details-dialog/representative-entry/representative-entry.component';
import {
    RepresentativeFileComponent
} from './entrepreneurs/entrepreneur-details-dialog/representative-entry/representative-file/representative-file.component';
import {
    DeliveryAddressTableComponent
} from './partners/partner-details-dialog/brand-tab/delivery-address-table/delivery-address-table.component';
import {EtailerService} from './_services/etailer.service';
import {
    SingleBrandTabComponent
} from './partners/partner-details-dialog/single-brand-tab/single-brand-tab.component';
import {
    EtailerEntryComponent
} from './partners/partner-details-dialog/single-brand-tab/etailer-entry/etailer-entry.component';

@NgModule({
    declarations: [
        VarDirective,
        CustomInfoWindowComponent,
        AddChainDialogComponent,
        AddChainTriggerComponent,
        AddFilterComponent,
        AddPartnerDialogComponent,
        AddPartnerTriggerComponent,
        AddEntrepreneurTriggerComponent,
        AdminConfigurationTabComponent,
        AdminPageComponent,
        AdminTeamTabComponent,
        AdminUserTabComponent,
        AppComponent,
        AvatarComponent,
        BodyWithSidebarComponent,
        BrandInfoComponent,
        BrandPickerComponent,
        CalendarEventNotificationComponent,
        CalendarScreenComponent,
        ChainDetailsDialogComponent,
        ChainsComponent,
        ChangePasswordComponent,
        ChangePasswordDialogComponent,
        ChipComponent,
        ChipListComponent,
        ChipSelectOneComponent,
        CogComponent,
        CogElementComponent,
        ConfigurationPickerComponent,
        ConfirmDialogComponent,
        ContactsComponent,
        CooperationPickerComponent,
        CustomDatepickerHeader,
        DateRangePickerComponent,
        DeliveryAddressTableComponent,
        DialogMessagingComponent,
        DynamicTableComponent,
        AdminUserTabDialogComponent,
        ErrorDialogComponent,
        EntrepreneurDetailsDialogComponent,
        EntrepreneurGeneralInformationTabComponent,
        EntrepreneurRepresentativeTabComponent,
        EventCalendarComponent,
        EventDialogComponent,
        ExtendedPicker,
        FilterListComponent,
        FilterMultiComponent,
        FilterSelectComponent,
        FinanceChartComponent,
        FinanceDetailsCardComponent,
        FinancesComponent,
        FinanceTableComponent,
        InfoDialogComponent,
        ImageStatusComponent,
        ProductsComponent,
        LoaderComponent,
        LoginComponent,
        MapComponent,
        MultiSelect,
        NavbarComponent,
        NotificationComponent,
        PartnerDetailsDialogComponent,
        PartnersComponent,
        PeriodPickerComponent,
        PlainEventNotificationComponent,
        PlainEventNotificationComponent,
        Playground,
        ProcuratorsComponent,
        ProcuratorInformationTabComponent,
        ProcuratorDetailsDialogComponent,
        ProductsImageComponent,
        ProductsDetailsDialogComponent,
        ResolutionPickerComponent,
        RepresentativeEntryComponent,
        RepresentativeFileComponent,
        RotatingCardComponent,
        RotatingTableComponent,
        SearchAsYouTypeInputComponent,
        SeriesActionDialogComponent,
        SeriesPickerComponent,
        ShortageTableComponent,
        SignPicker,
        SingleBrandTabComponent,
        SnackbarMessagingComponent,
        SolgarConstructComponent,
        SolgarLogoComponent,
        TaskDialogComponent,
        TasksComponent,
        ThemePickerComponent,
        UpdateConfigurationComponent,
        UtilWrapperComponent,
        AdminTeamTabDialogComponent,
        EventsComponent,
        ConfirmRemovingPartnerDialogComponent,
        ConfirmRemovingUserDialog,
        ChipSelectOneComponent,
        ChainsDetailMultiselectComponent,
        ChainAdderMultiselectComponent,
        EventsTableComponent,
        EventsTabComponent,
        ConfirmRemovingChainDialogComponent,
        ConfirmRemovingBrandDialogComponent,
        AdminContactsHistoryTabComponent,
        CalendarSidebarComponent,
        OnDropDialogComponent,
        GooglePlacesAutocompleteComponent,
        AdminConsentTabComponent,
        ConfirmActivatingConsentDialogComponent,
        ConfirmRemovingConsentDialogComponent,
        AdminConsentTabDialogComponent,
        ConsentsTabComponent,
        BenefitTabComponent,
        GeneralInformationTabComponent,
        BrandTabComponent,
        ExcelExportComponent,
        ActionDialogComponent,
        FileUploadComponent,
        OneDrivePickerComponent,
        BenefitAttachmentComponent,
        AddContactDialogComponent,
        ArchiveFinancialIdDialogComponent,
        AnalyticsComponent,
        AnalyticsPeriodPickerComponent,
        AnalyticsChartComponent,
        ChartModePickerComponent,
        SimpleCardComponent,
        EtailerEntryComponent,
        EntrepreneursComponent
    ],
    imports: [
        AppRoutingModule,
        AutoSizeInputModule,
        BrowserAnimationsModule,
        ButtonModule,
        CalendarModule,
        ChartModule,
        DeviceDetectorModule.forRoot(),
        DndModule,
        FlexLayoutModule,
        FullCalendarModule,
        HttpClientModule,
        MatDialogModule,
        MaterialModule,
        MatMomentDateModule,
        NgxDaterangepickerMd.forRoot({
            applyLabel: 'Okay',
            firstDay: 3
        }),
        NgxDnDModule,
        NgxMaskModule.forRoot(),
        NgxMatSelectSearchModule,
        OverlayModule,
        ReactiveFormsModule,
        SharedModule,
        ToastModule,
        QuillModule.forRoot()
    ],
    entryComponents: [
        CustomInfoWindowComponent,
        AddChainDialogComponent,
        AddPartnerDialogComponent,
        ChainDetailsDialogComponent,
        ChangePasswordDialogComponent,
        CustomDatepickerHeader,
        DialogMessagingComponent,
        AdminUserTabDialogComponent,
        AdminTeamTabDialogComponent,
        ErrorDialogComponent,
        EventDialogComponent,
        PartnerDetailsDialogComponent,
        SeriesActionDialogComponent,
        SnackbarMessagingComponent,
        TaskDialogComponent,
        ConfirmRemovingPartnerDialogComponent,
        ConfirmRemovingUserDialog,
        ConfirmRemovingChainDialogComponent,
        ConfirmRemovingBrandDialogComponent,
        OnDropDialogComponent,
        ConfirmActivatingConsentDialogComponent,
        ConfirmRemovingConsentDialogComponent,
        AdminConsentTabDialogComponent,
        ActionDialogComponent,
        AddContactDialogComponent,
        ArchiveFinancialIdDialogComponent
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
        {provide: MatPaginatorIntl, useClass: MatPaginatorIntlPlProvider},
        {provide: DateAdapter, useClass: MomentUtcDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_LOCALE, useValue: 'pl-PL'},
        AdminConfigurationTabService,
        AdminGuardService,
        AdminPageService,
        AdminTeamTabService,
        AdminUserTabService,
        AvatarService,
        BrandCooperationService,
        BrandCorrelationService,
        CacheService,
        CalendarScreenService,
        ChainDetailsScreenService,
        ChainsService,
        ChipListService,
        ChipService,
        DictionariesService,
        DynamicTableService,
        EntrepreneursService,
        EtailerService,
        EventCalendarService,
        ExcelUtils,
        FilterService,
        FinancesGuardService,
        FinancesService,
        ProductsService,
        ProcuratorsService,
        LoaderService,
        MapService,
        MessageService,
        NavbarService,
        NotificationService,
        PartnerDetailsScreenService,
        PartnersService,
        UserService,
        RedirectionService,
        ScreenService,
        ShortageService,
        TaskService,
        UserConfigService,
        UtilsService,
        EventsService,
        AdminContactsHistoryTabService,
        CalendarSidebarService,
        CalendarGuardService,
        MapGuardService
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
