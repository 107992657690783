import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { UserConfigService } from '../_services/user-config.service';
import { UtilsService } from '../_services/utils.service';
import { canViewAdminPanel } from '../_utils/auth-utils/auth-admin-panel';
import { canViewFinances } from '../_utils/auth-utils/auth-finances';
import { NavbarService } from './navbar.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    @HostBinding('class') componentCssClass;
    options: FormGroup;

    //TODO: remove once admin panel is complete. (Konrad) set to false before PROD release
    protected readonly showAdminPanel = true;

    constructor(fb: FormBuilder,
                private authenticationService: AuthenticationService,
                private userConfigService: UserConfigService,
                private router: Router,
                public overlayContainer: OverlayContainer,
                public readonly navbarService: NavbarService,
                public readonly utilsService: UtilsService) {
        this.options = fb.group({
            bottom: 0,
            fixed: false,
            top: 0
        });
    }

    private _currentUserRoles: number[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authenticationService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }


    ngOnInit(): void {
        this.utilsService.theme$.subscribe((theme: string) => this.onSetTheme(theme))
    }


    public onSetTheme(theme: string): void {
        this.userConfigService.updateTheme(theme).subscribe();
        this.overlayContainer.getContainerElement().classList.add(theme);
        this.componentCssClass = theme;
    }


    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }

    getFirstName(): string {
        if (!this.authenticationService.currentUserValue) {
            return;
        }
        return this.authenticationService.currentUserValue.firstName;
    }

    getLastName(): string {
        if (!this.authenticationService.currentUserValue) {
            return;
        }
        return this.authenticationService.currentUserValue.lastName;
    }

    isLoggedIn(): boolean {
        const currentUser = this.authenticationService.currentUserValue;
        return !!(currentUser && currentUser.token);
    }

    public canViewFinancesTab(): boolean {
        return canViewFinances(this.currentUserRoles)
    }

    public canViewAdminTab(): boolean {
        return canViewAdminPanel(this.currentUserRoles);
    }

}
