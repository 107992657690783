export enum DictionariesEnum {
    NULL = 'NULL',
    PEOPLE_OPERATIONS = 'PEOPLE_OPERATIONS',
    TEAMS = 'TEAMS',
    PEOPLE_TOA = 'PEOPLE_TOA',
    DISTRICTS = 'DISTRICTS',
    BRANDS = 'BRANDS',
    COOPERATION_STATUS = 'COOPERATION_STATUS',
    COOPERATION_TYPE = 'COOPERATION_TYPE',
    CONCERN_COOPERATION_TYPE = 'CONCERN_COOPERATION_TYPE',
    PARTNER_TYPES = 'PARTNER_TYPES',
    GRADES = 'GRADES',
    PHARMACY_TYPES = 'PHARMACY_TYPES',
    INFO_SOURCE = 'INFO_SOURCE',
    TRUTHY = 'TRUTHY',
    CHAINS = 'CHAINS',
    PARTNER_NAMES = 'PARTNER_NAMES',
    ENTREPRENEUR_NAMES = 'ENTREPRENEUR_NAMES',
    TOWNS = 'TOWNS',
    STREETS = 'STREETS',
    TASK_PRIORITIES = 'TASK_PRIORITIES',
    TASK_STATUSES = 'TASK_STATUSES',
    EVENTS = 'EVENTS',
    EVENT_SUBTYPES = 'EVENT_SUBTYPES',
    CONTACT_HISTORY_EVENT_TYPE = 'CONTACT_HISTORY_EVENT_TYPE',
    FINANCES_PRODUCTS_SOLGAR = 'FINANCES_PRODUCTS_SOLGAR',
    FINANCES_PRODUCTS_TERRANOVA = 'FINANCES_PRODUCTS_TERRANOVA',
    PRODUCTS = 'PRODUCTS',
    PRODUCT_CATEGORIES = 'PRODUCT_CATEGORIES'
}
