import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ChartResultModeEnum } from '../../_enums/chart-result-mode.enum';

interface ChartModeOption {
  value: ChartResultModeEnum;
  displayName: string;
}

@Component({
  selector: 'app-chart-mode-picker',
  templateUrl: './chart-mode-picker.component.html',
  styleUrls: ['./chart-mode-picker.component.css']
})
export class ChartModePickerComponent {
  modeControl = new FormControl(null);
  modeOptions: ChartModeOption[] = [
    { value: ChartResultModeEnum.PRICE, displayName: 'Kwotowy'},
    { value: ChartResultModeEnum.QUANTITY, displayName: 'Ilościowy'},
  ];

  @Input() set chartMode(newValue: ChartResultModeEnum) {
    if (newValue !== this.modeControl.value) {
        this.modeControl.setValue(newValue);
    }
  }
  @Output() chartModeChange = new EventEmitter<ChartResultModeEnum>();

  setPickedMode() {
    this.chartModeChange.emit(this.modeControl.value);
  }
}
