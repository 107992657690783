import { ChipElement } from '../filter-chips/chip-component/chip.component';
import { CogElementModel } from './cog-element.model';
import { ColumnElementModel } from './column-element.model';
import { SortElementModel } from './sort-element.model';

export class ConfigModel {
    constructor(public visibleColumnsOrdered: Array<ColumnElementModel>,
                public sort: SortElementModel,
                public filters: Array<ChipElement>,
                public cogColumnsOrdered: Array<CogElementModel>) {
    }
}
