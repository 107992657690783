import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExternalPersonModel } from '../../../_models/external-person.model';

@Injectable(
    {providedIn: 'root'}
)
export class AddContactService {

    constructor(private readonly _httpClient: HttpClient) {
    }

    getExternalContacts(): Observable<ExternalPersonModel[]> {
        return this._httpClient.get<ExternalPersonModel[]>(`/api/dicts/external-people-full-data`);
    }
}