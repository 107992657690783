import { Component, Input, OnInit } from '@angular/core';
import { ContactModel } from '../../../_models/contact.model';
import { DictModel } from '../../../_models/dict.model';
import { PartnerDetailsModel } from '../../../_models/partner-details.model';
import { AuthenticationService } from '../../../_services/authentication.service';
import { DictionariesService } from '../../../_services/dictionaries.service';
import { SozService } from '../../../_services/soz.service';
import {
    canChangeLoginOrResetSozPassword,
    canCreateOrDeactivateSozAccount,
    canEditAddressData,
    canEditChain,
    canEditCoordinatorData,
    canEditCreationDate,
    canEditLicenseNumber,
    canEditLicenseObtainDate,
    canEditNip,
    canEditName,
    canEditPartnerType,
    canEditSource,
    canEditSozFields,
    canEditSozLogin,
    canViewSozFields,
    canUseForceSozButton
} from '../../../_utils/auth-utils/auth-partner-details-general';
import {AuthRolesEnum, canPerformAction} from '../../../_utils/auth-utils/auth-commons';
import {canEditAdminPanel} from '../../../_utils/auth-utils/auth-admin-panel';
import {PartnerDetailsDialogModel} from '../partner-details-dialog.model';

@Component({
    selector: 'app-general-information-tab',
    templateUrl: './general-information-tab.component.html',
    styleUrls: ['general-information-tab.component.scss']
})
export class GeneralInformationTabComponent implements OnInit {
    @Input() partnerDetailsDialogModel: PartnerDetailsDialogModel
    @Input() partnerDetailsModel: PartnerDetailsModel
    @Input() disabled: boolean;
    @Input() anyBrandInCooperation: boolean;
    gradeDict: Array<DictModel>;
    infoSourceDict: Array<DictModel>;
    districtDict: Array<DictModel>;
    partnerTypeDict: Array<DictModel>;
    dictChains: Array<DictModel>;
    filteredChains: Array<DictModel> = [];
    filteredDisctricts: Array<DictModel> = [];
    @Input() showInvalidFields: boolean;
    nullValue: DictModel = {id: null, value: 'Usuń'};

    constructor(public readonly authService: AuthenticationService,
                private readonly dictService: DictionariesService,
                private readonly sozService: SozService
    ) {
    }

    private _currentUserRoles: number[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    get canEditChain(): boolean {
        return canEditChain(this.currentUserRoles);
    }

    get canEditName(): boolean {
        return canEditName(this.currentUserRoles);
    }

    get canEditPartnerType(): boolean {
        return canEditPartnerType(this.currentUserRoles);
    }

    get canEditSource(): boolean {
        return canEditSource(this.currentUserRoles);
    }

    get canEditCreationDate(): boolean {
        return canEditCreationDate(this.currentUserRoles);
    }

    get canEditLicenseNumber(): boolean {
        return canEditLicenseNumber(this.currentUserRoles);
    }

    get canEditLicenseObtainDate(): boolean {
        return canEditLicenseObtainDate(this.currentUserRoles);
    }

    get canEditNip(): boolean {
        return canEditNip(this.currentUserRoles);
    }

    get canEditAddressData(): boolean {
        return canEditAddressData(this.currentUserRoles);
    }

    get canEditCoordinatorData(): boolean {
        return canEditCoordinatorData(this.currentUserRoles);
    }

    get canEditSozFields(): boolean {
        return canEditSozFields(this.currentUserRoles);
    }

    get canEditSozLogin(): boolean {
        return canEditSozLogin(this.currentUserRoles);
    }

    get canViewSozFields(): boolean {
        return canViewSozFields(this.currentUserRoles);
    }

    get canCreateOrDeactivateSozAccount(): boolean {
        return canCreateOrDeactivateSozAccount(this.currentUserRoles);
    }

    get canChangeLoginOrResetSozPassword(): boolean {
        return canChangeLoginOrResetSozPassword(this.currentUserRoles);
    }

    get canUseForceSozButton(): boolean {
        return canUseForceSozButton(this.currentUserRoles);
    }

    isSozLoginRequired(): boolean {
        return this.anyBrandInCooperation;
    }

    ngOnInit(): void {
        if (!this.partnerDetailsModel.plainContacts?.length) {
            this.partnerDetailsModel.plainContacts = [new ContactModel(null, null, null, null)];
        }
        this.dictService.getDistricts().subscribe((districtDict: Array<DictModel>) => {
            this.districtDict = districtDict;
            this.filteredDisctricts = districtDict;
        });
        this.dictService.getInfoSourceType().subscribe((cooperationStatus: Array<DictModel>) => this.infoSourceDict = cooperationStatus);
        this.dictService.getGradeDict().subscribe((cooperationStatus: Array<DictModel>) => this.gradeDict = cooperationStatus);
        this.dictService.getAllChains().subscribe((chains: Array<DictModel>) => {
            this.dictChains = chains;
            this.filteredChains = chains;
        });
        this.dictService.getPartnerTypes().subscribe((partnerType: Array<DictModel>) => this.partnerTypeDict = partnerType);
    }

    public filterChains(event) {
        if (!this.dictChains) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredChains = this.dictChains;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredChains = this.dictChains.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public filterDisctricts(event) {
        if (!this.districtDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredDisctricts = this.districtDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredDisctricts = this.districtDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    createSozAccount(changeButton: boolean) {
        if (changeButton) {
            this.partnerDetailsModel.sozAccountCreated = true;
        }
        this.sozService.createSozAccount(this.partnerDetailsModel.id).subscribe();
    }

    toggleActive() {
        if (this.partnerDetailsModel.sozAccountActive) {
            this.partnerDetailsModel.sozAccountActive = false;
            this.sozService.updateSozAccountActivity(this.partnerDetailsModel.id, {active: false}).subscribe();
        } else {
            this.partnerDetailsModel.sozAccountActive = true;
            this.sozService.updateSozAccountActivity(this.partnerDetailsModel.id, {active: true}).subscribe();
        }
    }

    getSozActivateButtonTitle() {
        if (this.partnerDetailsModel.sozAccountActive) {
            return 'Dezaktywuj konto SOZ';
        }
        return 'Aktywuj konto SOZ';
    }

    resetSozPassword() {
        this.sozService.changePassword(this.partnerDetailsModel.id).subscribe();
    }

    getSozButtonTooltip() : string {
        if (!this.partnerDetailsModel.sozLogin) {
            return "Brak loginu SOZ"
        }

        if (!this.partnerDetailsDialogModel.partnerSaved || !this.partnerDetailsDialogModel.sozLoginSaved) {
            return "Partner nie jest zapisany"
        }

        const brandStatuses = this.partnerDetailsDialogModel.brandCooperationModel
            .map((brand) => brand.cooperationStatusId)
            .concat(
                this.partnerDetailsDialogModel.addedBrandCooperation.map((brand) => brand.cooperationStatusId)
            );

        if (!brandStatuses.includes(1)) {
            return "Brak aktywnej współpracy"
        }
        return ""
    }

    isSozButtonTooltipEnabled() : boolean {
        const brandStatuses = this.partnerDetailsDialogModel.brandCooperationModel
            .map((brand) => brand.cooperationStatusId)
            .concat(
                this.partnerDetailsDialogModel.addedBrandCooperation.map((brand) => brand.cooperationStatusId)
            );

        return this.partnerDetailsModel.sozLogin && this.partnerDetailsDialogModel.partnerSaved && this.partnerDetailsDialogModel.sozLoginSaved && brandStatuses.includes(9)
    }

    isCreateSozButtonDisabled() : boolean {
        const brandStatuses = this.partnerDetailsDialogModel.brandCooperationModel
            .map((brand) => brand.cooperationStatusId)
            .concat(
                this.partnerDetailsDialogModel.addedBrandCooperation.map((brand) => brand.cooperationStatusId)
            );

        return !this.partnerDetailsDialogModel.partnerSaved || !this.partnerDetailsDialogModel.sozLoginSaved && this.partnerDetailsDialogModel.sozLoginSaved && brandStatuses.includes(9)
    }
}
