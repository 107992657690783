import { Component, Input, OnInit } from '@angular/core';
import { EventNotificationModel } from '../../_models/event-notification.model';
import { NotificationService } from '../../_services/notification.service';
import * as moment from 'moment';
import { EventModel } from '../../_models/event-model';
import { CalendarScreenService } from '../../calendar/calendar-screen.service';


@Component({
    selector: 'app-calendar-event-notification',
    templateUrl: './calendar-event-notification.component.html',
    styleUrls: ['./calendar-event-notification.component.scss']
})
export class CalendarEventNotificationComponent implements OnInit {

    @Input() public calendarNotificationModel: EventNotificationModel;
    @Input() public pending: boolean = false;


    protected expandDetails: boolean = true;

    constructor(private readonly notificationService: NotificationService, private readonly calendarScreenService: CalendarScreenService) {
    }

    ngOnInit() {
    }

    openEvent() {
        this.calendarScreenService.getEvent(this.calendarNotificationModel.eventId + '').subscribe((value: EventModel) => {
            this.calendarScreenService.setShowEditDialog$(value);
        });
    }

    protected changeView() {
        this.expandDetails = !this.expandDetails;
    }

    protected getStartDate() {
        if (!this.calendarNotificationModel || !this.calendarNotificationModel.eventStartDate) {
            return null;
        }
        const date = moment(this.calendarNotificationModel.eventStartDate);
        return date.locale('pl').format('dddd, MMMM Do YYYY, h:mm:ss');
    }

    protected accept(event): void {
        this.preventBubbling(event);
        this.addToPending(true);
    }

    protected revoke(event): void {
        this.preventBubbling(event);
        this.addToPending(false);
    }

    protected renew(event): void {
        this.preventBubbling(event);
        this.removeFromPending();
    }

    private removeFromPending(): void {
        this.notificationService.removeFromPendingCalendarNotifications(this.calendarNotificationModel.id);
    }

    private preventBubbling(event): void {
        event.preventDefault();
        event.stopPropagation();
    }

    private addToPending(confirmed: boolean): void {
        this.notificationService.addNewPendingCalendarNotification({
            id: this.calendarNotificationModel.id, eventNotificationPatchModel: {
                seen: true,
                dismissed: true,
                eventConfirmation: confirmed
            }
        });
    }

    private dismissNotification(event): void {
        this.preventBubbling(event);
        this.notificationService.addNewPendingCalendarNotification({
            id: this.calendarNotificationModel.id, eventNotificationPatchModel: {
                dismissed: true
            }
        });
    }

}
