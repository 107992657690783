import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TeamModel } from '../../../../_models/team.model';

@Injectable()
export class AdminTeamTabService {

    constructor(private readonly _httpClient: HttpClient) {
    }

    public getTeams() {
        return this._httpClient.get<Array<TeamModel>>('api/team');
    }

    public addTeam(team: TeamModel) {
        return this._httpClient.post<TeamModel>('api/team', team);
    }

    public editTeam(team: TeamModel) {
        return this._httpClient.put(`api/team/${team.id}`, team);
    }

    public deleteTeam(id: number) {
        return this._httpClient.delete(`api/team/${id}`);
    }

}
