import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DynamicTableService {
    columnChanged = new Subject<number>();

}

export class DynamicTableColumn {
    constructor(public readonly name: string,
                public readonly sortAvailable: boolean) {
    }
}
