import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProductDialogModel} from './products-dialog.model';
import {ChainsDialogModel} from '../../chains/chains-detail-dialog/chains-dialog.model';
import {DictionariesService} from '../../_services/dictionaries.service';
import {ProductDetailsModel} from '../../_models/product-details.model';
import {DictModel} from '../../_models/dict.model';

@Component({
    selector: 'app-product-details-dialog',
    templateUrl: './products-details-dialog.component.html',
    styleUrls: ['./products-details-dialog.component.scss']
})
export class ProductsDetailsDialogComponent implements OnInit {

    constructor(public readonly dictionariesService: DictionariesService,
                @Inject(MAT_DIALOG_DATA) public productDialogModel: ProductDialogModel,
                private dialogRef: MatDialogRef<ChainsDialogModel>
    ) {}

    ngOnInit(): void {

    }

    public onResize(event): void {

    }

    public getStatusName() : string {
        let productStatusDict = this.dictionariesService.getProductStatusDict();
        return (productStatusDict[this.productDialogModel.productModel.status - 1] || new DictModel(2, "Wycofane")).value
    }

    public getStatusColor() : string {
        return {
            1: '#26c97b',
            2: '#ed4d49',
            3: '#edea49',
        }[this.productDialogModel.productModel.status] || '#ed4d49'
    }

    public exit() {
        this.dialogRef.close();
    }

    public getTaxValue(productModel: ProductDetailsModel) : number {
        return productModel.taxPercent * productModel.price / 100.0;
    }

    public getNumberFormat(value: number) : string {
        return new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(value)
    }
}
