import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import {
    canAddBrand,
    canAddPersonalContact,
    canEditBrandAmbassadorId,
    canEditBrandPersonalContacts,
    canEditDateOfOnlineSalesStart,
    canEditFinancialId,
    canEditIsOnlineSeller,
    canEditName,
    canEditOnBoarderId,
    canEditOnboardingDate,
    canEditOwnerId,
    canEditSoz,
    canEditStatus,
    canEditToaId,
    canEditWebPage,
    canRemoveBrand,
    canRemovePersonalContact,
    canSetStatusAsWspolpracaOrZakonczonaWspolprace,
    canEditEtailer,
    canGenerateEtailer
} from 'src/app/_utils/auth-utils/auth-partner-details-brand';
import { BrandCooperationModel } from '../../../_models/brand-cooperation.model';
import { ContactModel } from '../../../_models/contact.model';
import { DictModel } from '../../../_models/dict.model';
import { ExternalPersonModel } from '../../../_models/external-person.model';
import { AuthenticationService } from '../../../_services/authentication.service';
import { BrandCooperationService } from '../../../_services/brand-cooperation.service';
import { DictionariesService } from '../../../_services/dictionaries.service';
import { DialogUtils } from '../../../_utils/dialog.utils';
import { AddContactDialogComponent } from '../add-contact-dialog/add-contact-dialog.component';
import { ArchiveFinancialIdDialogComponent } from '../archive-financial-id/archive-financial-id-dialog.component';
import { ArchiveFinancialIdModel } from '../archive-financial-id/archive-financial-id.model';
import { ArchiveFinancialIdService } from '../archive-financial-id/archive-financial-id.service';
import { ConfirmRemovingBrandDialogComponent } from '../confirm-removing-brand/confirm-removing-brand-dialog.component';
import { PartnerDetailsDialogModel } from '../partner-details-dialog.model';
import {BrandCooperationDeliverAddressModel} from '../../../_models/brand-cooperation-deliver-address.model';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {AuthRolesEnum} from '../../../_utils/auth-utils/auth-commons';
import {EtailerService} from '../../../_services/etailer.service';
import {BrandEtailer} from '../../../_models/brand-etailer.model';

@Component({
    selector: 'app-brand-tab',
    templateUrl: './brand-tab.component.html',
    styleUrls: ['brand-tab.component.scss']
})
export class BrandTabComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() partnerDetailsDialogModel: PartnerDetailsDialogModel;
    @Input() disabled: boolean;
    @Input() showInvalidFields: boolean;
    public cooperationStatusDict: Array<DictModel>;
    public filteredCooperationStatus: Array<DictModel> = [];
    public dictBrands: Array<DictModel>;
    public cooperationTypeDict: Array<DictModel>;
    public peopleDict: Array<DictModel>;
    public filteredOnBoarders: Array<DictModel> = [];
    public filteredOwners: Array<DictModel> = [];
    public toaDict: Array<DictModel>;
    public filteredToas: Array<DictModel>;
    public ambassadorSearchCtrl0: FormControl = new FormControl({value: '', disabled: this.disabled});
    public ambassadorSearchCtrl1: FormControl = new FormControl({value: '', disabled: this.disabled});
    public nullValue: DictModel = {id: null, value: 'Usuń'};
    public searching: boolean = false;
    public allExternals: DictModel[];
    public filteredExternals1$: ReplaySubject<DictModel[]> = new ReplaySubject<DictModel[]>(1);
    public filteredExternals2$: ReplaySubject<DictModel[]> = new ReplaySubject<DictModel[]>(1);

    public brandEtailerLoaded: Array<boolean> = [false, false, false, false];

    archivedDataSource: MatTableDataSource<ArchiveFinancialIdModel>;
    displayedColumns: string[] = [
        'brand',
        'financialId',
        'date',
        'description'
    ];
    protected _onDestroy = new Subject<void>();

    constructor(public readonly authService: AuthenticationService,
                private readonly _dialog: MatDialog,
                private readonly apiService: BrandCooperationService,
                private readonly dictService: DictionariesService,
                private readonly archiveFinancialIdService: ArchiveFinancialIdService,
                private readonly etailerService: EtailerService
    ) {
    }

    private _archivedData: ArchiveFinancialIdModel[];

    get archivedData(): ArchiveFinancialIdModel[] {
        return this._archivedData;
    }

    @Input() set archivedData(value: ArchiveFinancialIdModel[]) {
        this._archivedData = value;
        this.archivedDataSource = new MatTableDataSource<ArchiveFinancialIdModel>(value);
    }

    private _currentUserRoles: number[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    public get canAddBrand(): boolean {
        return canAddBrand(this.currentUserRoles);
    }

    public get canRemoveBrand(): boolean {
        return canRemoveBrand(this.currentUserRoles);
    }

    public get canEditName(): boolean {
        return canEditName(this.currentUserRoles);
    }

    public get canEditOnBoarderId(): boolean {
        return canEditOnBoarderId(this.currentUserRoles);
    }

    public get canEditFinancialId(): boolean {
        return canEditFinancialId(this.currentUserRoles);
    }

    public get canEditOnboardingDate(): boolean {
        return canEditOnboardingDate(this.currentUserRoles);
    }

    public get canEditOwnerId(): boolean {
        return canEditOwnerId(this.currentUserRoles);
    }

    public get canEditToaId(): boolean {
        return canEditToaId(this.currentUserRoles);
    }

    public get canEditIsOnlineSeller(): boolean {
        return canEditIsOnlineSeller(this.currentUserRoles);
    }

    public get canEditDateOfOnlineSalesStart(): boolean {
        return canEditDateOfOnlineSalesStart(this.currentUserRoles);
    }

    public get canEditWebPage(): boolean {
        return canEditWebPage(this.currentUserRoles);
    }

    public get canEditBrandAmbassadorId(): boolean {
        return canEditBrandAmbassadorId(this.currentUserRoles);
    }

    public get canAddPersonalContact(): boolean {
        return canAddPersonalContact(this.currentUserRoles);
    }

    public get canRemovePersonalContact(): boolean {
        return canRemovePersonalContact(this.currentUserRoles);
    }

    public get canEditPersonalContacts(): boolean {
        return canEditBrandPersonalContacts(this.currentUserRoles);
    }

    public get canEditEtailer(): boolean {
        return canEditEtailer(this.currentUserRoles);
    }

    public get canGenerateEtailer(): boolean {
        return canGenerateEtailer(this.currentUserRoles);
    }

    public get canEditSoz(): boolean {
        return canEditSoz(this.currentUserRoles);
    }

    public canEditStatus(brand: BrandCooperationModel, val: DictModel): boolean {
        const roles = this.currentUserRoles;
        if (roles.includes(AuthRolesEnum.SYSTEM_ADMIN)) {
            return true;
        }

        if (roles.includes(AuthRolesEnum.TOA_MANAGER)) {
            return true;
        }

        if (roles.includes(AuthRolesEnum.TOA)) {
            return true;
        }

        if (roles.includes(AuthRolesEnum.LEGAL_DEPARTMENT)) {
            return true;
        }

        if (roles.includes(AuthRolesEnum.REGIONAL_COORDINATOR)) {
            if ([1, 7].includes(brand.cooperationStatusId)) {
                return false
            }

            return ![1, 7].includes(val.id);
        }

        return false;
    }

    archiveFinancialId(brand: BrandCooperationModel) {
        const archiveIdDialog = this._dialog.open(ArchiveFinancialIdDialogComponent,
            {
                width: '470px'
            });

        archiveIdDialog.afterClosed().pipe(takeUntil(this._onDestroy))
            .subscribe((result: ArchiveFinancialIdModel) => {
                    if (!result) {
                        return;
                    }
                    this.archiveFinancialIdService.archiveFinancialId(brand.id, result).pipe(
                        tap(() => brand.financialId = result.financialId),
                        switchMap(() => this.archiveFinancialIdService.getArchiveData(this.partnerDetailsDialogModel.partnerDetailsModel.id))
                    ).subscribe((data) =>
                        this.archivedDataSource = new MatTableDataSource<ArchiveFinancialIdModel>(data)
                    );
                }
            );
    }

    ngOnInit(): void {

        this.ambassadorSearchCtrl0.valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterExternals0();
            });
        this.ambassadorSearchCtrl1.valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterExternals1();
            });
        //TODO: remove one of them if possible
        this.dictService.getBrandDictionary().subscribe((brandCooperation: Array<DictModel>) => {
            this.dictBrands = brandCooperation;
        });

        this.dictService.getPeople().subscribe((peopleDict: Array<DictModel>) => {
            this.peopleDict = peopleDict;
            this.filteredOnBoarders = peopleDict;
            this.filteredOwners = peopleDict;
        });
        this.dictService.getToaPeople().subscribe((toaDict: Array<DictModel>) => {
            this.toaDict = toaDict;
            this.filteredToas = toaDict;
        });

        this.dictService.getCooperationStatues().subscribe((cooperationStatus: Array<DictModel>) => {
            this.cooperationStatusDict = cooperationStatus;
            this.filteredCooperationStatus = cooperationStatus;
        });
        this.dictService.getCooperationType().subscribe((agreementStatus: Array<DictModel>) => {
            this.cooperationTypeDict = agreementStatus;
            this.cooperationTypeDict.sort((a, b) => b.id - a.id);
        });
        this.dictService.getAllExternalPerson().subscribe(data => this.allExternals = data);

        // this.partnerDetailsDialogModel.brandCooperationModel.forEach(value => {
        //     value.deliverAddressSourceTable = new MatTableDataSource<BrandCooperationDeliverAddressModel>(value.deliverAddress);
        // })
    }

    ngAfterViewInit(): void {
        if (!this.partnerDetailsDialogModel || !this.partnerDetailsDialogModel.brandCooperationModel || this.partnerDetailsDialogModel.brandCooperationModel.length === 0) {
            console.log('noBrandCoop in afterViewInit');
        }
        this.partnerDetailsDialogModel.brandCooperationModel.forEach(
            (brand: BrandCooperationModel, index: number) =>
                brand.brandAmbassadorId && this.dictService.getExternalPersonById(brand.brandAmbassadorId).subscribe((extAmbasador) => {
                    setTimeout(() => {
                        if (index === 0) {
                            console.log('setting first search ctrl for:', extAmbasador);
                            this.ambassadorSearchCtrl0.setValue(' ');
                            console.log('clearing first search ctrl for', extAmbasador);
                            this.ambassadorSearchCtrl0.setValue('');
                        } else {
                            console.log('setting second search ctrl for:', extAmbasador);
                            this.ambassadorSearchCtrl1.setValue(' ');
                            console.log('clearing second search ctrl for', extAmbasador);
                            this.ambassadorSearchCtrl1.setValue('');
                        }
                    });
                })
        );
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    public getAllBrands(): Array<BrandCooperationModel> {
        return [...this.partnerDetailsDialogModel.brandCooperationModel, ...this.partnerDetailsDialogModel.addedBrandCooperation];
    }

    public getBrandName(brandId: number) {
        const brand = this.dictBrands && this.dictBrands.find(brand => brand.id === brandId);
        return brand && `${brand.value}` || '';
    }

    public getBrandShortName(brandId: number) {
        return brandId == 2 ? "tnc" : "snc";
    }

    public removeExistingBrand(brand: BrandCooperationModel): void {
        const deleteBrandDialog = this._dialog.open(ConfirmRemovingBrandDialogComponent,
            {
                width: DialogUtils.defaultDialogWidth
            });

        deleteBrandDialog.afterClosed().pipe(takeUntil(this._onDestroy))
            .subscribe((result: boolean) => {
                    if (!result) {
                        return;
                    }
                    this.apiService.deleteBrand(brand).subscribe();
                    const index = this.partnerDetailsDialogModel.brandCooperationModel.findIndex(el => el.id === brand.id);
                    this.partnerDetailsDialogModel.brandCooperationModel.splice(index, 1);
                }
            );

    }

    public editBrand(brandCooperationModel: BrandCooperationModel): void {
        if (!this.disabled && !this.partnerDetailsDialogModel.editedBrandCooperation.includes(brandCooperationModel) && !this.partnerDetailsDialogModel.addedBrandCooperation.includes(brandCooperationModel)) {
            this.partnerDetailsDialogModel.editedBrandCooperation.push(brandCooperationModel);
        }

        this.partnerDetailsDialogModel.partnerSaved = false;
    }

    public editBrandAuthorization(event: MatCheckboxChange, brandCooperationModel: BrandCooperationModel): void {
        if (!this.disabled && !this.partnerDetailsDialogModel.editedBrandCooperation.includes(brandCooperationModel) && !this.partnerDetailsDialogModel.addedBrandCooperation.includes(brandCooperationModel)) {
            this.partnerDetailsDialogModel.editedBrandCooperation.push(brandCooperationModel);
        }

        this.partnerDetailsDialogModel.partnerSaved = false;

        if (!event.checked) {
            brandCooperationModel.rightToProductsForSncPoints = false;
            brandCooperationModel.rightToReplanishment = false;
        }
    }

    public filterCooperationStatus(event) {
        if (!this.cooperationStatusDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredCooperationStatus = this.cooperationStatusDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredCooperationStatus = this.cooperationStatusDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public filterOnBoarder(event) {
        if (!this.peopleDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredOnBoarders = this.peopleDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredOnBoarders = this.peopleDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public filterOwners(event) {
        if (!this.peopleDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredOwners = this.peopleDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredOwners = this.peopleDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public filterToas(event) {
        if (!this.toaDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredToas = this.toaDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredToas = this.toaDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public removeCertainContactFromArrayForBrand(brand: BrandCooperationModel, contactModel: ContactModel): void {
        brand.plainContacts = brand.plainContacts.filter(contact => contact !== contactModel);
    }

    public addContactToArray(contactModels: ContactModel[]): void {
        contactModels.push(new ContactModel(null, null, null, null));
    }

    public addPersonContactToArray(externalPersonModels: ExternalPersonModel[]): void {
        const addContact = this._dialog.open(AddContactDialogComponent,
            {
                width: DialogUtils.defaultDialogWidth
            });

        addContact.afterClosed().pipe(takeUntil(this._onDestroy))
            .subscribe((result: ExternalPersonModel) => {
                    if (!result) {
                        return;
                    } else {
                        externalPersonModels.push(result);
                    }
                }
            );
    }

    public removeCertainPersonContactFromArrayForBrand(brand: BrandCooperationModel, contactModel: ExternalPersonModel): void {
        brand.peopleContacts = brand.peopleContacts.filter(contact => contact !== contactModel);
    }

    public removeAddedBrand(): void {
        this.partnerDetailsDialogModel.addedBrandCooperation.pop();
    }

    public addBrand(): void {
        let shortage: BrandCooperationModel = new BrandCooperationModel(this.partnerDetailsDialogModel.partnerDetailsModel.id, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, [], [], [], false, []);
        this.partnerDetailsDialogModel.addedBrandCooperation.push(shortage);
    }

    protected canEditMarketingData(): boolean {
        return this.authService.partnerFullEditScope() || this.authService.partnerMarketingDataEditScope();
    }

    //TODO: if dynamic approach fails, Refactor so it will be only one filterExternals left
    protected filterExternals0() {
        if (!this.allExternals) {
            return;
        }
        // get the search keyword
        let search = this.ambassadorSearchCtrl0.value;
        if (!search) {
            this.filteredExternals1$.next(this.allExternals.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredExternals1$.next(
            this.allExternals.filter(external => external.value.toLowerCase().indexOf(search) > -1)
        );
    }

    protected filterExternals1() {
        if (!this.allExternals) {
            return;
        }
        // get the search keyword
        let search = this.ambassadorSearchCtrl1.value;
        if (!search) {
            this.filteredExternals2$.next(this.allExternals.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredExternals2$.next(
            this.allExternals.filter(external => external.value.toLowerCase().indexOf(search) > -1)
        );
    }

    brandLoyaltyTooltip(brand: BrandCooperationModel): string {
        if (!brand.authorizedPoint) {
            return "Punkt bez autoryzacji nie ma prawa do punktów " + (brand.brandId == 1 ? "snc" : "tnc");
        }

        let partnerDetailsExtraModel = this.partnerDetailsDialogModel.partnerDetailsExtraModel;

        let concernLoyaltyProgram = brand.brandId === 1 ? partnerDetailsExtraModel.solgarConcernLoyaltyProgram : partnerDetailsExtraModel.terranovaConcernLoyaltyProgram;

        if (concernLoyaltyProgram === null) {
            return "Przedsiebiorca nie znaleziony"
        }
        if (!concernLoyaltyProgram) {
            return "Przedsiebiorca nie zezwala na punkty " + (brand.brandId == 1 ? "snc" : "tnc")
        }
        return "";
    }

    brandLoyaltyTooltipDisabled(brand: BrandCooperationModel): boolean {
        if (this.disabled) {
            return true;
        }

        if (!brand.authorizedPoint) {
            return false;
        }

        let partnerDetailsExtraModel = this.partnerDetailsDialogModel.partnerDetailsExtraModel;
        let concernLoyaltyProgram = brand.brandId === 1 ? partnerDetailsExtraModel.solgarConcernLoyaltyProgram : partnerDetailsExtraModel.terranovaConcernLoyaltyProgram;

        if (concernLoyaltyProgram === null) {
            return false;
        }

        return concernLoyaltyProgram;
    }

    isDisabledCooperation(brand: BrandCooperationModel, option: DictModel) {

        let partnerDetailsExtraModel = this.partnerDetailsDialogModel.partnerDetailsExtraModel;

        let concernCooperationStatusId = brand.brandId === 1 ? partnerDetailsExtraModel.solgarConcernCooperationStatusId : partnerDetailsExtraModel.terranovaConcernCooperationStatusId;

        if (concernCooperationStatusId === null) {
            return true;
        }

        switch (option.id) {
            case 1:
                return concernCooperationStatusId !== 9
            // 2 juz nie istnieje
            case 3:
                return concernCooperationStatusId !== 7
            case 4:
                return ![4, 5, 6].includes(concernCooperationStatusId)
        }

        return true
    }

    generateEtailerNumber(brand: BrandCooperationModel) {
        // const etailerModelObservable = this.etailerService.generateNumber(brand.id);
        // etailerModelObservable.subscribe(etailerModel => {
        //     brand.etailerNumber = etailerModel.etailerNumber;
        // });
    }

    generateEtailer(brand: BrandCooperationModel) {
        // const etailerModelObservable = this.etailerService.generateEtailer(brand.id, brand.etailerNumber, brand.webPage);
        // etailerModelObservable.subscribe(() => {
        //     this.brandEtailerLoaded[brand.brandId] = false;
        //     brand.etailer = false;
        //     setTimeout(() => {brand.etailer = true}, 20);
        // }, () => {
        //
        // });
    }

    sendEtailerToEmail(brand: BrandCooperationModel) {

    }

    generateEtailerTooltip(brand: BrandCooperationModel) : EtailerGeneratorTooltip{
        if (!this.partnerDetailsDialogModel.partnerSaved) {
            return {disabled: false, text: "Partner nie jest zapisany"}
        }
        if (brand.webPage == undefined || brand.webPage?.length == 0) {
            return {disabled: false, text: "Brakuje adresu strony www"}
        }

        return {disabled: true, text: ""};
    }

    addEtailerEntry(etailers: Array<BrandEtailer>) {
        etailers.push(new BrandEtailer(null, null, null));
    }
}

export interface EtailerGeneratorTooltip {
    disabled: boolean;
    text: string
}
