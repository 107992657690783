import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActionDialog } from '../messaging/action-dialog/action-dialog.component';
import { DialogMessage } from '../messaging/dialog-messaging/dialog-messaging.component';
import { SnackbarMessage } from '../messaging/snackbar-messaging/snackbar-messaging.component';

@Injectable()
export class MessageService {
    public snackbar$: Subject<SnackbarMessage> = new Subject<SnackbarMessage>();
    public dialog$: Subject<DialogMessage> = new Subject<DialogMessage>();
    public actionDialog$: Subject<ActionDialog> = new Subject<ActionDialog>();

    public setSnackbar(message: SnackbarMessage): void {
        this.snackbar$.next(message);
    }

    public setDialog(message: DialogMessage): void {
        this.dialog$.next(message);
    }


    public setActionDialog(action: ActionDialog): void {
        this.actionDialog$.next(action);
    }

}
