import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventModel } from '../../_models/event-model';
import { CalendarScreenService } from '../calendar-screen.service';
import { EventCalendarService } from '../event-calendar/event-calendar.service';
import { CalendarSidebarService } from './calendar-sidebar.service';

@Component({
    selector: 'app-calendar-sidebar',
    templateUrl: './calendar-sidebar.component.html',
    styleUrls: ['./calendar-sidebar.component.scss']
})
export class CalendarSidebarComponent implements OnInit, OnDestroy {

    @Input()
    public latestEvents: EventModel[];
    public eventId: string;
    private readonly unsubscribe$: Subject<null> = new Subject<null>();

    constructor(private readonly calendarScreenService: CalendarScreenService,
                private readonly eventCalendarService: EventCalendarService,
                private readonly calendarSidebarService: CalendarSidebarService) {

    }

    private static toMoment(date: string | Moment): Moment {
        if (typeof date === 'string') {
            return moment(date);
        }
        return date;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    public showEditDialog(eventId: number): void {
        if (!eventId) {
            return;
        }
        this.calendarScreenService.getEvent(eventId + '').subscribe(value => {
            value.refreshCalendar = true;
            this.calendarScreenService.setShowEditDialog$(value);
        });
    }

    ngOnInit(): void {
        this.setLatest();

        this.calendarSidebarService.getRefresh().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe(() => this.setLatest());
    }

    protected isSameDay(eventStart: string | Moment, eventEnd: string | Moment): boolean {

        const eventStartM: Moment = CalendarSidebarComponent.toMoment(eventStart);
        const eventEndM: Moment = CalendarSidebarComponent.toMoment(eventEnd);

        return eventEndM.isSame(eventStartM, 'date');
    }

    protected getFormattedDateSameDay(eventStart: Moment | string) {
        const eventStartM: Moment = CalendarSidebarComponent.toMoment(eventStart);
        return eventStartM.locale('pl').format('dddd, MMMM Do YYYY');
    }

    protected getFormattedTimeSameDay(eventStart: Moment | string, eventEnd: Moment | string) {
        const eventStartM: Moment = CalendarSidebarComponent.toMoment(eventStart);
        const eventEndM: Moment = CalendarSidebarComponent.toMoment(eventEnd);
        const timeStart: string = `${eventStartM.get('hours') === 0 && '00' || eventStartM.get('hours')}:${eventStartM.get('minutes') === 0 && '00' || eventStartM.get('minutes')}`;
        const timeEnd: string = `${eventEndM.get('hours') === 0 && '00' || eventEndM.get('hours')}:${eventEndM.get('minutes') === 0 && '00' || eventEndM.get('minutes')}`;
        return `${timeStart} - ${timeEnd}`;
    }

    protected getFormattedDate(date: Moment | string): string {
        if (!date) {
            return null;
        }

        const dateM: Moment = CalendarSidebarComponent.toMoment(date);

        if (!(dateM.get('minutes') + dateM.get('hours'))) {
            return dateM.locale('pl').format('dddd, MMMM Do YYYY');
        }
        return dateM.locale('pl').format('dddd, MMMM Do YYYY, HH:mm');

    }

    private setLatest(): void {
        this.calendarScreenService.getLatestEvents().pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((eventModels: EventModel[]) => {
            this.latestEvents = eventModels;
        });
    }


}
