import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class AdminPageService {

    constructor(private readonly _httpClient: HttpClient) {
    }

}
