import {EntrepreneurDetailsModel} from './entrepreneur-details.model';
import {EntrepreneurDeliveryAddressModel} from './entrepreneur-delivery-address.model';
import {EntrepreneurBrandCooperationModel} from './entrepreneur-brand-cooperation.model';
import {RepresentativeModel} from './representative.model';
import {EntrepreneurWspolnikModel} from './entrepreneur-wspolnik.model';

export class EntrepreneurBulkUpdateModel {

    constructor(public details : EntrepreneurDetailsModel,
                public brands: Array<EntrepreneurBrandCooperationModel>,
                public representatives: Array<RepresentativeModel>,
                public partners: Array<EntrepreneurWspolnikModel>)
    {}

}
