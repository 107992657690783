import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-configuration-picker',
    templateUrl: 'configuration-picker.template.html',
    styleUrls: ['configuration-picker.styles.scss']
})
export class ConfigurationPickerComponent {

    @Input()
    public activeConfigName: string;

    @Input()
    public configurationList: Array<string>;

    @Input()
    public activeConfigDefaultFlag: boolean = false;

    @Output()
    public pickConfigEvent = new EventEmitter<string>();

    @Output()
    public defaultConfigEvent = new EventEmitter<void>();


    public emitPickConfigEvent(configName: string) {
        this.pickConfigEvent.emit(configName);
    }

    public emitDefaultConfigEvent() {
        this.defaultConfigEvent.emit();
    }

}
