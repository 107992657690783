import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FileManagementService, UploadedFileWithSettings } from './file-management.service';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {

    @ViewChild('fileInput')
    fileInput: ElementRef;

    @Input() fieldId = 'test';

    files: Array<UploadedFileWithSettings> = [];

    constructor(private readonly fileManagementService: FileManagementService, private readonly http: HttpClient) {
    }

    ngOnInit(): void {
        this.getFilesMetadataForField();
    }

    openFilesDialog() {
        this.fileInput.nativeElement.click();
    }

    onNewFiles($event: any) {
        if (!this.fieldId) {
            throw new Error('Field id is required to upload file!');
        }
        const fileList: FileList = $event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0];
            const fileToUpload = new UploadedFileWithSettings(file.name);
            fileToUpload.uploadInProgress = true;
            this.files.push(fileToUpload);
            this.fileManagementService.uploadFile('', file, this.fieldId, (data) => {
                fileToUpload.uploadedFileResult = data;
                fileToUpload.successfullyUploaded = true;
            }, (error) => console.log(error), () => fileToUpload.uploadInProgress = false);
        }
    }

    downloadFile(file: UploadedFileWithSettings) {
        this.fileManagementService.downloadFile(file.uploadedFileResult).toPromise();
    }

    private getFilesMetadataForField() {
        this.fileManagementService.getFilesMetadataForField(this.fieldId).subscribe(values => {
            this.files = values.map(value => {
                const fileWithSettings = new UploadedFileWithSettings(value.name);
                fileWithSettings.uploadedFileResult = value;
                fileWithSettings.successfullyUploaded = true;
                return fileWithSettings;
            });
        });
    }
}
