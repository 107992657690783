import { ChainPartnerTableRecord } from './chain-partner-table-record.model';
import { ContactModel } from './contact.model';
import { ExternalPersonModel } from './external-person.model';

export class ChainDetailsModel {
    constructor(public name: string,
                public ownerId: number,
                public creationDate: Date,
                public updateDate: Date,
                public totalPartners: number,
                public totalCooperationApprovedSolgarPartners: number,
                public totalCooperationApprovedTerranovaPartners: number,
                public totalCooperationInProgressSolgarPartners: number,
                public totalCooperationInProgressTerranovaPartners: number,
                public plainContacts: Array<ContactModel>,
                public peopleContacts: Array<ExternalPersonModel>,
                public partners: Array<ChainPartnerTableRecord>,
                public toaId?: number,
                public sozLogin?: string,
                public sozAccountCreated?: boolean,
                public sozAccountActive?: boolean,
                public readonly id?: number
    ) {
    }
}
