import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { LoaderInterceptorSkipHeader } from '../_helpers/loader.inteceptor';
import { EventNotificationModel } from '../_models/event-notification.model';
import { PlainNotificationModel } from '../_models/plain-notification.model';
import { ApiUtils } from '../_utils/api.utils';
import {
    PendingCalendarNotification,
    PendingPlainNotification
} from '../notification-component/notification.component';


@Injectable()
export class NotificationService {

    private addToPendingCalendarNotifications$: Subject<PendingCalendarNotification> = new Subject<PendingCalendarNotification>();
    private removeFromPendingCalendarNotifications$: Subject<number> = new Subject<number>();
    private addToPendingPlainNotifications$: Subject<PendingPlainNotification> = new Subject<PendingPlainNotification>();

    constructor(private readonly http: HttpClient) {

    }

    public getNewPendingCalendarNotification(): Observable<PendingCalendarNotification> {
        return this.addToPendingCalendarNotifications$.asObservable();
    }

    public addNewPendingCalendarNotification(pendingNotification: PendingCalendarNotification): void {
        this.addToPendingCalendarNotifications$.next(pendingNotification);
    }

    public getRemovedPendingCalendarNotification(): Observable<number> {
        return this.removeFromPendingCalendarNotifications$.asObservable();
    }

    public removeFromPendingCalendarNotifications(pendingNotification: number): void {
        this.removeFromPendingCalendarNotifications$.next(pendingNotification);
    }

    public getNewPendingPlainNotification(): Observable<PendingPlainNotification> {
        return this.addToPendingPlainNotifications$.asObservable();
    }

    public addNewPendingPlainNotification(pendingNotification: PendingPlainNotification): void {
        this.addToPendingPlainNotifications$.next(pendingNotification);
    }

    public dismissAllNotificationsForUser(userId: number): Observable<void> {
        return this.http.patch<void>(`${ApiUtils.NOTIFICATION_API_URL}/bulk/person/${userId}/dismiss`, null);
    }


    public getEventNotificationForPersonId(personId: number): Observable<Array<EventNotificationModel>> {
        let params: HttpParams = new HttpParams();
        const currentDateTime: string = moment().toISOString();
        params = params.append('personId', personId.toString());
        params = params.append('currentDateTime', currentDateTime);
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        return this.http.get <Array<EventNotificationModel>>(`${ApiUtils.NOTIFICATION_API_URL}/event`, {
            params,
            headers
        });
    }

    public patchEventNotification(pendingCalendarNotification: PendingCalendarNotification): Observable<EventNotificationModel> {
        return this.http.patch<EventNotificationModel>(`${ApiUtils.NOTIFICATION_API_URL}/event/${pendingCalendarNotification.id}`, pendingCalendarNotification.eventNotificationPatchModel);
    }

    public getPlainNotificationForPersonId(personId: number): Observable<Array<PlainNotificationModel>> {
        let params: HttpParams = new HttpParams();
        const currentDateTime: string = moment().toISOString();
        params = params.append('personId', personId.toString());
        params = params.append('currentDateTime', currentDateTime);
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        return this.http.get <Array<PlainNotificationModel>>(`${ApiUtils.NOTIFICATION_API_URL}/plain`, {
            params,
            headers
        });
    }

    public patchPlainNotification(pendingPlainNotification: PendingPlainNotification): Observable<PlainNotificationModel> {
        return this.http.patch<PlainNotificationModel>(`${ApiUtils.NOTIFICATION_API_URL}/plain/${pendingPlainNotification.id}`, pendingPlainNotification.plainNotificationPatchModel);
    }


}
