import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ChainDetailsModel } from '../_models/chain-details.model';
import { CogElementModel } from '../_models/cog-element.model';
import { ColumnElementModel } from '../_models/column-element.model';
import { ConfigModel } from '../_models/config.model';
import { ScreenConfigModel } from '../_models/screen-config.model';
import { TableEventModel } from '../_models/table-event.model';
import { UserConfigModel } from '../_models/user-config.model';
import { AuthenticationService } from '../_services/authentication.service';
import { ScreenService } from '../_services/screen.service';
import { UserConfigService } from '../_services/user-config.service';
import { canAddChain } from '../_utils/auth-utils/auth-chains';
import { ChipFiltersUtils } from '../_utils/chip-filters.utils';
import { DialogUtils } from '../_utils/dialog.utils';
import { ExcelUtils } from '../_utils/excel.utils';
import {DynamicTableColumn, DynamicTableService} from '../dynamic-table/dynamic-table.service';
import { ChipElement } from '../filter-chips/chip-component/chip.component';
import { ChainDetailsScreenService } from './chains-detail-dialog/chain-details-screen.service';
import { ConfirmRemovingChainDialogComponent } from './chains-detail-dialog/confirm-removing/confirm-removing-chain-dialog.component';
import { ChainsService } from './chains.service';

@Component({
    selector: 'app-chains',
    templateUrl: './chains.component.html',
    styleUrls: ['./chains.component.scss']
})
export class ChainsComponent implements OnInit {

    public showConfiguration: boolean = false;
    public showConfigurationManager: boolean = false;
    public showFilter: boolean = false;
    public collapsed: boolean = true;

    columnsToDisplay: number = 7;

    columnsNumbers: Array<number> = [];
    public rowsPerPage = 15;
    public newConfigurationName: string;
    chipPresentFilters: Array<ChipElement> = null;
    chipChainPossibleFilters: ChipElement[] = ChipFiltersUtils.CHAIN_POSSIBLE_FILTERS;
    private dirty: boolean = false;
    private readonly SCREEN_API_URL = environment.apiUrl + '/api/screen';
    private _destroy: Subject<boolean> = new Subject<boolean>();


    constructor(public readonly chainsService: ChainsService,
                public http: HttpClient,
                private dialog: MatDialog,
                private readonly userConfigService: UserConfigService,
                private readonly dynamicTableService: DynamicTableService,
                private readonly detailsScreenService: ChainDetailsScreenService,
                private readonly excelUtils: ExcelUtils,
                private readonly screenService: ScreenService,
                public readonly authService: AuthenticationService) {
        //TODO: get max-column length from backend ask Konrad how.
        for (let i = 0; i < 8; ++i) {
            this.columnsNumbers.push(i);
        }
        this.userConfigService.getUserConfig().subscribe(
            (userConfig: UserConfigModel) => {
                if (userConfig && userConfig.chainsConfig) {
                    this.columnsToDisplay = userConfig.chainsConfig.columnToDisplay || 7;
                }
            },
            (error) => {
                console.log('Error on getting user config on finances.', error);
            }
        );

    }

    private _currentUserRoles: number[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    get canAddChain(): boolean {
        return canAddChain(this.currentUserRoles);
    }

    public abortEditingConfiguration(): void {
        this.showConfiguration = false;
    }


    public cleanConfigurationAndExit(): void {
        this.showConfigurationManager = false;
        this.newConfigurationName = null;
    }

    public showInitialState(): boolean {
        return !this.showConfiguration && !this.showFilter && !this.showConfigurationManager;
    }

    public exportToExcel(): void {
        this.excelUtils.exportToExcel(this.getSystemNameHeaders(), this.chainsService.sortQueryParam, this.getFilters(), 'chain', 'all');
    }

    updateColumnToDisplay(columnToDisplay: number) {
        this.columnsToDisplay = columnToDisplay;
        this.updateColToDisplayAmount();
        this.dynamicTableService.columnChanged.next(columnToDisplay);
        this.userConfigService.updateChainsConfigColumnToDisplay(columnToDisplay).subscribe();
        this.updateActiveConfig();
    };

    ngOnInit() {
        this.chainsService.setScreenData();
        this.chainsService.chipSubject.pipe(take(1)).subscribe(elem => {
            this.chipPresentFilters = elem || [];
        });

        this.chainsService.showDeleteConfirmationDialog.pipe(
            takeUntil(this._destroy)
        ).subscribe((chainDetails: ChainDetailsModel) => {
            if (!this.detailsScreenService.removingInitialized) {
                this.detailsScreenService.removingInitialized = true;
            } else {
                return;
            }

            const deleteDialog = this.dialog.open(ConfirmRemovingChainDialogComponent,
                {
                    width: DialogUtils.defaultDialogWidth
                });

            deleteDialog.afterClosed().pipe(takeUntil(this._destroy))
                .subscribe((result: boolean) => {
                        if (!result) {
                            return;
                        }
                        this.detailsScreenService.removeSubject(chainDetails).subscribe(() => this.chainsService.updateDataSubject.next(true));
                    },
                    () => this.detailsScreenService.removingInitialized = false,
                    () => this.detailsScreenService.removingInitialized = false
                );


        });

        this.chainsService.updateDataSubject.pipe(
            takeUntil(this._destroy)
        ).subscribe(() => this.chainsService.getUpdatedData());

    }

    public getFilters(): Array<ChipElement> {
        return this.chainsService.activeConfiguration.configJson.filters;
    }

    public chipUpdateList(chipList: ChipElement[]): void {
        this.chipPresentFilters = chipList;
    }

    public chipAddToList(chipElement: ChipElement): void {
        this.chipPresentFilters.push(chipElement);
    }

    public chipUpdateListAndSendRequest(chipList: ChipElement[]): void {
        this.chipUpdateList(chipList);
        this.chainsService.activeConfiguration.configJson.filters = chipList;

        this.updateActiveConfig();

    }

    public getHeaders(): Array<DynamicTableColumn> {
        if (!this.chainsService.activeConfiguration.configJson.visibleColumnsOrdered) {
            return;
        }
        return this.chainsService.activeConfiguration.configJson.visibleColumnsOrdered
            .map(col => new DynamicTableColumn(col.displayName, col.sortAvailable));
    }

    public getSystemNameHeaders(): Array<string> {
        if (!this.chainsService.activeConfiguration.configJson.visibleColumnsOrdered) {
            return;
        }
        return this.chainsService.activeConfiguration.configJson.visibleColumnsOrdered.map(col => col.systemName);
    }

    public getRows(): Array<Array<string>> {
        if (!this.chainsService.rowsForActiveConfiguration) {
            return;
        }
        return this.chainsService.rowsForActiveConfiguration;
    }

    public getPages(): number {
        if (!this.chainsService.pages) {
            return;
        }
        return this.chainsService.pages;
    }

    public getPage(): number {
        if (!this.chainsService.page) {
            return;
        }
        return this.chainsService.page;
    }

    public getTotalElements(): number {
        if (!this.chainsService.totalElements) {
            return;
        }
        return this.chainsService.totalElements;
    }

    public tableEvent(event: TableEventModel) {
        this.chainsService.page = event.page;
        this.rowsPerPage = event.rowsPerPage;
        this.chainsService.getUpdatedData(this.rowsPerPage);
    }

    public tableSortEvent(sortQueryParam: string) {
        this.chainsService.sortQueryParam = sortQueryParam;
        this.chainsService.getUpdatedData(this.rowsPerPage);
    }

    public assignPrevious(): void {
        if (this.dirty === false) {
            this.chainsService.previousConfiguration = new ScreenConfigModel(this.chainsService.activeConfiguration.id,
                this.chainsService.activeConfiguration.name, this.chainsService.activeConfiguration.isDefault, this.chainsService.activeConfiguration.screenType, new ConfigModel(
                    Object.assign([], this.chainsService.activeConfiguration.configJson.visibleColumnsOrdered),
                    this.chainsService.activeConfiguration.configJson.sort,
                    Object.assign([], this.chainsService.activeConfiguration.configJson.filters),
                    Object.assign([], this.chainsService.activeConfiguration.configJson.cogColumnsOrdered),
                ));
            this.dirty = true;
        }
    }

    public cogUpdateTempConfiguration($event: Array<CogElementModel>, columnToDisplay: number = 9): void {
        this.chainsService.activeConfiguration.configJson.visibleColumnsOrdered = this.cogElementsToVisibleColumns($event, columnToDisplay);
        this.chainsService.getUpdatedData(this.rowsPerPage, $event);
    }

    public updateColToDisplayAmount(columnToDisplay: number = 9): void {
        this.cogUpdateTempConfiguration(this.chainsService.activeConfiguration.configJson.cogColumnsOrdered, columnToDisplay)
    }

    public changeActiveConfig(configName: string): void {
        let candidateConfig = this.chainsService.configurationMap.get(configName);
        this.activate(candidateConfig)
    }

    public setScreenConfigAsDefault() {
        this.chainsService.activeConfiguration.isDefault = true;
        this.screenService.setDefaultScreenConfigData(this.SCREEN_API_URL, this.chainsService.activeConfiguration).subscribe(data => {
            this.chainsService.getUpdatedData(this.rowsPerPage);
        });
    }

    public updateActiveConfig(): void {
        let activeConfig = this.chainsService.activeConfiguration;
        this.screenService.updateScreenData(this.SCREEN_API_URL, activeConfig).subscribe(data => {
            this.chainsService.configurationMap.set(data.name, data);
            this.activate(data)
        });
    }

    public addNewConfig(): void {
        if (!this.chainsService.previousConfiguration) {
            this.chainsService.previousConfiguration = new ScreenConfigModel(this.chainsService.activeConfiguration.id,
                this.chainsService.activeConfiguration.name, this.chainsService.activeConfiguration.isDefault, this.chainsService.activeConfiguration.screenType, new ConfigModel(
                    Object.assign([], this.chainsService.activeConfiguration.configJson.visibleColumnsOrdered),
                    this.chainsService.activeConfiguration.configJson.sort,
                    Object.assign([], this.chainsService.activeConfiguration.configJson.filters),
                    Object.assign([], this.chainsService.activeConfiguration.configJson.cogColumnsOrdered),
                ));
        }
        let activeConfig = this.chainsService.activeConfiguration;
        this.dirty = false;
        activeConfig.id = null;
        activeConfig.isDefault = false;
        activeConfig.name = this.newConfigurationName;
        this.chainsService.configurationMap.set(this.chainsService.previousConfiguration.name, this.chainsService.previousConfiguration);
        this.screenService.saveScreenData(this.SCREEN_API_URL, activeConfig).subscribe(data => {
            this.chainsService.configurationMap.set(data.name, data);
            this.manipulateSelect(data.name);
            this.activate(data)
        });
        this.cleanConfigurationAndExit();

    }

    private manipulateSelect(name: string): void {
        let newNames: Array<string> = this.chainsService.selectNames.filter((item, index) => item != name);
        newNames.unshift(name);
        this.chainsService.setSelectNames(newNames);
    }

    private activate(candidateConfig: ScreenConfigModel): void {
        this.chainsService.setActiveConfiguration(candidateConfig);
        this.chainsService.getUpdatedData(this.rowsPerPage);
    }

    private cogElementsToVisibleColumns(cogElements: Array<CogElementModel>, columnToDisplay: number): Array<ColumnElementModel> {
        return cogElements.slice(0, columnToDisplay)
            .map((cogElementModel: CogElementModel) => new ColumnElementModel(cogElementModel.systemName, cogElementModel.displayName, cogElementModel.sortAvailable));
    }

}
