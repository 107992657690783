import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import * as _ from 'lodash';
import * as moment from 'moment';

import { ChipElement, ChipType } from '../filter-chips/chip-component/chip.component';
import { ChartNgModel, ChartUtil } from '../_utils/chart.util';
import { PeriodPicker } from '../finances/period-picker/period-picker.component';
import { ChartResultsResolutionEnum } from '../_enums/chart-results-resolution.enum';
import { DataSerieEnum } from '../_enums/data-serie.enum';
import { ChartModel } from '../_models/chart-model';
import { PlotDataModel } from '../_models/plot-data-model';
import { ExcelUtils } from '../_utils/excel.utils';
import { ScreenConfigModel } from '../_models/screen-config.model';
import { ScreenTypeEnum } from './../_enums/screen-type.enum';
import { of } from 'rxjs';
import {delay, tap} from 'rxjs/operators';
import { ConfigModel } from '../_models/config.model';
import { ChartResultModeEnum } from '../_enums/chart-result-mode.enum';
import { ChipFiltersUtils } from '../_utils/chip-filters.utils';
import { ChipValue } from './../filter-chips/chip-component/chip.component';
import { AnalyticsFiltersModel, AnalyticsSerieModel } from './analytics-filters.model';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/index';
import {AnalyticsResponseModel} from '../_models/analytics-response.model';
import {LoaderInterceptorSkipHeader} from '../_helpers/loader.inteceptor';
import {FileManagementService} from '../inputs/file-upload/file-management.service';
import {UserConfigService} from '../_services/user-config.service';
import {AnalyticsConfigModel} from '../_models/analytics-config.model';
import {PeriodPickerModel} from '../_models/period-picker.model';
import {RangeModel} from '../_models/range.model';
import {AnalyticsSeriesConfigModel} from '../_models/analytics-series-config.model';
import {ChartMarketCooperationEnum} from '../_enums/chart-market-cooperation.enum';
import {ChartSearchTypeEnum} from '../_enums/chart-search-type.enum';

const API_URL = environment.apiUrl + `/api/analytics`;

@Injectable()
export class AnalyticsService {
    configFetched = false;
    periodPicker: PeriodPicker;
    chartResultMode = ChartResultModeEnum.PRICE;
    resolution = ChartResultsResolutionEnum.WEEKS;
    analyticDataSeries: ChipElement[][] = [];
    seriesToDisplay: DataSerieEnum[] = [];

    isChartDrew = false;
    analyticsModel: AnalyticsResponseModel;
    plotLabels: string[];
    plotDataModels: PlotDataModel[];
    seriesToDisplayCopy: DataSerieEnum[] = [];
    chartResultModeCopy = ChartResultModeEnum.PRICE;

    chartColors: string[] = [];
    totalDataChartNg: ChartNgModel;
    incomeChartNg: ChartNgModel;
    returnChartNg: ChartNgModel;
    searchType: ChartSearchTypeEnum;

    constructor(private http: HttpClient,
                private readonly excelUtils: ExcelUtils,
                private readonly userConfigService: UserConfigService) {}

    exportToExcel() {
        const filters = this.getAllFilters();
        const fileName = `analityka_${filters.from}-${filters.to}.xlsx`;

        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');

        return this.http.post(API_URL + "/export/all", this.getAllFilters(), { headers: headers, responseType: 'blob' }).pipe(
            tap((body: Blob) => FileManagementService.download(body, fileName))
        ).subscribe( v => {}, error => console.log(error + " " + typeof error));

    }

    getAnalyticsConfiguration() {
        const mockedConfig: ScreenConfigModel[] = [
            new ScreenConfigModel(1111, 'Domyslna', true, ScreenTypeEnum.analytics, new ConfigModel([], null, null, null))
        ];
        return of(mockedConfig).pipe(delay(2000));
        // return this.http.get<ScreenConfigModel[]>(`/api/screen/?screenType=${ScreenTypeEnum.analytics}`);
    }

    generateChartData() {
        this.saveFilters();
        this.sendFilters().subscribe(data => {
            this.analyticsModel = data;
            this.initializeTotalPlotData();
            this.initializeLineChartModels();
        });
    }

    generateRandomColorSet(): void {
        this.chartColors = ChartUtil.generateRandomColorSet(this.periodPicker);
        ChartUtil.setChartColorsInLocalStorage(this.chartColors);
    }

    saveFilters() {
        var analyticsConfigModel = new AnalyticsConfigModel(
            this.analyticDataSeries.map(value => new AnalyticsSeriesConfigModel(value)),
            new PeriodPickerModel(new RangeModel(this.periodPicker.period.startDate.format('YYYY-MM-DD').toString(), this.periodPicker.period.endDate.format('YYYY-MM-DD').toString()), this.periodPicker.years),
            this.resolution,
            this.seriesToDisplay,
            this.chartResultMode);

        this.userConfigService.updateAnalyticsConfig(analyticsConfigModel).subscribe()
    }

    private getAllFilters() {
        let analyticsSeries: AnalyticsSerieModel[] = [];
        const mainFilters = {
            from: this.getDateAsString(this.periodPicker.period.startDate),
            to: this.getDateAsString(this.periodPicker.period.endDate),
            resolution: this.resolution,
            resultMode: this.chartResultMode,
            years: this.periodPicker.years,
            searchType: this.searchType
        };

        if (this.analyticDataSeries.length > 0) {
            analyticsSeries = this.analyticDataSeries.map((serie, index) => {
                const serieFilters = this.getDataSerieFilters(serie);
                console.log(serieFilters);
                const analyticSerie: AnalyticsSerieModel = {
                    seriesName: `Seria ${index + 1}`
                };
                serieFilters.forEach((filter: ChipValue[] | ChipValue) => {
                    if (Array.isArray(filter)) {
                        analyticSerie[filter[0].chipName] = filter.map(el => el.value);
                    } else {
                        analyticSerie[filter.chipName] = filter.value;
                    }
                });
                return analyticSerie;
            });
        }
        const filters: AnalyticsFiltersModel = {...mainFilters, analyticsSeries: analyticsSeries};
        return filters;
    }

    private getDataSerieFilters(dataSerie: ChipElement[]) {
        const filters = [];
        dataSerie.filter((chipElement) => chipElement.chipValue).forEach((chipElement) => {
            switch (chipElement.chipType) {
                case ChipType.MULTI:
                    filters.push(ChipFiltersUtils.getValueOfMultiselectChip(chipElement));
                    break;

                case ChipType.SELECT:
                    filters.push(ChipFiltersUtils.getValueOfSelectChip(chipElement));
                    break;

                case ChipType.TEXT:
                    filters.push(ChipFiltersUtils.getValueOfTextChip(chipElement, true));
                    break;
            }
        });
        return filters;
    }

    private sendFilters() : Observable<AnalyticsResponseModel> {
        return this.http.post<AnalyticsResponseModel>(API_URL + "/fetch", this.getAllFilters());
    }

    private getDateAsString(date: moment.Moment) {
        return date ? date.toDate().toLocaleDateString('en-GB') : null;
    }

    private initializeTotalPlotData() {
        this.plotLabels = this.analyticsModel.labels;
        this.plotDataModels = this.analyticsModel.plotData;
        this.seriesToDisplayCopy = this.seriesToDisplay;
        this.chartResultModeCopy = this.chartResultMode;
    }

    private initializeLineChartModels() {
        let incomeModels = _.cloneDeep(this.plotDataModels);
        incomeModels = incomeModels.filter((plotDataModel: PlotDataModel) => plotDataModel.name.includes('Obroty'));
        this.incomeChartNg = ChartUtil.generateChartModel(incomeModels, this.plotLabels, this.chartColors);

        let returnModel = _.cloneDeep(this.plotDataModels);
        returnModel = returnModel.filter((plotDataModel: PlotDataModel) => plotDataModel.name.includes('Zwroty'));
        this.returnChartNg = ChartUtil.generateChartModel(returnModel, this.plotLabels, this.chartColors);

        this.totalDataChartNg = ChartUtil.generateChartModel(this.plotDataModels, this.plotLabels, this.chartColors);
    }
}
