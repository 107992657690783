import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ChipElement } from '../../chip-component/chip.component';


@Component({
    selector: 'app-add-filter',
    templateUrl: './add-filter.component.html',
    styleUrls: ['./add-filter.component.scss']
})
export class AddFilterComponent implements OnInit {
    @ViewChild('chipInput', {static: false}) chipInput: ElementRef<HTMLInputElement>;
    chipListCtrl: FormControl = new FormControl();
    @Input() filterList: Array<ChipElement> = [];
    @Output() filterAdded: EventEmitter<ChipElement> = new EventEmitter<ChipElement>();
    @Input() placeholder: string = 'Dodaj filtr';
    @Input() width: string = '200px';
    filteredList$: Observable<ChipElement[]>;

    constructor() {

    }

    _presentFilters: Array<ChipElement> = [];

    @Input()
    set presentFilters(chipElements: ChipElement[]) {
        this._presentFilters = chipElements;
        this.chipListCtrl.setValue('');
    }

    ngOnInit(): void {
        this.filteredList$ = this.chipListCtrl.valueChanges.pipe(
            startWith(null),
            map((value: string | null) => {
                return this.sortByFilterName(!!value ? this.filterChipList(value, this.possibleFilters()) : this.possibleFilters() && this.possibleFilters().slice());
            })
        );
    }

    selected(): void {
        this.addFilter(this.chipListCtrl.value);
        this.chipInput.nativeElement.value = '';
        this.chipListCtrl.setValue(null);
        this.chipInput.nativeElement.blur();
    }

    addFilter(chipElement: ChipElement): void {
        chipElement.chipValue = null;
        this.filterAdded.emit(chipElement);
    }

    possibleFilters(): Array<ChipElement> {
        return this.filterList.filter((el: ChipElement) => !this._presentFilters.find(fil => fil.chipDescription.name === el.chipDescription.name))
    }

    private filterChipList(value: string, list: Array<ChipElement>): ChipElement[] {
        const filterValue = value && typeof value === 'string' && value.toLowerCase() || null;
        return list.filter((element: ChipElement) => element.chipDescription.display && element.chipDescription.display.toLowerCase().includes(filterValue));
    }

    private sortByFilterName(filterList: ChipElement[]) {
        return filterList.sort((a, b) => a.chipDescription.display < b.chipDescription.display ? -1 : 1);
    }
}
