import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArchiveFinancialIdModel } from './archive-financial-id.model';

@Injectable(
    {providedIn: 'root'}
)
export class ArchiveFinancialIdService {

    constructor(private readonly _httpClient: HttpClient) {
    }

    archiveFinancialId(brandCooperationId: number, archiveFinancialIdModel: ArchiveFinancialIdModel): Observable<ArchiveFinancialIdModel> {
        return this._httpClient.post<ArchiveFinancialIdModel>(`/api/brand-cooperation/${brandCooperationId}/financialId/archive`, archiveFinancialIdModel);
    }

    getArchiveData(partnerId: number): Observable<ArchiveFinancialIdModel[]> {
        return this._httpClient.get<ArchiveFinancialIdModel[]>(`/api/partner/${partnerId}/archival-financial-data`);
    }
}