import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TeamModel } from '../../../../../_models/team.model';
import { NgForm } from '@angular/forms';
import { DictionariesService } from '../../../../../_services/dictionaries.service';
import { DictModel } from '../../../../../_models/dict.model';
import { AdminTeamTabService } from '../admin-team-tab.service';
import { MessageService } from '../../../../../_services/message.service';
import { MessageSeverityEnum } from '../../../../../_enums/message-severity.enum';

@Component({
  selector: 'app-admin-team-tab-dialog',
  templateUrl: './admin-team-tab-dialog.component.html',
  styleUrls: ['./admin-team-tab-dialog.component.scss']
})
export class AdminTeamTabDialogComponent implements OnInit {
  public teams;
  public initUsers: Array<DictModel> = [];
  public filteredLeaders: Array<DictModel> = [];
  public filteredMembers: Array<DictModel> = [];
  public unfilteredLeaders;
  public wasFiltered: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public teamModel: TeamModel,
              private dialogRef: MatDialogRef<AdminTeamTabDialogComponent>,
              private readonly _dictsService: DictionariesService,
              private readonly _adminTeamTabService: AdminTeamTabService,
              private readonly messageService: MessageService) {
  }

  ngOnInit() {
    this._dictsService.getPeople().subscribe(users => {
      this.initUsers = users;
      this.filteredMembers = users;
      this.unfilteredLeaders = users.filter(user => this.teamModel.memberIds.indexOf(user.id) > -1);
    });

    this._adminTeamTabService.getTeams().subscribe(team => {
      this.teams = team;
    });
  }

  public submitDialog(teamForm: NgForm, teamModel) {
    if (teamModel.newTeam === true && this.teams.some(e => e.name.toLocaleLowerCase() === teamForm.value.teamname.toLocaleLowerCase())) {
      this.openSnackbar();
      return teamForm.invalid;
    }

    if (teamForm.valid) {
      this.dialogRef.close(true);
    }
  }

  public membersChanged(members: number[]) {
    this.teamModel.memberIds = members;
    this.setLeaders();
  }

  public leadersChanged(leaderId: number) {
    this.wasFiltered = false;
    this.teamModel.leaderId = leaderId;
  }

  public setLeaders() {
    this.unfilteredLeaders = this.initUsers.filter(user => this.teamModel.memberIds.indexOf(user.id) > -1);
  }

  public filterMembers(event) {
    if (!this.initUsers) {
      return;
    }

    let search = event;
    if (!search) {
      this.filteredMembers = this.initUsers;
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredMembers = this.initUsers.filter(user => user.value.toLowerCase().indexOf(search) > -1);
  }

  public filterLeader(event) {
    this.wasFiltered = true;

    if (!this.unfilteredLeaders) {
      return;
    }

    let search = event;
    if (!search) {
      this.filteredLeaders = this.unfilteredLeaders;
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredLeaders = this.unfilteredLeaders.filter(user => user.value.toLowerCase().indexOf(search) > -1);
  }

  public getLeaders() {
    if (this.wasFiltered) {
      return this.filteredLeaders;
    } else {
      return this.unfilteredLeaders;
    }
  }

  public openSnackbar() {
    this.messageService.setSnackbar({
      text: 'Zespół o podanej nazwie już istnieje',
      durationInSecond: 5,
      severity: MessageSeverityEnum.warn
    });
  }

}
