import { DictModel } from './dict.model';
import { PersonModel } from './person.model';

export class TaskModel {
    constructor(
        public id?: number,
        public status?: DictModel,
        public priority?: DictModel,
        public title?: string,
        public description?: string,
        public creationDate?: Date,
        public updateDate?: Date,
        public dueDate?: Date,
        public assignee?: PersonModel,
        public reporter?: PersonModel,
        public partners?: Array<DictModel>
    ) {
    }
}
