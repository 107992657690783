import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DictModel } from '../_models/dict.model';
import { AuthenticationService } from '../_services/authentication.service';
import { DictionariesService } from '../_services/dictionaries.service';
import { CalendarScreenService } from './calendar-screen.service';
import { EventActionDialogCommunicationModel } from './event-calendar/event-calendar.service';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar-screen.component.html',
    styleUrls: ['./calendar-screen.component.scss']
})
export class CalendarScreenComponent implements OnInit, OnDestroy {
    hideUtilsTrigger = false;
    hideUtils = false;
    usersPicked: number[] = [];
    public filteredCalendar: Array<DictModel> = [];
    dictionaryCalendar: DictModel[];
    private unsubscribe$: Subject<void> = new Subject<void>();
    private _currentUserId: number;

    constructor(private readonly calendarScreenService: CalendarScreenService,
                private readonly dictionariesService: DictionariesService,
                private readonly _authenticationService: AuthenticationService,) {

    }

    ngOnDestroy(): void {
        this.unsubscribe$.next(null);
        this.unsubscribe$.complete();
    }

    public usersPickedChange(userPicked: number[]): void {
        this.usersPicked = userPicked;
        this.filteredCalendar.sort(this._sortBySelected);
    }

    public usersPickedChanged(): void {
        localStorage.setItem(`usersCalendar${this._currentUserId}`, JSON.stringify(this.usersPicked));
        this.calendarScreenService.refreshCalendar.next(true);
    }

    ngOnInit() {
        this._currentUserId = this._authenticationService.currentUserValue.personId;
        this.usersPicked = localStorage.getItem(`usersCalendar${this._currentUserId}`) && JSON.parse(localStorage.getItem(`usersCalendar${this._currentUserId}`)) || [];
        if (this.usersPicked.length === 0) {
            this.usersPicked = [this._currentUserId];
            localStorage.setItem(`usersCalendar${this._currentUserId}`, JSON.stringify(this.usersPicked));
        }
        this.dictionariesService.getCalendars().subscribe((dict: Array<DictModel>) => {
            this.dictionaryCalendar = dict;
            this.filteredCalendar = dict.sort(this._sortBySelected);
        });
        this.calendarScreenService.acceptEventSubject.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((action: EventActionDialogCommunicationModel) =>
            this.calendarScreenService.confirmEvent(action).subscribe(() => this.calendarScreenService.refreshCalendar.next(true)));
        this.calendarScreenService.deleteEventSubject.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((action: EventActionDialogCommunicationModel) =>
            this.calendarScreenService.deleteEvent(action).subscribe(() => {
                this.calendarScreenService.refreshCalendar.next(true);
            }));
        this.calendarScreenService.patchEventSubject.pipe(
            takeUntil(this.unsubscribe$)
        ).subscribe((action: EventActionDialogCommunicationModel) =>
            this.calendarScreenService.patchEvent(action).subscribe(() => this.calendarScreenService.refreshCalendar.next(true)));
    }

    public filterUsers(event) {
        if (!this.usersPicked) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredCalendar = this.dictionaryCalendar;
            this.filteredCalendar.sort(this._sortBySelected);
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredCalendar = this.dictionaryCalendar.filter(user => (user.value.toLowerCase().indexOf(search) > -1) ||
            this.usersPicked.includes(user.id));
    }

    private _sortBySelected = (a, b) => {
        if (this.usersPicked?.includes(a.id) && !this.usersPicked?.includes(b.id)) {
            return -1;
        }

        if (!this.usersPicked?.includes(a.id) && this.usersPicked?.includes(b.id)) {
            return 1;
        }

        if (a.value === b.value) {
            return 0;
        }

        return a.value < b.value && -1 || 1;
    }

}
