import {Component, Input, OnInit} from '@angular/core';
import {EntrepreneurDetailsDialogModel} from '../entrepreneur-details-dialog.model';
import {EntrepreneurDetailsModel, EntrepreneurRepresentativeHistory} from '../../../_models/entrepreneur-details.model';
import {EntrepreneurBrandCooperationModel} from '../../../_models/entrepreneur-brand-cooperation.model';
import {DictModel} from '../../../_models/dict.model';
import {DictionariesService} from '../../../_services/dictionaries.service';
import {RepresentativeModel} from '../../../_models/representative.model';
import {BrandInfoModel} from '../mini/brand-info.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatTableDataSource} from '@angular/material/table';
import {concatMap, flatMap, map} from 'rxjs/operators';
import {Observable} from 'rxjs/index';
import {RepresentativeFile} from '../../../_models/representative-file.model';
import {canEditEntrepreneur, canEditCooperationDate, canAddRepresentativeEntrepreneur, canEditEntrepreneurBrand, canEditEntrepreneurBrandStatus} from '../../entrepreneur-guard';
import {AuthenticationService} from '../../../_services/authentication.service';
import {FormControl} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {MatSelectChange} from '@angular/material/select';

const CONCERN_API_URL = environment.apiUrl + '/api/concern';
const REPRESENTATIVE_API_URL = environment.apiUrl + '/api/representative';


@Component({
    selector: 'app-entrepreneur-representative-tab',
    templateUrl: './entrepreneur-representative-tab.component.html',
    styleUrls: ['entrepreneur-representative-tab.component.scss']
})
export class EntrepreneurRepresentativeTabComponent implements OnInit {

    public AGREEMENT_OR_OWS = [5, 7];

    @Input() entrepreneurDetailsDialogModel: EntrepreneurDetailsDialogModel;
    @Input() entrepreneurDetailsModel: EntrepreneurDetailsModel;
    @Input() disabled: boolean;
    entrepreneurBrands_: Array<EntrepreneurBrandCooperationModel>;
    @Input() representatives: Array<RepresentativeModel>;

    public concernCooperationStatus: Array<DictModel>;
    public filteredConcernCooperationStatus: Array<DictModel> = [];
    public dictBrands: Array<DictModel>;
    public peopleDict: Array<DictModel>;
    public filteredOnBoarders: Array<DictModel> = [];
    public filteredOwners: Array<DictModel> = [];
    public twsDict: Array<DictModel>;
    public filteredTws: Array<DictModel>;

    showInvalidFields: boolean;

    public historyDataSource: MatTableDataSource<EntrepreneurRepresentativeHistory>;
    public displayedColumns: string[] = [
        "solgar",
        "snc",
        "terranova",
        "tnc",
        "date",
        "representative",
        "person",
        "type",
        "signature"
    ]

    constructor(private dictService: DictionariesService,
                private authService: AuthenticationService,
                private http: HttpClient) {
    }

    @Input() set entrepreneurBrands(brands : Array<EntrepreneurBrandCooperationModel>) {
        this.entrepreneurBrands_ = brands
        if (!this.entrepreneurBrands_.find(brand => brand.brandId == 1)) {
            this.entrepreneurBrands_.splice(0, 0, new EntrepreneurBrandCooperationModel(null, 1, null, null, null, 9, false, null))
        }
        if (!this.entrepreneurBrands_.find(brand => brand.brandId == 2)) {
            this.entrepreneurBrands_.splice(0, 0, new EntrepreneurBrandCooperationModel(null, 2, null, null, null, 9, false, null))
        }
        this.entrepreneurBrands_.sort((a, b) => a.brandId - b.brandId);
    }

    ngOnInit(): void {
        this.dictService.getBrandDictionary().subscribe((brandCooperation: Array<DictModel>) => {
            this.dictBrands = brandCooperation;
        });

        this.dictService.getPeople().subscribe((peopleDict: Array<DictModel>) => {
            this.peopleDict = peopleDict;
            this.filteredOnBoarders = peopleDict;
            this.filteredOwners = peopleDict;
        });
        this.dictService.getToaPeople().subscribe((toaDict: Array<DictModel>) => {
            this.twsDict = toaDict;
            this.filteredTws = toaDict;
        });

        this.dictService.getConcernCooperationStatus().subscribe((concernCooperationStatus: Array<DictModel>) => {
            this.concernCooperationStatus = concernCooperationStatus;
            this.filteredConcernCooperationStatus = concernCooperationStatus;
        });

        this.initHistoryDataSource();
    }

    public postOwsAccept() {
        for (let entrepreneurBrand of this.entrepreneurBrands_) {
            if (entrepreneurBrand.cooperationStatusId == 1) {
                entrepreneurBrand.cooperationStatusId = 2;
            }
        }

        this.initHistoryDataSource();
    }

    public initHistoryDataSource() {

        let representativeHistory = this.entrepreneurDetailsDialogModel.entrepreneurDetailsModel.representativeHistory;
        representativeHistory = representativeHistory.sort((a, b) => {
            if (a.time > b.time) return 1;
            else if (a.time < b.time) return -1;
            else return 0;
        });
        this.entrepreneurDetailsModel.representativeHistory = representativeHistory;
        // this.entrepreneurDetailsModel.representativeHistory = this.entrepreneurDetailsDialogModel.entrepreneurDetailsModel.representativeHistory.sort((a, b) => {
        //     console.log("a:b " + (a.constructor.name) + " " + (b.constructor.name));
        //     return a.time.getTime() - b.time.getTime()
        // });
        this.historyDataSource = new MatTableDataSource<EntrepreneurRepresentativeHistory>(this.entrepreneurDetailsModel.representativeHistory);
    }

    editBrand(brand: EntrepreneurBrandCooperationModel) {

    }

    public filterOwners(event) {
        if (!this.peopleDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredOwners = this.peopleDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredOwners = this.peopleDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public filterTws(event) {
        if (!this.twsDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredTws = this.twsDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredTws = this.twsDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public getBrandShortName(brandId: number) {
        return brandId == 2 ? "TNC" : "SNC";
    }

    dodajReprezentanta() {
        this.representatives.splice(this.representatives.length, 0, new RepresentativeModel(null, null, null, null, false, false))
    }

    markDirty() {
        this.entrepreneurDetailsDialogModel.entrepreneurSaved = false;
    }

    changeLoyalty(brand: EntrepreneurBrandCooperationModel, $event: MatCheckboxChange) {
        if (!$event.checked) {
            this.entrepreneurDetailsDialogModel.loyaltyPointsChanged = true;
        }
    }

    getInfoModelForBrand(brandId: number) : BrandInfoModel {
        if (brandId == 1) {
            return new BrandInfoModel("Solgar", "assets/solgar-mini.png", "brand-solgar")
        }
        return new BrandInfoModel("Terranova", "assets/terranova-mini.png", "brand-terranova")
    }

    updatePartners() {

    }

    private _currentUserRoles: number[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    get canEditEntrepreneur() : boolean {
        return canEditEntrepreneur(this.currentUserRoles, this.entrepreneurBrands_)
    }

    get canEditCooperationDate() : boolean {
        return canEditCooperationDate(this.currentUserRoles)
    }

    canEditEntrepreneurBrand(brand: EntrepreneurBrandCooperationModel) : boolean {
        return canEditEntrepreneurBrand(this.currentUserRoles, brand)
    }

    get canAddRepresentativeEntrepreneur() : boolean {
        return canAddRepresentativeEntrepreneur(this.currentUserRoles, this.entrepreneurBrands_)
    }

    canEditEntrepreneurBrandStatus(brand: EntrepreneurBrandCooperationModel, newStatus: number) : boolean {
        return canEditEntrepreneurBrandStatus(this.currentUserRoles, brand, newStatus)
    }

    public getIcon(element: EntrepreneurRepresentativeHistory, brand: number, loyalty: boolean) : BrandAcceptIcon {

        const type = element.type;

        if (type == 'ACCEPT') {
            if (loyalty) {
                if (element.brands.find(eb => eb.brand == brand && eb.loyaltyAccepted)) {
                    return {icon: "check_circle", styleclass: "brand-accept-icon-green"}
                }
            } else {
                if (element.brands.find(eb => eb.brand == brand && eb.agreementAccepted)) {
                    return {icon: "check_circle", styleclass: "brand-accept-icon-green"}
                }
            }
        } else if (type == 'REQUEST') {
            if (element.brands.find(eb => eb.brand == brand)) {
                return {icon: "help", styleclass: "brand-accept-icon-blue"}
            }
        }

        return {icon: "cancel", styleclass: "brand-accept-icon-gray"}
    }

    public getTypeValue(element: EntrepreneurRepresentativeHistory): TypeEnumDescribe {
        if (element.type == 'ACCEPT') {
            return {text: "Akceptacja", styleclass: "history-type-chip-green"}
        } else {
            return {text: "Prośba o akceptacje", styleclass: "history-type-chip-blue"}
        }
    }

    uploadRepresentativeFile(representative: RepresentativeModel) {

    }

    getRepresentativeFiles(representativeModel: RepresentativeModel) : Observable<Array<RepresentativeFile>> {
        return this.http.get<Array<RepresentativeFile>>(`${REPRESENTATIVE_API_URL}/${representativeModel.id}/files`)
    }

    brandFieldsRequired(brand: EntrepreneurBrandCooperationModel) : boolean {
        return brand.twsId != null || brand.ownerId != null || (brand.cooperationStatusId !== null && brand.cooperationStatusId !== 9 && brand.cooperationStatusId !== 10);
    }

    onBrandCooperationChange(brand: EntrepreneurBrandCooperationModel, $event: MatSelectChange) {
        let newStatus = $event.value;

        if (brand.cooperationStatusId != newStatus && [5, 7, 12].includes(newStatus)) {
            let now = new Date()
            brand.onboardingDate = now;
            if (brand.firstOnboardingDate == null) {
                brand.firstOnboardingDate = now;
            }
        }
        brand.cooperationStatusId = newStatus;
    }

}

export interface BrandAcceptIcon {
    icon: string
    styleclass: string
}

export interface TypeEnumDescribe {
    text: string
    styleclass: string
}

export interface OWSAcceptance {
    valid: boolean
    reason: string
}
