import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

export class StorageObject {
    object: any;
    version: number;
}

@Injectable()
export class CacheService {

    public putDictionaryInLocalStorage(name: string, dictionary: any, version: number): Observable<any> {
        let localStorageEntry: StorageObject = localStorage.getItem(name) && JSON.parse(localStorage.getItem(name)) || null;
        if (!localStorageEntry || localStorageEntry.version < version) {
            localStorage.setItem(name, JSON.stringify({
                object: dictionary,
                version: version
            }));
            return of(dictionary);
        }
        return of(localStorageEntry.object);
    }

}
