import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {EntrepreneursService} from '../../entrepreneurs.service';
import {EntrepreneurDetailsModel} from '../../../_models/entrepreneur-details.model';
import {AddressModel} from '../../../_models/address.model';
import {EntrepreneurDetailsDialogModel} from '../../entrepreneur-details-dialog/entrepreneur-details-dialog.model';
import {
    EntrepreneurDetailsDialogComponent
} from '../../entrepreneur-details-dialog/entrepreneur-details-dialog.component';
import {DialogResultEnum} from '../../../_enums/dialog-result.enum';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-add-entrepreneur',
    templateUrl: './add-entrepreneur-trigger.component.html',
    styleUrls: ['./add-entrepreneur-trigger.component.scss']
})
export class AddEntrepreneurTriggerComponent {

    @Input() private subjectId: number;

    constructor(private dialog: MatDialog,
                private readonly entrepreneursService: EntrepreneursService,
    ) { }

    public openDialog(): void {
        const address = new AddressModel(null, null, null, null, null, null, null, null, null)
        const entrepreneursDetailsModel = new EntrepreneurDetailsModel(null, null, null, null, null, false, address, null, null, null, null, null, null, null,0, []);

        const dialogModel = new EntrepreneurDetailsDialogModel();
        dialogModel.notification = new Subject<DialogResultEnum>();
        dialogModel.entrepreneurSaved = false;
        dialogModel.newEntrepreneur = true;

        dialogModel.entrepreneurDetailsModel = entrepreneursDetailsModel
        dialogModel.wspolnicy = []
        dialogModel.entrepreneurDeliveryAddress = []
        dialogModel.entrepreneurBrands = []
        dialogModel.entrepreneurRepresentative = []

        const dialogRef = this.dialog.open(EntrepreneurDetailsDialogComponent,
            {
                data: dialogModel,
                maxWidth: '100%',
                panelClass: 'details-dialog',
            }
        );

        dialogModel.notification.subscribe((result) => {
            if (result == DialogResultEnum.EXIT) {
                return;
            }

            this.entrepreneursService.createEntrepreneur(dialogModel.entrepreneurDetailsModel).subscribe(
                () => this.entrepreneursService.getUpdatedData()
            );
            dialogRef.close()
        });
    }

    // public openDialog(): void {
    //     const partnerDetailsModel = new PartnerDetailsModel(null,
    //         null,
    //         new AddressModel(null, null, null, null, null, null, null, null, null),
    //         null,
    //         null,
    //         null,
    //         null,
    //         null,
    //         null,
    //         null,
    //         null,
    //         null,
    //         null,
    //         null,
    //         null,
    //         null,
    //         null);
    //     const dialogRef = this.dialog.open(AddPartnerDialogComponent,
    //         {
    //             data: partnerDetailsModel,
    //             maxWidth: '100%',
    //             panelClass: 'details-dialog',
    //         }
    //     );
    //
    //     dialogRef.afterClosed().subscribe((result: boolean) => {
    //
    //             if (!result) {
    //                 return;
    //             }
    //
    //         this.partnersService.addPartner(partnerDetailsModel).subscribe(
    //             () => this.partnersService.getUpdatedData()
    //         );
    //
    //         }
    //     );
    // }

}
