import {Component, OnInit} from '@angular/core';
import * as _ from 'lodash';

import {ChartResultsResolutionEnum} from '../_enums/chart-results-resolution.enum';
import {UserConfigService} from '../_services/user-config.service';
import {AnalyticsService} from './analytics.service';
import {DataSerieEnum} from './../_enums/data-serie.enum';
import {ScreenConfigModel} from '../_models/screen-config.model';
import {ChartUtil} from '../_utils/chart.util';
import {ChipElement} from '../filter-chips/chip-component/chip.component';
import {UserConfigModel} from '../_models/user-config.model';
import {PeriodPickerModel} from '../_models/period-picker.model';
import {ChartResultModeEnum} from '../_enums/chart-result-mode.enum';
import {ChipFiltersUtils} from '../_utils/chip-filters.utils';
import {RangeModel} from '../_models/range.model';
import {ChartCooperationModel} from '../_models/chart-cooperation.model';
import {ChartMarketCooperationEnum} from '../_enums/chart-market-cooperation.enum';
import {ChartSearchTypeEnum} from '../_enums/chart-search-type.enum';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
  providers: [AnalyticsService]
})
export class AnalyticsComponent implements OnInit {
  currentConfig: ScreenConfigModel;
  currentAnalyticDataSeries: ChipElement[][] = [];
  analyticDataSeriePossibleFilters = ChipFiltersUtils.ANALYTICS_POSSIBLE_FILTERS;

  constructor(
    public readonly analyticsService: AnalyticsService,
    private readonly userConfigService: UserConfigService
  ) { }

  get seriesToDisplay() {
    return this.analyticsService.seriesToDisplayCopy;
  }

  get chartModeToDisplay() {
    return this.analyticsService.chartResultModeCopy;
  }

  ngOnInit() {
    this.getAnalyticsConfiguration();
    this.setChartColors();
    this.getUserAnalyticsConfig();
  }

  exportToExcel() {
    this.analyticsService.exportToExcel();
  }

  generateChart() {
    this.analyticsService.generateChartData();
  }

  addDataSerie() {
    this.currentAnalyticDataSeries.push([]);
  }

  removeDataSerie(serieIndex: number) {
    this.currentAnalyticDataSeries.splice(serieIndex, 1);
    this.analyticsService.saveFilters();
  }

  copyDataSerie(serieIndex: number) {
    let currentAnalyticDataSery = this.currentAnalyticDataSeries[serieIndex];
    this.currentAnalyticDataSeries.splice(serieIndex, 0, _.cloneDeep(currentAnalyticDataSery));
    this.analyticsService.saveFilters();
  }

  clearAllDataSeries() {
    this.currentAnalyticDataSeries = [];
    this.analyticsService.saveFilters();
  }

  handleAddingFilterToSerie(serieIndex: number, chipFilter: ChipElement) {
    this.currentAnalyticDataSeries[serieIndex].push(chipFilter);
  }

  handleSerieFiltersChange(serieIndex: number, chipFilters: ChipElement[]) {
    //console.log("set filter: " + serieIndex + " " + JSON.stringify(chipFilters));
    this.currentAnalyticDataSeries[serieIndex] = _.cloneDeep(chipFilters);
  }


  handleSerieFiltersChangeAndSendRequest(serieIndex: number, chipFilters: ChipElement[]) {
    this.handleSerieFiltersChange(serieIndex, chipFilters);
    this.analyticsService.analyticDataSeries = this.currentAnalyticDataSeries;
    this.analyticsService.saveFilters();
  }

  handleChartResultsModeChange(selectedChartMode: ChartResultModeEnum) {
    this.analyticsService.chartResultMode = selectedChartMode;
    // this.analyticsService.generateChartData();
  }

  handleResolutionValueChange(selectedResolutionValue: ChartResultsResolutionEnum) {
      this.analyticsService.resolution = selectedResolutionValue;
      // this.analyticsService.generateChartData();
  }

  handleDataSeriesValueChange(selectedSeriesValue: DataSerieEnum[]) {
    this.analyticsService.seriesToDisplay = _.cloneDeep(selectedSeriesValue);
    // this.analyticsService.generateChartData();
  }

  isIncomeChartVisible() {
    return this.seriesToDisplay?.length === 1 && this.seriesToDisplay.includes(DataSerieEnum.INCOME) && this.analyticsService.incomeChartNg;
  }

  isReturnChartVisible() {
    return this.seriesToDisplay?.length === 1 && this.seriesToDisplay.includes(DataSerieEnum.RETURN) && this.analyticsService.returnChartNg;
  }

  isTotalDataChartVisible() {
    return this.seriesToDisplay?.length === 2 && this.analyticsService.totalDataChartNg;
  }

  private getAnalyticsConfiguration() {
    this.analyticsService.getAnalyticsConfiguration().subscribe(data => {
      this.currentConfig = data[0];
      // this.analyticsService.analyticDataSeries = data[0].configJson.filters || [];
    });
  }

  private getUserAnalyticsConfig() {
    this.userConfigService.getUserConfig().subscribe(
      (userConfig: UserConfigModel) => {
        // to replace userConfig.financesConfig => userConfig.analyticsConfig
        if (userConfig.analyticsConfig.periodPicker) {
          this.analyticsService.periodPicker = PeriodPickerModel.getPeriodPicker(userConfig.analyticsConfig.periodPicker);
        } else {
          this.analyticsService.periodPicker = PeriodPickerModel.getPeriodPicker(new PeriodPickerModel(new RangeModel("2020-01-01", "2020-01-31"), []));
        }
        this.analyticsService.resolution = userConfig.analyticsConfig.resolution || ChartResultsResolutionEnum.MONTHS;
        this.analyticsService.searchType = userConfig.analyticsConfig.searchType || ChartSearchTypeEnum.B2B;
        this.analyticsService.seriesToDisplay = userConfig.analyticsConfig.series || [DataSerieEnum.INCOME];
        this.analyticsService.chartResultMode = userConfig.analyticsConfig.chartResultMode || ChartResultModeEnum.PRICE;
        if (userConfig.analyticsConfig.seriesConfigs) {
          for (let i = 0; i < userConfig.analyticsConfig.seriesConfigs.length; i++) {
            let analyticsSeriesConfigModel = userConfig.analyticsConfig.seriesConfigs[i];
          }

          var map = userConfig.analyticsConfig.seriesConfigs.map(value => value.filtersWithValues);
          this.analyticsService.analyticDataSeries = _.cloneDeep(map) || [];
          this.currentAnalyticDataSeries = _.cloneDeep(map) || [];
        }
        this.analyticsService.configFetched = true;
      },
      (error) => {
        console.log('Error on getting user config on analytics.', error);
      }
    );
  }


  private setChartColors() {
    if (!ChartUtil.getChartColorsFromLocalStorage()) {
      this.analyticsService.generateRandomColorSet();
    } else {
        const colorsFromLocalSotrage = ChartUtil.getChartColorsFromLocalStorage();
        this.analyticsService.chartColors = JSON.parse(colorsFromLocalSotrage);
    }
  }

  handleCooperationValueChange($event: ChartSearchTypeEnum) {
    if ($event.length == 0) {
      $event = ChartSearchTypeEnum.B2B;
    }
    this.analyticsService.searchType = $event;
    this.userConfigService.updateCooperationType($event, "analytics").subscribe();
  }

  isExportEnabled() {
    return this.analyticsService.searchType == ChartSearchTypeEnum.B2B
  }

}
