import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FloatLabelType } from '@angular/material/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ChipElement } from '../chip-component/chip.component';


@Component({
    selector: 'app-chip-list',
    templateUrl: './chip-list.component.html',
    styleUrls: ['./chip-list.component.scss']
})
export class ChipListComponent implements OnInit {
    @ViewChild(MatAutocompleteTrigger, {static: true}) autocomplete: MatAutocompleteTrigger;
    @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;
    @ViewChild('chipInput', {static: true}) chipInput: ElementRef<HTMLInputElement>;


    @Input() possibleChips: Array<ChipElement>;
    possibleChips$: Observable<Array<ChipElement>>;
    chipListCtrl: FormControl = new FormControl();
    @Output() values: EventEmitter<Array<ChipElement>>;
    addedChips: Array<ChipElement> = [];

    constructor() {

    }

    chipClick(event) {
        event.stopPropagation();
        event.preventDefault();
    }

    ngOnInit(): void {
        this.possibleChips$ = this.chipListCtrl.valueChanges.pipe(
            startWith(''),
            map((val: ChipElement) => val && this._filterAhead(val) || this.possibleChips.slice())
        );
    }

    add(event: MatChipInputEvent): void {
        if (this.matAutocomplete && !this.matAutocomplete.isOpen) {
            const input = event.input;
            if (this.chipListCtrl.value) {
                this.addedChips.push(this.chipListCtrl.value);

            }
            input.value = '';
            this.chipListCtrl.setValue(null);
        }

    }

    remove(chipName: string): void {
        const index = this.addedChips.findIndex((chipEl: ChipElement) => chipEl.chipDescription.name === chipName);
        if (index >= 0) {
            this.addedChips.splice(index, 1);
        }
    }

    selected(): void {
        this.addedChips.push(this.chipListCtrl.value);
        this.chipInput.nativeElement.value = '';
        this.chipListCtrl.setValue(null);
    }

    protected shouldLabelFloat(): FloatLabelType {
        return this.addedChips && this.addedChips.length > 0 ? 'always' : 'auto';
    }

    private _filterAhead(value: ChipElement): Array<ChipElement> {
        const filterValue = value.chipDescription.display.toLowerCase();
        return this.possibleChips.filter((element: ChipElement) => element.chipDescription.display.toLowerCase().includes(filterValue));
    }

}
