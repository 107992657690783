import { ContactModel } from './contact.model';
import { ExternalPersonModel } from './external-person.model';
import {BrandCooperationDeliverAddressModel} from './brand-cooperation-deliver-address.model';
import {MatTableDataSource} from '@angular/material/table';
import {BrandEtailer} from './brand-etailer.model';

export class BrandCooperationModel {
    constructor(
        public partnerId: number,
        public brandId: number,
        public financialId: number,
        public cooperationStatusId: number,
        public toaId: number,
        public onBoarderId: number,
        public ownerId: number,
        public onboardingDate: Date,
        public firstOnboardingDate: Date,
        public endDate: Date,
        public cooperationEndNote: string,
        public marketingMaterials: boolean,
        public brandVisualization: boolean,
        public brandAmbassadorId: number,
        public webPage: string,
        public isOnlineSeller: boolean,
        public dateOfOnlineSalesStart: Date,
        public cooperationRenewalDate: Date,
        public plainContacts: Array<ContactModel>,
        public peopleContacts: Array<ExternalPersonModel>,
        public etailers: Array<BrandEtailer>,
        public etailer: boolean,
        public ediFlags: number[],
        public rightToReplanishment?: boolean,
        public cooperationTypeId?: number,
        public replanishmentSubmissionDate?: Date,
        public inSncLoyaltyProgram?: boolean,
        public rightToProductsForSncPoints?: boolean,
        public authorizedPoint?: boolean,
        public owsCooperationLastDay?: Date,
        public cooperationWithoutAgreement?: boolean,
        public etailerNumber?: string,
        public id?: number
    ) {
    }
}
