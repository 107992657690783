import {Component, EventEmitter, Input, Output} from '@angular/core';
import {EtailerModel} from '../../../../_models/etailer.model';
import {BrandEtailer} from '../../../../_models/brand-etailer.model';
import {BrandCooperationModel} from '../../../../_models/brand-cooperation.model';
import {AuthenticationService} from '../../../../_services/authentication.service';
import {MatDialog} from '@angular/material/dialog';
import {BrandCooperationService} from '../../../../_services/brand-cooperation.service';
import {DictionariesService} from '../../../../_services/dictionaries.service';
import {ArchiveFinancialIdService} from '../../archive-financial-id/archive-financial-id.service';
import {EtailerService} from '../../../../_services/etailer.service';
import {DictModel} from '../../../../_models/dict.model';
import {
    canEditDateOfOnlineSalesStart,
    canEditWebPage,
    canEditEtailer,
    canGenerateEtailer
} from 'src/app/_utils/auth-utils/auth-partner-details-brand';
import {EtailerGeneratorTooltip} from '../single-brand-tab.component';

@Component({
    selector: 'etailer-entry',
    templateUrl: './etailer-entry.component.html',
    styleUrls: ['etailer-entry.component.scss']
})
export class EtailerEntryComponent {

    @Input() brandCooperation: BrandCooperationModel;
    @Input() etailer: BrandEtailer;
    @Input() readonly disabled: boolean;
    @Input() readonly showInvalidFields: boolean;
    @Input() readonly partnerSaved: boolean;
    @Output() onChange: EventEmitter<BrandCooperationModel> = new EventEmitter<BrandCooperationModel>();
    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();

    public dictBrands: Array<DictModel>;
    public isLoading: boolean = false;

    constructor(public readonly authService: AuthenticationService,
                private readonly dictService: DictionariesService,
                private readonly etailerService: EtailerService
    ) {
    }

    private _currentUserRoles: number[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    ngOnInit() {
        this.dictService.getBrandDictionary().subscribe((brandCooperation: Array<DictModel>) => {
            this.dictBrands = brandCooperation;
        });

        this.isLoading = this.etailer.generated;
    }

    public getBrandName(brandId: number) {
        const brand = this.dictBrands && this.dictBrands.find(brand => brand.id === brandId);
        return brand && `${brand.value}` || '';
    }

    public get canEditDateOfOnlineSalesStart(): boolean {
        return canEditDateOfOnlineSalesStart(this.currentUserRoles);
    }

    public get canEditWebPage(): boolean {
        return canEditWebPage(this.currentUserRoles);
    }

    public get canEditEtailer(): boolean {
        return canEditEtailer(this.currentUserRoles);
    }

    public get canGenerateEtailer(): boolean {
        return canGenerateEtailer(this.currentUserRoles);
    }

    generateEtailerNumber() {
        if (this.etailer) {
            const etailerModelObservable = this.etailerService.generateNumber(this.etailer?.id, this.brandCooperation.id);
            etailerModelObservable.subscribe(etailerModel => {
                this.etailer.id = etailerModel.etailerId;
                this.etailer.etailerNumber = etailerModel.etailerNumber;
            });
        }
    }

    generateEtailer() {
        if (this.etailer && this.etailer.id) {
            this.etailer.generated = true;
            this.isLoading = true;
            const etailerModelObservable = this.etailerService.generateEtailer(this.etailer.id, this.etailer.etailerNumber, this.etailer.webPage);
            etailerModelObservable.subscribe(() => {
                this.etailer.generated = false;
                setTimeout(() => {
                    this.isLoading = false;
                    this.etailer.generated = true;
                }, 20);
            }, error => {
                console.log("Error when loading etailer: " + error);
                this.etailer.generated = false;
                this.isLoading = false;
            });
        }
    }

    generateEtailerTooltip() : EtailerGeneratorTooltip {
        if (!this.partnerSaved) {
            return {disabled: true, text: "Partner nie jest zapisany"}
        }

        if (!this.etailer) {
            return {disabled: true, text: "Brakuje obiektu etailera"}
        }

        if (this.isLoading) {
            return {disabled: true, text: "Etailer jest ładowany"}
        }

        if (this.etailer.webPage == undefined || this.etailer.webPage?.length == 0) {
            return {disabled: true, text: "Brakuje adresu strony www"}
        }

        if (this.etailer.etailerNumber == undefined || this.etailer.etailerNumber?.length == 0) {
            return {disabled: true, text: "Brakuje numeru etailera"}
        }

        return {disabled: false, text: ""};
    }

    editBrand(brandCooperation: BrandCooperationModel) {
        this.onChange.next(brandCooperation);
    }

    deleteEtailer() {
        this.onDelete.next({});
    }
}
