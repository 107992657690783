import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BrandNameEnum } from '../../_enums/brand-name.enum';
import { UserConfigService } from '../../_services/user-config.service';

@Component({
    selector: 'app-brand-picker',
    templateUrl: './brand-picker.component.html',
    styleUrls: ['./brand-picker.component.scss'],
})
export class BrandPickerComponent  {
    brandOptions: string[] = [
        BrandNameEnum.ALL,
        BrandNameEnum.TERRANOVA,
        BrandNameEnum.SOLGAR
    ];
    brandControl = new FormControl(null);

    @Input() set brand(newValue: BrandNameEnum) {
        if (newValue !== this.brandControl.value) {
            this.brandControl.setValue(newValue);
        }
    }
    @Output() brandChange = new EventEmitter<BrandNameEnum>();

    constructor(private readonly userConfigService: UserConfigService) {}

    setPickedBrand() {
        this.brandChange.emit(this.brandControl.value);
    }
}
