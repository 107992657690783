import {Component, Input, ViewChild} from '@angular/core';

import {ChartNgModel} from 'src/app/_utils/chart.util';
import {ChartResultModeEnum} from '../../_enums/chart-result-mode.enum';

@Component({
  selector: 'app-analytics-chart',
  templateUrl: './analytics-chart.component.html',
  styleUrls: ['./analytics-chart.component.css']
})
export class AnalyticsChartComponent {

  @Input() chartData: ChartNgModel;
  @Input() chartMode: ChartResultModeEnum;

  options = {
    plugins: {
        datalabels: {
            color: '#36A2EB'
        }
    },
    tooltips: {
        mode: 'x',
        intersect: false,
        callbacks: {
            label: (tooltipItem, data) => {
                let label = data.datasets[tooltipItem.datasetIndex].label || '';
                if (label) {
                    label += ': ';
                }
                if (this.chartMode === ChartResultModeEnum.PRICE) {
                    label += (Math.round(tooltipItem.yLabel * 100) / 100).toLocaleString('pl-PL', {
                        style: 'currency',
                        currency: 'PLN'
                    });
                } else {
                    label += (Math.round(tooltipItem.yLabel * 100) / 100).toLocaleString('pl-PL', {
                        useGrouping: true,
                        style: "decimal"
                    });
                }
                return label;
            }
        }
    },
    scales: {
        yAxes: [{
            ticks: {
                callback: (value, index, values) => {
                    if (this.chartMode === ChartResultModeEnum.PRICE) {
                        return value.toLocaleString('pl-PL', {style: 'currency', currency: 'PLN'});
                    } else {
                        return value.toLocaleString('pl-PL');
                    }
                }
            }
        }]
    }
  };
}
