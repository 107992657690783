import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Injector,
    OnInit,
    ViewChild
} from '@angular/core';
import MarkerClusterer from '@google/markerclustererplus';
import * as _ from 'lodash';
import {mergeMap, switchMap} from 'rxjs/operators';
import {BrandCooperationModel} from '../_models/brand-cooperation.model';
import {BrandCorrelationModel} from '../_models/brand-correlation.model';
import {BrandModel} from '../_models/brand.model';
import {ChainDetailsModel} from '../_models/chain-details.model';
import {DictModel} from '../_models/dict.model';
import {MapResultModel} from '../_models/map-result.model';
import {PartnerWithLocalizationModel} from '../_models/partner-with-localization.model';
import {RouteDetailsModel} from '../_models/route-details.model';
import {BrandCooperationService} from '../_services/brand-cooperation.service';
import {BrandCorrelationService} from '../_services/brand-correlation.service';
import {DictionariesService} from '../_services/dictionaries.service';
import {REDIRECTION_PARTNER_MAP, RedirectionService} from '../_services/redirection.service';
import {ChainsService} from '../chains/chains.service';
import {PartnerDetailsScreenService} from '../partners/partner-details-dialog/partner-details-screen.service';
import {CustomInfoWindowComponent} from './custom-info-window.component';
import {CooperationStatusMapEnum, LocalTravelMode, MapService} from './map.service';
import {GooglePlacesAutocompleteComponent} from './google-places-autocomplete/google-places-autocomplete.component';
import {canEditCorrelations} from '../_utils/auth-utils/auth-map';
import {AuthenticationService} from '../_services/authentication.service';
import LatLng = google.maps.LatLng;
import Marker = google.maps.Marker;
import PlaceResult = google.maps.places.PlaceResult;
import TravelMode = google.maps.TravelMode;
import DirectionsStatus = google.maps.DirectionsStatus;

class CorrelationModeData {
    constructor(public partnerForCorrelation: PartnerWithLocalizationModel,
                public partnerForCorrelationMarker: Marker,
                public correlationBrand: BrandModel,
                public alreadySavedCorrelations: Array<MapResultModel> = [],
                public chosenCorrelations: Array<MapResultModel> = []) {
    }
}

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnInit {

    public initialLatitude = this.mapService.initialLatitude;
    public initialLongitude = this.mapService.initialLongitude;

    public correlationMode = false;
    public correlationEditMode = false;
    public correlationModeData: CorrelationModeData;

    public sidebarVisible = true;
    public zoom = 8;
    public routeOrigin: LatLng;
    public routeDestination: LatLng;
    public drivingRouteDetails: RouteDetailsModel;
    public walkingRouteDetails: RouteDetailsModel;
    @ViewChild('gMap', {static: true}) mapElement: any;
    public map: google.maps.Map;
    @ViewChild('origin', {static: false})
    originAutocompleteComponent: GooglePlacesAutocompleteComponent;
    @ViewChild('destination', {static: false})
    destinationAutocompleteComponent: GooglePlacesAutocompleteComponent;
    /**
     *     first key -> brandId, second key -> partnerId
     */
    public partnerBrandCooperationsMap: Map<number, Map<number, BrandCooperationModel>>;
    public databaseMarkerActive = true;
    public canceledMarkerActive = true;
    public actualActionsMarkerActive = true;
    public cooperationMarkerActive = true;
    /**
     * clusterVisibility is a workaround for drawing performance issues
     */
    public clusterVisible = true;
    public brandsDictionary: Array<BrandModel>;
    public ownersDictionary: Array<DictModel>;
    public filteredOwners: Array<DictModel> = [];
    public chainsDictionary: Array<ChainDetailsModel>;
    public filteredChains: Array<ChainDetailsModel> = [];
    public statusesDictionary: Array<DictModel>;
    public filteredStatuses: Array<DictModel> = [];
    public selectedBrand: number;
    public selectedTravelMode: LocalTravelMode = LocalTravelMode.DRIVING;
    public selectedOwners = [];
    public selectedChains = [];
    public selectedStatuses = [];
    /**
     * partnerLocalizations filtered
     */
    public visiblePartners: Array<PartnerWithLocalizationModel> = [];
    public redirectionPartner: PartnerWithLocalizationModel;
    private cluster: MarkerClusterer;
    private readonly allBrandsOptionId = Number.POSITIVE_INFINITY;
    private originFromMarker = false;
    /**
     * All fetched partners
     */
    private partnerLocalizations: Array<PartnerWithLocalizationModel> = [];
    private directionsService = new google.maps.DirectionsService();
    private directionsRenderer = new google.maps.DirectionsRenderer;

    get canEditCorrelations() {
        return canEditCorrelations(this.authService.getCurrentUserRoles());
    }

    constructor(public readonly mapService: MapService,
                private readonly dictionariesService: DictionariesService,
                private readonly apiService: BrandCooperationService,
                private readonly partnerDetailsScreenService: PartnerDetailsScreenService,
                private readonly chainsService: ChainsService,
                private readonly authService: AuthenticationService,
                private readonly cd: ChangeDetectorRef,
                private readonly redirectionService: RedirectionService,
                private readonly brandCorrelationService: BrandCorrelationService,
                private readonly resolver: ComponentFactoryResolver,
                private readonly injector: Injector) {
    }

    ngOnInit() {
        this.partnerDetailsScreenService.getBrands()
            .pipe(
                mergeMap(brands => {
                    this.brandsDictionary = brands;
                    this.brandsDictionary.push(new BrandModel(this.allBrandsOptionId, 'Wszystkie'));
                    this.selectedBrand = this.brandsDictionary.filter(value => value.name === this.mapService.defaultBrandName)[0].id;
                    return this.apiService.getBrandCooperation();
                }),
                switchMap((brandCooperation: Array<BrandCooperationModel>) => {
                    this.partnerBrandCooperationsMap = new Map();
                    const brandIds = _.uniq(brandCooperation.map(coop => coop.brandId));
                    brandIds.forEach(brandId => this.partnerBrandCooperationsMap.set(brandId, new Map()));
                    brandCooperation.forEach(_brandCooperation => this.partnerBrandCooperationsMap.get(_brandCooperation.brandId)
                        .set(_brandCooperation.partnerId, _brandCooperation));
                    return this.mapService
                        .getPartnersLocalizationsByBrand(
                            [this.selectedBrand], this.selectedOwners, this.selectedChains, this.selectedStatuses);
                }))
            .subscribe(partners => {
                this.partnerLocalizations = partners
                    .map(partner => this.partnerWithLocalizationModelFactory(partner, this.getIcon(partner), true));
                this.visiblePartners = this.partnerLocalizations;
                this.handleRedirectionFromPartner();
                this.drawMarkers();
            });
        this.getDictionaries();
        this.initMap();
    }

    public cleanMap(): void {
        this.partnerLocalizations
            .forEach(value => {
                value.hideCircle();
            });
        this.cleanRouteComponents();
        this.originFromMarker = false;
        this.selectedOwners = [];
    }

    public cleanRouteComponents() {
        this.routeOrigin = null;
        this.routeDestination = null;
        if (this.originAutocompleteComponent) {
            this.originAutocompleteComponent.addressInput.nativeElement.value = '';
        }
        if (this.destinationAutocompleteComponent) {
            this.destinationAutocompleteComponent.addressInput.nativeElement.value = '';
        }
        this.drivingRouteDetails = null;
        this.walkingRouteDetails = null;
        this.directionsRenderer.setMap(null);
    }

    public cleanDestination() {
        this.routeDestination = null;
        this.drivingRouteDetails = null;
        this.walkingRouteDetails = null;
        this.directionsRenderer.setMap(null);
    }

    public cleanOrigin() {
        this.routeOrigin = null;
        this.drivingRouteDetails = null;
        this.walkingRouteDetails = null;
        this.directionsRenderer.setMap(null);
    }

    public onOriginAutocompleteSelected(selectedPlace: PlaceResult) {
        this.routeOrigin = selectedPlace.geometry.location;
        this.originFromMarker = false;
        if (this.routeDestination) {
            this.getRouteDetails();
            this.drawRoute();
        }
    }

    public onDestinationAutocompleteSelected(selectedPlace: PlaceResult) {
        this.routeDestination = selectedPlace.geometry.location;
        if (this.routeOrigin) {
            this.getRouteDetails();
            this.drawRoute();
        }
    }

    public setAsPartOfRoute(partnerLoc: PartnerWithLocalizationModel): void {
        const partner = partnerLoc.partner;
        const partnerLocation = new LatLng(partner.latitude, partner.longitude);
        const formattedAddress = partner.street + ', ' + partner.postalCode + ' ' + partner.town;
        if (!this.routeOrigin || !this.originFromMarker) {
            this.originFromMarker = true;
            this.routeOrigin = partnerLocation;
            this.originAutocompleteComponent.addressInput.nativeElement.value = formattedAddress;
            if (this.routeDestination) {
                this.getRouteDetails();
                this.drawRoute();
            }
        } else {
            this.routeDestination = partnerLocation;
            this.destinationAutocompleteComponent.addressInput.nativeElement.value = formattedAddress;
            this.originFromMarker = false;
            if (this.routeOrigin) {
                this.getRouteDetails();
                this.drawRoute();
            }
        }
        this.cd.detectChanges();
    }

    public newBrandSelected(newBrandId) {
        this.getPartnersLocalizations([newBrandId], this.selectedOwners, this.selectedChains, this.selectedStatuses);
    }

    public newTravelMode(newTravelMode) {
        this.selectedTravelMode = newTravelMode;
        if (this.routeOrigin && this.routeDestination) {
            this.getRouteDetails();
            this.drawRoute();
        }
    }

    public newOwnerSelected(newSelectedOwner) {
        this.getPartnersLocalizations([this.selectedBrand], newSelectedOwner, this.selectedChains, this.selectedStatuses);
        this.selectedOwners = newSelectedOwner;
    }

    public newChainSelected(newSelectedChain) {
        this.getPartnersLocalizations([this.selectedBrand], this.selectedOwners, newSelectedChain, this.selectedStatuses);
        this.selectedChains = newSelectedChain;
    }

    public newStatusSelected(newSelectedStatus) {
        this.getPartnersLocalizations([this.selectedBrand], this.selectedOwners, this.selectedChains, newSelectedStatus);
        this.selectedStatuses = newSelectedStatus;
    }

    public cleanFilter(clickEvent) {
        this.getPartnersLocalizations([this.selectedBrand], this.selectedOwners, this.selectedChains, this.selectedStatuses);
        clickEvent.stopPropagation();
    }

    public getLocalTravelModes(): Array<LocalTravelMode> {
        return Array<LocalTravelMode>(LocalTravelMode.DRIVING, LocalTravelMode.WALKING);
    }

    public hideMarkersByStatus(statuses: Array<number>, isVisible: boolean, withDrawing = true) {
        if (this.selectedBrand === this.allBrandsOptionId) {
            // TODO gdy pokazuje wszystkie to filtrowanie po solgarze, dopytać ewentualnie jak powinno działać ..
            this.hideMarkersForBrand(1, statuses, isVisible);
        } else {
            this.hideMarkersForBrand(this.selectedBrand, statuses, isVisible);
        }
        if (withDrawing) {
            this.visiblePartners = this.partnerLocalizations.filter(value => value.isMarkerVisible);
            this.drawMarkers();
        }
    }

    public canceledMarkerStatuses() {
        return [CooperationStatusMapEnum.ODMOWA_WSPOLPRACY, CooperationStatusMapEnum.ZAKONCZNONO_WSPOLPRACE];
    }

    public actualActionsMarkerStatuses() {
        return [CooperationStatusMapEnum.DO_WDROZENIA,
            CooperationStatusMapEnum.W_TRAKCIE_WDROZENIA,
            CooperationStatusMapEnum.LISTA_REZERWOWA,
            CooperationStatusMapEnum.POTENCJALNY_PARTNER];
    }

    public cooperationMarkerStatuses() {
        return [CooperationStatusMapEnum.WSPOLPRACA];
    }

    public enterCorrelationMode(partner: PartnerWithLocalizationModel, brand: BrandModel) {
        this.cleanMap();
        this.correlationMode = true;
        this.brandCorrelationService.getCorrelationsForBrandCoop(this.getBrandCooperationId(partner.partner.id, brand.id))
            .subscribe(correlations => {
                const correlatedPartners = correlations.map(correlatedPartner => new MapResultModel(correlatedPartner.name,
                    correlatedPartner.town,
                    correlatedPartner.street,
                    correlatedPartner.postalCode,
                    null,
                    correlatedPartner.latitude,
                    correlatedPartner.longitude,
                    null,
                    correlatedPartner.id));
                if (this.correlationModeData) {
                    this.correlationModeData.partnerForCorrelationMarker.setMap(null);
                }
                this.correlationModeData = new CorrelationModeData(partner,
                    this.buildMarker(partner.partner, null, this.map),
                    brand,
                    correlatedPartners);
                this.visiblePartners = correlatedPartners
                    .map(_partner => {
                        return this.partnerWithLocalizationModelFactory(_partner, this.mapService.correlationIcon, false);
                    });
                this.drawMarkers();
            });
    }

    public editCorrelations() {
        this.correlationEditMode = true;
        this.getPartnersLocalizations([this.correlationModeData.correlationBrand.id], [], [], [CooperationStatusMapEnum.WSPOLPRACA],
            () => {
                this.correlationModeData.chosenCorrelations = this.correlationModeData.chosenCorrelations.map(chosenCorrelation => {
                    const visiblePartner = this.visiblePartners.find(visible => visible.partner.id === chosenCorrelation.id);
                    visiblePartner.iconUrl = this.mapService.correlationIcon;
                    return visiblePartner.partner;
                });
                this.correlationModeData.alreadySavedCorrelations.forEach(savedCorrelation => {
                    const vPartner = this.visiblePartners.find(_vPartner => _vPartner.partner.id === savedCorrelation.id);
                    if (vPartner) {
                        vPartner.iconUrl = this.mapService.correlationIcon;
                    }
                });
                this.drawMarkers();
            });
    }

    public addOrRemoveFromCorrelations(partnerWithLocalization: PartnerWithLocalizationModel) {
        const partner = partnerWithLocalization.partner;
        if (this.correlationMode && this.correlationEditMode && partner.id !== this.correlationModeData.partnerForCorrelation.partner.id) {
            if (this.correlationModeData.alreadySavedCorrelations.find(chosenCorrelation => chosenCorrelation.id === partner.id)) {
                this.removeAlreadySavedCorrelation(partner);
            } else if (this.correlationModeData.chosenCorrelations.find(chosenCorrelation => chosenCorrelation.id === partner.id)) {
                this.removeCorrelationFromList(partner);
            } else {
                partnerWithLocalization.iconUrl = this.mapService.correlationIcon;
                this.correlationModeData.chosenCorrelations.push(partner);
            }
            this.drawMarkers();
        }
    }

    public removeAlreadySavedCorrelation(chosenCorrelation: MapResultModel) {
        this.brandCorrelationService.deleteCorrelation(chosenCorrelation.id, this.correlationModeData.partnerForCorrelation.partner.id)
            .subscribe(() => {
                this.correlationModeData.alreadySavedCorrelations = this.correlationModeData.alreadySavedCorrelations
                    .filter(value => value.id !== chosenCorrelation.id);
                this.visiblePartners
                    .find(value => value.partner.id === chosenCorrelation.id).iconUrl = this.mapService.cooperationIconUrlOn;
                this.cd.detectChanges();
            });
    }

    public removeCorrelationFromList(chosenCorrelation: MapResultModel): void {
        this.correlationModeData.chosenCorrelations = this.correlationModeData.chosenCorrelations
            .filter(value => value.id !== chosenCorrelation.id);
        const partner = this.visiblePartners.find(value => value.partner.id === chosenCorrelation.id);
        partner.iconUrl = this.mapService.cooperationIconUrlOn;
    }

    public saveCorrelations() {
        if (this.correlationMode && this.correlationEditMode) {
            const blockedId =
                this.getBrandCooperationId(this.correlationModeData.partnerForCorrelation.partner.id,
                    this.correlationModeData.correlationBrand.id);
            const blockedPartnerId = this.correlationModeData.partnerForCorrelation.partner.id;
            const model = this.correlationModeData.chosenCorrelations
                .map(blocker => new BrandCorrelationModel(blockedId,
                    blockedPartnerId,
                    this.getBrandCooperationId(blocker.id, this.correlationModeData.correlationBrand.id), blocker.id));
            this.brandCorrelationService.addCorrelations(model)
                .subscribe(() => {
                    this.enterCorrelationMode(this.correlationModeData.partnerForCorrelation, this.correlationModeData.correlationBrand);
                    this.correlationEditMode = false;
                }, () => {
                    this.correlationEditMode = false;
                });
        }
    }

    public filterOwners(event) {
        if (!this.ownersDictionary) {
            return;
        }
        let search = event;
        if (!search) {
            this.filteredOwners = this.ownersDictionary;
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredOwners = this.ownersDictionary.filter(owner => owner.value.toLowerCase().indexOf(search) > -1);
    }

    public filterChains(event) {
        if (!this.chainsDictionary) {
            return;
        }
        let search = event;
        if (!search) {
            this.filteredChains = this.chainsDictionary;
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredChains = this.chainsDictionary.filter(chain => chain.name.toLowerCase().indexOf(search) > -1);
    }

    public filterStatuses(event) {
        if (!this.statusesDictionary) {
            return;
        }
        let search = event;
        if (!search) {
            this.filteredStatuses = this.statusesDictionary;
            return;
        } else {
            search = search.toLowerCase();
        }
        this.filteredStatuses = this.statusesDictionary.filter(status => status.value.toLowerCase().indexOf(search) > -1);
    }

    public cancelCorrelationEdit() {
        this.correlationEditMode = false;
        this.enterCorrelationMode(this.correlationModeData.partnerForCorrelation, this.correlationModeData.correlationBrand);
    }

    public cancelCorrelationMode() {
        this.cleanMap();
        this.visiblePartners = [];
        this.cd.detectChanges();
        this.correlationEditMode = false;
        this.correlationMode = false;
        this.correlationModeData.partnerForCorrelationMarker.setMap(null);
        this.correlationModeData = null;
        this.getPartnersLocalizations([this.selectedBrand], this.selectedOwners, this.selectedChains, this.selectedStatuses);
    }

    private drawRoute() {
        let travelMode = TravelMode.DRIVING;
        if (this.selectedTravelMode === LocalTravelMode.WALKING) {
            travelMode = TravelMode.WALKING;
        }

        this.directionsRenderer.setMap(this.map);
        this.directionsService.route(
            {
                origin: this.routeOrigin,
                destination: this.routeDestination,
                travelMode: travelMode
            },
            (response, status) => {
                if (status === DirectionsStatus.OK) {
                    this.directionsRenderer.setDirections(response);
                }
            });
    }

    private getDictionaries(): void {
        this.dictionariesService.getPeople().subscribe(value => {
            this.ownersDictionary = value;
            this.filteredOwners = value;
        });
        this.chainsService.getListOfChains()
            .subscribe(chains => {
                this.chainsDictionary = chains;
                this.filteredChains = chains;
            });
        this.dictionariesService.getCooperationStatues()
            .subscribe(statuses => {
                this.statusesDictionary = statuses;
                this.filteredStatuses = statuses;
            });
    }

    private partnerWithLocalizationModelFactory(partner: MapResultModel,
                                                iconUrl: string,
                                                isMarkerVisible?: boolean,
                                                map?: google.maps.Map): PartnerWithLocalizationModel {
        const pwl = new PartnerWithLocalizationModel(partner, this.buildMarker(partner, iconUrl, map), iconUrl, isMarkerVisible);
        this.addClickHandlersForMarker(pwl);
        return pwl;
    }

    private buildMarker(partner: MapResultModel, iconUrl: string, map?: google.maps.Map): Marker {
        return new google.maps.Marker({
            position: new google.maps.LatLng(partner.latitude, partner.longitude),
            icon: iconUrl,
            map: map
        });
    }

    private drawMarkers() {
        const markers = this.visiblePartners.map(visiblePartner => visiblePartner.marker);
        if (this.cluster) {
            this.cluster.clearMarkers();
            this.cluster.addMarkers(markers);
        } else {
            this.cluster = new MarkerClusterer(this.map, markers,
                {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});
        }
        this.cluster.repaint();
        this.cd.detectChanges();
    }

    private addClickHandlersForMarker(visiblePartner: PartnerWithLocalizationModel) {
        visiblePartner.marker.addListener('click', () => {
            this.createInfoWindowForPartner(visiblePartner);
            visiblePartner.showInfoWindow(this.map);
            visiblePartner.addOrHideCircleToMap(this.map);
            this.addOrRemoveFromCorrelations(visiblePartner);
        });
    }

    private createInfoWindowForPartner(partner: PartnerWithLocalizationModel) {
        if (!partner.infoWindow) {
            const componentRef = this.createCustomInfoWindow(partner);
            const contentString = componentRef.location.nativeElement;
            const infoWindow = new google.maps.InfoWindow({
                content: contentString
            });
            partner.infoWindow = infoWindow;
        }
    }

    private createCustomInfoWindow(partner: PartnerWithLocalizationModel): ComponentRef<CustomInfoWindowComponent> {
        const factory = this.resolver.resolveComponentFactory(CustomInfoWindowComponent);
        const componentRef = factory.create(this.injector);
        componentRef.instance.partnerLoc = partner;
        componentRef.instance.brandsDictionary = this.brandsDictionary;
        componentRef.instance.partnerBrandCooperationsMap = this.partnerBrandCooperationsMap;
        componentRef.instance.statusesDictionary = this.statusesDictionary;
        componentRef.instance.enterCorrelationMode =
            (_partner: PartnerWithLocalizationModel, brand: BrandModel) => this.enterCorrelationMode(_partner, brand);
        componentRef.instance.setAsPartOfRoute = (partnerLoc: PartnerWithLocalizationModel) => this.setAsPartOfRoute(partnerLoc);
        componentRef.changeDetectorRef.detectChanges();
        return componentRef;
    }

    private initMap() {
        const mapProperties = {
            center: new google.maps.LatLng(this.initialLatitude, this.initialLongitude),
            zoom: this.zoom,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
        this.directionsRenderer.setMap(this.map);
    }

    /**
     * CODE BELOW IS FOR LOCAL DEVELOPMENT PURPOSES - ALLOWS TO REPLICATE MARKERS AND TEST MAP PERFORMANCE
     */
    // private replicatePartners(partnerLocalizations: MapResultModel[]): MapResultModel[] {
    //     const numberOfReplications = 800;
    //     const max = 50.2;
    //     const min = 1.1;
    //     let result: MapResultModel[] = [];
    //     for (let i = 0; i < numberOfReplications; i++) {
    //         result = result.concat(JSON.parse(JSON.stringify(partnerLocalizations)));
    //     }
    //     result.forEach(value => {
    //         value.latitude = this.getRandomIntInclusive(min, max);
    //         value.longitude = this.getRandomIntInclusive(min, max);
    //     });
    //     return result;
    // }
    // private getRandomIntInclusive(min, max) {
    //     return (max - min) * Math.random();
    // }

    private handleRedirectionFromPartner(): void {
        this.redirectionService.getRoutingState().subscribe(value => {
            if (value.hasOwnProperty('redirectionType') && value.redirectionType === REDIRECTION_PARTNER_MAP) {
                // this.redirectionPartner = this.partnerWithLocalizationModelFactory(value.value, null, true, this.map);
                this.redirectionPartner = this.visiblePartners.find(vPartner => vPartner.partner.id === value.value.id);
                if (this.redirectionPartner) {
                    this.createInfoWindowForPartner(this.redirectionPartner);
                    this.redirectionPartner.showInfoWindow(this.map);
                    this.initialLatitude = value.value.address.latitude;
                    this.initialLongitude = value.value.address.longitude;
                    this.map.setCenter(new LatLng(this.initialLatitude, this.initialLongitude));
                    this.zoom = 20;
                    this.map.setZoom(this.zoom);
                }
            }
        });
    }

    private hideMarkersForBrand(brand: number, statuses: Array<number>, isVisible: boolean) {
        if (!this.partnerBrandCooperationsMap.has(brand)) {
            return;
        }
        if (!statuses) {
            this.partnerLocalizations.forEach(value => {
                const cooperationsForSelectedBrand = this.partnerBrandCooperationsMap.get(brand);
                if (!cooperationsForSelectedBrand.has(value.partner.id)) {
                    value.isMarkerVisible = isVisible;
                }
            });
        } else {
            const cooperationsForSelectedBrand = this.partnerBrandCooperationsMap.get(brand);
            const partnersToHide = [];
            cooperationsForSelectedBrand.forEach((value, key) => {
                if (statuses.includes(value.cooperationStatusId)) {
                    partnersToHide.push(key);
                }
            });
            this.partnerLocalizations.forEach(value => {
                if (partnersToHide.includes(value.partner.id)) {
                    value.isMarkerVisible = isVisible;
                }
            });
        }
    }

    private getRouteDetails() {
        if (this.routeDestination && this.routeOrigin) {
            this.mapService.getDistanceBetweenPlaces(this.routeOrigin, this.routeDestination)
                .subscribe(value => {
                    value.forEach(routeDetails => {
                        if (routeDetails.travelMode === 'DRIVING') {
                            this.drivingRouteDetails = routeDetails;
                        } else if (routeDetails.travelMode === 'WALKING') {
                            this.walkingRouteDetails = routeDetails;
                        }
                    });
                    this.cd.detectChanges();
                });
        }
    }

    private getIcon(partnerLoc: MapResultModel): string {
        if (this.selectedBrand === this.allBrandsOptionId) {
            return this.mapService.getAllBrandsIconPath(this.partnerBrandCooperationsMap, partnerLoc.id);
        } else {
            return this.mapService.getIconForBrand(this.partnerBrandCooperationsMap.get(this.selectedBrand).has(partnerLoc.id) ?
                this.partnerBrandCooperationsMap.get(this.selectedBrand).get(partnerLoc.id) : null);
        }
    }

    private getPartnersLocalizations(brands: Array<number>,
                                     owners: Array<number>,
                                     chains: Array<number>,
                                     statuses: Array<number>,
                                     callback?: Function) {
        this.mapService.getPartnersLocalizationsByBrand(brands.includes(this.allBrandsOptionId) ? [] : brands, owners, chains, statuses)
            .subscribe(partners => {
                this.partnerLocalizations = partners.map(partner => {
                    const iconUrl = this.getIcon(partner);
                    return this.partnerWithLocalizationModelFactory(partner, iconUrl, true);
                });
                this.clusterVisible = false;
                this.cd.detectChanges();
                this.visiblePartners = this.partnerLocalizations;
                this.hideMarkers();
                this.clusterVisible = true;
                if (callback) {
                    callback();
                }
            });
    }

    private hideMarkers() {
        let statusesToFilter = [];
        if (!this.canceledMarkerActive) {
            statusesToFilter = statusesToFilter.concat(this.canceledMarkerStatuses());
        }
        if (!this.cooperationMarkerActive) {
            statusesToFilter = statusesToFilter.concat(this.cooperationMarkerStatuses());
        }
        if (!this.actualActionsMarkerActive) {
            statusesToFilter = statusesToFilter.concat(this.actualActionsMarkerStatuses());
        }
        if (!this.databaseMarkerActive) {
            this.hideMarkersByStatus(null, false, false);
        }
        this.hideMarkersByStatus(statusesToFilter, false);
    }

    private getBrandCooperationId(partnerId: number, brandId: number) {
        const cooperationsForBrand = this.partnerBrandCooperationsMap.get(brandId);
        if (!cooperationsForBrand.has(partnerId)) {
            return null;
        }
        return cooperationsForBrand.get(partnerId).id;
    }

}
