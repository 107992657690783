import { DataSerieEnum } from './../_enums/data-serie.enum';
import { BrandNameEnum } from '../_enums/brand-name.enum';
import { FinanceChosenViewEnum } from '../_enums/finance-chosen-view.enum';
import { ChartResultsResolutionEnum } from '../_enums/chart-results-resolution.enum';
import { ChipElement } from '../filter-chips/chip-component/chip.component';
import { PeriodPickerModel } from './period-picker.model';
import {ChartMarketCooperationEnum} from '../_enums/chart-market-cooperation.enum';
import {ChartResultModeEnum} from '../_enums/chart-result-mode.enum';
import {ChartSearchTypeEnum} from '../_enums/chart-search-type.enum';

export class FinancesConfigModel {
    screenConfig?: Array<number>;
    periodPicker?: PeriodPickerModel;
    series?: DataSerieEnum[];
    resolution?: ChartResultsResolutionEnum;
    chartMode?: ChartResultModeEnum;
    chosenView?: FinanceChosenViewEnum;
    filtersWithValues?: Array<ChipElement>;
    brandPicked?: BrandNameEnum;
    searchType?: ChartSearchTypeEnum;
    chartColors?: Array<string>;
}
