import { Component, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as localization from 'moment/locale/pl';
import { LocaleConfig } from 'ngx-daterangepicker-material';

import { ComparisonPeriodModel } from 'src/app/_models/comparison-period.model';
import { AnalyticsService } from './../analytics.service';
import { PeriodPicker } from 'src/app/finances/period-picker/period-picker.component';
import { Range } from './../../finances/period-picker/period-picker.component';

moment.locale('pl', localization);

@Component({
  selector: 'app-analytics-period-picker',
  templateUrl: './analytics-period-picker.component.html',
  styleUrls: ['./analytics-period-picker.component.scss']
})

export class AnalyticsPeriodPickerComponent {
  maxDate: Moment;
  years: Array<ComparisonPeriodModel> = [];
  locale: LocaleConfig = {
      applyLabel: 'Akceptuj',
      customRangeLabel: ' - ',
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
      firstDay: moment.localeData().firstDayOfWeek(),
  };
  @Output() periodPickerOutput = new EventEmitter<PeriodPicker>();

  constructor(public readonly analyticsService: AnalyticsService) {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear; i > 2000; --i) {
        this.years.push(new ComparisonPeriodModel(i - 2000));
    }
    this.years = this.years.reverse();
    this.maxDate = moment(new Date());
  }

  setPickedYearsPeriod() {
    if (this.analyticsService.periodPicker.period && this.analyticsService.resolution) {
        //this.analyticsService.generateChartData();
    }
  }

  updateDateRange(period: Range): void {
    this.analyticsService.periodPicker.period = period;
    this.setPickedYearsPeriod();
  }
}
