import { MapBrandCooperationModel } from './map-brand-cooperation.model';

export class MapResultModel {

    constructor(
        public readonly name: string,
        public readonly town: string,
        public readonly street: string,
        public readonly  postalCode: string,
        public readonly districtId: number,
        public readonly latitude: number,
        public readonly longitude: number,
        public readonly cooperations: Array<MapBrandCooperationModel>,
        public readonly id?: number) {

    }


}
