import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageSeverityEnum } from '../../_enums/message-severity.enum';
import { MessagingUtils } from '../messaging.utils';

export class ActionDialog {
    headerText: string;
    text: string;
    confirmTxt: string;
    refuseTxt: string;
    severity: MessageSeverityEnum;
    callbackFn?: () => void;
    refusedFn?: () => void
}

@Component({
    selector: 'app-action-dialog',
    templateUrl: './action-dialog.component.html',
    styleUrls: ['./action-dialog.component.scss']
})
export class ActionDialogComponent {

    constructor(private _dialogRef: MatDialogRef<ActionDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public action: ActionDialog) {
    }

    getLabel(): string {
        return MessagingUtils.getMessageSeverityLabel(this.action.severity);
    }

    getButtonColor(): string {
        return MessagingUtils.getButtonColorBySeverity(this.action.severity);
    }

    protected confirm() {
        if (this.action.callbackFn) {
            this.action.callbackFn();
        }
        this._dialogRef.close(true);
    }

    protected refuse() {
        if (this.action.refusedFn) {
            this.action.refusedFn();
        }
        this._dialogRef.close(false);

    }


}
