import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BrandCorrelationModel } from '../_models/brand-correlation.model';
import { CorrelatedPartnerModel } from '../_models/correlated-partner.model';
import { ApiUtils } from '../_utils/api.utils';

@Injectable()
export class BrandCorrelationService {

    constructor(private readonly http: HttpClient) {
    }

    public addCorrelations(model: Array<BrandCorrelationModel>): Observable<Array<BrandCorrelationModel>> {
        return this.http.post<Array<BrandCorrelationModel>>(ApiUtils.BRAND_CORRELATION_API_URL + '/bulk', model);
    }

    public getCorrelationsForBrandCoop(brandCoopId: number): Observable<Array<CorrelatedPartnerModel>> {
        return this.http.get<Array<CorrelatedPartnerModel>>(ApiUtils.BRAND_CORRELATION_API_URL + `/${brandCoopId}/partner`);
    }

    public deleteCorrelation(blockerBrandCoopId: number, blockedBrandCoopId: number): Observable<any> {
        return this.http.delete(ApiUtils.BRAND_CORRELATION_API_URL + `/${blockerBrandCoopId}/${blockedBrandCoopId}`);
    }
}
