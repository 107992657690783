import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TaskModel } from '../_models/task.model';
import { ApiUtils } from '../_utils/api.utils';
import { SearchCriteria } from '../_models/search-criteria.model';

@Injectable()
export class TaskService {


    constructor(private readonly http: HttpClient) {
    }

    public getTasks(pageParams: SearchCriteria, httpParams: HttpParams = null): Observable<any> {
        let params = SearchCriteria.getRequestParams(pageParams, httpParams);
        return this.http.get(`${ApiUtils.TASK_API_URL}`, {params});
    }

    public saveTask(task: TaskModel): Observable<any> {
        return this.http.post(`${ApiUtils.TASK_API_URL}`, task);
    }

    public updateTask(task: TaskModel): Observable<any> {
        return this.http.put(`${ApiUtils.TASK_API_URL}/${task.id}`, task);
    }

    public removeTasks(taskIdCollection: Array<number>): Observable<any> {
        const path = 'id=';
        const idsPath = taskIdCollection.join('&' + path);
        return this.http.delete<any>(`${ApiUtils.TASK_API_URL}?${path}${idsPath}`);
    }


}
