import { AuthRolesEnum, canPerformAction } from './auth-commons';

/**
 * Being able to view map means being able to enter map page.
 *
 */
export const canViewMap = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ];
    return canPerformAction(roles, hasRightsToPerformActions);
};

export const canEditMap = (roles: number[]): boolean => {
    return false;
};

export const canEditCorrelations = (roles: number[] | null): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.SYSTEM_ADMIN
    ];
    return canPerformAction(roles, hasRightsToPerformActions);
};
