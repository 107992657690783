import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PeriodPickerModel } from '../_models/period-picker.model';
import { UserConfigModel } from '../_models/user-config.model';
import { FinancesConfigModel } from '../_models/finances-config.model';
import { ChipElement } from '../filter-chips/chip-component/chip.component';
import {RangeModel} from '../_models/range.model';
import {AnalyticsConfigModel} from '../_models/analytics-config.model';
import {ChartMarketCooperationEnum} from '../_enums/chart-market-cooperation.enum';
import {LoaderInterceptorSkipHeader} from '../_helpers/loader.inteceptor';
import {ChartResultModeEnum} from '../_enums/chart-result-mode.enum';
import {ChartSearchTypeEnum} from '../_enums/chart-search-type.enum';

@Injectable()
export class UserConfigService {

    private url: string = '/api/userConfig';

    constructor(private http: HttpClient) {
    }

    public getUserConfig(): Observable<UserConfigModel> {
        return this.http.get<UserConfigModel>(this.url);
    }

    public updateUserConfig(model: UserConfigModel): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(this.url, model);
    }

    public updateTheme(theme: string): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/theme`, theme);
    }

    public updatePartnersConfigColumnToDisplay(columnToDisplay: number): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/partnersConfig/columnToDisplay`, columnToDisplay);
    }

    public updateProductsConfigColumnToDisplay(columnToDisplay: number): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/productsConfig/columnToDisplay`, columnToDisplay);
    }

    public updateCooperationType(searchType: ChartSearchTypeEnum, page: string): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/${page}/searchType`, searchType);
    }

    public updateProductsRange(rangeModel: RangeModel): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/productsRange`, rangeModel);
    }

    public updateProductsPageSize(pageSize: number) {
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        return this.http.put<UserConfigModel>(`${this.url}/productsPageSize`, pageSize, {headers: headers});
    }

    public updateChainsConfigColumnToDisplay(columnToDisplay: number): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/chainsConfig/columnToDisplay`, columnToDisplay);
    }

    public updateEventsConfigColumnToDisplay(columnToDisplay: number): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/eventsConfig/columnToDisplay`, columnToDisplay);
    }

    public updatePeriodPicker(periodPicker: PeriodPickerModel): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/periodPicker`, periodPicker);
    }

    public updateSeries(series: Array<number>): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/series`, series);
    }

    public updateResolution(resolution: string): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/resolution`, resolution);
    }

    public updateBrandPicked(brand: string): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/brandPicked`, brand);
    }

    public updateScreenConfig(screenConfig: Array<number>): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/screenConfig`, screenConfig);
    }

    public updateFinancesFiltersWithValues(filtersWithValues: Array<ChipElement>): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/financesConfig/filtersWithValues`, filtersWithValues);
    }

    public updateAnalyticsConfig(analyticsConfigModel: AnalyticsConfigModel): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/analyticsConfig`, analyticsConfigModel);
    }

    public updateTasksFiltersWithValues(filtersWithValues: Array<ChipElement>): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/tasksConfig/filtersWithValues`, filtersWithValues);
    }

    public updateChosenView(chosenView: string): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/chosenView`, chosenView);
    }

    public newUserConfig(model: UserConfigModel): Observable<UserConfigModel> {
        return this.http.post<UserConfigModel>(this.url, model);
    }

    public updateChartColors(model: FinancesConfigModel): Observable<FinancesConfigModel> {
        return this.http.post<FinancesConfigModel>(`${this.url}/chartColors`, FinancesConfigModel);
    }

    public updateResultMode(selectedChartResultModeValue: ChartResultModeEnum): Observable<UserConfigModel> {
        return this.http.put<UserConfigModel>(`${this.url}/financesConfig/chartResultMode`, selectedChartResultModeValue);
    }
}
