import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ProcuratorModel} from '../_models/procurator.model';
import {Observable} from 'rxjs/index';
import {Subject} from 'rxjs';
import {ProcuratorsService} from './procurators.service';
import {ProcuratorDetailsModel} from '../_models/procurator-details.model';
import {DialogResultEnum} from '../_enums/dialog-result.enum';
import {ProcuratorDetailsDialogComponent} from './procurator-details-dialog/procurator-details-dialog.component';
import {ProcuratorDialogModel} from './procurator-details-dialog/procurator-dialog.model';
import {MatDialog} from '@angular/material/dialog';
import {DictionariesService} from '../_services/dictionaries.service';
import {DictModel} from '../_models/dict.model';

@Component({
    selector: 'app-procurators-page',
    templateUrl: './procurators.component.html',
    styleUrls: ['./procurators.component.scss']
})
export class ProcuratorsComponent implements OnInit, OnDestroy {

    public displayedColumns: string[] = [
        //'id',
        'login',
        'name',
        'lastname',
        'position',
        'active',
        'partners',
    ];

    protected procuratorsSource: MatTableDataSource<ProcuratorModelMini> = new MatTableDataSource<ProcuratorModelMini>();
    private tempFilter: string;
    private roles: DictModel[];

    constructor(private readonly procuratorsService: ProcuratorsService,
                private readonly dictionaryServices: DictionariesService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.getProcurators().subscribe((procurators: ProcuratorDetailsModel[]) => {
            this.procuratorsSource.data = procurators.map(e => ProcuratorsComponent.mapToMini(e));
        });

        this.dictionaryServices.getProcuratorRoles().subscribe(roles => this.roles = roles);
    }

    private getProcurators() : Observable<Array<ProcuratorDetailsModel>> {
        // let proc: ProcuratorModel[] = [
        //     new ProcuratorModel(1, "a", "b", "c", "d", true, ["1", "11", "333"]),
        //     new ProcuratorModel(2, "aa", "bb", "cc", "dd", true, ["2", "22"])
        // ];
        //
        // return of(proc).pipe(delay(2000));
        return this.procuratorsService.getProcurators();
    }

    ngOnDestroy(): void {
    }

    newProcurator() {
        let model: ProcuratorDetailsModel = {
            id: null,
            login: null,
            name: null,
            lastname: null,
            role: null,
            active: false,
            partners: []
        }

        let procuratorDialogModel = new ProcuratorDialogModel();
        procuratorDialogModel.newProcurator = true;
        procuratorDialogModel.detailsModel = model;
        procuratorDialogModel.notification = new Subject<DialogResultEnum>();

        const dialogRef = this.dialog.open(ProcuratorDetailsDialogComponent,
            {
                data: procuratorDialogModel,
                maxWidth: '1400px',
                panelClass: 'details-dialog',
            }
        );

        procuratorDialogModel.notification.subscribe((result: DialogResultEnum) => {

            if (result == DialogResultEnum.SAVE) {
                this.procuratorsService.newProcurator(procuratorDialogModel.detailsModel).subscribe(() => {
                        dialogRef.close();
                        this.ngOnInit();
                    }, () => {
                    }
                );
            }

        });
    }

    openDialog(row: ProcuratorModel) {
        console.log("openDialog(" + JSON.stringify(row) + ")");
        this.procuratorsService.showProcuratorDetails(row.id, true);
    }

    private static mapToMini(procuratorModel: ProcuratorDetailsModel) : ProcuratorModelMini {
        return new ProcuratorModelMini(
            procuratorModel.id,
            procuratorModel.login,
            procuratorModel.name,
            procuratorModel.lastname,
            procuratorModel.role,
            procuratorModel.active,
            procuratorModel.partners?.length || 0
        );
    }

    applyFilter(filterValue: string) {
        this.tempFilter = filterValue;
        this.procuratorsSource.filter = filterValue.trim().toLowerCase();
    }

    getRoleName(role: number) {
        let dictModel = this.roles[role];
        return dictModel ? dictModel.value : "Brak";
    }
}

class ProcuratorModelMini {
    constructor(public id: number,
                public login: string,
                public name: string,
                public lastname: string,
                public role: number,
                public active: boolean,
                public partners: number) {
    }
}
