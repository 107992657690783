import {HttpClient, HttpParams} from '@angular/common/http';

import {Injectable} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {BrandNameEnum} from '../_enums/brand-name.enum';
import {FinanceChosenViewEnum} from '../_enums/finance-chosen-view.enum';
import {ChartResultsResolutionEnum} from '../_enums/chart-results-resolution.enum';
import {ChartModel} from '../_models/chart-model';
import {FinancialCards} from '../_models/financial-cards';
import {PlotDataModel} from '../_models/plot-data-model';
import {RecordModel} from '../_models/record-model';
import {RotatingProducts} from '../_models/rotating-products.model';
import {ScreenConfigModel} from '../_models/screen-config.model';
import {YearlyDataModel} from '../_models/yearly-data-model';
import {ChipFiltersUtils} from '../_utils/chip-filters.utils';
import {ChipElement} from '../filter-chips/chip-component/chip.component';
import {ChartNgModel, ChartUtil} from '../_utils/chart.util';
import {CardDataModel} from './details-card/card-data.model';
import {CardModel} from './details-card/card.model';
import {PeriodPicker} from './period-picker/period-picker.component';
import {FinanceRow} from './table/finance-table.component';
import {ExcelUtils} from '../_utils/excel.utils';
import {DataSerieEnum} from '../_enums/data-serie.enum';
import {ChartMarketCooperationEnum} from '../_enums/chart-market-cooperation.enum';
import {ChartResultModeEnum} from '../_enums/chart-result-mode.enum';
import {ChartSearchTypeEnum} from '../_enums/chart-search-type.enum';

const API_URL = '/api/finance';

@Injectable()
export class FinancesService {

    public financialCardsModelToNameMapper = {
        JANUARY: 'Styczeń',
        FEBRUARY: 'Luty',
        MARCH: 'Marzec',
        APRIL: 'Kwiecień',
        MAY: 'Maj',
        JUNE: 'Czerwiec',
        JULY: 'Lipiec',
        AUGUST: 'Sierpień',
        SEPTEMBER: 'Wrzesień',
        OCTOBER: 'Październik',
        NOVEMBER: 'Listopad',
        DECEMBER: 'Grudzień',
        SUM: 'Suma',
        DIFF_SUM: 'Różnica sum',
        AVERAGE: 'Średnia P/m-c',
        DIFF_AVG: 'Różnica średnich P/m-c',
        DIFF_PERCENTAGE: 'Procentowa różnica'
    };

    public financialCardsOrder = {
        JANUARY: 0,
        FEBRUARY: 1,
        MARCH: 2,
        APRIL: 3,
        MAY: 4,
        JUNE: 5,
        JULY: 6,
        AUGUST: 7,
        SEPTEMBER: 8,
        OCTOBER: 9,
        NOVEMBER: 10,
        DECEMBER: 11,
        SUM: 12,
        DIFF_SUM: 13,
        AVERAGE: 14,
        DIFF_AVG: 15,
        DIFF_PERCENTAGE: 16,
    };


    public page = 1;
    public size = 5;
    public totalRecords: number;
    public sortQueryParam: string = null;

    public configFetched = false;
    public periodPicker: PeriodPicker;
    public resolution = ChartResultsResolutionEnum.DAYS;
    public brandPicked: BrandNameEnum = BrandNameEnum.ALL;
    public chartResultMode: ChartResultModeEnum = ChartResultModeEnum.PRICE;
    public searchType: ChartSearchTypeEnum = ChartSearchTypeEnum.B2B;

    public filtersValueArray: Array<ChipElement> = [];

    public chartModel: ChartModel;
    public plotLabels: Array<string>;
    public plotDataModels: Array<PlotDataModel>;

    public chartColors: Array<string> = [];

    public totalDataChartNg: ChartNgModel;
    public incomeChartNg: ChartNgModel;
    public returningChartNg: ChartNgModel;

    public totalIncomeCards: Array<CardModel>;
    public totalReturningCards: Array<CardModel>;

    //Table fields
    public rowsDb = [];
    public tableData: MatTableDataSource<FinanceRow>;
    public chosenView = FinanceChosenViewEnum.CHART;

    //Rotating incomeProductQuantityPercentageShare fields
    public productShareDTOS: Array<RotatingProducts>;
    public seriesToDisplay: DataSerieEnum[];

    constructor(private http: HttpClient, private readonly excelUtils: ExcelUtils) {
    }

    public getPartnersPage(): Observable<any> {
        let params = new HttpParams();
        params = params.append('page', this.page.toString());
        params = params.append('size', this.size.toString());
        if (this.sortQueryParam) {
            params = params.append('sort', this.sortQueryParam);
        }
        const from = this.periodPicker.period.startDate.toDate().toLocaleDateString('en-GB');
        const to = this.periodPicker.period.endDate.toDate().toLocaleDateString('en-GB');
        params = params.append('from', from);
        params = params.append('to', to);
        params = params.append('resolution', this.resolution);
        params = params.append('resultMode', this.chartResultMode);
        params = params.append('searchType', this.searchType);
        if (this.brandPicked !== BrandNameEnum.ALL) {
            params = params.append('brandName', this.brandPicked);
        }
        if (this.periodPicker.years && this.periodPicker.years.length > 0) {
            this.periodPicker.years.forEach((year: number) => params = params.append('years', year.toString()));
        }
        if (this.filtersValueArray && this.filtersValueArray.length > 0) {
            params = ChipFiltersUtils.prepareParams(this.filtersValueArray, params);
        }
        return this.http.get<any>(API_URL, {params});
    }

    public getFinancesConfiguration(): Observable<Array<ScreenConfigModel>> {
        return this.http.get<Array<ScreenConfigModel>>(`/api/screen/?screenType=FINANCE`);
    }

    public generateIncomeCardData(tableDataModels: Array<FinancialCards>, mapper: any): Array<CardModel> {
        return this.sortFinancialCards(tableDataModels).map((tableDataModel: FinancialCards) =>
            new CardModel(mapper[tableDataModel.label],
                tableDataModel.yearlyData.map(
                    (yearlyDataModel: YearlyDataModel) =>
                        new CardDataModel(yearlyDataModel.label, yearlyDataModel.financialFields.incomeValue)
                )
            )
        );
    }

    public generateReturningCardData(tableDataModels: Array<FinancialCards>, mapper: any): Array<CardModel> {
        return this.sortFinancialCards(tableDataModels).map((tableDataModel: FinancialCards) =>
            new CardModel(mapper[tableDataModel.label],
                tableDataModel.yearlyData.map(
                    (yearlyDataModel: YearlyDataModel) =>
                        new CardDataModel(yearlyDataModel.label, yearlyDataModel.financialFields.returningValueAndPercentage)
                )
            )
        );
    }


    public generateChartData(): void {
        this.getPartnersPage().subscribe(data => {
            this.totalRecords = data.totalElements;
            this.chartModel = data.content[0];
            this.initializeTotalFinancialData();
            this.initializeLineChartModels();
            this.initializeCardsForLineCharts();
            this.initializeTableData();
            this.initializeRotatingProductTableData();
        });
    }

    private sortFinancialCards(tableDataModels: Array<FinancialCards>): Array<FinancialCards> {
        return tableDataModels.sort((a, b) => this.financialCardsOrder[a.label] - this.financialCardsOrder[b.label]);
    }

    private initializeTotalFinancialData(): void {
        this.plotLabels = this.chartModel.labels;
        this.plotDataModels = this.chartModel.totalData.plotData;
    }

    private initializeTableData(): void {
        this.chartModel.records.map((record: RecordModel) => this.rowsDb.push(['', record.client.name && record.client.name.trim(), record.client.district && record.client.district.trim(), record.client.town && record.client.town.trim()]));
        this.tableData = new MatTableDataSource(
            this.chartModel.records.map((record: RecordModel) => {
                return {
                    name: record.client.name,
                    district: record.client.district,
                    town: record.client.town,
                    street: record.client.street,
                    owner: record.client.owner,
                    plotData: record.financialSeriesAndCardsPair.plotData,
                    financialCards: record.financialSeriesAndCardsPair.financialCards,
                    rotatingProductData: record.productShareAndCardsPair.productShareDTOS,
                    rotatingCards: record.productShareAndCardsPair.productShareCards
                };
            }));
    }

    private initializeLineChartModels(): void {
        let incomeModels = _.cloneDeep(this.plotDataModels);
        incomeModels = incomeModels.filter((plotDataModel: PlotDataModel) => plotDataModel.name.includes('Obroty'));
        this.incomeChartNg = ChartUtil.generateChartModel(incomeModels, this.plotLabels, this.chartColors);


        let returningModel = _.cloneDeep(this.plotDataModels);
        returningModel = returningModel.filter((plotDataModel: PlotDataModel) => plotDataModel.name.includes('Zwroty'));
        this.returningChartNg = ChartUtil.generateChartModel(returningModel, this.plotLabels, this.chartColors);

        this.totalDataChartNg = ChartUtil.generateChartModel(this.plotDataModels, this.plotLabels, this.chartColors);

    }

    private initializeCardsForLineCharts(): void {
        this.totalIncomeCards = this.generateIncomeCardData(this.chartModel.totalData.financialCards, this.financialCardsModelToNameMapper);
        this.totalReturningCards = this.generateReturningCardData(this.chartModel.totalData.financialCards, this.financialCardsModelToNameMapper);
    }

    public generateRandomColorSet(): void {
        this.chartColors = ChartUtil.generateRandomColorSet(this.periodPicker);
        ChartUtil.setChartColorsInLocalStorage(this.chartColors);
    }

    public changeChartColors(): void {
        this.generateRandomColorSet();
        this.generateChartData();
    }

    private initializeRotatingProductTableData(): void {
        this.productShareDTOS = this.chartModel.productShareAndCardsPair.productShareDTOS;
    }

    exportToExcel(systemNameHeaders: Array<string>) {
        let params = new HttpParams();
        const from = this.periodPicker.period.startDate.toDate().toLocaleDateString('en-GB');
        const to = this.periodPicker.period.endDate.toDate().toLocaleDateString('en-GB');
        params = params.append('from', from);
        params = params.append('to', to);
        params = params.append('resolution', this.resolution);
        params = params.append('resultMode', this.chartResultMode);
        params = params.append('searchType', this.searchType);
        if (this.periodPicker.years && this.periodPicker.years.length > 0) {
            this.periodPicker.years.forEach((year: number) => params = params.append('years', year.toString()));
        }
        if (this.brandPicked !== BrandNameEnum.ALL) {
            params = params.append('brandName', this.brandPicked);
        }
        systemNameHeaders.slice(0, 9).forEach(
            column => {
                params = params.append('columns', column.toString());
            }
        );
        if (this.filtersValueArray && this.filtersValueArray.length > 0) {
            params = ChipFiltersUtils.prepareParams(this.filtersValueArray, params);
        }

        const fileName = 'finanse_' + from + '-' + to +'.xlsx';
        this.excelUtils.downloadExcelFile(
            params,
            'finance',
            'all',
            fileName);
    }
}
