import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ScreenTypeEnum } from '../_enums/screen-type.enum';
import { ChainDetailsModel } from '../_models/chain-details.model';
import { ChainPartnerTableRecord } from '../_models/chain-partner-table-record.model';
import { ChainPartnerTable } from '../_models/chain-partner-table.model';
import { ColumnElementModel } from '../_models/column-element.model';
import { ConfigModel } from '../_models/config.model';
import { ScreenConfigModel } from '../_models/screen-config.model';
import { PageParams, ScreenService } from '../_services/screen.service';
import { ApiUtils } from '../_utils/api.utils';
import { ChipFiltersUtils } from '../_utils/chip-filters.utils';
import { ChipElement } from '../filter-chips/chip-component/chip.component';
import { InitialObjectForPartnerDetails } from '../partners/partners.service';

@Injectable()
export class ChainsService {

    public columns: string[] = [
        'name',
        'brandCooperations',
        'onboardingDate',
        'owner',
        'onboarder',
        'toaAgent',
        'town',
        'street',
    ];
    public innerPage: number = 1;
    public innerSize: number = 5;
    public selectNames: Array<string> = [];
    public configurationMap: Map<string, ScreenConfigModel> = new Map<string, ScreenConfigModel>();
    public activeConfiguration: ScreenConfigModel = new ScreenConfigModel(
        null,
        null,
        null,
        null,
        new ConfigModel([], null, [], []));
    public previousConfiguration: ScreenConfigModel = null;
    public page = 1;
    public pages: number;
    public totalElements: number;
    public rowsForActiveConfiguration: Array<Array<string>> = [];
    public filtersForActiveConfiguration: Array<ChipElement> = [];
    public sortQueryParam: string = null;
    public chipSubject: Subject<ChipElement[]> = new Subject<ChipElement[]>();
    // For chains dialog multiselect feature
    public partnersMultiselectList: Array<ChainPartnerTableRecord> = [];
    public addedPartnersMultiselectList: Array<ChainPartnerTableRecord> = [];
    public selectedPartners: Array<ChainPartnerTableRecord> = [];
    public addedPartners: Array<ChainPartnerTableRecord> = [];
    public totalPages: number;
    public updateDataSubject: Subject<boolean> = new Subject<boolean>();
    public showDeleteConfirmationDialog: Subject<ChainDetailsModel> = new Subject<ChainDetailsModel>();
    private showChainDetails$: Subject<InitialObjectForPartnerDetails> = new Subject<InitialObjectForPartnerDetails>();
    private readonly SCREEN_API_URL = environment.apiUrl + '/api/screen';
    private readonly CHAIN_API_URL = environment.apiUrl + '/api/chain';
    private requestsToInitLeft: number = 0;

    constructor(public http: HttpClient, private readonly screenService: ScreenService) {
    }

    public showChainDetails(id: number, canEdit?: boolean): void {
        this.showChainDetails$.next({id: id, canEdit: !!canEdit});
    }

    public hideChainDetails(): void {
        this.showChainDetails$.next(null);
    }

    public getShowChainDetails(): Observable<InitialObjectForPartnerDetails> {
        return this.showChainDetails$.asObservable();
    }

    //Rozdzielić na backendzie
    public getScreenConfig() {
        this.requestsToInitLeft++;
        console.debug('getScreenconfig');
        return this.http.get<Array<ScreenConfigModel>>(`${this.SCREEN_API_URL}/?screenType=${ScreenTypeEnum.chains}`).pipe(
            tap((configurationList: Array<ScreenConfigModel>) => this.setInitialConfigurationOnScreen(configurationList)),
            finalize(() => this.requestsToInitLeft--)
        ).subscribe();
    }

    //Rozdzielić na backendzieł
    public getChainData() {
        this.requestsToInitLeft++;
        return this.http.get(this.CHAIN_API_URL).pipe(
            tap(data => this.setInitialData(data)),
            finalize(() => this.requestsToInitLeft--)
        ).subscribe();
    }


    public setScreenData(size: number = 15) {
        this.http.get<Array<ScreenConfigModel>>(`${this.SCREEN_API_URL}/?screenType=${ScreenTypeEnum.chains}`).pipe(
            tap((configurationList: Array<ScreenConfigModel>) => this.setInitialConfigurationOnScreen(configurationList)),
            tap(() => this.getUpdatedData(size))
        ).subscribe();
    }

    public getUpdatedData(size: number = 15, visibleColumnsOrdered: ColumnElementModel[] = this.activeConfiguration.configJson.visibleColumnsOrdered) {
        let params: HttpParams = null;
        if (this.activeConfiguration.configJson.filters && this.activeConfiguration.configJson.filters.length > 0) {
            this.filtersForActiveConfiguration = this.activeConfiguration.configJson.filters;

            params = ChipFiltersUtils.prepareParams(this.filtersForActiveConfiguration);
        }


        const pageParams: PageParams = {
            url: this.CHAIN_API_URL,
            page: this.page,
            size: size,
            columnsOrder: visibleColumnsOrdered,
            sortQueryParam: this.sortQueryParam
        };

        this.screenService.getUpdatedData(pageParams, params).pipe(
            tap(data => this.setInitialData(data))
        ).subscribe();
    }

    public setActiveConfiguration(model: ScreenConfigModel): void {
        this.activeConfiguration = Object.assign({}, model);
    }

    public setSelectNames(newNames: Array<string>): void {
        this.selectNames = newNames;
    }

    public getListOfChains(): Observable<Array<ChainDetailsModel>> {
        return this.http.get<Array<ChainDetailsModel>>('/api/chain/all');
    }

    public addChain(chainModel: ChainDetailsModel): Observable<ChainDetailsModel> {
        return this.http.post<ChainDetailsModel>(this.CHAIN_API_URL, chainModel);
    }

    public getPartnersList(filterList: ChipElement[]) {
        let params: HttpParams = ChipFiltersUtils.prepareParams(filterList);
        params = params.append('page', '1');
        params = params.append('size', '10');
        params = params.append('columns', 'name');
        params = params.append('columns', 'town');
        params = params.append('columns', 'street');
        params = params.append('columns', 'postalCode');
        params = params.append('columns', 'brandCooperations');


        return this.http.get<ChainPartnerTable>(`${ApiUtils.PARTNER_API_URL}/with/configuration`, {params})
            .pipe(
                tap(partners => {
                    this.partnersMultiselectList = partners.content;
                    this.addedPartnersMultiselectList = partners.content;
                    this.totalPages = partners.totalPages;
                })
            );
    }

    protected setInitialData(data): void {
        this.rowsForActiveConfiguration = this.screenService.setRows(data, this.activeConfiguration.configJson.visibleColumnsOrdered);

        this.chipSubject.next(this.filtersForActiveConfiguration);

        this.pages = data['totalPages'];
        this.totalElements = data['totalElements'];
        this.page = data['pageable'] ? data['pageable']['pageNumber'] : 0;
    }

    private setInitialConfigurationOnScreen(configurationList: Array<ScreenConfigModel>): void {
        this.activeConfiguration = Object.assign({}, configurationList.find(configuration => configuration.isDefault));
        this.selectNames = [];
        this.selectNames.push(this.activeConfiguration.name);
        this.configurationMap = new Map<string, ScreenConfigModel>(configurationList
            .sort((a, b) => a.name < b.name ? -1 : 1)
            .map(singleConfig => {
                if (!singleConfig.isDefault) {
                    this.selectNames.push(singleConfig.name);
                }
                return [singleConfig.name, singleConfig] as [string, ScreenConfigModel];
            }));
        this.configurationMap.set(this.activeConfiguration.name, this.activeConfiguration);
    }

}
