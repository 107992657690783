import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddressModel } from '../../../_models/address.model';
import { PartnerDetailsModel } from '../../../_models/partner-details.model';
import { PartnersService } from '../../partners.service';

import { AddPartnerDialogComponent } from '../add-partner-dialog.component';


@Component({
    selector: 'app-add-partner',
    templateUrl: './add-partner-trigger.component.html',
    styleUrls: ['./add-partner-trigger.component.scss']
})
export class AddPartnerTriggerComponent {

    constructor(private dialog: MatDialog,
                private readonly partnersService: PartnersService,
    ) {
    }

    public openDialog(): void {
        const partnerDetailsModel = new PartnerDetailsModel(null,
            null,
            new AddressModel(null, null, null, null, null, null, null, null, null),
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null);
        const dialogRef = this.dialog.open(AddPartnerDialogComponent,
            {
                data: partnerDetailsModel,
                maxWidth: '100%',
                panelClass: 'details-dialog',
            }
        );

        dialogRef.afterClosed().subscribe((result: boolean) => {

                if (!result) {
                    return;
                }

            this.partnersService.addPartner(partnerDetailsModel).subscribe(
                () => this.partnersService.getUpdatedData()
            );

            }
        );
    }

}
