export class EntrepreneurBrandCooperationModel {

    constructor(public id: number,
                public brandId: number,
                public financialId: number,
                public ownerId: number,
                public twsId: number,
                public cooperationStatusId: number,
                public inLoyaltyProgram: boolean,
                public onboardingDate?: Date,
                public firstOnboardingDate?: Date,
                public owsCooperationLastDay?: Date,
                ) {

    }
}
