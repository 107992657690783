import { AuthRolesEnum, canPerformAction } from './auth-commons';

/**
 * Editing calendar means doing any actions located on calendar page, in this case viewing and editing has same scope
 * so it sufficient to prevent anyone else from entering the calendar(note, at the moment everyone has rights to edit and view calendars).
 */
export const canEditCalendar = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canViewCalendar = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER,
        AuthRolesEnum.CHIEF_EXECUTIVE_OFFICER_DEPUTY,
        AuthRolesEnum.REGIONAL_DIRECTOR,
        AuthRolesEnum.REGIONAL_COORDINATOR,
        AuthRolesEnum.ADMINISTRATION_DEPARTMENT,
        AuthRolesEnum.TOA,
        AuthRolesEnum.TOA_MANAGER,
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canEditCalendar(roles) || canPerformAction(roles, hasRightsToPerformActions);
}
