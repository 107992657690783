import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { saveAs as importedSaveAs } from 'file-saver';
import { LoaderInterceptorSkipHeader } from '../../_helpers/loader.inteceptor';


export class UploadedFile {
    constructor(public readonly oneDriveId: string,
                public readonly name: string,
                public readonly linkId: string,
                public readonly editLink: string,
                public readonly downloadUrl: string,
                public readonly createdDateTime: string,
                public readonly lastModifiedDateTime: string,
                public readonly size: number) {
    }
}

export class UploadedFileWithSettings {
    public uploadInProgress = false;
    public successfullyUploaded = false;
    public uploadedFileResult: UploadedFile;

    constructor(public name: string) {
    }
}

const DEFAULT_DOWNLOADED_FILE_NAME = 'document';

@Injectable({
    providedIn: 'root'
})
export class FileManagementService {
    constructor(private http: HttpClient) {
    }

    public getFilesMetadataForField(fieldId: string): Observable<Array<UploadedFile>> {
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        return this.http.get<Array<UploadedFile>>(`api/files/${fieldId}`, {headers: headers});
    }

    public uploadFile(path: string, file: File, fieldId: string, successCallback: (file: UploadedFile) => void, errorCallback: (error) => void, completedCallback: () => void) {
        const formData: FormData = new FormData();
        formData.append('uploadedFile', file, file.name);
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        this.http.post<UploadedFile>(`api/files/${path}/${fieldId}`, formData, {headers: headers})
            .subscribe(
                data => successCallback(data),
                error => errorCallback(error),
                () => completedCallback()
            );
    }

    public downloadFile(file: UploadedFile): Observable<any> {
        const url = `api/files/file/${file.oneDriveId}`;
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        return this.http.get(url, {headers: headers, observe: 'response', responseType: 'blob'}).pipe(
            tap((resp: HttpResponse<Blob>) => FileManagementService.download(resp.body, file.name)),
            catchError(error => {
                return of(null);
            })
        );
    }

    deleteFile(uploadedFileResult: UploadedFile, fieldId: string): Observable<any> {
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        const url = `api/files/${fieldId}/${uploadedFileResult.oneDriveId}`;
        return this.http.delete(url, {headers: headers});
    }

    public static download(data: any, fileName: string): void {
        const blob = new Blob([data], {type: data.type});
        importedSaveAs(blob, fileName || DEFAULT_DOWNLOADED_FILE_NAME);
    }
}
