import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ChartMarketCooperationEnum} from '../../_enums/chart-market-cooperation.enum';
import {ChartCooperationModel} from '../../_models/chart-cooperation.model';
import {ChartSearchTypeEnum} from '../../_enums/chart-search-type.enum';

@Component({
    selector: 'app-cooperation-picker',
    templateUrl: './cooperation-picker.component.html',
    styleUrls: ['./cooperation-picker.component.scss'],
})
export class CooperationPickerComponent {

    cooperationControl = new FormControl(null);
    cooperations: Array<ChartCooperationModel> = [
        {value: ChartSearchTypeEnum.B2B, displayName: 'B2B'},
        {value: ChartSearchTypeEnum.B2C_WITH_ALLEGRO, displayName: 'B2C z Allegro'},
        {value: ChartSearchTypeEnum.B2C_WITHOUT_ALLEGRO, displayName: 'B2C bez Allegro'},
        {value: ChartSearchTypeEnum.ALLEGRO, displayName: 'Allegro'},
    ];

    @Input() set searchType(newValue: ChartSearchTypeEnum) {
        if (newValue !== this.cooperationControl.value) {
            this.cooperationControl.setValue(newValue&& newValue.length > 0
                ? newValue
                : [ChartSearchTypeEnum.B2B]);
        }
    }
    @Output() cooperationChange = new EventEmitter<ChartSearchTypeEnum>();

    setPickedCooperation() {
        const selectedResolution = this.cooperationControl.value;
        if (selectedResolution) {
            this.cooperationChange.emit(selectedResolution);
        }
    }
}


