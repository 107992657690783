import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageSeverityEnum } from '../../_enums/message-severity.enum';
import { ChainDetailsModel } from '../../_models/chain-details.model';
import { ChainPartnerTableRecord } from '../../_models/chain-partner-table-record.model';
import { DictModel } from '../../_models/dict.model';
import { PersonModel } from '../../_models/person.model';
import { DictionariesService } from '../../_services/dictionaries.service';
import { MessageService } from '../../_services/message.service';
import { ObjectUtils } from '../../_utils/object.utils';
import { ValidationUtils } from '../../_utils/validation.utils';
import { ChainsService } from '../chains.service';


@Component({
    selector: 'app-add-chain-dialog',
    templateUrl: './add-chain-dialog.component.html',
    styleUrls: ['./add-chain-dialog.component.scss']
})
export class AddChainDialogComponent implements OnInit {
    public disabled: boolean = true;
    public breakpoint: number;
    public peopleDict: Array<DictModel>;
    public filteredOwners: Array<DictModel> = [];
    // public toaDict: Array<DictModel>;
    public showInvalidFields: boolean = false;

    public filteredAddedPartners: Array<ChainPartnerTableRecord> = [];

    constructor(@Inject(MAT_DIALOG_DATA) public chainModel: ChainDetailsModel,
                private readonly dictService: DictionariesService,
                private dialogRef: MatDialogRef<AddChainDialogComponent>,
                private messageService: MessageService,
                private chainsService: ChainsService) {
    }

    ngOnInit(): void {
        this.breakpoint = (window.innerWidth <= 800) ? 1 : 12;
        this.dictService.getPeople().subscribe((peopleDict: Array<DictModel>) => {
            this.peopleDict = peopleDict;
            this.filteredOwners = peopleDict;
        });
        // this.dictService.getToaPeople().subscribe((toaDict: Array<DictModel>) => this.toaDict = toaDict);
        //this.chainsService.addedPartners = this.chainModel.chainModel.partners;
        this.filteredAddedPartners = this.chainsService.addedPartners;
    }

    public onResize(event): void {
        this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 12;
    }

    public selectionChange(event: ChainPartnerTableRecord[]) {
        this.chainsService.addedPartners = event;
        this.filteredAddedPartners = this.chainsService.addedPartners;
    }

    public setColspanOrReturnDefault(colspan: number): number {
        return colspan > this.breakpoint ? 1 : colspan;
    }

    public ownerChanged(owner: number) {
        this.chainModel.ownerId = owner;
    }

    public filterOwner(event) {
        if (!this.peopleDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredOwners = this.peopleDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredOwners = this.peopleDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    public submitDialog() {
        this.chainModel.partners = this.chainsService.addedPartners;
        this.showInvalidFields = false;
        if (this.isFormInvalid()) {
            this.showInvalidFields = true;
            this.messageService.setSnackbar({
                severity: MessageSeverityEnum.failure,
                durationInSecond: 5,
                text: 'Wypełnij wszystkie wymagane pola!'
            });
            return;
        }
        this.dialogRef.close(true);
    }

    public isFormInvalid(): boolean {

        if (!this.chainModel) {
            return true;
        }
        return !ValidationUtils.isChainValid(this.chainModel);
    }

    protected getPersonName(personModel: PersonModel): string {
        return [personModel.firstName, personModel.familyName].filter((ObjectUtils.nonFalsy)).join(' ');
    }

    public filterAddedPartners(event) {
        if (!this.chainsService.addedPartners) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredAddedPartners = this.chainsService.addedPartners;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredAddedPartners = this.chainsService.addedPartners.filter(
            partner => partner.name.toLowerCase().indexOf(search) > -1 ||
                partner.town.toLowerCase().indexOf(search) > -1 ||
                partner.street.toLowerCase().indexOf(search) > -1 ||
                partner.postalCode.toLowerCase().indexOf(search) > -1 ||
                partner.brandCooperations.toLowerCase().indexOf(search) > -1
        );
    }


}
