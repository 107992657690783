import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserConfigService} from '../_services/user-config.service';
import {ProductsService} from './products.service';
import {ChipElement} from '../filter-chips/chip-component/chip.component';
import {ChipFiltersUtils} from '../_utils/chip-filters.utils';
import {TableEventModel} from '../_models/table-event.model';
import {CogElementModel} from '../_models/cog-element.model';
import {ChainDetailsModel} from '../_models/chain-details.model';
import {Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {DynamicTableColumn, DynamicTableService} from '../dynamic-table/dynamic-table.service';
import {ExcelUtils} from '../_utils/excel.utils';
import {ScreenService} from '../_services/screen.service';
import {PartnerDetailsScreenService} from '../partners/partner-details-dialog/partner-details-screen.service';
import {RedirectionService} from '../_services/redirection.service';
import {AdminConfigurationTabService} from '../pages/admin-page/sub-pages/admin-configuration-tab/admin-configuration-tab.service';
import {AuthenticationService} from '../_services/authentication.service';
import {DictionariesService} from '../_services/dictionaries.service';
import {UserConfigModel} from '../_models/user-config.model';
import {ScreenConfigModel} from '../_models/screen-config.model';
import {ConfigModel} from '../_models/config.model';
import {ColumnElementModel} from '../_models/column-element.model';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DateRange} from '../inputs/date-range-picker/date-range-picker.component';
import {RangeModel} from '../_models/range.model';
import {ChartMarketCooperationEnum} from '../_enums/chart-market-cooperation.enum';
import {ChartSearchTypeEnum} from '../_enums/chart-search-type.enum';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {

    public showConfiguration: boolean = false;
    public showConfigurationManager: boolean = false;
    public showFilter: boolean = false;
    public collapsed: boolean = true;
    columnsToDisplay: number = 9;
    columnsNumbers: Array<number> = [];
    dataLoaded: boolean = false;
    public newConfigurationName: string;
    public redirectionChain: ChainDetailsModel;
    chipPresentFilters: Array<ChipElement> = null;
    chipPartnerPossibleFilters: ChipElement[] = ChipFiltersUtils.PRODUCTS_POSSIBLE_FILTERS;
    private _destroy: Subject<boolean> = new Subject<boolean>();
    private dirty: boolean = false;
    private readonly SCREEN_API_URL = environment.apiUrl + '/api/screen';
    private currentConfig: ScreenConfigModel;

    constructor(public readonly productsService: ProductsService,
                public http: HttpClient,
                private dialog: MatDialog,
                private readonly dynamicTableService: DynamicTableService,
                private readonly userConfigService: UserConfigService,
                private readonly excelUtils: ExcelUtils,
                private readonly screenService: ScreenService,
                private readonly detailsScreenService: PartnerDetailsScreenService,
                public readonly redirectionService: RedirectionService,
                public readonly adminConfigurationTabService: AdminConfigurationTabService,
                public readonly authService: AuthenticationService,
                private readonly dictionariesService: DictionariesService) {
        for (let i = 0; i < 28; ++i) {
            this.columnsNumbers.push(i);
        }


        this.userConfigService.getUserConfig().subscribe(
            (userConfig: UserConfigModel) => {
                if (userConfig && userConfig.productsConfig) {
                    this.columnsToDisplay = userConfig.productsConfig.columnToDisplay || 9;
                    this.productsService.pageSize = userConfig.productsConfig.pageSize || 100;
                }
                this.dataLoaded = true;
            },
            (error) => {
                console.log('Error on getting user config on finances.', error);
                this.dataLoaded = true;
            }
        );

    }

    ngOnInit() {
        this.productsService.getProductsConfiguration().subscribe(configurationList => {
            this.productsService.setInitialConfigurationOnScreen(configurationList);
            this.productsService.filtersValueArray = this.productsService.activeConfiguration.configJson.filters || [];
            this.chipPresentFilters = this.productsService.filtersValueArray;


            this.userConfigService.getUserConfig().subscribe(
                (userConfig: UserConfigModel) => {
                    this.productsService.periodRange = RangeModel.getDateRange(userConfig.productsConfig.range);
                    this.productsService.searchType = userConfig.productsConfig.searchType || ChartSearchTypeEnum.B2B;
                    this.productsService.generateTable();
                },
                (error) => {
                    console.log('Error on getting user config on products.', error);
                    this.productsService.generateTable();

                }
            );
        });
    }

    ngOnDestroy(): void {
        this._destroy.next(true);
        this._destroy.complete();
    }

    private _currentUserRoles: number[];

    get currentUserRoles(): number[] {
        if (!this._currentUserRoles?.length) {
            this._currentUserRoles = this.authService.getCurrentUserRoles() || [];
        }
        return this._currentUserRoles;
    }

    public cleanAndExitConfiguration(): void {
        this.cleanConfigurationAndExit();
        this.abortEditingConfiguration();
    }

    public abortEditingConfiguration(): void {
        this.showConfiguration = false;
    }

    public showInitialState(): boolean {
        return !this.showConfiguration && !this.showFilter && !this.showConfigurationManager;
    }

    public exportToExcel(): void {
        this.productsService.exportToExcel(this.getSystemNameHeaders());
    }

    updateColumnToDisplay(columnToDisplay: number) {
        this.columnsToDisplay = columnToDisplay;
        this.updateColToDisplayAmount();
        this.dynamicTableService.columnChanged.next(columnToDisplay);
        this.userConfigService.updateProductsConfigColumnToDisplay(columnToDisplay).subscribe();
        //TODO: lets talk about it Konrad
        this.updateActiveConfig();
    };

    public getHeaders(): Array<DynamicTableColumn> {
        if (!this.productsService.activeConfiguration.configJson || !this.productsService.activeConfiguration.configJson.visibleColumnsOrdered) {
            return;
        }
        return this.productsService.activeConfiguration.configJson.visibleColumnsOrdered
            .map(col => new DynamicTableColumn(col.displayName, col.sortAvailable));
    }

    public getSystemNameHeaders(): Array<string> {
        if (!this.productsService.activeConfiguration.configJson.visibleColumnsOrdered) {
            return;
        }
        return this.productsService.activeConfiguration.configJson.visibleColumnsOrdered.map(col => col.systemName);
    }

    public getRows(): Array<Array<string>> {
        if (!this.productsService.rowsForActiveConfiguration) {
            return;
        }
        return this.productsService.rowsForActiveConfiguration;
    }

    public getPages(): number {
        if (!this.productsService.pages) {
            return;
        }
        return this.productsService.pages;
    }

    public getPage(): number {
        if (!this.productsService.page) {
            return;
        }
        return this.productsService.page;
    }

    public getTotalElements(): number {
        if (!this.productsService.totalElements) {
            return;
        }
        return this.productsService.totalElements;
    }

    public tableEvent(event: TableEventModel) {
        this.productsService.page = event.page;
        this.productsService.pageSize = event.rowsPerPage;
        this.userConfigService.updateProductsPageSize(this.productsService.pageSize).subscribe();
        //TODO: Czy aktualna strona powinna być zapisana do konfiguracji? Jeśli tak to dodać, spytać Konrada
        this.productsService.prepareTableData();
    }

    public tableSortEvent(sortQueryParam: string) {
        this.productsService.sortQueryParam = sortQueryParam;
        this.productsService.page = 0;
        //TODO: Czy aktualne sortowanie powinno być zapisane do konfiguracji? Jeśli tak to dodać, spytać Konrada
        this.productsService.prepareTableData();
    }

    public assignPrevious(): void {
        if (this.dirty === false) {
            this.productsService.previousConfiguration = new ScreenConfigModel(this.productsService.activeConfiguration.id,
                this.productsService.activeConfiguration.name, this.productsService.activeConfiguration.isDefault, this.productsService.activeConfiguration.screenType, new ConfigModel(
                    Object.assign([], this.productsService.activeConfiguration.configJson.visibleColumnsOrdered),
                    this.productsService.activeConfiguration.configJson.sort,
                    Object.assign([], this.productsService.activeConfiguration.configJson.filters),
                    Object.assign([], this.productsService.activeConfiguration.configJson.cogColumnsOrdered),
                ));
            this.dirty = true;
        }
    }

    public getMaxDate() : Moment {
        return moment(new Date());
    }

    public cogUpdateTempConfiguration($event: Array<CogElementModel>, columnToDisplay: number = 9): void {
        this.productsService.activeConfiguration.configJson.visibleColumnsOrdered = this.cogElementsToVisibleColumns($event, columnToDisplay);
        this.productsService.prepareTableData($event);
    }

    public updateColToDisplayAmount(): void {
        this.cogUpdateTempConfiguration(this.productsService.activeConfiguration.configJson.cogColumnsOrdered, this.columnsToDisplay || 9);
    }

    public changeActiveConfig(configName: string): void {
        let candidateConfig = this.productsService.configurationMap.get(configName);
        this.activate(candidateConfig);
    }

    public setScreenConfigAsDefault() {
        this.productsService.activeConfiguration.isDefault = true;
        this.screenService.setDefaultScreenConfigData(this.SCREEN_API_URL, this.productsService.activeConfiguration).subscribe(data => {
            this.productsService.prepareTableData();
        });
    }

    public updateActiveConfig(): void {
        let activeConfig = this.productsService.activeConfiguration;
        this.screenService.updateScreenData(this.SCREEN_API_URL, activeConfig).subscribe(data => {
            this.productsService.configurationMap.set(data.name, data);
            this.activate(data);
        });
    }

    public addNewConfig(): void {
        if (!this.productsService.previousConfiguration) {
            this.productsService.previousConfiguration = new ScreenConfigModel(this.productsService.activeConfiguration.id,
                this.productsService.activeConfiguration.name, this.productsService.activeConfiguration.isDefault, this.productsService.activeConfiguration.screenType, new ConfigModel(
                    Object.assign([], this.productsService.activeConfiguration.configJson.visibleColumnsOrdered),
                    this.productsService.activeConfiguration.configJson.sort,
                    Object.assign([], this.productsService.activeConfiguration.configJson.filters),
                    Object.assign([], this.productsService.activeConfiguration.configJson.cogColumnsOrdered),
                ));
        }
        let activeConfig = this.productsService.activeConfiguration;
        this.dirty = false;
        activeConfig.id = null;
        activeConfig.isDefault = false;
        activeConfig.name = this.newConfigurationName;
        this.productsService.configurationMap.set(this.productsService.previousConfiguration.name, this.productsService.previousConfiguration);
        this.screenService.saveScreenData(this.SCREEN_API_URL, activeConfig).subscribe(data => {
            this.productsService.configurationMap.set(data.name, data);
            this.manipulateSelect(data.name);
            this.activate(data);
        });
        this.cleanConfigurationAndExit();

    }

    public getFilters(): Array<ChipElement> {
        return this.productsService.activeConfiguration.configJson.filters;
    }

    public chipUpdateList(chipList: ChipElement[]): void {
        console.log(chipList);
        this.chipPresentFilters = chipList;
    }

    public chipAddToList(chipElement: ChipElement): void {
        this.chipPresentFilters.push(chipElement);
    }

    public chipUpdateListAndSendRequest(chipList: ChipElement[]): void {
        this.chipUpdateList(chipList);
        this.productsService.activeConfiguration.configJson.filters = chipList;
        this.updateActiveConfig();
        this.productsService.generateTable();
    }

    public chipUpdateListAndSendRequestForRedirection(chipList: ChipElement[]): void {
        this.chipUpdateList(chipList);
        this.productsService.activeConfiguration.configJson.filters = chipList;
        this.productsService.generateTable();
    }

    private cleanConfigurationAndExit(): void {
        this.showConfigurationManager = false;
        this.newConfigurationName = null;
    }

    private manipulateSelect(name: string): void {
        let newNames: Array<string> = this.productsService.selectNames.filter((item, index) => item != name);
        newNames.unshift(name);
        this.productsService.setSelectNames(newNames);
    }

    private activate(candidateConfig: ScreenConfigModel): void {
        this.productsService.setActiveConfiguration(candidateConfig);
        this.productsService.generateTable();
    }

    private cogElementsToVisibleColumns(cogElements: Array<CogElementModel>, columnToDisplay: number): Array<ColumnElementModel> {
        return cogElements.slice(0, columnToDisplay)
            .map((cogElementModel: CogElementModel) => new ColumnElementModel(cogElementModel.systemName, cogElementModel.displayName, cogElementModel.sortAvailable));
    }

    private setRedirectionFilters() {

    }

    updatePeriod(period: DateRange) {
        this.productsService.periodRange = period;
        let startDateString = period.startDate.format('YYYY-MM-DD').toString();
        let endDateString = period.endDate.format('YYYY-MM-DD').toString();
        this.userConfigService.updateProductsRange(new RangeModel(startDateString, endDateString)).subscribe()
        this.productsService.generateTable()
    }

    handleCooperationValueChange($event: ChartSearchTypeEnum) {
        if ($event.length == 0) {
            $event = ChartSearchTypeEnum.B2B;
        }
        this.productsService.searchType = $event;
        this.userConfigService.updateCooperationType($event, "products").subscribe();
        this.productsService.generateTable()
    }
}
