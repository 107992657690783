import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ErrorModel} from '../_models/error/error.model';
import {MessageService} from '../_services/message.service';
import {ConfirmDialogModel} from './confirm-dialog-model';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public model: ConfirmDialogModel, private readonly messageService: MessageService) {
    }

    ngOnInit() {
    }

}
