import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import {EtailerModel} from '../_models/etailer.model';
import {Injectable} from '@angular/core';

@Injectable()
export class EtailerService {

    public constructor(private http: HttpClient) {
    }

    public generateNumber(brandEtailerId: number, brandCooperationId: number) : Observable<EtailerModel> {
        return this.http.post<EtailerModel>("/api/etailer/generateNumber", {brandEtailerId: brandEtailerId, brandCooperationId: brandCooperationId})
    }

    public deleteEtailer(brandEtailerId: number) : Observable<ArrayBuffer> {
        return this.http.delete<ArrayBuffer>("/api/etailer/" + brandEtailerId)
    }

    public generateEtailer(etailerId: number, etailerTitle: string, etailerSubtitle: string) : Observable<any> {
        return this.http.post<any>("/api/etailer/create/" + etailerId, {etailerTitle: etailerTitle, etailerSubtitle: etailerSubtitle})
    }

}
