import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { LoaderInterceptorSkipHeader } from '../_helpers/loader.inteceptor';
import { BrandCooperationModel } from '../_models/brand-cooperation.model';
import { RouteDetailsModel } from '../_models/route-details.model';
import { DictionariesService } from '../_services/dictionaries.service';
import { MapResultModel } from '../_models/map-result.model';
import LatLng = google.maps.LatLng;


export enum CooperationStatusMapEnum {
    POTENCJALNY_PARTNER = 4,
    DO_WDROZENIA = 5,
    W_TRAKCIE_WDROZENIA = 3,
    WSPOLPRACA = 1,
    ZAKONCZNONO_WSPOLPRACE = 7,
    LISTA_REZERWOWA = 6,
    ODMOWA_WSPOLPRACY = 2,
    DO_WERYFIKACJI = 8
}

export enum LocalTravelMode {
    DRIVING = 'Autem',
    WALKING = 'Pieszo',
}

@Injectable()
export class MapService {

    public initialLatitude = 52.229676;
    public initialLongitude = 21.012229;

    public readonly defaultBrandName = 'Solgar';

    private readonly iconsPath = 'assets/map';
    public readonly correlationIcon = this.iconsPath + '/correlation/black.png';
    public readonly databaseIconUrlOn = this.iconsPath + '/default_on.png';
    public readonly databaseIconUrlOff = this.iconsPath + '/default_off.png';
    public readonly canceledIconUrlOn = this.iconsPath + '/denial_on.png';
    public readonly canceledIconUrlOff = this.iconsPath + '/denial_off.png';
    public readonly onboardingIconUrlOn = this.iconsPath + '/has_channel_on.png';
    public readonly onboardingIconUrlOff = this.iconsPath + '/has_channel_off.png';
    public readonly cooperationIconUrlOn = this.iconsPath + '/cooperation_on.png';
    public readonly cooperationIconUrlOff = this.iconsPath + '/cooperation_off.png';
    private statusesDict;
    private readonly allBrandsSelectedIconPath = '/all';
    private readonly statusShortcuts = {
        actual: 'Actual',
        none: 'None',
        cancel: 'Cancel',
        coop: 'Coop'
    };

    private readonly iconsAllBrands = {
        solgarActualTerrNone: 'sol_akt_terr_brak.png',
        solgarActualTerrCancel: 'sol_akt_terr_odm.png',
        solgarActualTerrCoop: 'sol_akt_terr_wsp.png',
        solgarNoneTerrActual: 'sol_brak_terr_akt.png',
        solgarNoneTerrCancel: 'sol_brak_terr_odm.png',
        solgarNoneTerrCoop: 'sol_brak_terr_wspol.png',
        solgarCancelTerrActual: 'sol_odm_terr_akt.png',
        solgarCancelTerrNone: 'sol_odm_terr_brak.png',
        solgarCancelTerrCoop: 'sol_odm_terr_wsp.png',
        solgarCoopTerrActual: 'sol_wsp_terr_akt.png',
        solgarCoopTerrNone: 'sol_wsp_terr_brak.png',
        solgarCoopTerrCancel: 'sol_wsp_terr_odm.png'
    };

    constructor(public http: HttpClient, private readonly  dictionariesService: DictionariesService) {
        dictionariesService.getCooperationStatues().subscribe((data) => this.statusesDict = data);
    }

    public getIconForBrand(brandCooperation: BrandCooperationModel): string {
        if (!brandCooperation) {
            return this.databaseIconUrlOn;
        }
        if (brandCooperation.cooperationStatusId === CooperationStatusMapEnum.WSPOLPRACA) {
            return this.cooperationIconUrlOn;
        } else if (brandCooperation.cooperationStatusId === CooperationStatusMapEnum.DO_WDROZENIA ||
            brandCooperation.cooperationStatusId === CooperationStatusMapEnum.W_TRAKCIE_WDROZENIA ||
            brandCooperation.cooperationStatusId === CooperationStatusMapEnum.LISTA_REZERWOWA ||
            brandCooperation.cooperationStatusId === CooperationStatusMapEnum.POTENCJALNY_PARTNER) {
            return this.onboardingIconUrlOn;
        } else if (brandCooperation.cooperationStatusId === CooperationStatusMapEnum.ZAKONCZNONO_WSPOLPRACE ||
            brandCooperation.cooperationStatusId === CooperationStatusMapEnum.ODMOWA_WSPOLPRACY) {
            return this.canceledIconUrlOn;
        }
        return this.databaseIconUrlOn;
    }

    public getAllBrandsIconPath(partnerBrandCooperationsMap: Map<number, Map<number, BrandCooperationModel>>, partnerId: number): string {

        const solgarStatuses = partnerBrandCooperationsMap.get(1);
        const terranovaStatuses = partnerBrandCooperationsMap.get(2);

        if (!solgarStatuses.has(partnerId) && !terranovaStatuses.has(partnerId)) {
            return this.databaseIconUrlOn;
        }

        const solgarIconPath = this.getIconPath(solgarStatuses.has(partnerId) ? solgarStatuses.get(partnerId) : null);
        const terranovaIconPath = this.getIconPath(terranovaStatuses.has(partnerId) ? terranovaStatuses.get(partnerId) : null);

        if (solgarIconPath === terranovaIconPath) {
            return this.getIconForBrand(solgarStatuses.get(partnerId));
        } else {
            const iconPath = this.iconsAllBrands['solgar' + solgarIconPath + 'Terr' + terranovaIconPath];
            if (!iconPath) {
                console.error('No icon found for solgar and terranova statuses!');
                return this.databaseIconUrlOn;
            }
            return this.iconsPath + this.allBrandsSelectedIconPath + '/' +
                this.iconsAllBrands['solgar' + solgarIconPath + 'Terr' + terranovaIconPath];
        }
    }

    public getPartnersLocalizationsByBrand(brands: Array<number>,
                                           owners: Array<number>,
                                           chains: Array<number>,
                                           statuses: Array<number>): Observable<Array<MapResultModel>> {
        const brandsString = brands.join(',');
        const ownersString = owners.join(',');
        const chainsString = chains.join(',');
        return this.http.get<Array<MapResultModel>>
        (`${environment.apiUrl}/api/map/partners?brand=${brandsString}&owner=${ownersString}&chain=${chainsString}&status=${statuses}`);
    }

    public getDistanceBetweenPlaces(routeOrigin: LatLng, routeDestination: LatLng): Observable<Array<RouteDetailsModel>> {
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        return this.http.get<Array<RouteDetailsModel>>(`${environment.apiUrl}/api/map/partners/distance?originLat=${routeOrigin.lat()}&originLng=${routeOrigin.lng()}&destLat=${routeDestination.lat()}&destLng=${routeDestination.lng()}`, {
            headers
        });
    }

    private getIconPath(coop: BrandCooperationModel): string {
        if (!coop) {
            return this.statusShortcuts.none;
        }
        if (coop.cooperationStatusId === CooperationStatusMapEnum.WSPOLPRACA) {
            return this.statusShortcuts.coop;
        } else if (coop.cooperationStatusId === CooperationStatusMapEnum.DO_WDROZENIA ||
            coop.cooperationStatusId === CooperationStatusMapEnum.W_TRAKCIE_WDROZENIA ||
            coop.cooperationStatusId === CooperationStatusMapEnum.LISTA_REZERWOWA) {
            return this.statusShortcuts.actual;
        } else if (coop.cooperationStatusId === CooperationStatusMapEnum.ZAKONCZNONO_WSPOLPRACE ||
            coop.cooperationStatusId === CooperationStatusMapEnum.ODMOWA_WSPOLPRACY) {
            return this.statusShortcuts.cancel;
        } else {
            return this.statusShortcuts.none;
        }
    }

}
