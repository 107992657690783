import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageSeverityEnum } from '../../_enums/message-severity.enum';
import { AuthenticationService } from '../../_services/authentication.service';
import { MessageService } from '../../_services/message.service';
import { DialogUtils } from '../../_utils/dialog.utils';
import { AvatarService } from '../avatar-service';
import { ChangePasswordDialogComponent } from './dialog/change-password-dialog.component';

export interface Password {
    newPassword: string;
}

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
    constructor(private _snackBar: MatSnackBar,
                public dialog: MatDialog,
                private readonly avatarService: AvatarService,
                private readonly httpClient: HttpClient,
                private readonly authService: AuthenticationService,
                private readonly messageService: MessageService) {
    }

    ngOnInit(): void {
        this.openDialog();
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
            width: DialogUtils.defaultDialogWidth,
            data: {newPassword: null}
        });

        dialogRef.afterClosed().subscribe((password: string) => {
            if (password) {
                this.authService.changePasswordForCurrentUser(password).subscribe(
                    () => this.messageService.setSnackbar({
                        text: 'Hasło zmieniono pomyślnie',
                        durationInSecond: 5,
                        severity: MessageSeverityEnum.success
                    }),
                    () => this.messageService.setSnackbar({
                        text: 'Błąd podczas zmiany hasła!',
                        durationInSecond: 5,
                        severity: MessageSeverityEnum.failure
                    }),
                );
            } else {
                this.messageService.setSnackbar({
                    text: 'Hasło nie zostało zmienione',
                    durationInSecond: 5,
                    severity: MessageSeverityEnum.info
                });
            }
            this.avatarService.showChangePassword = false;

        });
    }
}
