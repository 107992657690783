import { Component, Input, OnInit } from '@angular/core';
import { FinancialCards } from '../../_models/financial-cards';
import { CardDataModel } from './card-data.model';
import { CardModel } from './card.model';


@Component({
    selector: 'app-finance-card',
    templateUrl: './finance-details-card.component.html',
    styleUrls: ['./card.component.scss'],
})
export class FinanceDetailsCardComponent implements OnInit {

    @Input() tableData: Array<FinancialCards>;
    @Input() isLastOrSecondFromTheEnd: boolean;
    @Input() isIncomeCard: boolean;

    private _cardModel: CardModel;

    get cardModel(): CardModel {
        return this._cardModel;
    }

    @Input()
    set cardModel(value: CardModel) {
        value.cardDataModels.sort((a, b) => a.year - b.year);
        this._cardModel = value;
    }

    ngOnInit(): void {
    }

    public calculateTrends(): number {
        if (!this._cardModel || !this._cardModel.cardDataModels || this._cardModel.cardDataModels.length === 0) {
            return 0;
        }
        if (this._cardModel.cardDataModels.length === 1) {
            return this.handleStringToNumber(this._cardModel.cardDataModels[0].value)
        }
        return this.handleStringToNumber(this._cardModel.cardDataModels[0].value) - this.handleStringToNumber(this._cardModel.cardDataModels[1].value);
    }

    public showDescription(value: CardDataModel): string {
        if (value.value !== null) {
            return value.value;
        } else {
            return 'Brak danych dla różnicy'
        }
    }

    public addInverseClass(): string {
        return this.isIncomeCard ? '' : 'inverse'
    }

    private handleStringToNumber(numberAsString: string): number {
        if (numberAsString.indexOf('b') > -1) {
            return 0;
        }
        let prep = numberAsString.replace(/\s/g, '').replace(',', '.');
        let numberPartOnly = prep.slice(0, prep.indexOf('z'));
        return Number(numberPartOnly);
    }

}