import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class CalendarSidebarService {

    private readonly refreshSidebar: Subject<void> = new Subject<void>();

    public getRefresh(): Observable<void> {
        return this.refreshSidebar.asObservable();
    }

    public refresh(): void {
        this.refreshSidebar.next(null);
    }


}
