export class RepresentativeModel {

    constructor(public id : number,
                public login : string,
                public name : string,
                public lastname : string,
                public active : boolean,
                public procurator : boolean) {
    }

}
