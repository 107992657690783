import { AuthRolesEnum, canPerformAction } from './auth-commons';

/**
 * Editing admin panel means doing any actions located on admin panel, in this case viewing and editing has same scope
 * so it sufficient to prevent anyone else from entering the admin panel
 */
export const canEditAdminPanel = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canPerformAction(roles, hasRightsToPerformActions);
}

export const canViewAdminPanel = (roles: number[]): boolean => {
    const hasRightsToPerformActions: AuthRolesEnum[] = [
        AuthRolesEnum.SYSTEM_ADMIN
    ]
    return canEditAdminPanel(roles) || canPerformAction(roles, hasRightsToPerformActions);
}