import {AddressModel} from './address.model';
import {Moment} from 'moment';

export class EntrepreneurDetailsModel {

    constructor(public id: number,
                public name: string,
                public nip: string,
                public regon: string,
                public krs: string,
                public spolkaCywilna: boolean,
                public address: AddressModel,
                public email: string,
                public phone: string,
                public correspondenceAddress: AddressModel,
                public correspondenceEmail: string,
                public creationDate: Date,
                public updateDate: Date,
                public notes: string,
                public owsMinNumberAccepts: number,
                public representativeHistory: Array<EntrepreneurRepresentativeHistory>,
                ) {
    }

}

export class EntrepreneurRepresentativeHistory {
    constructor(public id: number,
                public time: Moment,
                public person: string,
                public representativeName: string,
                public type: string,
                public signature: string,
                public brands: Array<EntrepreneurRepresentativeHistoryBrand>) {
    }

}

export class EntrepreneurRepresentativeHistoryBrand {
    constructor(public brand: number,
                public agreementAccepted: boolean,
                public loyaltyAccepted: boolean,
    ) {
    }
}
