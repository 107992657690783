import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BenefitActionModel, BenefitModel } from '../_models/benefit.model';

const API_URL = '/api/benefit';

@Injectable(
    {providedIn: 'root'}
)
export class BenefitService {

    constructor(private readonly _httpClient: HttpClient) {
    }

    getBenefitByPartnerId(partnerId: number): Observable<BenefitModel[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('partnerId', partnerId.toString())
        return this._httpClient.get<BenefitModel[]>(API_URL, {params});
    }

    addBenefit(benefitModel: BenefitModel): Observable<BenefitModel> {
        return this._httpClient.post<BenefitModel>(`${API_URL}`, benefitModel);
    }

    updateBenefit(benefitId: number, benefitModel: BenefitModel): Observable<BenefitModel> {
        return this._httpClient.put<BenefitModel>(`${API_URL}/${benefitId}`, benefitModel);
    }

    patchBenefit(benefitId: number, benefitActionModel: BenefitActionModel): Observable<BenefitModel> {
        return this._httpClient.patch<BenefitModel>(`${API_URL}/${benefitId}`, benefitActionModel);
    }

    deleteByBenefitId(benefitId: number): Observable<void> {
        return this._httpClient.delete<void>(`${API_URL}/${benefitId}`);
    }
}
