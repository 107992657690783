import { MessageSeverityEnum } from '../_enums/message-severity.enum';

export class MessagingUtils {
    private static severityToClass = {
        0: 'info',
        1: 'warn',
        2: 'success',
        3: 'failure',
        4: 'default'
    };

    private static severityToLabel = {
        0: 'info',
        1: 'error',
        2: 'done',
        3: 'cancel'
    };

    public static getMessageSeverityClass(severity: MessageSeverityEnum): string {
        return MessagingUtils.severityToClass[severity || MessagingUtils.severityToClass[4]];
    }

    public static getMessageSeverityLabel(severity: MessageSeverityEnum): string {
        return MessagingUtils.severityToLabel[severity || MessagingUtils.severityToLabel[0]];
    }

    static getButtonColorBySeverity(severity: MessageSeverityEnum): string {
        if (severity === 1) {
            return 'warn';
        }
        return 'primary';
    }
}
