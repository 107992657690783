import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

class OperatorClass {
    value: string;
    class: string;
}

export class FuncOrOperator {
    value: string;
    displayValue: string;
}

@Component({
    selector: 'app-sign-picker',
    templateUrl: './sign-picker.component.html',
    styleUrls: ['./sign-picker.component.scss']
})
export class SignPicker implements OnInit {
    funcOrOperator: FuncOrOperator = {value: 'EQ', displayValue: 'równe'};

    @Input() funcValue;


    @Input() operatorMode: boolean = true;

    @Output() funcOrOperatorOut: EventEmitter<string> = new EventEmitter<string>();


    operatorsString: FuncOrOperator[] = [
        {value: 'EQ', displayValue: 'równe'},
        {value: 'GT', displayValue: 'większe od'},
        {value: 'GOE', displayValue: 'większe lub równe'},
        {value: 'LT', displayValue: 'mniejsze od'},
        {value: 'LOE', displayValue: 'mniejsze lub równe'},
        {value: 'BETWEEN', displayValue: 'pomiędzy'},
    ];

    functions: FuncOrOperator[] = [
        {value: 'CONTAINS', displayValue: 'zawiera się'},
        {value: 'STARTSWITH', displayValue: 'zaczyna się'},
        {value: 'EQ', displayValue: 'równe'},
    ];

    showDrawer: boolean = false;

    constructor(private readonly eRef: ElementRef) {

    }

    @HostListener('document:click', ['$event'])
    hideOnOutsideClick(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.showDrawer = false;
        }
    }

    preventBubbling(event) {
        event.stopPropagation();
        event.preventDefault();
    }

    ngOnInit(): void {
        if (this.funcValue && this.operatorMode) {
            this.funcOrOperator = this.operatorsString.find(val => val.value === this.funcValue);
        } else if (this.funcValue) {
            this.funcOrOperator = this.functions.find(val => val.value === this.funcValue);

        }
    }

    setOperator(operator: FuncOrOperator, event) {
        this.preventBubbling(event);
        this.funcOrOperator = operator;
        this.funcOrOperatorOut.emit(this.funcOrOperator.value);
        this.showDrawer = false;
    }

    openDrawer() {
        this.showDrawer = true;
    }

    closeDrawer() {
        this.showDrawer = false;
    }

}
