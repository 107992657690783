import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-solgar-construct-logo',
    templateUrl: './solgar-construct.component.html',
    styleUrls: ['../logo.component.scss']
})
export class SolgarConstructComponent {
    @Input() height: string = '30px';
    @Input() logoColorClass: string = 'logo-color';
}
