import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { replacePolishChars } from 'src/app/_utils/table-utils';
import { MessageSeverityEnum } from '../../../../_enums/message-severity.enum';
import { DictModel } from '../../../../_models/dict.model';
import { PersonModel } from '../../../../_models/person.model';
import { AuthenticationService } from '../../../../_services/authentication.service';
import { DictionariesService } from '../../../../_services/dictionaries.service';
import { MessageService } from '../../../../_services/message.service';
import { UserService } from '../../../../_services/user.service';
import { AdminPageService } from '../../admin-page.service';
import { AdminUserTabService } from './admin-user-tab.service';
import { AdminUserTabDialogComponent } from './dialog/admin-user-tab-dialog.component';

@Component({
    selector: 'app-admin-user-tab',
    templateUrl: './admin-user-tab.component.html',
    styleUrls: ['./admin-user-tab.component.scss']
})
export class AdminUserTabComponent implements OnInit, OnDestroy {

    public displayedColumns: string[] = [
        'username',
        'firstName',
        'familyName',
        'domainRoles',
        'systemRoles',
        'email',
        'phoneNo',
        'logCount',
        'lastLoginTime',
        'star'
    ];
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    protected usersSource: MatTableDataSource<PersonModel>;
    protected dictDomainRoles: DictModel[] = [];
    protected dictSozRoles: DictModel[] = [];
    protected dictSystemRole: DictModel[] = [];
    private tempFilter: string;
    private _destroy: Subject<any> = new Subject();
    private pageSize: number = 20;
    private _currentUserId: number;

    constructor(private readonly _adminUserTabService: AdminUserTabService,
                private readonly _adminPageService: AdminPageService,
                private readonly _userService: UserService,
                private readonly _dictService: DictionariesService,
                private readonly _authenticationService: AuthenticationService,
                private _messageService: MessageService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this._currentUserId = this._authenticationService.currentUserValue.personId;
        this.initializeUsers();
        this._dictService.getDomainRoles().subscribe((dict: DictModel[]) => this.dictDomainRoles = dict);
        this._dictService.getSozRoles().subscribe((dict: DictModel[]) => this.dictSozRoles = dict);
        this._dictService.getSystemRoles().subscribe((dict: DictModel[]) => this.dictSystemRole = dict);

        this.pageSize = localStorage.getItem(`adminPageUsersPageSize${this._currentUserId}`) && JSON.parse(localStorage.getItem(`adminPageUsersPageSize${this._currentUserId}`)) || 20;
        this._adminUserTabService.refresh$.pipe(takeUntil(this._destroy)).subscribe(() => this.initializeUsers());
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    applyFilter(filterValue: string) {
        this.tempFilter = filterValue;
        this.usersSource.filter = filterValue.trim().toLowerCase();
    }

    public getPageSize(): number {
        return this.pageSize;
    }

    public setPageSize(event: PageEvent): void {
        this.pageSize = event.pageSize;
        localStorage.setItem(`adminPageUsersPageSize${this._currentUserId}`, JSON.stringify(this.pageSize));
    }

    getLastLoginTime(date: string): string {
        const options = {
            year: 'numeric', month: 'numeric', day: 'numeric',
            hour: 'numeric', minute: 'numeric', second: 'numeric',
            hour12: false,
        };
        return date && new Intl.DateTimeFormat('pl-PL', options).format(new Date(date)) || '';
    }

    protected getDomainRoles(personModel: PersonModel) {
        if (!personModel) {
            return;
        }

        if (!this.dictDomainRoles) {
            return 'Nie załadowano słownika ról';
        }
        return personModel && personModel.domainRoleIds && personModel.domainRoleIds.length > 0 && personModel.domainRoleIds
            .map((id: number) => this.dictDomainRoles.find(value => value.id === id) && this.dictDomainRoles.find(value => value.id === id).value || '')
            .join(', ') || '';
    }

    protected getSystemRoles(personModel: PersonModel) {
        if (!personModel) {
            return;
        }

        if (!this.dictSystemRole) {
            return 'Nie załadowano słownika ról';
        }
        return personModel && personModel.systemData && personModel.systemData.systemRoleIds && personModel.systemData.systemRoleIds.length > 0 && personModel.systemData.systemRoleIds
            .map((id: number) => this.dictSystemRole.find(value => value.id === id) && this.dictSystemRole.find(value => value.id === id).value || '')
            .join(', ') || '';
    }

    protected openDialog(row: PersonModel) {
        let model: PersonModel;

        if (!row) {
            model = {
                id: null,
                firstName: null,
                familyName: null,
                systemData: {
                    username: null,
                    id: null
                },
                contact: {
                    id: null,
                    phoneNo: null,
                    email: null,
                    description: null,
                },
                teamPerson: [],
                domainRoleIds: [],
                newPerson: true
            };
        } else {
            model = _.cloneDeep(row);
        }

        const dialogRef = this.dialog.open(AdminUserTabDialogComponent,
            {
                maxWidth: '100%',
                panelClass: 'details-dialog',
                data: model,
            }
        );

        dialogRef.afterClosed()
            .pipe(takeUntil(this._destroy))
            .subscribe((result: boolean) => {
                    if (!result) {
                        return;
                    }
                    if (model.newPerson) {
                        this._userService.addUser(model).pipe(
                            tap(() => this._messageService.setSnackbar({
                                    durationInSecond: 5,
                                    severity: MessageSeverityEnum.success,
                                    text: `Dodano nowego użytkownika`
                                }),
                                () => this._messageService.setSnackbar({
                                    durationInSecond: 5,
                                    severity: MessageSeverityEnum.failure,
                                    text: `Nie dodano nowego użytkownika`
                                })
                            ),
                            switchMap(() => this._userService.getAllUsers())
                        ).subscribe(this._setTableData);
                    } else {
                        this._userService.updateUsers(model).pipe(
                            tap(() => this._messageService.setSnackbar({
                                    durationInSecond: 5,
                                    severity: MessageSeverityEnum.success,
                                    text: `Dane zostały pomyślnie zmienione`
                                }),
                                () => this._messageService.setSnackbar({
                                    durationInSecond: 5,
                                    severity: MessageSeverityEnum.failure,
                                    text: `Nie udało się zmienić danych`
                                })
                            ),
                            switchMap(() => this._userService.getAllUsers())
                        ).subscribe(this._setTableData);
                    }

                }
            );

    }

    private initializeUsers() {
        this._userService.getAllUsers()
            .subscribe(this._setTableData);
    }

    private _setTableData = (users: PersonModel[]) => {
        this.usersSource = new MatTableDataSource<PersonModel>(users);
        this.usersSource.paginator = this.paginator;
        this.usersSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'username':
                    return item && item.systemData && item.systemData.username || '';
                case 'email':
                    return item && item.contact && item.contact.email || '';
                case 'phoneNo':
                    return item && item.contact && item.contact.phoneNo || '';
                case 'domainRoles':
                    return this.dictDomainRoles && item && item.domainRoleIds && item.domainRoleIds.length > 0 && item.domainRoleIds.map((id: number) => this.dictDomainRoles.find(value => value.id === id).value || '')
                        .join(', ') || '';
                case 'systemRoles':
                    return this.dictSystemRole && item && item.systemData && item.systemData.systemRoleIds && item.systemData.systemRoleIds.length > 0 &&
                        item.systemData.systemRoleIds.map((id: number) => this.dictSystemRole.find(value => value.id === id) && this.dictSystemRole.find(value => value.id === id).value || '').join(', ') || '';
                case 'firstName':
                    return item && item.firstName && replacePolishChars(item.firstName) || '';
                case 'familyName':
                    return item && item.familyName && replacePolishChars(item.familyName) || '';
                case 'logCount':
                    return item && item.systemData && item.systemData.logCount || '';
                case 'lastLoginTime':
                    return item && item.systemData && item.systemData.lastLoginTime || '';

                default:
                    return item[property];

            }
        };
        this.usersSource.sort = this.sort;
        this.usersSource.filterPredicate = (data, filter) => {
            const contactPhone = data && data.contact && data.contact.phoneNo || '';
            const contactMail = data && data.contact && data.contact.email || '';
            const username = data && data.systemData && data.systemData.username || '';
            const roles = this.dictDomainRoles && data.domainRoleIds && data.domainRoleIds.length > 0 && data.domainRoleIds.map((id: number) => this.dictDomainRoles.find(value => value.id === id).value || '')
                .join(', ') || '';
            const lastLoginCount = data && data.systemData && data.systemData.logCount || '';
            const systemRoles = this.dictSystemRole && data && data.systemData && data.systemData.systemRoleIds && data.systemData.systemRoleIds.length > 0 &&
                data.systemData.systemRoleIds.map((id: number) => this.dictSystemRole.find(value => value.id === id) && this.dictSystemRole.find(value => value.id === id).value || '').join(', ') || '';
            const options = {
                year: 'numeric', month: 'numeric', day: 'numeric',
                hour: 'numeric', minute: 'numeric', second: 'numeric',
                hour12: false,
            };

            const lastLoginTime = data && data.systemData && data.systemData.lastLoginTime && new Intl.DateTimeFormat('pl-PL', options).format(new Date(data.systemData.lastLoginTime)) || '';
            let dataStr = username + data.firstName + data.familyName + contactMail + contactPhone + username + roles + lastLoginCount + systemRoles + lastLoginTime;
            dataStr = dataStr && dataStr.toLowerCase() || '';
            filter = filter && filter.toLowerCase();

            return dataStr.indexOf(filter) != -1;
        };

        if (this.tempFilter) {
            this.applyFilter(this.tempFilter);
        }


    };

}
