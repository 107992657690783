export class ComparisonPeriodModel {
    viewValue: string;
    private PREV = 'wstecz';
    private SINGULAR_PRIM = 'rok';
    private SINGULAR_BIS = 'lata';
    private PLURAL = 'lat';

    constructor(public readonly value: number) {
        this.viewValue = `${value} ${this.getViewValueAlternation(value)} ${this.PREV}`;
    }

    private getViewValueAlternation(value: number): string {
        if (value === 1) {
            return this.SINGULAR_PRIM;
        }
        return value % 10 < 4 ? this.viewValue = this.SINGULAR_BIS : this.PLURAL;
    }

}