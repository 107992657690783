import {Component, Input, OnInit} from '@angular/core';
import {FinancesService} from '../finances.service';

@Component({
    selector: 'simple-card',
    templateUrl: './simple-card.template.html',
    styleUrls: ['./simple-card.style.scss'],
    providers: [FinancesService],
})
export class SimpleCardComponent implements OnInit {

    @Input() public name: string;
    @Input() public description: string;
    @Input() public description2: string;
    @Input() public entries: Array<string>;

    ngOnInit(): void {
    }

}
