import * as moment from 'moment';
import {DateRange} from '../inputs/date-range-picker/date-range-picker.component';

export class RangeModel {
    constructor(public startDate: string,
                public endDate: string) {
    }

    public static getDateRange(rangeModel: RangeModel): DateRange {
        return {
            startDate: moment(rangeModel.startDate),
            endDate: moment(rangeModel.endDate)
        };
    }

}
