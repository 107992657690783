export class PlainNotificationModel {
    constructor(public readonly id: number,
                public readonly personId: number,
                public readonly title: string,
                public readonly description: string,
                public readonly seen: boolean,
                public readonly dismissed: boolean,
                public readonly createdAt: string) {
    }
}
