import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileManagementService, UploadedFileWithSettings } from '../../../inputs/file-upload/file-management.service';

@Component({
    selector: 'app-benefit-attachment',
    templateUrl: './benefit-attachment.component.html',
    styleUrls: ['./benefit-attachment.component.scss']
})
export class BenefitAttachmentComponent implements OnInit {

    private readonly BENEFIT_FOLDER = 'benefit';

    @ViewChild('fileInput')
    fileInput: ElementRef;

    @Input() fieldId = 'testBenefit';
    @Input() disabled = false;

    file: UploadedFileWithSettings;

    loadingCurrentState = true;
    uploadingFile = false;
    downloadingOrRemovingFile = false;

    constructor(private readonly fileManagementService: FileManagementService, private readonly http: HttpClient) {
    }

    ngOnInit(): void {
        this.getFilesMetadataForField();
    }

    openFilesDialog() {
        this.fileInput.nativeElement.click();
    }

    onNewFiles($event: any) {
        if (!this.fieldId) {
            throw new Error('Field id is required to upload file!');
        }
        this.uploadingFile = true;
        const fileList: FileList = $event.target.files;
        if (fileList.length > 0) {
            const file: File = fileList[0];
            const fileToUpload = new UploadedFileWithSettings(file.name);
            fileToUpload.uploadInProgress = true;
            this.fileManagementService.uploadFile(this.BENEFIT_FOLDER, file, this.fieldId, (data) => {
                this.file = fileToUpload;
                fileToUpload.uploadedFileResult = data;
                fileToUpload.successfullyUploaded = true;
                this.uploadingFile = false;
            }, (error) => {
                this.file = null;
                this.uploadingFile = false;
            }, () => {
            });
        }
    }

    downloadFile(file: UploadedFileWithSettings) {
        this.downloadingOrRemovingFile = true;
        this.fileManagementService.downloadFile(file.uploadedFileResult).toPromise().then(() => {
            this.downloadingOrRemovingFile = false;
        }).catch(() => {
            this.downloadingOrRemovingFile = false;
        });
    }

    deleteFile(file: UploadedFileWithSettings) {
        this.downloadingOrRemovingFile = true;
        this.fileManagementService.deleteFile(file.uploadedFileResult, this.fieldId).toPromise().then(() => {
            this.downloadingOrRemovingFile = false;
            this.file = null;
            this.getFilesMetadataForField();
        }).catch(() => {
            this.downloadingOrRemovingFile = false;
        });
    }

    private getFilesMetadataForField() {
        this.loadingCurrentState = true;
        this.fileManagementService.getFilesMetadataForField(this.fieldId).subscribe(values => {
            if (values.length > 1) {
                console.error('Should be only one file!!!');
            }
            const files = values.map(value => {
                const fileWithSettings = new UploadedFileWithSettings(value.name);
                fileWithSettings.uploadedFileResult = value;
                fileWithSettings.successfullyUploaded = true;
                return fileWithSettings;
            });
            if (files.length > 0) {
                this.file = files[0];
            }
            this.loadingCurrentState = false;
        }, () => {
            this.loadingCurrentState = false;
        });
    }
}
