import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventApi } from '@fullcalendar/core/api/EventApi';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventModel } from '../../_models/event-model';
import { EventPersonModel } from '../../_models/event-person-model';
import { AuthenticationService } from '../../_services/authentication.service';
import { UserService } from '../../_services/user.service';
import { DialogUtils } from '../../_utils/dialog.utils';
import { CalendarScreenService } from '../calendar-screen.service';
import { CalendarSidebarService } from '../sidebar/calendar-sidebar.service';
import { EventCalendarService } from './event-calendar.service';
import { DialogResponse, OnDropDialogComponent } from './on-drop-dialog/on-drop-dialog.component';

@Component({
    selector: 'app-event-calendar',
    templateUrl: './event-calendar.component.html',
    styleUrls: ['./event-calendar.component.scss']
})
export class EventCalendarComponent implements OnInit, OnDestroy {

    currentUserId: number;
    @ViewChild('eventCalendar', {static: true}) eventCalendar: FullCalendarComponent;
    events: Array<EventModel>;
    options = {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        defaultView: 'dayGridMonth',
        defaultDate: moment(new Date()).format('YYYY-MM-DD'),
        displayEventEnd: true,
        customButtons: {
            prevExtended: {
                icon: 'fc-icon fc-icon-chevron-left',
                click: () => {
                    this.prevClick();
                }
            },
            nextExtended: {
                icon: 'fc-icon fc-icon-chevron-right',
                click: () => {
                    this.nextClick();
                }
            },
            todayExtended: {
                text: 'Dzisiaj',
                click: () => {
                    this.todayClick();
                }
            },
            monthExtended: {
                text: 'Miesiąc',
                click: () => {
                    this.monthClick();
                }
            },
            weekExtended: {
                text: 'Tydzień',
                click: () => {
                    this.weekClick();
                }
            },
            dayExtended: {
                text: 'Dzień',
                click: () => {
                    this.dayClick();
                }
            }
        },
        header: {
            left: 'todayExtended prevExtended,nextExtended',
            center: 'title',
            right: 'monthExtended,weekExtended,dayExtended'
        },
        height: 750,
        aspectRatio: 1, // this doesnt work either,
        editable: true,
        locale: 'pl',
        displayEventTime: true,
        timeFormat: '' +
            'H(:mm)',
        eventTimeFormat: {
            hour: '2-digit',
            minute: '2-digit'
        },
        selectable: true,
    };
    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(public readonly eventCalendarService: EventCalendarService,
                private readonly personService: UserService,
                private _dialog: MatDialog,
                private readonly calendarScreenService: CalendarScreenService,
                private readonly authService: AuthenticationService,
                private readonly calendarSidebarService: CalendarSidebarService) {
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    ngOnInit() {
        this.currentUserId = this.authService.currentUserValue.personId;
        this.setEventsForCurrentView();
        this.calendarScreenService.refreshCalendar
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((shouldRefresh: boolean) => {
                shouldRefresh && this.setEventsForCurrentView();
                this.calendarSidebarService.refresh();
            });
    }

    public setEventsForCurrentView(): void {
        setTimeout(
            () =>
                this.calendarScreenService.getEventsByParams(
                    this.currentUserId,
                    moment(this.eventCalendar.getApi().view.activeStart),
                    moment(this.eventCalendar.getApi().view.activeEnd)).subscribe((events: Array<EventModel>) =>
                    this.events = events.map((eventModel: EventModel) => {
                        const accepted = this.hasCurrentUserAccepted(eventModel);
                        eventModel.extendedProps.acceptedByCurrentUser = accepted;
                        eventModel.backgroundColor = accepted && eventModel.backgroundColor || 'rgba(255,0,0,0.2)';
                        eventModel.borderColor = accepted && eventModel.backgroundColor || 'rgba(255,0,0,0.2)';
                        return eventModel;
                    })
                ), 0
        );
    }

    public nextClick() {
        this.eventCalendar.getApi().next();
        this.setEventsForCurrentView();
    }

    public prevClick() {
        this.eventCalendar.getApi().prev();
        this.setEventsForCurrentView();
    };

    public todayClick() {
        this.eventCalendar.getApi().today();
        this.setEventsForCurrentView();
    };

    public dayClick() {
        this.eventCalendar.getApi().changeView('dayGridDay');
        this.calendarScreenService.currentView = 'dayGridDay';
        this.setEventsForCurrentView();
    };

    public weekClick() {
        this.eventCalendar.getApi().changeView('dayGridWeek');
        this.calendarScreenService.currentView = 'dayGridDay';
        this.setEventsForCurrentView();
    };

    public monthClick() {
        this.eventCalendar.getApi().changeView('dayGridMonth');
        this.calendarScreenService.currentView = 'dayGridMonth';
        this.setEventsForCurrentView();
    };

    public dateClick(event: any) {
        const newEvent: EventModel = {
            start: moment(event.date),
            end: moment(event.date),
            refreshCalendar: true
        };

        // Prevents spontaneous double click which causes modal to hide immediately on touch screen;
        setTimeout(() => {
            this.calendarScreenService.setShowAddDialog$(newEvent)
        }, 0);
    }

    public selectRange(event: any) {
        const touchEvent: TouchEvent = (event.jsEvent as TouchEvent);
        touchEvent.stopPropagation();
        touchEvent.preventDefault();
        const startOfAnEvent = moment(event.start);
        let endOfAnEvent = moment(event.end);
        if (endOfAnEvent.diff(startOfAnEvent, 'days') === 1) {
            endOfAnEvent = startOfAnEvent;
        }
        const newEvent: EventModel = {
            start: startOfAnEvent,
            end: endOfAnEvent,
            refreshCalendar: true
        };

        setTimeout(() => {
            this.calendarScreenService.setShowAddDialog$(newEvent)
        }, 0);
    }

    //https://fullcalendar.io/docs/eventDrop
    public eventDrop(eventDrop) {
        const event: EventApi = eventDrop.event;
        if (!event || !event.extendedProps || event.extendedProps.hostId !== this.currentUserId) {
            eventDrop.revert();
        } else {
            this.openMoveOrCopyDialog(event, eventDrop);
        }
    }

    public eventOnResize(eventResize) {
        const event: EventApi = eventResize.event;
        if (!event || !event.extendedProps || event.extendedProps.hostId !== this.currentUserId) {
            eventResize.revert();
        } else {
            this.calendarScreenService.patchOnDrop(event).subscribe(() => this.calendarSidebarService.refresh());
        }

    }

    public eventClick(event: EventApi) {
        const eventStart = moment(event.start);
        const eventEnd = event.end && moment(event.end) || eventStart;

        this.calendarScreenService.getEventDescription(event.id).subscribe(description => {
            let extendedProps = _.cloneDeep(event.extendedProps);
            extendedProps.description = description;

            let eventModel: EventModel = {
                id: +event.id,
                title: event.title,
                host: event.extendedProps.host,
                start: eventStart,
                end: eventEnd,
                extendedProps: extendedProps,
                backgroundColor: event.backgroundColor || null,
                borderColor: event.borderColor || null,
            };

            eventModel.refreshCalendar = true;

            this.calendarScreenService.setShowEditDialog$(eventModel);
        });
    }

    private openMoveOrCopyDialog(event: EventApi, eventDrop) {
        const dialogRef = this._dialog.open(OnDropDialogComponent, {
            width: DialogUtils.defaultDialogWidth
        });

        dialogRef.afterClosed()
            .subscribe((response: DialogResponse) => {
                if (response === 'copy') {
                    eventDrop.revert();
                    this.calendarScreenService.copyOnDrop(event, this.currentUserId).subscribe(() => {
                        this.calendarScreenService.refreshCalendar.next(true);
                        this.calendarSidebarService.refresh();
                    });
                } else if (response === 'move') {
                    this.calendarScreenService.patchOnDrop(event).subscribe(() => this.calendarSidebarService.refresh());
                } else {
                    eventDrop.revert();
                }
            })
    }

    private hasCurrentUserAccepted(eventModel: EventModel): boolean {
        if (eventModel.hostId === this.currentUserId) {
            return true;
        }
        const currentEventPersonModel: EventPersonModel = eventModel.extendedProps.guests.find((personModel: EventPersonModel) => personModel.personId === this.currentUserId);

        if (!currentEventPersonModel) {
            return true;
        }

        return !!currentEventPersonModel.accepted;

    }


}
