import {MapResultModel} from './map-result.model';
import Marker = google.maps.Marker;
import InfoWindow = google.maps.InfoWindow;

export class PartnerWithLocalizationModel {

    private circle: google.maps.Circle;

    constructor(public readonly partner: MapResultModel,
                public readonly marker: Marker,
                private _iconUrl: string,
                public isMarkerVisible = true) {
    }

    private _infoWindow: InfoWindow;

    set infoWindow(infoWindow: InfoWindow) {
        if (this._infoWindow) {
            this._infoWindow.close();
        }
        this._infoWindow = infoWindow;
    }

    set iconUrl(iconUrl: string) {
        this._iconUrl = iconUrl;
        this.marker.setIcon(iconUrl);
    }

    public showInfoWindow(map: google.maps.Map) {
        this._infoWindow.open(map, this.marker);
    }

    public hideCircle() {
        if (this.circle) {
            this.circle.setVisible(false);
        }
    }

    public addOrHideCircleToMap(map: google.maps.Map) {
        if (this.circle) {
            this.circle.setVisible(!this.circle.getVisible());
        } else {
            this.circle = new google.maps.Circle({
                strokeColor: 'black',
                strokeOpacity: 1,
                strokeWeight: 0.5,
                fillOpacity: 0,
                map: map,
                center: {lat: this.partner.latitude, lng: this.partner.longitude},
                radius: 500
            });
        }
    }
}
