import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ScreenTypeEnum } from '../_enums/screen-type.enum';
import { ColumnElementModel } from '../_models/column-element.model';
import { ConfigModel } from '../_models/config.model';
import { ScreenConfigModel } from '../_models/screen-config.model';
import { ScreenService } from '../_services/screen.service';
import { ChipFiltersUtils } from '../_utils/chip-filters.utils';
import { ChipElement } from '../filter-chips/chip-component/chip.component';
import { EventSearchCriteria } from './event-search-criteria';

@Injectable()
export class EventsService {

    public columns: string[] = [
        'name',
        'brandCooperations',
        'onboardingDate',
        'owner',
        'onboarder',
        'toaAgent',
        'town',
        'street',
    ];

    public selectNames: Array<string> = [];
    public configurationMap: Map<string, ScreenConfigModel> = new Map<string, ScreenConfigModel>();
    public activeConfiguration: ScreenConfigModel = new ScreenConfigModel(
        null,
        null,
        null,
        null,
        new ConfigModel([], null, [], []));
    public previousConfiguration: ScreenConfigModel = null;
    public page = 1;
    public pages: number;
    public totalElements: number;
    public rowsForActiveConfiguration: Array<Array<string>> = [];
    public filtersForActiveConfiguration = new Map<ScreenTypeEnum, Array<ChipElement>>([
        [ScreenTypeEnum.events, []],
        [ScreenTypeEnum.events_partner, []]
    ]);

    public sortQueryParam: string = null;
    public chipSubjectMap = new Map([
        [ScreenTypeEnum.events, new Subject<ChipElement[]>()],
        [ScreenTypeEnum.events_partner, new Subject<ChipElement[]>()]
    ]);
    public readonly refreshData: Subject<boolean> = new Subject<boolean>();
    private readonly EVENTS_API_URL = environment.apiUrl + '/api/event';
    private readonly SCREEN_API_URL = environment.apiUrl + '/api/screen';

    constructor(public http: HttpClient, private readonly screenService: ScreenService) {
    }

    public setScreenData(screenType: ScreenTypeEnum.events | ScreenTypeEnum.events_partner, partnerId: string = null, size: number = 15) {
        this.http.get<Array<ScreenConfigModel>>(`${this.SCREEN_API_URL}/?screenType=${screenType}`).pipe(
            tap((configurationList: Array<ScreenConfigModel>) => this.setInitialConfigurationOnScreen(configurationList)),
            tap(() => this.getUpdatedData(screenType, size, partnerId))
        ).subscribe();
    }

    public getUpdatedData(screenType: ScreenTypeEnum.events | ScreenTypeEnum.events_partner, size: number = 15, partnerId: string = null, visibleColumnsOrdered: ColumnElementModel[] = this.activeConfiguration.configJson.visibleColumnsOrdered) {
        const criteria = new EventSearchCriteria(this.page, size, visibleColumnsOrdered, this.sortQueryParam);
        this.getEventsWithConfiguration(criteria, screenType, partnerId);
    }

    public getEventsWithConfiguration(criteria: EventSearchCriteria, screenType: ScreenTypeEnum.events | ScreenTypeEnum.events_partner, partnerId?: string) {
        let params = this.getRequestParams(criteria, screenType);
        if (partnerId) {
            params = params.append('partnerIds', partnerId);
        }
        this.http.get(`${this.EVENTS_API_URL}/with/configuration`, {params}).subscribe(value => this.setInitialData(value, screenType));
    }

    private getRequestParams(pageParams: EventSearchCriteria, screenType: ScreenTypeEnum.events_partner | ScreenTypeEnum.events, httpParams: HttpParams = null): HttpParams {
        let params = httpParams || new HttpParams();
        if (this.activeConfiguration.configJson.filters && this.activeConfiguration.configJson.filters.length > 0) {
            this.filtersForActiveConfiguration.set(screenType, this.activeConfiguration.configJson.filters);

            params = ChipFiltersUtils.prepareParams(this.filtersForActiveConfiguration.get(screenType));
        }
        params = params.append('page', pageParams.page && pageParams.page.toString() || '1');
        params = params.append('size', pageParams.size.toString());
        if (pageParams.sort) {
            params = params.append('sort', pageParams.sort);
        }
        params = params.append('columns', 'id');
        pageParams.columns.forEach(
            column => {
                params = params.append('columns', column.systemName);
            }
        );

        return params;
    }


    protected setInitialData(data, screenType: ScreenTypeEnum.events | ScreenTypeEnum.events_partner): void {
        this.rowsForActiveConfiguration = this.screenService.setRows(data, this.activeConfiguration.configJson.visibleColumnsOrdered);

        this.chipSubjectMap.get(screenType).next(this.filtersForActiveConfiguration.get(screenType));

        this.pages = data['totalPages'];
        this.totalElements = data['totalElements'];
        this.page = data['pageable'] ? data['pageable']['pageNumber'] : 0;
    }

    public setActiveConfiguration(model: ScreenConfigModel): void {
        this.activeConfiguration = Object.assign({}, model);
    }

    public setSelectNames(newNames: Array<string>): void {
        this.selectNames = newNames;
    }

    private setInitialConfigurationOnScreen(configurationList: Array<ScreenConfigModel>): void {
        this.activeConfiguration = Object.assign({}, configurationList.find(configuration => configuration.isDefault));
        this.selectNames = [];
        this.selectNames.push(this.activeConfiguration.name);
        this.configurationMap = new Map<string, ScreenConfigModel>(configurationList
            .sort((a, b) => a.name < b.name ? -1 : 1)
            .map(singleConfig => {
                if (!singleConfig.isDefault) {
                    this.selectNames.push(singleConfig.name);
                }
                return [singleConfig.name, singleConfig] as [string, ScreenConfigModel];
            }));
        this.configurationMap.set(this.activeConfiguration.name, this.activeConfiguration);
    }

}
