import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageSeverityEnum } from '../../../../../_enums/message-severity.enum';
import { ConsentModel } from '../../../../../_models/consent.model';
import { DictModel } from '../../../../../_models/dict.model';
import { AuthenticationService } from '../../../../../_services/authentication.service';
import { DictionariesService } from '../../../../../_services/dictionaries.service';
import { MessageService } from '../../../../../_services/message.service';
import { ConfirmRemovingConsentDialogComponent } from '../confirm-removing/confirm-removing-consent-dialog.component';
import { ConsentService } from '../consent.service';


@Component({
    selector: 'app-admin-consent-tab-dialog',
    templateUrl: './admin-consent-tab-dialog.component.html',
    styleUrls: ['./admin-consent-tab-dialog.component.scss']
})
export class AdminConsentTabDialogComponent implements OnInit, OnDestroy {
    public filteredConsents: Array<DictModel> = [];
    public filteredBrands: Array<DictModel> = [];
    private _consentTypesDict: DictModel[];
    private _brandsDict: DictModel[];
    private _destroy: Subject<void> = new Subject<void>();

    constructor(@Inject(MAT_DIALOG_DATA) public consentModel: ConsentModel,
                private readonly dialog: MatDialog,
                private readonly dictService: DictionariesService,
                private dialogRef: MatDialogRef<AdminConsentTabDialogComponent>,
                private messageService: MessageService,
                private readonly consentService: ConsentService,
                private readonly _authService: AuthenticationService) {
    }

    getTitle() {
        if (this.consentModel.consentReadonly) {
            return 'Podgląd zgody';
        }
        if (this.consentModel.newConsent) {
            return 'Stwórz nową zgodę';
        }
        if (this.consentModel.active) {
            return 'Edytuj aktywną zgode';
        }
        return 'Edytuj zgodę';
    }

    ngOnDestroy(): void {
        this._destroy.next();
        this._destroy.complete();
    }

    ngOnInit(): void {
        combineLatest([this.dictService.getConsentTypes(), this.dictService.getBrandDictionary()])
            .pipe(takeUntil(this._destroy))
            .subscribe(([consentTypes, brands]: [DictModel[], DictModel[]]) => {
                this._consentTypesDict = consentTypes;
                this._brandsDict = brands;
                this.filteredConsents = consentTypes;
                this.filteredBrands = brands;
            });

    }

    filterConsents(event: string) {
        if (!this._consentTypesDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredConsents = this._consentTypesDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredConsents = this._consentTypesDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }

    filterBrands(event: string) {
        if (!this._brandsDict) {
            return;
        }

        let search = event;
        if (!search) {
            this.filteredBrands = this._brandsDict;
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredBrands = this._brandsDict.filter(user => user.value.toLowerCase().indexOf(search) > -1);
    }


    public submitDialog(userForm: NgForm) {
        if (userForm.valid) {
            this.dialogRef.close(true);
        } else {
            this.messageService.setSnackbar({
                durationInSecond: 5,
                severity: MessageSeverityEnum.failure,
                text: `Wypełnij wszystkie wymagane pola!`
            });
        }
    }

    delete() {
        const dialogRef = this.dialog.open(ConfirmRemovingConsentDialogComponent,
            {
                maxWidth: '100%',
            }
        );

        dialogRef.afterClosed()
            .subscribe((result: boolean) => {
                    if (result) {
                        this.consentService.deleteConsent(this.consentModel.id)
                            .subscribe(() => {
                                    this.consentService.refreshConsents$.next();
                                    this.dialogRef.close(false);
                                }
                            );
                    }
                }
            );
    }

}
