import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const API_URL = '/api/finance';

@Injectable()
export class ChipListService {

    constructor(private readonly httpClient: HttpClient) {
    }

}
