import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChipElement, ChipValue } from '../../chip-component/chip.component';


@Component({
    selector: 'app-filter-list',
    templateUrl: './filter-list.component.html',
    styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent {

    @Input() staticFilters: Array<ChipElement> = [];
    @Input() presentFilters: Array<ChipElement> = [];
    @Input() bottomDividerVisible = true;
    @Output() updatedList: EventEmitter<ChipElement[]> = new EventEmitter<ChipElement[]>();
    @Output() updatedListWithValue: EventEmitter<ChipElement[]> = new EventEmitter<ChipElement[]>();

    constructor() {
    }

    clearChips() {
        this.presentFilters.forEach(presentFilter => {
           presentFilter.chipValue.value = [];
        });
        this.presentFilters.length = 0;
        this.updatedListWithValue.emit(this.presentFilters);
        this.updatedList.emit(this.presentFilters.filter((chipElement: ChipElement) => chipElement.chipValue));
    }

    updateValue(chipValue: ChipValue) {
        //[...this.presentFilters, ...this.staticFilters].find((chipElement: ChipElement) => chipElement.chipDescription.name === chipValue.chipName).chipValue = chipValue;
        this.presentFilters.find((chipElement: ChipElement) => chipElement.chipDescription.name === chipValue.chipName).chipValue = chipValue;
        this.updatedListWithValue.emit(this.presentFilters);
    }

    removeFilter(chipName: string): void {
        let hadValue = false;
        const index = this.presentFilters.findIndex((chipEl: ChipElement) => chipEl.chipDescription.name === chipName);
        if (index >= 0) {
            hadValue = !!this.presentFilters[index].chipValue;
            this.presentFilters.splice(index, 1);
        }
        if (hadValue) {
            this.updatedListWithValue.emit(this.presentFilters);
        } else {
            this.updatedList.emit(this.presentFilters);
        }
    }

}
