import {Component, Input} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {LoaderInterceptorSkipHeader} from '../../../../_helpers/loader.inteceptor';
import {catchError, tap} from 'rxjs/operators';
import {FileManagementService, UploadedFile} from '../../../../inputs/file-upload/file-management.service';
import {environment} from '../../../../../environments/environment';
import {RepresentativeModel} from '../../../../_models/representative.model';

const REPRESENTATIVE_API_URL = environment.apiUrl + '/api/representative';

@Component({
    selector: 'app-representative-file',
    templateUrl: './representative-file.component.html',
    styleUrls: ['representative-file.component.scss']
})
export class RepresentativeFileComponent {

    @Input()
    representative: RepresentativeModel;
    @Input()
    filename: string;

    constructor(private http: HttpClient) {
    }

    download() {
        this.downloadFile(this.filename).subscribe(() => {

        });
    }

    public downloadFile(filename: string): Observable<any> {
        const url = `${REPRESENTATIVE_API_URL}/${this.representative.id}/files/${filename}`;
        const headers = new HttpHeaders().set(LoaderInterceptorSkipHeader, '');
        return this.http.get(url, {headers: headers, observe: 'response', responseType: 'blob'}).pipe(
            tap((resp: HttpResponse<Blob>) => FileManagementService.download(resp.body, filename)),
            catchError(error => {
                return of(null);
            })
        );
    }
}
